import { FloWalletData, NetworkLoyaltyType } from "./checkout";

export enum RewardsMode {
  LOYALTY = "LOYALTY",
  REWARDS_WALLET = "REWARDS_WALLET",
  NONE = "NONE",
}

export enum RewardsPayloadType {
  LOYALTY = "LOYALTY",
  REWARDS_WALLET = "WALLET",
}

export interface UseRewardsReturn {
  state: {
    isTileLoading: boolean;
    error: any;
    mode: RewardsMode;
    rewardsConfig: RewardsConfigType;
  };
  actions: {
    removeRewards: (payload: RewardsRemovePayload) => Promise<any>;
    updateRewards: (payload: RewardsUpdatePayload) => Promise<any>;
    setSelectedEmail: (email: string) => void;
  };
}

export interface RewardsWalletData {
  floWalletData: FloWalletData;
  isWalletCreated: boolean;
}

export type RewardsConfigType =
  | { mode: RewardsMode.LOYALTY; data: NetworkLoyaltyType }
  | { mode: RewardsMode.REWARDS_WALLET; data: RewardsWalletData }
  | { mode: RewardsMode.NONE; data: {} };

export type RewardsUpdatePayload = {
  redemption_id: string;
  rewards_type: RewardsPayloadType;
  email?: string;
  amount?: number;
};

export type RewardsRemovePayload = {
  rewards_type: RewardsPayloadType;
};
