import React, { Suspense, useEffect, useMemo, useState } from "react";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import MethodsContainer from "components/payments/methods/MethodsContainer";
import GiftCard from "components/payments/methods/GiftCard";
import ShopfiyGiftCard from "components/payments/methods/ShopifyGiftCard";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import DividerWithLabel from "components/common/DividerWithLabel";
import { useLocale } from "lib/hooks/useLocale";
import Gyftr from "../Gyftr";
import { UPICollect } from "./upi/CollectAndIntent";

const PaymentMethods: React.FC<{}> = ({}) => {
  const { t } = useLocale();
  const {
    state: { paymentMethods },
    actions: { handleNonPaymentOrderPlacement },
  } = usePaymentContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const [availableMethods, setAvailableMethods] = useState<any[]>([]);
  const [unAvailableMethods, setUnAvailableMethods] = useState<any[]>([]);

  useEffect(() => {
    if (!paymentMethods) return;

    const { available, unavailable } = paymentMethods.reduce(
      (acc, method) => {
        if (
          method.mode === "GC" ||
          method.mode === "LOYALTY" ||
          method.mode === "REWARDS_WALLET" ||
          method.mode === "FREE" ||
          method.mode === "SHOPIFY_GC" ||
          method.mode === "GYFTR"
        ) {
          // ignore the above methods we handle them in their own components
          return acc;
        } else if (method.is_available) {
          acc.available.push(method);
        } else {
          acc.unavailable.push(method);
        }
        return acc;
      },
      { available: [], unavailable: [] },
    );

    setAvailableMethods(available);
    setUnAvailableMethods(unavailable);
  }, [paymentMethods]);

  const hasFreeMethod = paymentMethods?.some((e: any) => e.mode === "FREE");
  const freeMethodEnabled = paymentMethods?.find((e: any) => e.mode === "FREE")?.is_available;
  const hasGCMode = paymentMethods?.find((e: any) => e.mode === "GC");
  const hasShopifyGCMode = paymentMethods?.find((e: any) => e.mode === "SHOPIFY_GC");
  const hasGyftrMode = paymentMethods?.find((e: any) => e.mode === "GYFTR");

  // handle upi separately
  const hasUpiIntentMode = paymentMethods?.find((e: any) => e.mode === "UPI_INTENT");
  const isUpiIntentModeAvailable = hasUpiIntentMode?.is_available;

  const shouldShowUPIIntentWithIconsTile = useMemo(() => {
    return merchant?.upiTileWithIcons && isUpiIntentModeAvailable;
  }, [merchant, isUpiIntentModeAvailable]);

  const modifiedAvailableMethods = useMemo(() => {
    if (!shouldShowUPIIntentWithIconsTile) {
      return availableMethods;
    }
    return availableMethods.filter((method) => method.mode !== "UPI_INTENT");
  }, [availableMethods, shouldShowUPIIntentWithIconsTile]);

  const modifiedUnAvailableMethods = useMemo(() => {
    if (!shouldShowUPIIntentWithIconsTile) {
      return unAvailableMethods;
    }
    return unAvailableMethods.filter((method) => method.mode !== "UPI_INTENT");
  }, [unAvailableMethods, shouldShowUPIIntentWithIconsTile]);

  const shouldShowGyftrMode = useMemo(() => {
    if (!hasGyftrMode) return false;
    if (hasGyftrMode?.is_available) return true;
    return hasGyftrMode?.unapplicability_reasons?.some(
      (reason: any) => reason?.type === "INVALID_DISCOUNTS",
    );
  }, [hasGyftrMode]);

  if (!Boolean(paymentMethods)) return <></>;

  return (
    <section className="space-y-3">
      {hasGyftrMode && shouldShowGyftrMode && <Gyftr />}
      {Boolean(availableMethods.length) && (
        <>
          <div className={`flex w-full flex-row  items-center justify-between px-2 pt-2 text-xs`}>
            <h2 className="text-sm font-medium text-coal-light">{t("all_payment_methods") as string}</h2>
          </div>
          <div className={`overflow-clip-margin-2 flex flex-col space-y-4 overflow-clip `}>
            {shouldShowUPIIntentWithIconsTile && <UPICollect />}
            <MethodsContainer renderMethods={modifiedAvailableMethods} />
          </div>
        </>
      )}

      {hasShopifyGCMode && hasShopifyGCMode?.is_available && <ShopfiyGiftCard />}

      {Boolean(unAvailableMethods.length) && (
        <>
          <div className={`flex w-full flex-row items-center justify-between gap-1 py-3 text-xs`}>
            <div className="flex w-full flex-col items-start justify-between gap-1">
              <DividerWithLabel className="text-xs uppercase" label={t("unavailable_methods")} />
            </div>
          </div>

          <div className={`overflow-clip-margin-2 flex flex-col space-y-4 overflow-clip`}>
            {hasUpiIntentMode && !isUpiIntentModeAvailable && <UPICollect />}
            <MethodsContainer renderMethods={modifiedUnAvailableMethods} />
          </div>
        </>
      )}

      {hasShopifyGCMode && !hasShopifyGCMode?.is_available && <ShopfiyGiftCard />}

      {hasFreeMethod && (
        <>
          <div className="mt-3 w-full">
            <PrimaryButton
              isDisabled={!freeMethodEnabled}
              buttonText={t("place_order")}
              height="h-14"
              onClick={() => handleNonPaymentOrderPlacement("FREE")}
            />
          </div>
        </>
      )}

      {hasGCMode && <GiftCard />}
    </section>
  );
};

export default React.memo(PaymentMethods);
