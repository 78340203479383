import React, { useEffect, useRef } from "react";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import "react-phone-number-input/style.css";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import TrustBadges from "components/common/TrustBadges";
import TermsConditions from "components/common/TermsConditions";
import Login from "components/auth/Login";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface MultiFactorAuthenticationProps {
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultiFactorAuthentication: React.FC<MultiFactorAuthenticationProps> = ({}) => {
  const isGuestUser = useRef<boolean>(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const {
    actions: { setCheckoutView },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  //** Side Effects */
  useEffect(() => {
    //Login page loaded event
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_AUTH_PHONE_LOADED,
      eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
      eventType: "load",
    });
    setCheckoutView("AUTH");

    return () => {
      if (isGuestUser.current === null || isGuestUser.current === undefined) return;
      if (isGuestUser.current) {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_GUEST_LOGIN,
          eventFor: [analyticsTypes.SF_ANALYTICS],
          eventType: "flo_action",
          metaData: {
            userData: {
              type: analyticsEvents.FLO_GUEST_LOGIN,
            },
            authSource: "SHOPFLO",
          },
        });
        return;
      }

      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_RETURN_USER_LOGIN,
        eventType: "flo_action",
        metaData: {
          userData: {
            type: analyticsEvents.FLO_RETURN_USER_LOGIN,
          },
          authSource: "SHOPFLO",
        },
      });
    };
  }, []);

  return (
    <>
      <Login context={"AUTH"} />
      <TrustBadges />
      <TermsConditions />
      {Boolean(merchant?.customBanners?.auth) && (
        <>
          <img
            src={merchant?.customBanners?.auth}
            alt="custom-banner-auth"
            className="mt-6 aspect-[33/10] max-h-[6.625rem] w-full max-w-[22rem]"
          />
        </>
      )}
    </>
  );
};

export default MultiFactorAuthentication;
