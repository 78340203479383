import React from "react";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { useLocale } from "lib/hooks/useLocale";
import ConfirmationPopup from "components/common/popups/ConfirmationPopup";

interface OfflinePaymentProps {}

const OfflinePayment: React.FC<OfflinePaymentProps> = ({}) => {
  const {
    state: { paymentMethods, openPaymentMethodDialog },
    actions: { setPaymentMethodDialog, handleNonPaymentOrderPlacement },
  } = usePaymentContext();
  const { t } = useLocale();

  const offlinePaymentModeMetadata = paymentMethods?.find(
    (paymentMethod: any) => paymentMethod.mode === "OFFLINE_PAYMENT",
  );
  if (!offlinePaymentModeMetadata) return <></>;

  return (
    <>
      <ConfirmationPopup
        popupTitle={
          offlinePaymentModeMetadata?.metadata?.prompt_metadata?.title ?? t("cod_confirmation_popup_header")
        }
        popupBody={offlinePaymentModeMetadata?.metadata?.prompt_metadata?.description}
        onClickPrimaryBtn={(e) => {
          e?.preventDefault();
          handleNonPaymentOrderPlacement("OFFLINE_PAYMENT");
        }}
        id="flo__payments__offlinePaymentModal"
        isOpen={openPaymentMethodDialog === "OFFLINE_PAYMENT"}
        closePopup={() => setPaymentMethodDialog("NONE")}
        btnsFlexDirection="flex-col"
        modalType="OFFLINE_PAYMENT_CONFIRMATION"
        showSecondaryBtn={false}
      />
    </>
  );
};

export default OfflinePayment;
