import { DialogBody, GenericDialog } from "components/common/dialogs/GenericDialog";
import { useLocale } from "lib/hooks/useLocale";
import { CartDialogType } from "lib/types/cart";
import React from "react";
import { ChevronDown, FileText } from "react-feather";
import TrustBadgeGray from "assests/images/trust-badge-gray.svg";
import { useAuthContext } from "lib/contexts/AuthProvider";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { currencyFormatter } from "lib/utils/formatters";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { putRequest } from "lib/core/apiClient";
import { inIframe, isThirdPartyCookieEnabled, publishPostMessage } from "lib/utils/helpers";
import { eventTypes } from "lib/utils/constants";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import { useMerchantContext } from "lib/contexts/MerchantProvider";

interface BillingDialogProps {
  open: boolean;
  setDialog: (dialog: CartDialogType) => void;
  isLoading?: boolean;
}

const BillingSummaryDialog: React.FC<BillingDialogProps> = ({ open, setDialog, isLoading = false }) => {
  const { t } = useLocale();
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: { billing, checkoutId, checkoutItems, appliedCoupons },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const analytics = useSendAnalyticsEvent();

  const handleAuthenticated = async () => {
    await putRequest(`/checkout/v1/cart/${checkoutId}/checkout`, {});
    publishPostMessage(eventTypes.CART_TO_CHECKOUT, {
      checkoutId: checkoutId,
      lineItems: checkoutItems,
      cartTotal: billing.total_payable,
      coupons: appliedCoupons,
    });
  };

  const handleCheckoutClick = () => {
    if (isAuthenticated) {
      analytics.sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_CHECKOUT_CLICKED,
        eventType: "click",
      });
      handleAuthenticated();
      setDialog(null);
      return;
    }
    if (inIframe() && !isThirdPartyCookieEnabled()) {
      analytics.sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_CHECKOUT_CLICKED,
        eventType: "click",
      });
      let redirectUrl = new URL(document.location.href);
      let checkoutUrlSearchParams = new URLSearchParams(redirectUrl.search);
      checkoutUrlSearchParams.delete("page");
      checkoutUrlSearchParams.append("checkoutId", checkoutId);
      checkoutUrlSearchParams.append("referrer", "cart");
      redirectUrl.search = checkoutUrlSearchParams.toString();
      publishPostMessage(eventTypes.PARENT_REDIRECT, {
        redirectUrl: redirectUrl.href,
        clearCart: false,
      });
      return;
    }
    setDialog("authentication");
  };

  return (
    <GenericDialog
      isOpen={open}
      setIsOpen={() => {
        setDialog(null);
      }}
      translateAxis="y"
      customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
      dialogOverlay={true}
      closeOnOverlayClick={true}>
      <DialogBody className={`flex flex-col gap-3 !p-3 !pb-14`}>
        <div className="flex items-center justify-between">
          <h3 className="flex gap-2 text-sm font-medium text-coal-dark">
            <FileText className="h-4 w-4" />
            {t("bill_summary")}
          </h3>
          <button onClick={() => setDialog(null)}>
            <ChevronDown className="h-4 w-4 text-coal-dark" strokeWidth={2} />
          </button>
        </div>
        <div className="space-y-1">
          <div className="flex w-full justify-between text-xs text-coal-light">
            <span>{t("cart_total")}</span>
            <span className="text-coal-dark">
              {currencyFormatter(
                Boolean(merchant?.showOriginalPrice)
                  ? billing?.original_sub_total
                  : billing?.sub_total + billing?.addon_total,
              )}
            </span>
          </div>
          <div className="flex w-full justify-between text-xs text-coal-light">
            <span>{t("delivery_fee")}</span>
            <span className="text-coal-dark">
              {Boolean(billing.shipping) && billing.shipping >= 0
                ? currencyFormatter(billing?.shipping)
                : t("to_be_calculated")}
            </span>
          </div>

          {Boolean(merchant?.showOriginalPrice) &&
            Boolean(billing?.original_sub_total - billing?.sub_total) &&
            Boolean(billing?.original_sub_total - billing?.sub_total > 0) && (
              <div className="flex w-full justify-between text-xs text-coal-light">
                <span>{t("discount_on_mrp")}</span>
                <span className="font-medium text-yay-dark">
                  {currencyFormatter(billing?.original_sub_total - billing?.sub_total)}
                </span>
              </div>
            )}

          {Boolean(billing?.discount) && (
            <div className="flex w-full justify-between text-xs text-coal-light">
              <span>{t("discount")}</span>
              <span className="font-medium text-yay-dark">{currencyFormatter(billing?.discount ?? "")}</span>
            </div>
          )}
          <div className="flex w-full justify-between text-sm font-semibold text-carbon-dark">
            <div>{t("grand_total")}</div>
            <div>{currencyFormatter(billing.total_payable)}</div>
          </div>
        </div>
        <PrimaryButton buttonText="Checkout" onClick={handleCheckoutClick} isLoading={isLoading} />

        <img src={TrustBadgeGray} className="h-4" alt={"shopflo-logo"} />
      </DialogBody>
    </GenericDialog>
  );
};

export default BillingSummaryDialog;
