import { XIcon } from "@heroicons/react/solid";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { DialogBody, DialogFooter, DialogHeader } from "components/common/dialogs/GenericDialog";
import Price from "components/common/Price";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useLocale } from "lib/hooks/useLocale";
import { LoyaltyRedemptionHandle, YotpoLoyaltyType } from "lib/types/checkout";
import { currencyFormatter } from "lib/utils/formatters";
import React, { useState } from "react";

interface LoyaltyCoinsSelectorProps {
  setIsOpen: (value: boolean) => void;
  loyaltyData: YotpoLoyaltyType;
  updateLoyaltyDetails: (id: string) => Promise<void>;
  removeLoyaltyDetails: () => Promise<void>;
  appliedLoyaltyHandle: LoyaltyRedemptionHandle;
}

const LoyaltyCoinsSelector: React.FC<LoyaltyCoinsSelectorProps> = ({
  setIsOpen,
  loyaltyData,
  updateLoyaltyDetails,
  appliedLoyaltyHandle,
}) => {
  const { t } = useLocale();
  const {
    state: { merchant },
  } = useMerchantContext();

  const [selectedHandle, setSelectedHandle] = useState<string>(
    Boolean(appliedLoyaltyHandle?.id?.length) ? appliedLoyaltyHandle?.id : "ALL",
  );

  return (
    <>
      <DialogHeader className="mt-2">
        <div className="flex h-full w-full flex-row items-center justify-between ">
          <div className="items-left flex flex-col justify-center gap-1">
            <h1 className="-mx-3 text-sm text-carbon-dark">
              {t("loyalty_payment_header", { brand: loyaltyData?.coinNamePlural })}
            </h1>
            <h2 className="-mx-3 text-xs text-gray-dark">
              {t("you_have_x_coins", {
                total: currencyFormatter(loyaltyData?.totalPointsBalance, false),
                brand: loyaltyData?.coinNamePlural,
              })}
            </h2>
          </div>
          <button className="-mx-3 outline-none">
            <XIcon className="h-6 w-6 cursor-pointer text-coal-dark" onClick={() => setIsOpen(false)} />
          </button>
        </div>
      </DialogHeader>
      <DialogBody className="!overflow-x-hidden !pb-28">
        <ul className="-mx-3 flex flex-col gap-4">
          {loyaltyData?.redemptionOptions?.map((option: LoyaltyRedemptionHandle) => {
            return (
              <li
                key={option?.id}
                className={`mx-5 flex cursor-pointer items-center justify-between rounded-2xl border px-3 py-4 text-sm ${
                  selectedHandle === option?.id
                    ? "border-2 border-primary-dark px-[11px] py-[15px] text-coal-dark"
                    : "text-coal-light"
                }`}
                onClick={() => {
                  setSelectedHandle(option?.id);
                }}>
                <h2>
                  {t("x_coins", {
                    total: currencyFormatter(option?.totalPoints, false),
                    brand: loyaltyData?.coinNamePlural,
                  })}
                  &nbsp;
                  {option?.id === "ALL" ? t("maximum_coins") : ""}
                </h2>
                <Price total={currencyFormatter(option?.totalPrice)} />
              </li>
            );
          })}
        </ul>
      </DialogBody>
      <DialogFooter className="pb-16">
        <PrimaryButton
          buttonText={t("redeem")}
          onClick={() => {
            if (appliedLoyaltyHandle?.id === selectedHandle) {
              setIsOpen(false);
              return;
            }
            updateLoyaltyDetails(selectedHandle);
            setIsOpen(false);
          }}
          height="h-14"
          isLoading={false}
          isDisabled={false}
          isCheckout={true}
          showCustomTerms={merchant?.merchantId === "39e49c34-1261-4205-aed7-bd87f9eee7b8"}
          customTermsLink={
            merchant?.merchantId === "39e49c34-1261-4205-aed7-bd87f9eee7b8"
              ? "https://blissclub.com/pages/faq"
              : ""
          }
        />
      </DialogFooter>
    </>
  );
};

export default LoyaltyCoinsSelector;
