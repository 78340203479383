import React, { useEffect, useState } from "react";
import { BillingType } from "lib/types/checkout";
import { useLocale } from "lib/hooks/useLocale";
import { AppliedGiftCardsType } from "lib/contexts/CheckoutProvider";
import { getDefaultShippingHandle } from "lib/utils/checkout";
import Price from "components/common/Price";
import { maskString } from "lib/utils/helpers";
import { currencyFormatter } from "lib/utils/formatters";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import PopCoinsIcon from "assests/images/popcoins-icon.png";

const BillingDetails: React.FC<BillingType> = ({
  sub_total,
  total_payable,
  discount,
  shipping,
  tax,
  taxes_included,
  serviceable,
  prepaid_discount,
  prepaid_total,
  original_sub_total,
}) => {
  const { t } = useLocale();
  const {
    state: { merchant },
  } = useMerchantContext();

  const {
    state: {
      shippingHandles,
      appliedGiftCards,
      appliedWalletData,
      appliedLoyalty,
      initialCheckoutStep,
      billing,
    },
    actions: { setCheckoutModal },
  } = useCheckoutContext();
  const [selectedShippingHandle, setSelectedShippingHandle] = useState<string>("");

  useEffect(() => {
    const selectedHandle = getDefaultShippingHandle(shippingHandles);
    if (Boolean(selectedHandle)) {
      setSelectedShippingHandle(selectedHandle?.handle_name ?? "");
    }
  }, [shippingHandles]);

  const handleShippingChange = () => {
    setCheckoutModal("SHIPPING_HANDLES");
  };

  return (
    <>
      <ul className="flex w-full flex-col space-y-2 px-6 pb-6">
        <li className="flex flex-row justify-between text-base">
          <p className="font-semibold text-coal-light">{t("subtotal")}</p>
          <p className="font-medium text-coal-dark">
            <Price
              customCSS="!text-base"
              total={Boolean(merchant?.showOriginalPrice) ? original_sub_total : sub_total}
            />
          </p>
        </li>
        {Boolean(merchant?.showOriginalPrice) &&
          Boolean(original_sub_total - sub_total) &&
          Boolean(original_sub_total - sub_total > 0) && (
            <li className="flex flex-row justify-between text-base">
              <p className="font-semibold text-coal-light">{t("discount_on_mrp")}</p>
              <p className="inline-flex font-medium text-yay-dark">
                <Price customCSS="!text-base" total={original_sub_total - sub_total} isDiscounted={true} />
              </p>
            </li>
          )}
        <>
          <li className="flex flex-row justify-between text-base">
            <p className="font-semibold text-coal-light">{t("shipping")}</p>
            <p className={`font-regular text-gray-dark ${shipping !== -1 && "font-normal uppercase"}`}>
              {shipping === -1 ? (
                t("calculated_at_next_step")
              ) : shipping === 0 ? (
                t("free_shipping")
              ) : (
                <Price customCSS="!text-base" total={shipping ?? ""} />
              )}
            </p>
          </li>
          {Boolean(shipping !== -1) &&
            Boolean(shippingHandles?.length > 1) &&
            initialCheckoutStep !== "PAYMENTS" && (
              <p className="text-base text-coal-light">
                {selectedShippingHandle}{" "}
                <button className="font-semibold text-coal-dark underline" onClick={handleShippingChange}>
                  Change
                </button>
              </p>
            )}
        </>
        {!Boolean(taxes_included) && Boolean(tax) && (
          <li className="flex flex-row justify-between text-base">
            <p className="font-semibold text-coal-light">{t("tax")}</p>
            <p className="font-medium text-coal-dark">
              <Price customCSS="!text-base" total={tax ?? ""} />
            </p>
          </li>
        )}
        {Boolean(discount) && (
          <li className="flex flex-row justify-between text-base">
            <p className="font-semibold text-coal-light">{t("discount")}</p>
            <p className="inline-flex font-medium text-yay-dark">
              <Price customCSS="!text-base" total={discount ?? ""} isDiscounted={true} />
            </p>
          </li>
        )}
        {Boolean(appliedGiftCards?.length) && (
          <>
            {appliedGiftCards.map((giftCard: AppliedGiftCardsType) => {
              return (
                <li className="flex flex-row justify-between text-base" key={giftCard?.cardId}>
                  <p className="font-normal text-coal-light">{`${t("gift_card")} ${maskString(
                    giftCard?.displayName,
                    9,
                    5,
                    "•",
                  )}`}</p>
                  <p className="font inline-flex font-medium text-yay-dark">
                    <Price customCSS="!text-base" total={giftCard.cardValue ?? ""} isDiscounted={true} />
                  </p>
                </li>
              );
            })}
          </>
        )}
        {Boolean(appliedLoyalty) && Boolean(appliedLoyalty.totalPoints > 0) && (
          <>
            <li className="flex flex-row justify-between text-base" key={"applied_wallet"}>
              <p className="inline-flex items-center font-medium text-coal-light">
                {t("redeem_x_coins", {
                  total: currencyFormatter(appliedLoyalty?.totalPoints, false),
                  brand: appliedLoyalty.provider === "POP_COINS" ? " " : t("default_wallet_currency"),
                })}
                {appliedLoyalty.provider === "POP_COINS" && (
                  <span className="inline-flex ml-0.5 items-center space-x-1">
                    <img src={PopCoinsIcon} alt="pop-coin" className="w-4 h-4 rounded-full" />
                    {t("popcoins")}
                  </span>
                )}
              </p>
              <p className="font inline-flex font-medium text-yay-dark">
                <Price customCSS="!text-base" total={appliedLoyalty?.totalPrice} isDiscounted={true} />
              </p>
            </li>
          </>
        )}
        {Boolean(appliedWalletData) && appliedWalletData.totalReducedPoints > 0 && (
          <li className="flex flex-row justify-between text-base" key={"applied_wallet"}>
            <p className="font-medium text-coal-light">
              {t("redeem_x_coins", {
                total: currencyFormatter(appliedWalletData?.totalReducedPoints, false),
                brand: t("default_wallet_currency"),
              })}
            </p>
            <p className="font inline-flex font-medium text-yay-dark">
              <Price
                customCSS="!text-base"
                total={appliedWalletData?.totalReductionAmount}
                isDiscounted={true}
              />
            </p>
          </li>
        )}
        {Boolean(billing?.gift_card) && (
          <li className="flex flex-row justify-between text-xs" key={"gift_card"}>
            <p className="font-medium text-coal-light flex items-center">
              {t("epay_balance_applied")}
            </p>
            <p className="font inline-flex font-medium text-yay-dark">
              <Price customCSS="" total={billing?.gift_card} isDiscounted={true} />
            </p>
          </li>
        )}

        {/* {Boolean(prepaidDiscount) && (
          <li className="flex flex-row justify-between text-base font-normal text-coal-light">
            <p className="text-base">{t("prepaid_discount")}</p>
            <p className="text-yay-dark"> -₹{prepaidDiscount}</p>
          </li>
        )} */}
        <li className="flex flex-row justify-between pt-4 text-base font-medium text-coal-dark">
          <p className="text-lg">{t("total")}</p>
          <p>
            <Price customCSS="!text-lg" total={total_payable ?? ""} />
          </p>
        </li>
      </ul>
    </>
  );
};

export default BillingDetails;
