import React from "react";
import { IconProps } from "./types";

const RewardCoinIcon: React.FC<IconProps> = ({ fill = "#4D4D4D" }) => {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Coins">
          <path
            id="Vector"
            d="M11.75 5.38188V5.25C11.75 4.33625 11.0731 3.52812 9.84313 2.975C8.80438 2.5075 7.4375 2.25 6 2.25C4.5625 2.25 3.19562 2.5075 2.15687 2.975C0.926875 3.52812 0.25 4.33625 0.25 5.25V7.75C0.25 8.66375 0.926875 9.47187 2.15687 10.025C2.82529 10.3143 3.52915 10.5135 4.25 10.6175V10.75C4.25 11.6637 4.92688 12.4719 6.15688 13.025C7.19563 13.4925 8.5625 13.75 10 13.75C11.4375 13.75 12.8044 13.4925 13.8431 13.025C15.0731 12.4719 15.75 11.6637 15.75 10.75V8.25C15.75 6.86625 14.1694 5.755 11.75 5.38188ZM14.25 8.25C14.25 8.73438 12.8894 9.655 10.3869 9.7425C11.2706 9.2125 11.75 8.51875 11.75 7.75V6.9025C13.3675 7.19688 14.25 7.8425 14.25 8.25ZM6.71063 9.22688C6.48375 9.24188 6.24687 9.25 6 9.25C5.6825 9.25 5.38188 9.23625 5.09812 9.21187C5.07449 9.20876 5.05071 9.20688 5.02688 9.20625C4.9325 9.1975 4.83938 9.1875 4.75 9.17625V8.18312C5.16517 8.22773 5.58244 8.25005 6 8.25C6.41756 8.25005 6.83483 8.22773 7.25 8.18312V9.17625C7.09438 9.195 6.93313 9.21125 6.76562 9.22313C6.75 9.22375 6.72875 9.225 6.71063 9.22688ZM10.25 7.32125V7.75C10.25 8.04875 9.7325 8.51312 8.75 8.84625V7.90875C9.12406 7.81058 9.48962 7.68245 9.84313 7.52563C9.98646 7.46104 10.1221 7.39292 10.25 7.32125ZM6 3.75C8.75 3.75 10.25 4.74062 10.25 5.25C10.25 5.75938 8.75 6.75 6 6.75C3.25 6.75 1.75 5.75938 1.75 5.25C1.75 4.74062 3.25 3.75 6 3.75ZM1.75 7.75V7.32125C1.87833 7.3925 2.01396 7.46042 2.15687 7.525C2.51038 7.68183 2.87594 7.80995 3.25 7.90812V8.84562C2.2675 8.51312 1.75 8.04875 1.75 7.75ZM5.75 10.75C5.83313 10.75 5.91625 10.75 6 10.75C6.22667 10.75 6.45104 10.7433 6.67312 10.73C6.85854 10.7954 7.05083 10.855 7.25 10.9087V11.8462C6.2675 11.5131 5.75 11.0487 5.75 10.75ZM8.75 12.1763V11.1838C9.16518 11.2282 9.58245 11.2503 10 11.25C10.4176 11.2501 10.8348 11.2277 11.25 11.1831V12.1763C10.4196 12.2746 9.58043 12.2746 8.75 12.1763ZM12.75 11.8462V10.9087C13.1241 10.8106 13.4896 10.6825 13.8431 10.5256C13.9865 10.4615 14.1221 10.3935 14.25 10.3219V10.75C14.25 11.0487 13.7325 11.5131 12.75 11.8462Z"
            fill={fill}
          />
        </g>
      </svg>
    </>
  );
};

export default RewardCoinIcon;
