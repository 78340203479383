import { useCheckoutErrors } from "lib/hooks/useCheckoutErrors";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { classNames } from "lib/utils/helpers";
import { AlertCircle, AlertTriangle } from "react-feather";

const OrderSummaryErrorHeader = () => {
  const {
    state: { checkoutLoading },
  } = useCheckoutContext();
  const { errorMessage, isAddItemsWorthDelivery, isWeightBasedShipping } = useCheckoutErrors();
  if (!errorMessage || checkoutLoading || !(isWeightBasedShipping || isAddItemsWorthDelivery)) {
    return null;
  }
  return (
    <div className="relative z-10 px-3 py-2 text-ouch">
      <AlertTriangle className="h-4 w-4 text-ouch" />
    </div>
  );
};

const OrderSummaryErrorCustom = ({
  customErrorMessage,
  rounded = "center",
}: {
  customErrorMessage?: string;
  rounded?: "bottom" | "center";
}) => {
  const {
    state: { checkoutLoading },
  } = useCheckoutContext();
  const { errorMessage } = useCheckoutErrors();
  if ((!customErrorMessage && !errorMessage) || checkoutLoading) {
    return null;
  }
  return (
    <div className="relative text-xs text-coal-dark overflow-hidden w-full">
      <div
        className={classNames(
          "absolute w-full h-full bg-ouch opacity-10",
          rounded === "bottom" ? "rounded-b-lg" : "rounded-lg",
        )}></div>
      <div className="flex items-center">
        <AlertCircle className="ml-2 text-ouch" size={16} />
        <div className="relative z-10 px-2 py-2 text-ouch text-left">
          {customErrorMessage ?? errorMessage}
        </div>
      </div>
    </div>
  );
};

export { OrderSummaryErrorHeader, OrderSummaryErrorCustom };
