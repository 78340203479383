import { GenericDialog } from "components/common/dialogs/GenericDialog";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import Price from "components/common/Price";
import GiftCardDialog from "components/payments/dialogs/GiftCardDialog";
import { AppliedGiftCardsType } from "lib/contexts/CheckoutProvider";
import { deleteRequest } from "lib/core/apiClient";
import { maskString } from "lib/utils/helpers";
import { errorToast } from "lib/utils/toasters";
import React, { useState } from "react";
import { XCircle, ArrowRight } from "react-feather";
import { mutate } from "swr";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { getUnApplicablePaymentReason } from "lib/utils/payments";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { apiURI } from "lib/utils/constants";
import { useLocale } from "lib/hooks/useLocale";

interface GiftCardProps {}

const GiftCardIcon = React.lazy(() => import("assests/icons/GiftCard"));

const GiftCard: React.FC<GiftCardProps> = ({}) => {
  const { t } = useLocale();
  const {
    state: { appliedGiftCards, checkoutId, isC2P, hasDefaultShippingHandleSelected, initialCheckoutStep },
    actions: { updateCheckoutBasedOnCheckoutResponse, setCheckoutModal },
  } = useCheckoutContext();

  const {
    state: { paymentMethods },
    actions: { handleUnapplicablePaymentMethod },
  } = usePaymentContext();
  const gcModeConfig = paymentMethods?.find((e: any) => e.mode === "GC");
  const unapplicabilityReason = getUnApplicablePaymentReason(
    gcModeConfig?.unavailability_reasons,
    gcModeConfig?.metadata?.title,
  );

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDeleteGiftCard = async (giftCardToBeDeleted: string) => {
    if (!Boolean(appliedGiftCards?.find((giftCard) => giftCard?.cardId === giftCardToBeDeleted))) return;

    try {
      setIsLoading(true);
      const payload = {
        src_reference_id: giftCardToBeDeleted,
      };
      const response = await deleteRequest(`/checkout/v1/checkout/${checkoutId}/rewards/gc/remove`, payload);
      handleCheckoutResponse(response);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
    } catch (e) {
      errorToast(t("delete_gift_card_error"));
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse, true);
  };

  const handleGCClick = () => {
    if (!gcModeConfig.is_available) {
      handleUnapplicablePaymentMethod(gcModeConfig.mode, gcModeConfig.unavailability_reasons);
      return;
    }
    const hasDefaultHandle = hasDefaultShippingHandleSelected;
    if (!Boolean(hasDefaultHandle && initialCheckoutStep !== "PAYMENTS")) {
      setCheckoutModal("SHIPPING_HANDLES");
      return;
    }
    setOpenDialog(true);
  };

  return (
    <div className="border-base overflow-hidden bg-white">
      <div
        onClick={() => {
          handleGCClick();
        }}>
        <div className="bg-red flex w-full cursor-pointer flex-row items-center justify-center space-x-3 p-3">
          <div className={`flex h-9 w-9 items-center justify-center`}>
            <GiftCardIcon />
          </div>
          <div className="flex w-full flex-1 flex-col items-start justify-start space-y-1">
            <h3 className="text-sm font-medium text-coal-dark">{gcModeConfig?.metadata?.title ?? ""}</h3>
            {!Boolean(gcModeConfig?.metadata?.hideDescription) && (
              <p className="text-xs font-normal text-gray-dark">
                {gcModeConfig?.metadata?.description ?? ""}
              </p>
            )}
          </div>

          <ArrowRight className="text-gray-dark" strokeWidth={2} size={16} />
        </div>
        {Boolean(!gcModeConfig.is_available) && Boolean(unapplicabilityReason) && (
          <div className="bg-[#FAFAFA]">
            <p className="px-3 py-2 text-xs font-normal text-gray-dark">{unapplicabilityReason}</p>
          </div>
        )}
      </div>
      {Boolean(appliedGiftCards?.length) && (
        <div className="flex w-full flex-col bg-yay-lighter">
          {appliedGiftCards.map((giftCard: AppliedGiftCardsType) => {
            return (
              <div className="border-box flex w-full flex-row px-3 py-3" key={giftCard?.cardId}>
                <div className="flex w-full flex-row items-center justify-between overflow-hidden pr-2">
                  <h3 className="flex w-full items-center space-x-1 truncate pr-2 text-sm font-normal text-coal-light">
                    <p className="truncate text-sm font-medium uppercase text-coal-dark">
                      {maskString(giftCard?.displayName, 9, 5, "•")}
                    </p>
                  </h3>
                  <p className="inline-flex w-1/2 justify-end overflow-hidden text-sm font-medium text-yay-dark">
                    <Price total={giftCard?.cardValue} isDiscounted={true} />
                  </p>
                </div>
                {!Boolean(isC2P) && (
                  <button
                    className="flex h-4 w-4 items-center justify-center"
                    onClick={() => handleDeleteGiftCard(giftCard?.cardId)}>
                    <XCircle className="h-4 w-4 text-coal-dark" strokeWidth={2.5} />
                  </button>
                )}
              </div>
            );
          })}
        </div>
      )}

      <GenericDialog
        isOpen={openDialog}
        translateAxis="y"
        modalType="GIFT_CARDS"
        dialogOverlay={true}
        customClass="overflow-scroll md:!top-auto md:absolute">
        <GiftCardDialog setIsOpen={(value: boolean) => setOpenDialog(value)} />
      </GenericDialog>

      {isLoading && <OverlaySpinner />}
    </div>
  );
};

export default GiftCard;
