import { useCallback, useMemo } from "react";

import { useUserContext } from "lib/contexts/UserProvider";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { CheckoutErrorResponseCode } from "lib/types/checkout";
import { useLocale } from "./useLocale";
import { checkoutInfoToast } from "lib/utils/toasters";
import { CheckoutErrorResponse } from "lib/types/checkout";

export function useCheckoutErrors() {
  const { state } = useCheckoutContext();
  const { t } = useLocale();
  const {
    state: { user },
  } = useUserContext();

  const errorResponses = state.errorResponses;
  const shippingHandles = state.shippingHandles;
  const isCheckoutLoading = state.checkoutLoading;

  const errorFlags = useMemo(() => {
    return errorResponses.reduce(
      (flags, error) => {
        switch (error.code) {
          case CheckoutErrorResponseCode.UARC_006:
            flags.isAddItemsWorthDeliveryError = true;
            break;
          case CheckoutErrorResponseCode.UARC_007:
            flags.isWeightBasedShippingError = true;
            break;
          case CheckoutErrorResponseCode.UARC_008:
            flags.isSingleItemServicibilityError = true;
            break;
          case CheckoutErrorResponseCode.UARC_009:
            flags.isMultipleItemServicibilityError = true;
            break;
          case CheckoutErrorResponseCode.UARC_010:
          case CheckoutErrorResponseCode.UARC_005:
            flags.isSelectedAddressNotServiceableError = true;
            break;
        }
        return flags;
      },
      {
        isAddItemsWorthDeliveryError: false,
        isWeightBasedShippingError: false,
        isSingleItemServicibilityError: false,
        isMultipleItemServicibilityError: false,
        isSelectedAddressNotServiceableError: false,
      },
    );
  }, [errorResponses]);

  const {
    isAddItemsWorthDeliveryError,
    isWeightBasedShippingError,
    isSingleItemServicibilityError,
    isMultipleItemServicibilityError,
    isSelectedAddressNotServiceableError,
  } = errorFlags;

  const errorMessage = useMemo(() => {
    if (isCheckoutLoading) {
      return "";
    }
    if (isSingleItemServicibilityError) {
      return t("single_item_unservicable_on_pincode", { pincode: user?.default_shipping_address?.zip });
    } else if (isMultipleItemServicibilityError) {
      return t("multiple_item_unservicable_on_pincode", { pincode: user?.default_shipping_address?.zip });
    } else if (isAddItemsWorthDeliveryError) {
      return errorResponses.find((error) => error.code === CheckoutErrorResponseCode.UARC_006)?.message;
    } else if (isWeightBasedShippingError) {
      return errorResponses.find((error) => error.code === CheckoutErrorResponseCode.UARC_007)?.message;
    } else if (isSelectedAddressNotServiceableError) {
      const errorMessage005 = errorResponses.find(
        (error) => error.code === CheckoutErrorResponseCode.UARC_005,
      )?.message;
      const errorMessage010 = errorResponses.find(
        (error) => error.code === CheckoutErrorResponseCode.UARC_010,
      )?.message;
      return errorMessage005 || errorMessage010;
    }

    if (errorResponses.length === 0 && shippingHandles.length === 0) {
      return t("pincode_not_serviceable");
    }
    return "";
  }, [errorResponses, shippingHandles, isCheckoutLoading]);

  const triggerCheckoutErrorInfoToast = useCallback(() => {
    if (errorMessage) {
      checkoutInfoToast(errorMessage);
    }
  }, [errorMessage]);

  return {
    errorMessage,
    isItemNotServicable: isSingleItemServicibilityError || isMultipleItemServicibilityError,
    isAddressNotServicable: isSelectedAddressNotServiceableError,
    isNotServicable: checkIfHasServiceabilityError(errorResponses) || shippingHandles.length === 0,
    isAddItemsWorthDelivery: isAddItemsWorthDeliveryError,
    isWeightBasedShipping: isWeightBasedShippingError,
    triggerCheckoutErrorInfoToast,
  };
}

export const checkIfHasServiceabilityError = (errorResponses: CheckoutErrorResponse[] | undefined) => {
  if (!errorResponses) return false;
  const errorCodes = [
    CheckoutErrorResponseCode.UARC_006,
    CheckoutErrorResponseCode.UARC_007,
    CheckoutErrorResponseCode.UARC_008,
    CheckoutErrorResponseCode.UARC_009,
    CheckoutErrorResponseCode.UARC_010,
    CheckoutErrorResponseCode.UARC_005,
  ];
  return errorResponses.some((error) => errorCodes.includes(error.code));
};
