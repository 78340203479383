import GenericButton from "components/common/buttons/GenericButton";
import NotPlacedImg from "assests/images/order-failed.svg";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { redirectUrl } from "lib/utils/helpers";

export default function NotPlaced({
  primaryMessage = "Some items in your cart seem to have gone out of stock",
  secondaryMessage = "Any amount deducted will be refunded to you within 5-7 business days.",
  refundId,
  buttonText = "Back to store",
}: {
  primaryMessage?: string;
  secondaryMessage?: string;
  refundId?: string;
  buttonText?: string;
}) {
  const {
    state: { merchant },
  } = useMerchantContext();

  const onButtonClick = () => {
    if (!merchant) return;
    redirectUrl(`${merchant.brandUrl}/?floRedirectStatus=oos`);
  };
  return (
    <div className={`flex h-full flex-col px-10 py-14`}>
      <div className="flex h-full flex-col items-center justify-center text-center">
        <img src={NotPlacedImg} alt="fallen leaf" className="" />
        <h2 className="py-3 text-xl font-medium">Uh oh</h2>
        <p className="text-coal-dark">Your order was not placed.</p>

        <hr className="my-8 w-3/4" />
        <div className="flex flex-col items-center space-y-5">
          {primaryMessage ? <h3 className="text-sm font-medium text-coal-dark">{primaryMessage}</h3> : null}

          {secondaryMessage ? <p className="text-sm text-coal-dark">{secondaryMessage}</p> : null}

          {refundId ? <p className="text-sm text-coal-light">Refund ID: {refundId}</p> : null}
        </div>
      </div>
      <GenericButton
        height="h-14"
        width="w-full"
        customClass={`flex items-center justify-center text-base font-medium rounded-lg text-btnPrimaryText bg-primary-dark px-4`}
        buttonText={buttonText}
        onClick={onButtonClick}
      />
    </div>
  );
}
