import { Truck, Wind, Zap } from 'react-feather';

export enum ShippingDeliveryIconType {
  Truck = "truck",
  WindTruck = "windTruck",
  Zap = "zap",
}

export interface ShippingDeliveryIconProps {
  icon: ShippingDeliveryIconType;
  color?: string;
}

const ShippingDeliveryIcon = ({ icon, color }: ShippingDeliveryIconProps) => {
  switch (icon) {
    case ShippingDeliveryIconType.Truck:
      return <Truck size={16} aria-label="Truck Icon" color={color} />;
    case ShippingDeliveryIconType.WindTruck:
      return (
        <div className="flex" aria-label="Wind and Truck Icons">
          <Wind size={16} color={color} />
          <Truck size={16} color={color} />
        </div>
      );
    case ShippingDeliveryIconType.Zap:
      return <Zap size={16} aria-label="Zap Icon" color={color} />;
    default:
      return <Truck size={16} aria-label="Truck Icon" color={color} />;
  }
};

export default ShippingDeliveryIcon;
