import React, { useMemo } from "react";
import { currencyFormatter } from "lib/utils/formatters";
import { useLocale } from "lib/hooks/useLocale";
import { classNames } from "lib/utils/helpers";

interface PriceProps {
  total: number | string;
  compareAt?: number | string;
  isDiscounted?: boolean;
  customCSS?: string;
  discountPercentage?: number;
  orientation?: "vertical" | "horizontal";
  isSplitCod?: boolean;
}

export const usePrice = (
  total: number | string,
): {
  formattedPrice: string;
} => {
  const formattedPrice = useMemo(() => {
    if (total === 0) {
      return "0";
    }
    const price = currencyFormatter(total);
    return price;
  }, [total]);

  if (typeof total === "string") return { formattedPrice: total };

  return { formattedPrice };
};

const Price: React.FC<PriceProps> = ({
  total,
  compareAt = 0,
  isDiscounted,
  customCSS,
  discountPercentage,
  orientation = "vertical",
  isSplitCod = false,
}) => {
  const { t } = useLocale();
  const { formattedPrice } = usePrice(total);

  if (typeof total === "string") return <>{total}</>;

  return (
    <span className={`flex flex-row items-end space-x-1`}>
      <span
        className={classNames(
          `flex ${isSplitCod ? "text-md" : `text-sm`}`,
          Boolean(compareAt) ? "items-end" : "",
          orientation === "vertical" ? "flex-col" : "flex-row items-center gap-1",
          customCSS ? customCSS : "",
        )}>
        {Boolean(compareAt) &&
          parseInt(compareAt?.toString() ?? "0") !== 0 &&
          parseInt(compareAt?.toString() ?? "0") >= parseInt(total?.toString() ?? "0") &&
          compareAt !== total && (
            <span
              className={classNames(
                "font-normal text-gray-dark line-through",
                orientation === "vertical" ? "text-xxs" : " text-[10px] leading-3",
              )}>
              {currencyFormatter(compareAt)}
            </span>
          )}
        <span>
          {parseInt(formattedPrice ?? "0") === 0 ? (
            <span className="text-yay-dark">{t("automatic_freebies_free_text")}</span>
          ) : (
            <>
              {isDiscounted && <span>–&nbsp;</span>}
              <span>{formattedPrice?.substring(0, formattedPrice.indexOf("."))}</span>
              <span className={`${Number.isInteger(total) ? "" : "text-xs font-normal"}`}>
                {formattedPrice?.substring(formattedPrice.indexOf("."), formattedPrice?.length)}
              </span>
            </>
          )}
        </span>
      </span>
      {Boolean(discountPercentage) && (
        <span className="text-sm font-medium text-yay-dark">{discountPercentage}% off</span>
      )}
    </span>
  );
};

export default Price;
