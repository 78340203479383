
import { UPIIntentMethodType } from "lib/types/payments";

export const MethodLogo: React.FC<UPIIntentMethodType> = ({ logo, name, isLoading = false }) => {
  return (
    <div className="flex cursor-pointer flex-col items-center space-y-4">
      <div className={`rounded-lg ${isLoading && "upiLogo-loader cursor-not-allowed"} h-8 w-8`}>
        <img
          src={logo}
          alt={name}
          className={`h-10 w-10 rounded object-contain ${isLoading && "upiLogoLoader-img"}`}
        />
      </div>
      <p className="text-xs font-medium text-coal-dark">{name}</p>
    </div>
  );
};
