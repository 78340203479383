import { XIcon } from "@heroicons/react/solid";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { DialogBody, DialogHeader } from "components/common/dialogs/GenericDialog";
import InputField from "components/common/forms/InputField";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { postRequest } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import { ErrorType } from "lib/types/address";
import { apiURI } from "lib/utils/constants";
import { errorToast } from "lib/utils/toasters";
import React, { useState } from "react";
import { mutate } from "swr";

interface GiftCardDialogProps {
  setIsOpen: (value: boolean) => void;
}

interface GiftCardPayload {
  card: string;
  pin: string;
}

const GiftCardDialog: React.FC<GiftCardDialogProps> = ({ setIsOpen }) => {
  const { t } = useLocale();
  const {
    state: { appliedGiftCards, checkoutId, appliedCoupons, billing },
    actions: { updateCheckoutBasedOnCheckoutResponse },
  } = useCheckoutContext();

  const [gcNumber, setGCNumber] = useState<string>("");
  const [gcPin, setGCPin] = useState<string>("");
  const [gcError, setGCError] = useState<ErrorType>({
    status: false,
    message: t("gift_card_redeem_error"),
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGiftCardSubmit = async () => {
    if (!Boolean(gcNumber) || !Boolean(gcPin)) {
      setGCError((prev) => ({ ...prev, status: true, message: t("gift_card_redeem_error") }));
      return;
    }
    try {
      setGCError((prev) => ({ ...prev, status: false }));
      setIsLoading(true);
      const payload: GiftCardPayload = {
        card: gcNumber,
        pin: gcPin,
      };
      const response = await postRequest(`/checkout/v1/checkout/${checkoutId}/rewards/gc/apply`, payload);
      if (response?.status && response?.status === "FAILED") {
        setGCPin("");
        setGCError((prev) => ({
          ...prev,
          status: true,
          message: response?.error ?? t("gift_card_redeem_error"),
        }));
        setIsLoading(false);
        return;
      }
      handleCheckoutResponse(response);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
      setIsOpen(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorToast(t("gift_card_error"));
      console.error(error);
    }
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse, true);
  };

  return (
    <>
      <DialogHeader className="pt-[1.25rem]">
        <div className="flex h-full w-full flex-row items-center justify-between ">
          <h1 className="text-base font-medium">
            {appliedGiftCards.length > 0
              ? (t("use_another_gift_card") as string)
              : (t("use_gift_card") as string)}
          </h1>
          <button className="outline-none">
            <XIcon className="h-6 w-6 cursor-pointer text-coal-dark" onClick={() => setIsOpen(false)} />
          </button>
        </div>
      </DialogHeader>
      <DialogBody>
        <div className="relative flex w-full flex-col space-y-4 px-6 pt-4 pb-8">
          <InputField
            type="text"
            id="gcNumber"
            name="gcNumber"
            value={gcNumber}
            placeholder={"Gift card number"}
            onChange={(e) => {
              setGCNumber(e.target.value);
            }}
            error={gcError}
            disabled={isLoading}
            maxLength={16}
          />
          <InputField
            type="password"
            id="gcPin"
            name="gcPin"
            value={gcPin}
            placeholder={"Gift card pin"}
            onChange={(e) => {
              setGCPin(e.target.value);
            }}
            disabled={isLoading}
            maxLength={6}
          />
          <PrimaryButton
            height="h-14"
            isLoading={isLoading}
            buttonText={isLoading ? "" : t("redeem")}
            onClick={handleGiftCardSubmit}
          />
        </div>
      </DialogBody>
      {isLoading && <OverlaySpinner />}
    </>
  );
};

export default GiftCardDialog;
