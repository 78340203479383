import React, { memo } from "react";
import CashfreePaymentForm from "components/payments/dialogs/CashfreePaymentForm";
import CODConfirmation from "components/payments/dialogs/CODConfirmation";
import OfflinePayment from "components/payments/dialogs/OfflinePayment";
import SplitCOD from "components/payments/dialogs/SplitCOD";
import UPIIntent from "components/payments/dialogs/UPIIntent";
import PlaceCODOrderDialog from "./PlaceCODOrder";
import ConfirmOtpForCOD from "./ConfirmOtpForCOD";

interface PaymentMethodDialogProps {}

const PaymentMethodDialog: React.FC<PaymentMethodDialogProps> = memo(() => {
  return (
    <>
      <CODConfirmation />
      <OfflinePayment />
      <CashfreePaymentForm />
      <SplitCOD />
      <UPIIntent />
      <PlaceCODOrderDialog />
      <ConfirmOtpForCOD />
    </>
  );
});

export default PaymentMethodDialog;
