import { usePaymentContext } from "lib/contexts/PaymentProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { useLocale } from "lib/hooks/useLocale";
import { UpiIntentApps, UPIIntentMethodType } from "lib/types/payments";
import React, { useMemo } from "react";
import useSWR from "swr";
import { staticOptions } from "lib/core/apiClient";
import { getUpiAppLink } from "lib/utils/payments";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { getAutomationPayloadForMethod, isMobile, redirectUrl } from "lib/utils/helpers";
import { DialogBody, DialogHeader, GenericDialog } from "components/common/dialogs/GenericDialog";
import { Dialog } from "@headlessui/react";
import { X } from "react-feather";
import { getPaymentMethodAction } from "lib/core/apiMethods";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import InputField from "components/common/forms/InputField";
import useForm, { FormField } from "lib/hooks/useForm";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { errorToast } from "lib/utils/toasters";
import { apps } from "components/payments/methods/upi/common";
import { MethodLogo } from "components/payments/methods/upi/MethodLogo";

const UPIIntent = () => {
  const { t } = useLocale();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const {
    actions: { setPaymentMethodDialog, setPaymentState },
    state: { openPaymentMethodDialog, paymentMethods },
  } = usePaymentContext();

  const {
    state: { checkoutId },
    actions: { setCheckoutExpired },
  } = useCheckoutContext();

  const upiApps: UPIIntentMethodType[] = useMemo(() => {
    const availableApps = paymentMethods?.find((e: any) => e.mode === "UPI_INTENT")?.metadata?.available_apps;
    if (availableApps) {
      const filteredApps: any = apps.filter((app: any) => availableApps.includes(app.id));
      return filteredApps;
    }
    return apps;
  }, [t, paymentMethods]);

  const {
    data: upiIntentUrl,
    error: responseErr,
    isValidating: isLoading,
  } = useSWR(
    openPaymentMethodDialog === "UPI_INTENT" && isMobile() ? `UPI_INTENT` : null,
    (mode) => getPaymentMethodAction(mode, checkoutId, undefined, undefined, setCheckoutExpired),
    staticOptions,
  );

  const openUPIApp = (upiApp: UpiIntentApps) => {
    if (!Boolean(upiIntentUrl) || isLoading) {
      return;
    }
    const url = upiIntentUrl?.next_steps?.pg_action?.action_url;
    const upiAppLink = getUpiAppLink(upiApp, url ?? "");
    if (!Boolean(upiAppLink)) return;
    const methodData = paymentMethods?.find((e: any) => e.mode === "UPI_INTENT");

    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
      eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
      eventType: "click",
      metaData: {
        methodType: "upi_intent",
        automationData: getAutomationPayloadForMethod(methodData),
      },
    });
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_PAYMENT_MODE_SELECTED,
      eventType: "click",
      metaData: {
        modeType: upiApp,
        methodType: "upi_intent",
        automationData: getAutomationPayloadForMethod(methodData),
      },
    });

    setPaymentState({
      isProcessingDialogVisible: true,
      paymentStatus: "PROCESSING",
      isPolling: true,
    });

    redirectUrl(upiAppLink);
  };

  const {
    handleSubmit,
    setErrors,
    inputProps,
    state: { values, errors },
  } = useForm({
    initialState: { id: "", save: false },
    validationSchema: {
      id: {
        required: t("invalid_upi"),
        type: "string",
        when: (value: string) => {
          const upiRegex = /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/;
          const isValid = upiRegex.test(value);
          if (!isValid) {
            return {
              status: true,
              message: t("invalid_upi"),
            };
          }
        },
      },
    },
  });

  const onSubmit = async (values: { id: string; save: boolean }) => {
    try {
      const methodData = paymentMethods?.find((e: any) => e.mode === "UPI_COLLECT");
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
        eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
        eventType: "click",
        metaData: {
          methodType: "UPI_COLLECT",
          automationData: getAutomationPayloadForMethod(methodData),
        },
      });
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_PAYMENT_MODE_SELECTED,
        eventType: "click",
        metaData: {
          modeType: "UPI_COLLECT",
          methodType: "UPI_COLLECT",
          automationData: getAutomationPayloadForMethod(methodData),
        },
      });

      setPaymentState({
        isProcessingDialogVisible: true,
        paymentStatus: "PROCESSING",
        isPolling: true,
      });
      await getPaymentMethodAction(
        "UPI_COLLECT",
        checkoutId,
        undefined,
        {
          vpa: values?.id,
        },
        setCheckoutExpired,
      );
    } catch (e: any) {
      setPaymentState({
        isProcessingDialogVisible: false,
      });
      setErrors({ id: { status: true, message: t("invalid_upi") } });
      errorToast(t("invalid_upi"));
      throw new Error(e);
    }
  };

  const tileTitle = useMemo(() => {
    const upiIntent = paymentMethods.find((method: any) => method?.mode === "UPI_INTENT");
    return upiIntent?.metadata?.title ?? "Pay using UPI";
  }, [paymentMethods]);

  return (
    <GenericDialog
      isOpen={openPaymentMethodDialog === "UPI_INTENT"}
      setIsOpen={() => setPaymentMethodDialog("NONE")}
      translateAxis="y"
      customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
      modalType="UPI_INTENT"
      dialogOverlay={true}>
      <DialogHeader>
        <Dialog.Title
          as="h3"
          className="flex h-full w-full flex-row items-center justify-between bg-white py-4 text-base font-medium text-carbon-dark">
          <div className="flex items-center">
            <h2 className="inline-flex gap-2 font-medium text-carbon-dark">{tileTitle}</h2>
          </div>
          <button className="outline-none">
            <X
              className="h-6 w-6 cursor-pointer text-coal-dark"
              onClick={() => setPaymentMethodDialog("NONE")}
            />
          </button>
        </Dialog.Title>
      </DialogHeader>
      <DialogBody className="flex flex-col px-3 pb-36">
        <>
          {isMobile() && !responseErr && upiIntentUrl && (
            <div className="box-border w-full">
              <div className="flex w-full flex-col rounded-2xl border border-[#F0F0F0]">
                <ul className="flex w-full flex-row items-center justify-around py-4">
                  {upiApps?.map((method) => (
                    <li key={method.id} onClick={() => openUPIApp(method.id)}>
                      <MethodLogo logo={method.logo} name={method.name} id={method.id} isLoading={isLoading} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="mt-3">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col space-y-6 rounded-2xl border border-[#F0F0F0] p-3 "
              id="card-form">
              <InputField
                type="text"
                placeholder={t("upi_placeholder")}
                {...inputProps(FormField.id)}
                autoComplete="off"
                error={errors.id}
              />
              <PrimaryButton
                type="submit"
                buttonText={t("proceed_to_pay")}
                isCheckout={false}
                isDisabled={!values?.id?.length || errors?.id?.status}
              />
            </form>
          </div>
        </>
      </DialogBody>
    </GenericDialog>
  );
};

export default UPIIntent;
