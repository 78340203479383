import { XIcon } from "@heroicons/react/solid";
import OTPForm from "components/auth/forms/OTPForm";
import { DialogBody, DialogHeader } from "components/common/dialogs/GenericDialog";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import useForm from "lib/hooks/useForm";
import { useLocale } from "lib/hooks/useLocale";
import useReCaptcha from "lib/hooks/useReCaptcha";
import { YotpoLoyaltyType } from "lib/types/checkout";
import { OTPChannel, OTPVerificationDetails } from "lib/types/user";
import { resentOtp, sendOtpWithPhoneNumber, verifyOtp } from "lib/utils/auth";
import { analyticsEvents } from "lib/utils/constants";
import React, { useEffect, useState } from "react";

interface OTPDialogProps {
  setIsOpen: (value: boolean) => void;
  setIsVerified: (value: boolean) => void;
  refreshLoyalty: () => Promise<void>;
  loyaltyData: YotpoLoyaltyType;
}

const OTPDialog: React.FC<OTPDialogProps> = ({ setIsOpen, setIsVerified, loyaltyData, refreshLoyalty }) => {
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const {
    state: { user },
  } = useUserContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const { t } = useLocale();
  const {
    state: { shopifySessionId },
  } = useCheckoutContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(-1);
  const [counterExpired, setCounterExpired] = useState<boolean>(false);
  const [resendOTPCounter, setResendOTPCounter] = useState<number>(0);
  const [wrongOTPCounter, setWrongOTPCounter] = useState<number>(0);
  const [contextId, setContextId] = useState<string>("");
  const { generateReCaptchaToken } = useReCaptcha();

  const {
    handleSubmit,
    setValueOf,
    setErrors,
    state: { values, errors },
  } = useForm({
    initialState: { otp: "" },
    validationSchema: {
      otp: {
        required: t("enter_your_otp"),
        numeric: t("otp_incorrect"),
        length: {
          limit: 4,
          message: t("otp_incorrect"),
        },
        formatters: ["NUMERIC"],
      },
    },
  });

  useEffect(() => {
    sendOtp();
  }, []);

  useEffect(() => {
    if (counter === 0) setCounterExpired(true);
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const sendOtp = async () => {
    try {
      setIsLoading(true);
      setErrors({ otp: { status: false, showAlert: false } });
      setValueOf("otp", "");
      let verificationDetails: OTPVerificationDetails = {
        identifier: loyaltyData?.email ?? "",
        merchantId: merchant?.merchantId ?? "",
        userId: user?.uid,
        forceVerification: true,
      };
      const reCaptchaToken = await generateReCaptchaToken("login");
      const result = await sendOtpWithPhoneNumber(verificationDetails, { context: "AUTH" }, reCaptchaToken);
      setCounter(30);
      setCounterExpired(false);
      setResendOTPCounter((oldValues) => oldValues + 1);
      setIsLoading(false);
      if (!Boolean(result)) return;
      setContextId(result.context_id);
    } catch (err: any) {
      setIsLoading(false);
      if (err?.response?.status === 400) {
        setErrors({
          phoneNumber: {
            status: true,
            message: t("invalid_email"),
          },
        });
      }
    }
  };

  const validateOtp = async (otp: string) => {
    if (Boolean(otp) && otp?.length !== 4) return;
    setErrors({ otp: { status: false } });
    setIsLoading(true);

    try {
      // OTP entered event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_AUTH_OTP_ENTERED,
        eventType: "click",
      });
      await verifyOtp(contextId, otp, true);
      setIsVerified(true);
      setIsLoading(false);
      refreshLoyalty();
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
      setErrors({ otp: { status: true, message: t("otp_incorrect") } });
      setWrongOTPCounter((oldValues) => oldValues + 1);
    }
  };

  const handleResendOtp = async (channel: OTPChannel) => {
    setIsLoading(true);
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_AUTH_OTP_RESEND,
      eventType: "click",
    });
    setValueOf("otp", "");
    try {
      await resentOtp(contextId, channel);
      setCounter(30);
      setCounterExpired(false);
      setResendOTPCounter((oldValues) => oldValues + 1);
    } catch (err) {
      console.error(err);
    }
    setErrors({ otp: { status: false } });
    setIsLoading(false);
  };

  useEffect(() => {
    if (values.otp && values.otp.length === 4) {
      validateOtp(values.otp);
    }
  }, [values.otp]);

  return (
    <>
      <DialogHeader>
        <div className="flex h-full w-full flex-row items-center justify-between ">
          <h1 className="text-base font-medium"> {t("confirm_email")}</h1>
          <button className="outline-none">
            <XIcon className="h-6 w-6 cursor-pointer text-coal-dark" onClick={() => setIsOpen(false)} />
          </button>
        </div>
      </DialogHeader>
      <DialogBody>
        <div className="px-5">
          <OTPForm
            isLoading={Boolean(isLoading || isLoading)}
            otpLength={4}
            otpValue={values.otp ?? ""}
            handleSubmit={handleSubmit(validateOtp)}
            handleChange={(code: string) => {
              setValueOf("otp", code);
              if (errors?.otp?.status) {
                setErrors({ otp: { status: false } });
              }
            }}
            validateOtp={validateOtp}
            handleResendOtp={handleResendOtp}
            error={errors?.otp?.status || false}
            errorMessage={errors?.otp?.message || ""}
            autoFocus={true}
            otpCounter={counter}
            otpCounterExpired={counterExpired}
            emailValidation={true}
            headerComponent={
              <div className={`flex w-full flex-row items-center justify-center pb-5`}>
                <h1 className="text-sm font-normal text-coal-dark">
                  {t("verify_otp_header")}
                  &nbsp;{loyaltyData?.email}
                </h1>
              </div>
            }
          />
        </div>
      </DialogBody>
    </>
  );
};

export default OTPDialog;
