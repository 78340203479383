import React, { ChangeEvent, useEffect, useState, useCallback, useMemo } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { ArrowLeft, ArrowRight, Gift, Tag } from "react-feather";
import InputField from "components/common/forms/InputField";
import { DialogHeader, DialogBody } from "components/common/dialogs/GenericDialog";
import { Dialog } from "@headlessui/react";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import GenericButton from "components/common/buttons/GenericButton";
import DiscountBadge from "assests/images/discount-badge.svg?react";
import { analyticsEvents } from "lib/utils/constants";
import { AppliedCouponType } from "lib/contexts/CheckoutProvider";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import { isCouponStackable, isOnlyFreebieAutoApplied } from "lib/utils/checkout";
import { ErrorType } from "lib/types/address";
import { currencyFormatter } from "lib/utils/formatters";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import CalloutCard from "components/coupons/cards/CalloutCard";
import credLogo from "assests/images/credLogo.svg";
import tickerTapeLogo from "assests/images/tickerTapeLogo.svg";
import { SavingItemsType } from "lib/types/checkout.ts";
import CouponSummaryStrip from "components/common/CouponSummaryStrip.tsx";

interface CouponDialogProps {
  setOpenDialog: () => void;
  handleDiscountApply: (code: string, isManual: boolean) => void;
  handleDeleteCoupon: (couponToBeDeleted: string) => void;
  savingItems: SavingItemsType;
  couponDisplayType?: string;
  isCouponsLoading: boolean;
  couponApplyError: ErrorType;
  setCouponApplyError: (value: React.SetStateAction<ErrorType>) => void;
}

interface CouponCardProps {
  couponCode: string;
  couponHeader: string;
  couponTitle: string;
  couponDesc: {
    title: string;
    description: string[];
  };
  moreInfo: string[];
  isApplicable: boolean;
  unApplicabilityReason: string;
  isStackable: boolean;
  totalDiscount: number;
  isAutoApplicable: boolean;
}

interface ProductSelectorCardProps {
  coupon: AppliedCouponType;
}

const ReplaceCouponDialog = React.lazy(() => import("../../checkout/dialogs/ReplaceCouponDialog.tsx"));

const CouponDialog: React.FC<CouponDialogProps> = ({
  setOpenDialog,
  handleDiscountApply,
  handleDeleteCoupon,
  savingItems,
  couponDisplayType,
  isCouponsLoading,
  couponApplyError,
  setCouponApplyError,
}) => {
  const { t } = useLocale();
  const {
    state: { coupons, appliedCoupons, billing },
    actions: { setCheckoutModal, setDiscountProductSelectorModal },
  } = useCheckoutContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const {
    state: { merchant },
  } = useMerchantContext();

  const nonRewardAppliedCoupons = useMemo(
    () => appliedCoupons.filter((coupon) => !coupon.isReward),
    [appliedCoupons],
  );

  const rewardDiscounts = useMemo(
    () => appliedCoupons?.filter((coupon) => coupon.isReward),
    [appliedCoupons],
  );

  const productSelectorDiscounts = useMemo(
    () => appliedCoupons?.filter((coupon) => coupon.isProductSelector),
    [appliedCoupons],
  );

  const [manualCoupon, setManualCoupon] = useState<string>("");
  // const [openWhoopsPopup, setOpenWhoopsPopup] = useState<boolean>(false);
  const [openReplaceCouponDialog, setOpenReplaceCouponDialog] = useState<boolean>(false);
  const [selectedCoupon, setSelectedCoupon] = useState<string>("");

  useEffect(() => {
    //coupons loaded event
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_COUPON_LOADED,
      eventType: "load",
      metaData: {
        couponData: { coupons_list: coupons },
      },
    });

    return () => {
      setCouponApplyError({ status: false, message: "" });
    };
  }, []);

  const onlyFreebiesApplied = useCallback(
    () => nonRewardAppliedCoupons.every((coupon: AppliedCouponType) => coupon?.isFreebie),
    [nonRewardAppliedCoupons],
  );

  const handleCouponBack = useCallback(() => {
    //coupon back event
    setOpenDialog();
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_BACK_CLICK,
      eventType: "click",
    });
  }, [sendAnalyticsEvent, setOpenDialog]);

  const onApplyClick = (couponCode: string, isStackable: boolean) => {
    setSelectedCoupon(couponCode);
    if (Boolean(isStackable)) {
      setOpenReplaceCouponDialog(true);
      return;
    }
    handleDiscountApply(couponCode, false);
    setOpenReplaceCouponDialog(false);
  };

  const onManualCouponApply = (couponCode: string) => {
    setSelectedCoupon(couponCode);
    if (isCouponStackable(coupons, couponCode)) {
      setOpenReplaceCouponDialog(true);
      return;
    }
    handleDiscountApply(couponCode, true);
    setOpenReplaceCouponDialog(false);
  };

  const mapRewardLogo = (walletType: string) => {
    switch (walletType) {
      case "CRED":
        return credLogo;
      case "TICKERTAPE":
        return tickerTapeLogo;
      default:
        return credLogo;
    }
  };

  const mapRewardTitle = (walletType: string) => {
    switch (walletType) {
      case "CRED":
        return t("cred_reward_header");
      case "TICKERTAPE":
        return t("ticker_tape_reward_header");
      default:
        return t("cred_reward_header");
    }
  };

  const mapRewardHeader = (walletType: string, amount: number | string) => {
    switch (walletType) {
      case "CRED":
        return t("cashback_amount", { amount });
      case "TICKERTAPE":
        return t("ticker_tape_gold_amount", { amount });
      default:
        return t("cashback_amount", { amount });
    }
  };

  const mapRewardDescription = (walletType: string) => {
    switch (walletType) {
      case "CRED":
        return t("claim_cred_cashback");
      case "TICKERTAPE":
        return t("claim_ticker_tape_cashback");
      default:
        return t("claim_cred_cashback");
    }
  };

  const CouponCard: React.FC<CouponCardProps> = React.memo(
    ({
      couponCode,
      couponHeader,
      couponTitle,
      couponDesc,
      moreInfo,
      isApplicable,
      unApplicabilityReason,
      isStackable,
      totalDiscount,
      isAutoApplicable,
    }) => {
      console.log("couponCode", couponCode);
      const [showMore, setShowMore] = useState<boolean>(false);
      const greyedOut = !isApplicable && !isStackable;
      return (
        <div
          className={`group relative box-border flex h-full min-h-[10rem] w-full flex-col items-start overflow-hidden rounded-2xl border-[1px] border-gray-light`}>
          <div
            className={`flex w-full flex-row items-center justify-between rounded-lg bg-gradient-to-b py-2 pl-3 pr-2 ${
              greyedOut ? "from-gray-lighter to-white" : "from-primary-lighter to-white"
            }`}>
            {/* Coupon Ticket */}
            <div className={`flex flex-row items-center ${greyedOut ? "w-full" : "w-3/4"}`}>
              {/* If no brand logo we will use this logo */}
              {/* { brandLogo ? <img src={BrandLogo} alt="Brand Logo" className="object-contain object-center w-8 h-8 pt-1" /> : */}
              <div className="flex h-8 w-8 items-center justify-start">
                <DiscountBadge
                  className={`mr-2 h-6 w-6 text-coal-light ${
                    greyedOut ? "fill-gray-dark" : "fill-primary-dark"
                  }`}
                />
              </div>

              <h3 className="truncate pr-2 text-base font-semibold uppercase text-coal-dark">
                {Boolean(couponTitle) ? couponTitle : couponCode}
              </h3>
            </div>

            {!greyedOut && (
              <GenericButton
                customClass={`flex items-center justify-center object-contain rounded-lg
              text-base font-medium text-btnPrimaryText bg-primary-dark`}
                width="w-24"
                height="h-10"
                onClick={() => {
                  !greyedOut && onApplyClick(couponCode, isStackable);
                }}
                buttonText={t("apply_btn")}
              />
            )}
          </div>
          <div className="group relative flex min-h-[6.5rem] w-full flex-col items-start justify-between gap-2 overflow-x-hidden px-3 pb-4">
            <div className="flex flex-col">
              <div className="flex flex-col space-y-1">
                <h3 className="text-base font-semibold text-coal-dark">{couponHeader}</h3>
                {couponDesc && couponDesc.title && (
                  <p className="text-sm font-normal text-coal-light">{couponDesc.title}</p>
                )}
              </div>
            </div>
            {!greyedOut && totalDiscount > 0 && (
              <p className="text-xs font-medium text-yay-dark">
                {nonRewardAppliedCoupons?.filter((coupon) => !(coupon?.isFreebie && coupon?.autoApplied))
                  .length > 0
                  ? t("save_x_more", { price: totalDiscount })
                  : t("save_x", { price: totalDiscount })}
              </p>
            )}
            {greyedOut && <p className="text-xs font-medium text-ouch">{unApplicabilityReason}</p>}
            {showMore ? (
              <ul className="flex w-full flex-col space-y-1 rounded-lg bg-gray-lighter px-2 py-3">
                {moreInfo?.map((point: string, index: number) => (
                  <li
                    className="ml-4 list-disc text-xs font-normal text-coal-light"
                    key={`${couponCode}--${index}`}>
                    <p>{point}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <button
                onClick={() => setShowMore(true)}
                className="w-fit text-sm font-medium text-coal-dark outline-none">
                {t("more")}
              </button>
            )}
          </div>
        </div>
      );
    },
  );

  const ProductSelectorCard: React.FC<ProductSelectorCardProps> = React.memo(({ coupon }) => {
    const [showMore, setShowMore] = useState<boolean>(false);
    return (
      <div
        className={`group relative box-border flex h-full min-h-[10rem] w-full flex-col items-start overflow-hidden rounded-2xl border-[1px] border-gray-light`}>
        <div
          className={`flex w-full flex-row items-center justify-between rounded-lg bg-gradient-to-b py-2 pl-3 pr-2 from-primary-lighter to-white`}>
          {/* Coupon Ticket */}
          <div className={`flex flex-row items-center w-3/4`}>
            {/* If no brand logo we will use this logo */}
            {/* { brandLogo ? <img src={BrandLogo} alt="Brand Logo" className="object-contain object-center w-8 h-8 pt-1" /> : */}
            <div className="flex h-8 w-8 items-center justify-start">
              <Gift className={`mr-2 h-6 w-6 text-primary-dark`} />
            </div>

            <h3 className="truncate pr-2 text-base font-semibold uppercase text-coal-dark">
              {Boolean(coupon.title) ? coupon.title : coupon.code}
            </h3>
          </div>
          <p
            className="text-sm font-semibold text-coal-dark py-2 px-4 cursor-pointer"
            onClick={() => setDiscountProductSelectorModal("DISCOUNTED_PRODUCT_SELECTOR")}>
            {t("view")}
          </p>
        </div>
        <div className="group relative flex min-h-[6.5rem] w-full flex-col items-start justify-between gap-2 overflow-x-hidden px-3 pb-4">
          <div className="flex flex-col">
            <div className="flex flex-col space-y-1">
              <h3 className="overflow-x-hidden text-base font-semibold text-coal-dark">
                {coupon.rewardData?.header}
              </h3>
              <p className="text-sm font-normal text-coal-light">{coupon.rewardData?.description}</p>
            </div>
          </div>
          {showMore ? (
            <ul className="flex w-full flex-col space-y-1 rounded-lg bg-gray-lighter px-2 py-3">
              {coupon.rewardData?.longDescription?.map((point: string, index: number) => (
                <li
                  className="ml-4 list-disc text-xs font-normal text-coal-light"
                  key={`${coupon.code}--${index}`}>
                  <p>{point}</p>
                </li>
              ))}
            </ul>
          ) : (
            <button
              onClick={() => setShowMore(true)}
              className="w-fit text-sm font-medium text-coal-dark outline-none">
              {t("more")}
            </button>
          )}
        </div>
      </div>
    );
  });

  return (
    <React.Fragment>
      <DialogHeader className="!px-3">
        <div className="flex w-full flex-col rounded-t-inherit pb-4">
          <Dialog.Title
            as="div"
            className="flex h-16 w-full flex-row items-center rounded-t-inherit bg-white px-3">
            <div
              className="-ml-3 flex h-12 w-12 cursor-pointer items-center justify-center"
              onClick={handleCouponBack}>
              <ArrowLeft className="h-6 w-6 text-coal-dark" />
            </div>
            <div className="jutify-center flex flex-col gap-0.5">
              <h1 className="text-base font-medium text-coal-dark">{t("coupons")}</h1>
              <p className="text-xs font-normal text-gray-dark">
                {t("cart_value")}&nbsp;·&nbsp;
                {currencyFormatter(billing.sub_total)}
              </p>
            </div>
            {/* <h1 className="text-base font-medium text-coal-dark">{t("apply_coupons")}</h1>
            <h1 className="ml-auto text-base font-medium text-coal-dark">
              <Price
                total={billing?.total_payable ?? ""}
                compareAt={
                  Boolean((billing?.sub_total ?? 0) > (billing?.total_payable ?? 0)) ? billing?.sub_total : ""
                }
              />
            </h1> */}
          </Dialog.Title>
          <div className="border-box relative !mb-3 flex w-full gap-1.5 ">
            <Tag
              className={`absolute left-4 top-[1.3rem] z-10 h-4 w-4 text-coal-dark ${
                !Boolean(coupons?.length) && Boolean(nonRewardAppliedCoupons?.length)
                  ? "top-5"
                  : "top-[1.3rem]"
              }`}
              strokeWidth={2.5}
            />
            <InputField
              type="text"
              placeholder={t("enter_coupon_code")}
              name="coupon-code"
              id="coupon-code"
              maxLength={50}
              autoComplete="off"
              autoFocus={false}
              value={manualCoupon}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setManualCoupon(e.target.value.trim())}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                e.key === "Enter" && handleDiscountApply(manualCoupon, true)
              }
              customClass={`h-[3.25rem] border border-gray-light !rounded-2xl  placeholder-gray-dark text-sm font-normal text-coal-light w-full !pl-[2.4rem]  ${
                Boolean(manualCoupon?.length) ? "!rounded-r-lg" : ""
              }`} // on focus remove 2px padding from left to avoid placeholder shifing because of the ring
              showLabel={false}
              tabIndex={-1}
              error={couponApplyError}
            />

            {Boolean(manualCoupon?.length) && (
              <GenericButton
                buttonText=""
                height="h-12"
                width=""
                customClass="flex items-center justify-center rounded-l-lg w-14 rounded-2xl bg-primary-dark"
                iconComponent={<ArrowRight className="h-4 w-4 text-btnPrimaryText" />}
                onClick={() => handleDiscountApply(manualCoupon, true)}
              />
            )}
          </div>
        </div>
      </DialogHeader>
      <DialogBody className="scrollbar-hide">
        <div className={couponApplyError?.status ? "pt-[92px]" : "pt-[72px]"}>
          {Boolean(nonRewardAppliedCoupons?.length) && (
            <div className="mx-3 mb-3 rounded-2xl bg-yay-lighter">
              {!Boolean(isOnlyFreebieAutoApplied(nonRewardAppliedCoupons)) && (
                <div className={couponApplyError?.status ? "mt-3" : "mt-0"}>
                  <CouponSummaryStrip
                    showAppliedCount={true}
                    showRewards={false}
                    showSavings={true}
                    showAppliedCoupons={true}
                    savingItems={savingItems}
                    couponDisplayType={couponDisplayType}
                    handleDeleteCoupon={handleDeleteCoupon}
                    defaultOpen={true}
                    parent="DIALOG"
                  />
                </div>
              )}
            </div>
          )}

          {rewardDiscounts?.map((discount) => (
            <div className="mx-3 mb-3 overflow-hidden rounded-2xl border border-gray-light">
              <div className="rounded-[14px] ">
                <div className="to-[#94949400)] bg-gradient-to-b from-[#9494941A] from-0% to-100%">
                  <div className="flex items-center justify-between px-3 py-5 text-sm font-medium text-yay-dark">
                    <div className="flex items-center space-x-2 text-coal-dark">
                      <img
                        src={mapRewardLogo(discount.couponRewardConfig?.walletType ?? "CRED")}
                        alt={`${discount.couponRewardConfig?.walletType ?? "CRED"}-logo`}
                        className={`rounded-[2px]`}
                      />
                      {/* <span className="text-base font-semibold">{discount.rewardData?.header}</span> */}
                      <span className="text-base font-semibold">
                        {mapRewardTitle(discount.couponRewardConfig?.walletType ?? "CRED")}
                      </span>
                    </div>
                    {discount.couponRewardConfig?.walletType === "TICKERTAPE" ? (
                      <span
                        className="text-xs text-coal-dark font-semibold underline cursor-pointer"
                        onClick={() => handleDeleteCoupon(discount.code)}>
                        {t("remove")}
                      </span>
                    ) : (
                      <span className="text-xs font-semibold">{t("unlocked")}</span>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-2 px-3 pb-2 text-sm font-medium text-coal-dark">
                  <div className="text-base font-semibold text-yay-dark">
                    {mapRewardHeader(
                      discount.couponRewardConfig?.walletType ?? "CRED",
                      discount.couponRewardConfig?.amount ?? 0,
                    )}
                  </div>
                  <div className="text-xs font-medium text-coal-dark">
                    {mapRewardDescription(discount.couponRewardConfig?.walletType ?? "CRED")}
                  </div>
                </div>

                {Boolean(discount?.rewardData?.longDescription?.length) ? (
                  <div className="px-3">
                    <details className="group pb-3 text-[#4d4d4d]">
                      <summary className="flex select-none list-none items-center text-xs font-semibold group-open:hidden">
                        {t("more")}
                        {/* <ChevronDown className="h-[14px] w-[14px] rotate-0 transform transition-all duration-300 group-open:-rotate-180" /> */}
                      </summary>
                      <ul className="flex w-full flex-col space-y-1 rounded-lg bg-gray-lighter px-2 py-3">
                        {discount?.rewardData?.longDescription.map((item, index) => (
                          <li
                            className="ml-4 list-disc text-xs font-normal text-coal-light"
                            key={`${item}--${index}`}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </details>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
          <div className="overflow-x-hidden px-3">
            {Boolean(isCouponsLoading) && (
              <ul className="flex flex-col space-y-4">
                <li key="coupon-loader-1">
                  <CouponCardSkeletonLoader />
                </li>
                <li key="coupon-loader-2">
                  <CouponCardSkeletonLoader />
                </li>
              </ul>
            )}
            {Boolean(productSelectorDiscounts.length) && (
              <div className="flex flex-col space-y-4">
                <ul className="flex flex-col space-y-4">
                  {productSelectorDiscounts?.map((coupon: AppliedCouponType) => (
                    <ProductSelectorCard coupon={coupon} />
                  ))}
                </ul>
                <p className="pb-3 text-xs font-semibold uppercase text-coal-light">
                  {t("available_coupons")}
                </p>
              </div>
            )}

            {Boolean(coupons?.length) && !Boolean(isCouponsLoading) && (
              <>
                <ul className="flex flex-col space-y-4">
                  {coupons?.map((coupon: any) => {
                    return coupon?.coupon_details?.coupon_type !== "CALLOUT_CARD" &&
                      (coupon.is_applicable ||
                        coupon?.un_applicability_reason?.reason === "UARC_002" ||
                        coupon?.un_applicability_reason?.reason === "UARC_007") ? (
                      <li key={coupon.coupon_details?.coupon_code}>
                        <CouponCard
                          isApplicable={
                            coupon.is_applicable ||
                            Boolean(coupon?.un_applicability_reason?.reason === "UARC_007") ||
                            coupon?.un_applicability_reason?.reason === "UARC_007"
                          }
                          couponCode={coupon.coupon_details?.coupon_code}
                          couponHeader={coupon.coupon_details?.header}
                          couponTitle={coupon.coupon_details?.title}
                          couponDesc={coupon.coupon_details?.description}
                          moreInfo={coupon.coupon_details?.long_description}
                          unApplicabilityReason={coupon.un_applicability_reason?.description}
                          isStackable={Boolean(coupon?.un_applicability_reason?.reason === "UARC_002")}
                          totalDiscount={coupon?.total_discount}
                          isAutoApplicable={coupon?.coupon_details?.auto_applicable}
                        />
                      </li>
                    ) : (
                      ""
                    );
                  })}
                </ul>

                {coupons?.some((coupon: any) => coupon?.coupon_details?.coupon_type === "CALLOUT_CARD") ? (
                  <p className="pb-3 pt-5 text-xs font-semibold uppercase text-coal-light">
                    {t("additional_offers")}
                  </p>
                ) : (
                  ""
                )}
                <div className="flex flex-col space-y-4">
                  <ul className="flex flex-col space-y-4">
                    {coupons?.map((coupon: any) => {
                      return coupon?.is_applicable &&
                        coupon?.coupon_details?.coupon_type === "CALLOUT_CARD" ? (
                        <li key={coupon.coupon_details?.coupon_code}>
                          <CalloutCard
                            isApplicable={coupon?.is_applicable}
                            couponTitle={coupon.coupon_details?.title}
                            couponHeader={coupon.coupon_details?.header}
                            couponDescription={coupon.coupon_details?.description}
                            couponLongDescription={coupon.coupon_details?.long_description}
                            unApplicabilityReason={coupon.un_applicability_reason?.description}
                          />
                        </li>
                      ) : (
                        ""
                      );
                    })}
                  </ul>
                  <ul className="flex flex-col space-y-4">
                    {coupons?.map((coupon: any) => {
                      return !coupon?.is_applicable &&
                        coupon?.coupon_details?.coupon_type === "CALLOUT_CARD" ? (
                        <li key={coupon.coupon_details?.coupon_code}>
                          <CalloutCard
                            isApplicable={coupon?.is_applicable}
                            couponTitle={coupon.coupon_details?.title}
                            couponHeader={coupon.coupon_details?.header}
                            couponDescription={coupon.coupon_details?.description}
                            couponLongDescription={coupon.coupon_details?.long_description}
                            unApplicabilityReason={coupon.un_applicability_reason?.description}
                          />
                        </li>
                      ) : (
                        ""
                      );
                    })}
                  </ul>
                </div>

                {coupons?.some(
                  (coupon: any) =>
                    coupon?.is_applicable === false && coupon?.coupon_details?.coupon_type !== "CALLOUT_CARD",
                ) ? (
                  <p className="pb-3 pt-5 text-xs font-semibold uppercase text-coal-light">
                    {t("unavailable_coupons")}
                  </p>
                ) : (
                  ""
                )}
                <ul className="flex flex-col space-y-4">
                  {coupons?.map((coupon: any) => {
                    return coupon?.coupon_details?.coupon_type !== "CALLOUT_CARD" &&
                      !coupon?.is_applicable &&
                      coupon?.un_applicability_reason?.reason !== "UARC_002" &&
                      coupon?.un_applicability_reason?.reason !== "UARC_007" ? (
                      <li key={coupon.coupon_details?.coupon_code}>
                        <CouponCard
                          isApplicable={coupon.is_applicable}
                          couponCode={coupon.coupon_details?.coupon_code}
                          couponHeader={coupon.coupon_details?.header}
                          couponTitle={coupon.coupon_details?.title}
                          couponDesc={coupon.coupon_details?.description}
                          moreInfo={coupon.coupon_details?.long_description}
                          unApplicabilityReason={coupon.un_applicability_reason?.description}
                          isStackable={Boolean(coupon?.un_applicability_reason?.reason === "UARC_002")}
                          totalDiscount={coupon?.total_discount}
                          isAutoApplicable={coupon?.coupon_details?.auto_applicable}
                        />
                      </li>
                    ) : (
                      ""
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
        {/* <div className="flex justify-center w-full px-20">
          <div className="w-full h-6 border-b border-b-gray-light"></div>
        </div> */}
      </DialogBody>
      {/* <GenericPopup
        content={
          <>
            <div className="flex flex-col justify-between w-full h-full py-2">
              <p className="text-lg font-medium text-coal-dark">{t("discounts_whoops_title")}</p>
              <p className="text-sm font-normal text-coal-light">{t("discounts_whoops_description")}</p>
            </div>
          </>
        }
        isOpen={openWhoopsPopup}
        duration={2000}
        closePopup={closeCouponPopup}
        height={"h-36"}
        width={"w-3/4"}
      /> */}
      <GenericDialog
        isOpen={openReplaceCouponDialog}
        setIsOpen={(value: boolean) => setOpenReplaceCouponDialog(value)}
        translateAxis="y"
        customClass="overflow-scroll md:!top-auto md:absolute"
        dialogOverlay={true}
        modalType="REPLACE_COUPON">
        <ReplaceCouponDialog
          setIsOpen={() => setOpenReplaceCouponDialog(false)}
          applyCoupon={async () => {
            await handleDiscountApply(selectedCoupon, false);
            setOpenReplaceCouponDialog(false);
          }}
        />
      </GenericDialog>
    </React.Fragment>
  );
};

const CouponCardSkeletonLoader = () => {
  return (
    <>
      <div
        className={`group relative box-border flex min-h-[10rem] w-full flex-col items-start rounded-2xl border-[1px] border-gray-light py-2 pl-3 pr-2`}>
        <div className="flex w-full flex-col space-y-2">
          <div className="skeleton-loader h-4 w-3/4 rounded"></div>
          <div className="skeleton-loader h-4 w-1/2 rounded"></div>
          <div className="skeleton-loader h-4 w-1/2 rounded"></div>
        </div>
      </div>
    </>
  );
};

export default CouponDialog;
