export type PaymentMethodsType = {
  allowedPaymentMethods: {
    card: boolean;
    cod: boolean;
    credit_card: boolean;
    debit_card: boolean;
    emi: boolean;
    netbanking: boolean;
    upi: boolean;
    wallet: boolean;
  };
  wallets: {
    airtelmoney: boolean;
    freecharge: boolean;
    jiomoney: boolean;
    mobikwik: boolean;
    olamoney: boolean;
    payzapp: boolean;
  };
  netbankingBanks: string[];
};

export type NonPaymentMethodType = "COD" | "FREE" | "OFFLINE_PAYMENT";

export type PaymentStatusType =
  | "PROCESSING"
  | "PROCESSING_UPI"
  | "DRAFT"
  | "ORDER_FAILED"
  | "PAYMENT_FAILED"
  | "PAYMENT_CANCELLED"
  | "PLACING_ORDER";

export type RzpPaymentModes =
  | "upi"
  | "wallet"
  | "netbanking"
  | "card"
  | "paylater"
  | "emi"
  | "cred"
  | "paypal";

export type RzpAvailableWallets =
  | "freecharge"
  | "payzapp"
  | "olamoney"
  | "phonepe"
  | "airtelmoney"
  | "mobikwik"
  | "jiomoney"
  | "amazonpay"
  | "paypal"
  | "phonepeswitch";

export type RzpBaseOptions = {
  amount: number;
  currency?: string;
  rzpId: string;
  checkoutId?: string;
  merchantId?: string;
  merchantName?: string;
  userPhone: string;
  userEmail: string;
  redirectUrl?: string;
  accountId?: string;
  method: RzpPaymentModes;
};

export type RzpOptionsCustom = RzpBaseOptions & {
  userId: string;
  onFailureHandler: (method?: string, response?: any) => void;
  methodPayload: any;
};

export type RzpCustomImplCard = {
  method: "card";
  methodPayload: RzpCustomImplCardPayload;
};

export type RzpCustomImplCardPayload = {
  card: {
    number: number;
    name: string;
    expiry_month: number;
    expiry_year: number;
    cvv: number;
  };
};

export type RzpCustomImplWallet = {
  method: "wallet";
  methodPayload: RzpCustomImplWalletPayload;
};

export type RzpCustomImplWalletPayload = {
  wallet: RzpAvailableWallets;
};

export type RzpCustomImplNetbanking = {
  method: "netbanking";
  methodPayload: RzpCustomImplNetbankingPayload;
};

export type RzpCustomImplNetbankingPayload = {};

export type RzpCustomImplUpi = {
  method: "upi";
  methodPayload: {};
};

export type RzpCustomImplPaylater = {
  method: "paylater";
  methodPayload: {};
};

export type RzpCustomImplEmi = {
  method: "emi";
  methodPayload: {};
};

export type RzpOptionsDefault = RzpBaseOptions & {
  method: string;
  brandLogoUrl?: string;
  brandThemeColor?: string;
  userName?: string;
  onSuccessHandler: (method: string, response: any) => void;
};

export interface CfBaseOptions {
  orderToken: string;
  onError?: () => void;
}

export type PaymentModeType =
  | "CARD"
  | "UPI_COLLECT"
  | "UPI_INTENT"
  | "UPI"
  | "WALLET"
  | "NETBANKING"
  | "EMI"
  | "PAY_LATER"
  | "COD"
  | "SNPM"
  | "LZP"
  | "SAVED_MODES"
  | "CASHFREE"
  | "CRED"
  | "GC"
  | "PL"
  | "LOYALTY"
  | "PAYPAL"
  | "BHARATX"
  | "REWARDS_WALLET"
  | "OFFLINE_PAYMENT"
  | "CF"
  | "SPLIT_COD"
  | "SIMPL"
  | "SIMPL_PL";

export type UpiIntentApps = "phonepe" | "gpay" | "paytm" | "others";

export type RzpWalletsType =
  | "airtelmoney"
  | "freecharge"
  | "jiomoney"
  | "mobikwik"
  | "olamoney"
  | "payzapp"
  | "paypal"
  | "phonepe"
  | "amazonpay";

export type PaymentGateways = "RP" | "CF" | "SNPM" | "PAYU" | "PAYTM" | "PHONEPE" | "BHARATX" | "EASEBUZZ" | "SIMPL";

export type PaymentGatewayImpl = "DEFAULT" | "CUSTOM";

export type MethodType = {
  [key in PaymentModeType]?: {
    logo: any;
    header: string;
    subheader: string;
    mappers?: { RP?: RzpPaymentModes; CF?: CfDefaultPaymentModes };
    isDisabled?: boolean;
  };
};
export type PAYUIframeData = {
  txnid: string;
  amount: number;
  productinfo: string;
  firstname: string;
  phone: string;
  email: string;
  surl: string;
  furl: string;
  udf5: string;
  enforce_paymethod: string;
  pg: string;
  key: string;
  hash: string;
};

//ToDo: Make the type keys conditional based on Payment gateways
export type InitializePayment = {
  amount: number;
  pgOrderId: string;
  pg: PaymentGateways;
  pgImpl: PaymentGatewayImpl;
  pgRedirectUrl?: string;
  pgAction?: "new_tab" | "redirect";
  pgAccountId?: string;
  paymentMode: PaymentModeType;
  userId: string;
  userName: string;
  userPhone: string;
  userEmail: string;
  checkoutId: string;
  merchantId?: string;
  merchantName?: string;
  currency?: string;
  brandLogoUrl?: string;
  brandThemeColor?: string;
  onSuccessHandler: (...args: any) => void;
  onFailureHandler: (...args: any) => void;
  onCancelHandler: (...args: any) => void;
  redirectUrl?: string;
  methodPayload?: any;
  floOrderReference?: string;
  pgVersion?: any;
  // define this from payu's documentation
  pgData?: PAYUIframeData;
  shouldRenderIframe?: boolean;
};

export type PaymentInitiator = {
  pg: PaymentGateways;
  pgImpl: PaymentGatewayImpl;
  paymentMode: PaymentModeType;
  paymentModePayload?: any;
  pgOrderId?: string;
  redirectUrl?: string;
  pgCustomerId?: string;
  pgAccountId?: string;
};

export type CfDefaultPaymentModes =
  | "card"
  | "netbanking"
  | "app"
  | "upi"
  | "paylater"
  | "credicardemi"
  | "cardlessemi";

export interface CfCustomPayment {
  order_token: string;
  method: CfDefaultPaymentModes;
  methodPayload?: any;
}

export interface CfDefaultPayment {
  orderToken: string;
  paymentMode: string;
  brandThemeColor: string;
  onSuccessHandler: (method: string, response: any) => void;
  onFailureHandler: (method?: string, response?: any) => void;
  pgVersion?: string;
}

export type CfWalletType =
  | "gpay"
  | "phonepe"
  | "ola"
  | "paytm"
  | "amazon"
  | "airtel"
  | "freecharge"
  | "mobikwik"
  | "jio";

export type PaymentRetryModes =
  | "CHANGE_PAYMENT_METHOD"
  | "RETRY_PAYMENT"
  | "CONVERT_TO_COD"
  | "SEND_WHATSAPP_LINK"
  | "PLACE_ORDER_AGAIN"
  | "CLOSE_PAYMENT";

export interface PaymentStatusDetails {
  amount: number;
  paymentMode: string;
  primaryCTA: PaymentRetryModes;
  secondaryCTA?: PaymentRetryModes;
  failureReason?: string;
  failureSource?: string;
}

export type PaymentRedirectionModes = "PAYMENTS" | "COD";

export interface PaymentProcessingType {
  cancelPaymentCallback?: () => void;
  paymentStatus: PaymentStatusType;
}

export interface UnApplicablePaymentReasonType {
  type: string;
  values: [string];
}

export type EmiDetailsType = {
  tenure: number;
  remainingAmount: number;
  downPayment: number;
};

export enum PAYMENT_METHOD_MAPPINGS {
  COD = "COD_ORDER_PLACING",
  OFFLINE_PAYMENT = "OFFLINE_PAYMENT"
}

export type PaymentModeAutomationPayloadForEvents = {
  id: string;
  blockedModes: string[];
  allowedModes: string[];
};

export interface UPIIntentMethodType {
  logo: string;
  name: string;
  id: UpiIntentApps;
  intentUrl?: string;
  isLoading?: boolean;
}

