import { postRequest } from "lib/core/apiClient";

export const verifyCodOtp = async (contextId: string, otp: string, checkoutId: string) => {
  if (!contextId || !otp) return;

  try {
    const payload = {
      context_id: contextId,
      otp: otp,
      checkout_id: checkoutId,
    };

    const response = await postRequest(`/checkout/v1/checkout/${checkoutId}/cod-otp/verify`, payload);
    return response;
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const resentCodOtp = async (contextId: string, phone: string, checkoutId: string) => {
  if (!contextId) return;

  try {
    const payload = {
      context_id: contextId,
      phone,
      checkout_id: checkoutId,
    };

    const response = await postRequest(`/checkout/v1/checkout/${checkoutId}/cod-otp/resend`, payload);
    return response;
  } catch (e: any) {
    console.error(e);
    throw e;
  }
};
