import { XIcon } from "@heroicons/react/solid";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { DialogHeader, DialogBody } from "components/common/dialogs/GenericDialog";
import InputField from "components/common/forms/InputField";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { postRequest } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import { useState } from "react";
import { mutate } from "swr";
import GyftrIcon from "assests/images/GyftrLogo.png"

interface VoucherState {
  code: string;
  error: string;
  isLoading: boolean;
}

const AddCouponDialog = () => {
  const { t } = useLocale();
  const {
    state: { checkoutModal, checkoutId },
    actions: { setCheckoutModal },
  } = useCheckoutContext();

  const [voucherState, setVoucherState] = useState<VoucherState>({
    code: "",
    error: "",
    isLoading: false,
  });

  const handleAddVoucher = async () => {
    setVoucherState((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      const response: any = await postRequest(`/checkout/v2/checkout/${checkoutId}/gc/voucher/recharge`, {
        voucher_payment_mode: "GYFTR",
        voucher_code: voucherState.code,
      });
      if (response.status === "SUCCESS") {
        mutate([`/checkout/v2/checkout/${checkoutId}/gc/voucher?voucher_payment_mode=GYFTR`, "CHECKOUT"]);
        setCheckoutModal("GYFTR_REDEEM_VOUCHER_DIALOG");
      } else {
        setVoucherState((prev) => ({
          ...prev,
          error: response.message,
        }));
      }
    } catch (error) {
      setVoucherState((prev) => ({
        ...prev,
        error: "Unable to process voucher. Please try again.",
      }));
    } finally {
      setVoucherState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  return (
    <>
      <DialogHeader>
        <div className="flex h-full w-full flex-row items-center justify-between border-b border-dashed border-carbon-lighter py-4 mt-2">
          <div className="flex flex-row items-center gap-2">
            <img src={GyftrIcon} alt="Gyftr" className="w-6 h-6" />
            <h1 className={`text-base font-medium`}>{t("gift_voucher_or_epay")}</h1>
          </div>
          <button className="outline-none">
            <XIcon
              className="h-6 w-6 cursor-pointer text-coal-dark"
              onClick={() => {
                setCheckoutModal("NONE");
              }}
            />
          </button>
        </div>
      </DialogHeader>
      <DialogBody className="!pb-4 !pt-12">
        <div className="relative flex w-full flex-col space-y-3 px-6 pb-8 pt-4 mt-4">
          <div className="flex flex-col gap-2">
            <InputField
              type="text"
              label=""
              id="voucher_code"
              name="voucherCode"
              value={voucherState.code}
              onChange={(e) =>
                setVoucherState((prev) => ({
                  ...prev,
                  code: e.target.value,
                  error: "",
                }))
              }
              filled={Boolean(voucherState.code?.length)}
              placeholder="Enter gift voucher code"
              autoComplete="off"
              required={false}
            />
            {voucherState.error && <p className="text-xs px-2 pb-2 text-red-dark">{voucherState.error}</p>}
          </div>

          <PrimaryButton
            buttonText={t("add_gift_voucher_to_epay")}
            onClick={() => handleAddVoucher()}
            onTouchStart={() => handleAddVoucher()}
            height="h-14"
            isLoading={voucherState.isLoading}
            isDisabled={false}
          />
        </div>
      </DialogBody>
    </>
  );
};

export default AddCouponDialog;
