import React from "react";

interface SeperatorProps {
  title: string;
}

const Seperator: React.FC<SeperatorProps> = ({ title }) => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="w-6 border-[0.25px]"></div>
      <div className="px-2 text-xxs font-medium text-coal-light">{title}</div>
      <div className="w-6 border-[0.25px]"></div>
    </div>
  );
};

export default Seperator;
