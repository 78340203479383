import { deleteRequest, putRequest } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import { DialogBody, DialogHeader } from "components/common/dialogs/GenericDialog";
import { FloWalletData } from "lib/types/checkout";
import React, { useEffect, useMemo, useState } from "react";
import { Star } from "react-feather";
import { currencyFormatter } from "lib/utils/formatters";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import FloWalletPointsSelector from "components/payments/dialogs/FloWalletPointsSelector";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import editIcon from "assests/images/edit.svg";
import { classNames, floorToTwoDecimals } from "lib/utils/helpers";
import SwitchCouponDialog from "components/checkout/dialogs/SwitchCouponDialog";
import { getDefaultShippingHandle } from "lib/utils/checkout";
import useRewards from "lib/hooks/useRewards";
import { RewardsMode, RewardsPayloadType, RewardsUpdatePayload } from "lib/types/rewards";
import { RewardsProps } from "components/checkout/Rewards";
import { mutate } from "swr";
import { errorToast } from "lib/utils/toasters";
import { apiURI } from "lib/utils/constants";
import { AnimateLoading } from "components/animation/AnimateLoading";

const FloWallet: React.FC<RewardsProps> = ({ context = "checkout" }) => {
  const { t } = useLocale();
  const {
    state: {
      checkoutModal,
      checkoutId,
      appliedCoupons,
      appliedWalletData,
      hasDefaultShippingHandleSelected,
      shippingHandles,
      initialCheckoutStep,
      billing,
    },
    actions: { setCheckoutModal, updateCheckoutBasedOnCheckoutResponse },
  } = useCheckoutContext();

  const {
    state: { mode, rewardsConfig, isTileLoading, error },
    actions: { removeRewards, updateRewards },
  } = useRewards();

  const [isWalletCreated, setIsWalletCreated] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [floWalletData, setFloWalletData] = useState<FloWalletData>();
  const [openSelectorDialog, setOpenSelectorDialog] = useState<boolean>(false);
  const [showReplacementLoader, setShowReplacementLoader] = useState<boolean>(false);

  useEffect(() => {
    if (mode === RewardsMode.REWARDS_WALLET && rewardsConfig.mode === RewardsMode.REWARDS_WALLET) {
      const walletConfig = rewardsConfig.data;
      setFloWalletData(walletConfig.floWalletData);
      setIsWalletCreated(Boolean(walletConfig.isWalletCreated));
    }
  }, [appliedCoupons, appliedWalletData, isTileLoading, rewardsConfig]);

  const invalidDiscountCodes =
    floWalletData?.unavailabilityReasons.map((discount: any) => {
      if (discount?.type === "INVALID_DISCOUNTS") {
        return discount?.values;
      }
      return "";
    }) ?? [];

  const burnEnabled = Boolean(floWalletData?.burnEnabled);
  const earnEnabled = Boolean(floWalletData?.earnEnabled);

  const maxBurnableAmount = floWalletData?.maxBurnableAmount;

  const isRewardApplied = burnEnabled ? billing.rewards >= 0 : billing.rewards > 0;
  const shouldShowFloWallet = useMemo(() => {
    let showFloWallet = isWalletCreated && isRewardApplied;
    if (maxBurnableAmount === 0 || context === "cart") {
      showFloWallet = false;
    }
    return showFloWallet;
  }, [maxBurnableAmount, isWalletCreated, isRewardApplied, context]);

  const hasUnhandledAutomationBlockBeforeRedeem = useMemo(() => {
    if (
      !Boolean(appliedWalletData?.totalReducedPoints) &&
      floWalletData &&
      !floWalletData.isAvailable &&
      !Boolean(floWalletData.unavailabilityReasons.length)
    ) {
      return true;
    }
    return false;
  }, [floWalletData, appliedWalletData]);

  const hasUnhandledAutomationBlockAfterRedeem = useMemo(() => {
    if (
      Boolean(appliedWalletData?.totalReducedPoints) &&
      floWalletData &&
      !floWalletData.isAvailable &&
      !Boolean(floWalletData.unavailabilityReasons.length)
    ) {
      return true;
    }
    return false;
  }, [floWalletData, appliedWalletData]);

  const walletBalanceGreaterThanBurnableAmount =
    floWalletData?.totalPointsBalance && floWalletData?.maxBurnablePoints
      ? floWalletData?.totalPointsBalance > floWalletData?.maxBurnablePoints
      : false;

  const burnedMessage = floWalletData?.currencyBurnedTemplate
    ? floWalletData?.currencyBurnedTemplate
        ?.replace(
          "<rewards_value>",
          appliedWalletData?.totalReducedPoints > 0
            ? appliedWalletData?.totalReducedPoints?.toString()
            : floWalletData?.maxBurnablePoints?.toString(),
        )
        ?.replace("<coin_name>", floWalletData.coinNamePlural)
        ?.replace(
          "<currency_value>",
          appliedWalletData?.totalReductionAmount > 0
            ? appliedWalletData?.totalReductionAmount?.toString()
            : floWalletData?.maxBurnableAmount?.toString(),
        )
    : "";

  const handleRewardConfirmation = async () => {
    const previousShippingHandle = getDefaultShippingHandle(shippingHandles);
    const promises = invalidDiscountCodes[0]?.map((code: string) =>
      deleteRequest(`/checkout/v1/checkout/${checkoutId}/discounts`, { discount_code: code }),
    );
    const responses = await Promise.allSettled(promises);
    const hasFailedPromises = responses.some((response) => response.status !== "fulfilled");
    if (hasFailedPromises) {
      errorToast(t("failed_to_replace_coupons"));
      return;
    }
    updateFloWalletData(floWalletData?.maxBurnablePoints ?? 0);

    if (previousShippingHandle) {
      const payload = {
        shipping_handle: previousShippingHandle.id,
      };

      const response = await putRequest(`/checkout/${checkoutId}/shipping-handle`, payload, 'KRATOS_PRIVATE');
      updateCheckoutBasedOnCheckoutResponse(response);
    }
  };

  const updateFloWalletData = async (points: number) => {
    const payload: RewardsUpdatePayload = {
      amount: floWalletData?.coinConversionRate
        ? parseFloat(floorToTwoDecimals(points * floWalletData?.coinConversionRate).toFixed(2))
        : floWalletData?.maxBurnableAmount,
      rewards_type: RewardsPayloadType.REWARDS_WALLET,
      redemption_id: checkoutId,
    };
    try {
      setIsLoading(true);
      const response = await updateRewards(payload);
      updateCheckoutBasedOnCheckoutResponse(response);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      if (invalidDiscountCodes[0]?.length) {
        mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
        mutate(`/checkout/v1/checkout/${checkoutId}/discounts`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeFloWalletData = async () => {
    try {
      setIsLoading(true);
      const response = await removeRewards({
        rewards_type: RewardsPayloadType.REWARDS_WALLET,
      });
      updateCheckoutBasedOnCheckoutResponse(response);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedeemClicked = (event: React.MouseEvent<Element, MouseEvent>) => {
    event?.stopPropagation();
    if (!floWalletData?.isAvailable && !hasUnhandledAutomationBlockAfterRedeem) {
      setCheckoutModal("REWARD_WALLET_CONFIRMATION");
      return;
    }

    const hasDefaultHandle = hasDefaultShippingHandleSelected;
    if (!Boolean(hasDefaultHandle) && initialCheckoutStep !== "PAYMENTS") {
      setCheckoutModal("SHIPPING_HANDLES");
      return;
    }
    if (Boolean(appliedWalletData?.totalReducedPoints)) {
      removeFloWalletData();
    } else {
      updateFloWalletData(floWalletData?.maxBurnablePoints ?? 0);
    }
  };

  const renderFloWallet = () => {
    if (!floWalletData) {
      return <></>;
    }

    return (
      <div className="rounded-2xl border-2 border-yay-light bg-white px-3 py-4">
        <div className="flex justify-between">
          <div className="flex items-center space-x-2 text-coal-dark">
            <Star className="h-4 w-4 stroke-[2.7px]" />
            <span className="text-sm font-medium">{t("rewards_tile_header")}</span>
          </div>

          {/* <span className="text-base font-medium text-yay-dark">–&nbsp;{150}</span> */}
        </div>

        <div className="mt-2 flex items-center justify-between space-x-3">
          <div className="relative flex flex-1 items-center space-x-1.5 rounded-lg border border-[#2C874A33] bg-[#2C874A0D] p-2">
            <span className="flex items-center justify-center" onClick={handleRedeemClicked}>
              <input
                type="checkbox"
                checked={Boolean(appliedWalletData?.totalReducedPoints)}
                className="peer relative h-3 w-3 shrink-0 cursor-pointer appearance-none rounded-sm border-[1.5px] border-yay-dark bg-[#2C874A0D] checked:border-0 checked:bg-yay-dark focus:outline-none "
                id="flo-reward-redeem"
                readOnly
              />
              <svg
                className="pointer-events-none absolute hidden h-2.5 w-2.5 stroke-white outline-none peer-checked:block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round">
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
            <label htmlFor="flo-reward-redeem" className="text-sm font-medium text-yay-dark">
              {Boolean(burnedMessage)
                ? burnedMessage
                : t("use_x_coins_worth_amount", {
                    coins:
                      appliedWalletData?.totalReducedPoints > 0
                        ? appliedWalletData?.totalReducedPoints
                        : floWalletData?.maxBurnablePoints,
                    coin_name: floWalletData.coinNamePlural,
                    amount: currencyFormatter(
                      appliedWalletData?.totalReductionAmount > 0
                        ? appliedWalletData?.totalReductionAmount
                        : floWalletData?.maxBurnableAmount,
                    ),
                  })}
            </label>
          </div>
          <button
            className="rounded-lg border border-gray-light bg-gray-lightest px-3 py-2"
            onClick={() => {
              if (Boolean(!floWalletData.isAvailable) && !hasUnhandledAutomationBlockAfterRedeem) {
                setCheckoutModal("REWARD_WALLET_CONFIRMATION");
                return;
              }
              const hasDefaultHandle = hasDefaultShippingHandleSelected;
              if (!Boolean(hasDefaultHandle) && initialCheckoutStep !== "PAYMENTS") {
                setCheckoutModal("SHIPPING_HANDLES");
                return;
              }
              setOpenSelectorDialog(true);
            }}>
            <img src={editIcon} alt="edit" />
          </button>
        </div>
        <div className="mt-1.5 flex space-x-1 text-xs font-normal">
          {walletBalanceGreaterThanBurnableAmount ? (
            <>
              <span className="text-gray-dark">
                {t("wallet_balance")}{" "}
                <span className="font-medium text-coal-light">{floWalletData.totalPointsBalance}</span>
              </span>
              <span className="text-coal-light">{"·"}</span>
            </>
          ) : null}

          <span className="text-gray-dark">
            {t("max_usable")}{" "}
            <span className="font-medium text-coal-light">
              {floWalletData.maxBurnablePoints} {floWalletData.coinNamePlural}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderEarnTile = () => {
    if (!floWalletData) {
      return <></>;
    }

    return (
      <div
        className={classNames(
          `rounded-lg border mb-2 border-dashed  border-[#2C874A4D] bg-white font-normal  text-yay-dark`,
        )}
        style={{
          ...(floWalletData.earnTileCustomization?.outline_color
            ? { borderColor: floWalletData.earnTileCustomization?.outline_color }
            : {}),
          ...(floWalletData.earnTileCustomization?.text_color
            ? {
                color: floWalletData.earnTileCustomization?.text_color,
              }
            : {}),
          ...(floWalletData.earnTileCustomization?.outline === "dashed"
            ? { borderStyle: floWalletData.earnTileCustomization?.outline }
            : { borderStyle: "solid" }),
        }}>
        <div
          className={classNames("flex items-center space-x-2.5 rounded-[7px]  p-2")}
          style={{
            background: floWalletData.earnTileCustomization?.background_color
              ? floWalletData.earnTileCustomization?.background_color
              : "#2C874A1A",
          }}>
          {floWalletData.earnTileCustomization?.is_credit_name_visible !== "none" ? (
            <span className="text-xs font-medium ">{floWalletData.coinNamePlural}</span>
          ) : null}

          <div
            className={classNames(
              "border-yay-dark text-sm",
              floWalletData.earnTileCustomization?.is_credit_name_visible !== "none" ? "border-l px-2" : "",
            )}
            style={{
              ...(floWalletData.earnTileCustomization?.outline_color
                ? { borderColor: floWalletData.earnTileCustomization?.outline_color }
                : {}),
            }}>
            {floWalletData.rewardsEarnedMessage ? (
              floWalletData.rewardsEarnedMessage
            ) : (
              <>
                <span className="">
                  {t("earn_x_cashback_with_purchase", {
                    amount: currencyFormatter(`${floWalletData.maxEarnablePoints}`, false),
                  })}
                </span>
              </>
            )}
            <div className="text-xs">
              {floWalletData.currencyEarnedMessage
                ? floWalletData.currencyEarnedMessage
                : t("x_off_on_next_order", {
                    amount: currencyFormatter(`${floWalletData.maxEarnableAmount}`),
                  })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (Boolean(error)) {
    return <></>;
  }

  return (
    <>
      <AnimateLoading loading={Boolean(isTileLoading) || Boolean(isLoading)}>
        <AnimateLoading.Content>
          <div className="space-y-3">
            {shouldShowFloWallet && !hasUnhandledAutomationBlockBeforeRedeem && renderFloWallet()}
            {earnEnabled && Boolean(floWalletData?.earnMultiplier) && renderEarnTile()}
          </div>
        </AnimateLoading.Content>
      </AnimateLoading>

      <GenericDialog
        isOpen={checkoutModal === "REWARD_WALLET_CONFIRMATION"}
        translateAxis="y"
        dialogOverlay={true}
        modalType="REWARD_WALLET_CONFIRMATION"
        customClass="overflow-scroll md:!top-auto md:absolute">
        <SwitchCouponDialog
          closePopup={() => setCheckoutModal("NONE")}
          applyCoupon={async () => {
            try {
              setCheckoutModal("NONE");
              setShowReplacementLoader(true);
              await handleRewardConfirmation();
            } catch (error) {
              console.error(error);
            } finally {
              setShowReplacementLoader(false);
            }
          }}
          appliedDiscountCode={floWalletData?.coinName ?? ""}
          invalidDiscountCodes={invalidDiscountCodes[0]}
          invalidReason={"REWARDS_NOT_COMBINABLE"}
          isReward
        />
      </GenericDialog>

      <GenericDialog
        isOpen={showReplacementLoader}
        translateAxis="y"
        customClass="overflow-scroll md:!top-auto md:absolute"
        dialogOverlay={true}
        modalType="PROCESSING">
        <DialogHeader>
          <div className="flex h-full w-full flex-row items-center justify-between">
            <h1 className="text-base font-medium"> {t("replacing_coupons")}</h1>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="h-[5px] w-full overflow-hidden bg-carbon-lighter">
            <div className="progress left-right h-full w-full bg-primary-dark"></div>
          </div>
        </DialogBody>
      </GenericDialog>

      {floWalletData && (
        <GenericDialog
          isOpen={openSelectorDialog}
          translateAxis="y"
          dialogOverlay={true}
          modalType="NONE"
          customClass="overflow-scroll md:!top-auto md:absolute">
          <FloWalletPointsSelector
            setIsOpen={(value: boolean) => setOpenSelectorDialog(value)}
            floWalletData={floWalletData}
            updateWalletData={updateFloWalletData}
          />
        </GenericDialog>
      )}
    </>
  );
};

export default FloWallet;
