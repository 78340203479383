import { createContext, useCallback, useContext, useMemo, useReducer } from "react";
import Cookies from "js-cookie";
import { ThirdPartyAuthType } from "lib/types/checkout";
import { getAccessToken, setAuthCookies, setAuthCookiesInLocalStorage } from "lib/utils/auth";
import { constants, eventTypes } from "lib/utils/constants";
import { publishPostMessage } from "lib/utils/helpers";
import { useSearchParams } from "react-router-dom";

const Context = createContext<AuthContext>({ state: {}, actions: {} } as AuthContext);

export enum ActionType {
  SET_LOGGED_IN,
  SET_LOCK_DISCOUNTS,
  SET_THIRDPARTY_AUTH,
  SET_LOGGED_OUT,
  LOGIN,
  LOGOUT,
}

export type Action =
  | { type: ActionType.SET_LOGGED_IN; payload: boolean }
  | { type: ActionType.SET_THIRDPARTY_AUTH; payload: ThirdPartyAuthType[] | [] }
  | { type: ActionType.SET_LOCK_DISCOUNTS; payload: boolean }
  | { type: ActionType.SET_LOGGED_OUT; payload: boolean };

export interface AuthState {
  isAuthenticated: boolean;
  lockDiscounts: boolean;
  thirdPartyAuth: ThirdPartyAuthType[] | [];
  isLoggedOut: boolean;
}

export interface AuthContext {
  state: AuthState;
  actions: {
    setLoggedIn: (value: boolean) => void;
    setLockDiscounts: (value: boolean) => void;
    setThirdPartyAuth: (data: ThirdPartyAuthType[] | []) => void;
    setLoggedOut: (value: boolean) => void;
    login: (
      idToken: string,
      refreshToken: string,
      authExpiry?: number,
      refreshExpiry?: number,
      shouldUpdateCart?: boolean,
    ) => void;
    logout: (isCart?: boolean) => void;
  };
}

function reducer(state: AuthState, action: Action): AuthState {
  switch (action.type) {
    case ActionType.SET_LOGGED_IN: {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    }
    case ActionType.SET_LOCK_DISCOUNTS: {
      return {
        ...state,
        lockDiscounts: action.payload,
      };
    }
    case ActionType.SET_THIRDPARTY_AUTH: {
      return {
        ...state,
        thirdPartyAuth: action.payload,
      };
    }
    case ActionType.SET_LOGGED_OUT: {
      return {
        ...state,
        isLoggedOut: action.payload,
      };
    }
  }
  return state;
}

const AuthInitialState: AuthState = {
  isAuthenticated: Boolean(getAccessToken()),
  lockDiscounts: false,
  thirdPartyAuth: [],
  isLoggedOut: false,
};

export const AuthProvider: React.FC<React.PropsWithChildren<{ initialState?: AuthState }>> = ({
  initialState = AuthInitialState,
  children,
}) => {
  const [reducerState, dispatch] = useReducer(reducer, initialState);
  const [searchParams, setSearchParams] = useSearchParams();
  const setLoggedIn = useCallback((loggedIn: boolean) => {
    dispatch({
      type: ActionType.SET_LOGGED_IN,
      payload: loggedIn,
    });
  }, []);

  const setLockDiscounts = useCallback((lockDiscounts: boolean) => {
    dispatch({
      type: ActionType.SET_LOCK_DISCOUNTS,
      payload: lockDiscounts,
    });
  }, []);

  const setThirdPartyAuth = useCallback((data: ThirdPartyAuthType[] | []) => {
    dispatch({
      type: ActionType.SET_THIRDPARTY_AUTH,
      payload: data,
    });
  }, []);

  const setLoggedOut = useCallback((loggedOut: boolean) => {
    dispatch({
      type: ActionType.SET_LOGGED_OUT,
      payload: loggedOut,
    });
  }, []);

  const login = useCallback(
    (
      idToken: string,
      refreshToken: string,
      authExpiry?: number,
      refreshExpiry?: number,
      shouldUpdateCart?: boolean,
    ) => {
      try {
        setAuthCookies(idToken, refreshToken, authExpiry, refreshExpiry);
        setAuthCookiesInLocalStorage(idToken, refreshToken, authExpiry, refreshExpiry);
        setLockDiscounts(true);
        setLoggedIn(true);
        if (Boolean(shouldUpdateCart)) {
          publishPostMessage(eventTypes.LOGIN_FROM_CHECKOUT, {
            idToken,
            refreshToken,
            authExpiry,
            refreshExpiry,
          });
        }
      } catch (err) {
        setLoggedIn(false);
        console.error(err);
      }
    },
    [setLockDiscounts, setLoggedIn],
  );

  const logout = useCallback(
    (isCart?: boolean) => {
      Cookies.remove(constants.AUTH_COOKIE_CLIENT, { path: "/" });
      Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT, { path: "/" });
      localStorage.removeItem(constants.ORDER_HISTORY_COOKIE_CLIENT);
      Cookies.set(constants.AUTH_COOKIE_CLIENT, "", {
        expires: new Date(Date.now() - 1),
      });
      Cookies.set(constants.AUTH_LOGGED_OUT, "true", { path: "/" });
      localStorage.removeItem(constants.AUTH_COOKIE_CLIENT);
      localStorage.removeItem(constants.REFRESH_TOKEN_COOKIE_CLIENT);
      localStorage.removeItem(constants.LOCAL_STORAGE_AUTH_COOKIE_CLIENT_EXPIRY);
      localStorage.removeItem(constants.LOCAL_STORAGE_REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY);
      setLoggedOut(true);
      setLockDiscounts(true);
      setLoggedIn(false);
      if (!isCart && searchParams.has("checkoutId") && !searchParams.has("fromCart")) {
        publishPostMessage(eventTypes.LOGOUT_FROM_CHECKOUT, {});
        searchParams?.delete("checkoutId");
        searchParams?.append("fromCart", "true");
        setSearchParams(searchParams.toString());
      }
    },
    [setLockDiscounts, setLoggedIn, searchParams, setSearchParams],
  );

  const actions = useMemo(
    () => ({
      setLoggedIn,
      setLockDiscounts,
      setThirdPartyAuth,
      setLoggedOut,
      login,
      logout,
    }),
    [login, logout, setLockDiscounts, setLoggedIn, setThirdPartyAuth],
  );

  return (
    <Context.Provider
      value={{
        state: reducerState,
        actions,
      }}>
      {children}
    </Context.Provider>
  );
};

export function useAuthContext() {
  if (!Boolean(Context)) throw new Error("useAuthContext must be used within a AuthProvider");
  const AuthContext = useContext(Context);
  return AuthContext as AuthContext;
}
