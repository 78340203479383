import { useLocale } from "lib/hooks/useLocale";

export const PaymentSkeleton: React.FC<{ showGyftrLoading?: boolean }> = ({ showGyftrLoading = false }) => {
  const { t } = useLocale();
  return (
    <>
      <div className="border-base px-3 py-4 bg-white space-y-2">
        <div className="skeleton-loader h-[16px] rounded-md w-2/5"></div>
        <div className="skeleton-loader h-[16px] rounded-md w-full"></div>
      </div>
      <div className={`flex w-full flex-row  items-center justify-between p-2 text-xs mt-3`}>
        <h2 className="text-sm font-medium text-coal-light">{t("all_payment_methods") as string}</h2>
      </div>
      <div className="space-y-4">
        {new Array(4).fill(null).map((_, index) => (
          <div key={index} className="bg-white flex p-3 gap-2 rounded-md border-base">
            <div className="skeleton-loader h-[42px] w-[42px] rounded-md"></div>
            <div className="flex flex-col gap-2 flex-1">
              <div className="skeleton-loader h-[16px] w-2/5 rounded-md"></div>
              <div className="skeleton-loader h-[16px] w-full rounded-md"></div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
