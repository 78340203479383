import React, { useEffect, useState } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { AddressCard } from "components/address/cards/AddressCard";
import { DialogFooter } from "components/common/dialogs/GenericDialog";
import { useUserContext } from "lib/contexts/UserProvider";
import { AddressCardFields, AddressErrorType, PartialAddressPayloadType } from "lib/types/address";
import { putRequest, getRequest } from "lib/core/apiClient";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { parseUserData } from "lib/utils/user";
import { getItems } from "lib/utils/checkout";
import { UserType } from "lib/types/user";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { AddressSource, analyticsEvents, apiURI } from "lib/utils/constants";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { ShippingHandlesType, CheckoutActions, CheckoutErrorResponse } from "lib/types/checkout";
import { errorToast } from "lib/utils/toasters";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { CtaConfig } from "lib/types/merchant";
import { checkMandatoryFields, classNames, redirectUrl, scrollToID } from "lib/utils/helpers";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { mutate } from "swr";
import { getDetailsByZip } from "lib/core/apiMethods";
import { AnimateLoading } from "components/animation/AnimateLoading";
import { useMerchantUiConfig } from "lib/contexts/MerchantUIConfigProvider";
import { checkIfHasServiceabilityError, useCheckoutErrors } from "lib/hooks/useCheckoutErrors";

interface AddressModifyProps {
  setIsOpen?: () => void;
  isPrimaryBtnLoading?: boolean;
  handleEditAddress: (id: string) => void;
}

const AddressModify: React.FC<AddressModifyProps> = ({
  setIsOpen,
  isPrimaryBtnLoading = false,
  handleEditAddress,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAddrId, setSelectedAddrId] = useState<string>("");
  const {
    state: { addressConfig, merchant },
  } = useMerchantContext();

  const {
    state: { isValidating },
  } = useMerchantUiConfig();

  const { t } = useLocale();
  const {
    state: { user },
    actions: { setUserData },
  } = useUserContext();
  const {
    state: { checkoutId, isC2P, actionUrls, initialCheckoutStep, checkoutValidations, checkoutLoading },
    actions: {
      setCheckoutModal,
      setCheckoutView,
      setShippingHandles,
      setOOSItems,
      updateCheckoutBasedOnCheckoutResponse,
      setCheckoutValidations,
    },
  } = useCheckoutContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const [addressErrors, setAddressErrors] = useState<AddressErrorType>({});
  const { triggerCheckoutErrorInfoToast } = useCheckoutErrors();

  useEffect(() => {
    if (Boolean(isC2P)) return;
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_ADDRESSES_LIST_LOADED,
      eventType: "load",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedAddrId(user?.default_shipping_address?.id ?? "");
  }, [user]);

  const updateDefaultAddress = async () => {
    const selectedAddress = user?.addresses?.find(
      (address: AddressCardFields) => address?.id === selectedAddrId,
    );
    if (!selectedAddress) {
      setSelectedAddrId("ADDRESS_NEW");
      setCheckoutModal("ADDRESS_NEW");
      return;
    }
    const addressValidity = checkMandatoryFields(selectedAddress, addressConfig, checkoutId);
    if (!addressValidity.available) {
      setCheckoutValidations({
        ...checkoutValidations,
        address: {
          isValid: false,
          metadata: {
            addressId: selectedAddrId,
            fieldData: addressValidity?.errorFieldData,
          },
        },
      });
      handleEditAddress(selectedAddrId);
      return;
    }
    setCheckoutValidations({
      ...checkoutValidations,
      address: {
        isValid: true,
      },
    });

    if (!Boolean(user?.addresses) && initialCheckoutStep !== "PAYMENTS") {
      setCheckoutModal("ADDRESS_NEW");
      return;
    }

    if (!Boolean(selectedAddrId)) {
      errorToast(t("address_not_selected"));
      return;
    }

    let addressPayload: PartialAddressPayloadType | {} = {};
    if (!Boolean(selectedAddress?.state_code)) {
      try {
        const stateCodeResponse = await getDetailsByZip(selectedAddress?.zip ?? "");
        const tempPayload: PartialAddressPayloadType = {
          account_id: user?.uid,
          source: AddressSource.SHIPPING_ADDRESS,
          address_type: selectedAddress?.type || "OTHER",
          data: {
            state_code: stateCodeResponse?.state_code,
          },
        };
        addressPayload = { ...tempPayload };
      } catch (e) {
        console.error(e);
      }
    }

    try {
      setIsLoading(true);
      const checkoutResponse: any = await putRequest(
        `/checkout/${checkoutId}/address/${selectedAddrId}`,
        addressPayload,
        "KRATOS_PRIVATE",
      );
      const accountResponse = await getRequest("/gatekeeper/v1/accounts?include=address,attributes");
      handleResponseSuccess(checkoutResponse, accountResponse);
      setIsLoading(false);

      const showShippingHandles = checkoutResponse?.metadata?.show_shipping_handle_selector ?? false;
      const shippingHandles = checkoutResponse?.metadata?.available_shipping_handles ?? [];

      const isNotServicable =
        checkIfHasServiceabilityError(
          checkoutResponse?.metadata?.error_responses as CheckoutErrorResponse[],
        ) || shippingHandles.length === 0;

      if (checkoutResponse?.metadata?.error_response?.code === "OUT_OF_STOCK") {
        const oosItems = checkoutResponse?.metadata?.error_response?.metadata?.items;
        setOOSItems(getItems(oosItems, true) ?? []);
        setCheckoutModal("OOS");
        return;
      }

      if (isNotServicable && initialCheckoutStep !== "PAYMENTS") {
        scrollToID("flo_add__address");
        triggerCheckoutErrorInfoToast();
        return;
      }

      if (isNotServicable && initialCheckoutStep === "PAYMENTS") {
        const addressErr = { ...addressErrors };
        addressErr[selectedAddrId] = {
          hasError: true,
          // todo: shradhan check if this is correct
          errorMessage: t("pincode_not_serviceable_item_in_cart"),
        };
        setAddressErrors(addressErr);
        scrollToID("flo_add__address");
      }

      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (shippingHandles.length > 0 && Boolean(showShippingHandles) && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
        return;
      }
      if (initialCheckoutStep === "PAYMENTS") {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_SHIPPING_HANDLES_LOADED,
          eventType: "load",
          metaData: {
            shippingData: {
              availableHandles: shippingHandles,
            },
          },
        });
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
        mutate(`UPI_INTENT`);
      }

      if (checkoutResponse?.metadata?.action_urls?.[CheckoutActions.ADDRESS_SELECT]?.success_url) {
        redirectUrl(checkoutResponse.metadata.action_urls[CheckoutActions.ADDRESS_SELECT].success_url);
        return;
      }

      if (
        actionUrls &&
        actionUrls[CheckoutActions.ADDRESS_SELECT] &&
        actionUrls[CheckoutActions.ADDRESS_SELECT].success_url
      ) {
        redirectUrl(actionUrls[CheckoutActions.ADDRESS_SELECT].success_url);
        return;
      }
      if (!isNotServicable) {
        setCheckoutModal("NONE");
      }
      setCheckoutView("PAYMENTS");
    } catch (e) {
      console.error(e);
      setIsLoading(false);

      if (
        actionUrls &&
        actionUrls[CheckoutActions.ADDRESS_SELECT] &&
        actionUrls[CheckoutActions.ADDRESS_SELECT].failure_url
      ) {
        redirectUrl(actionUrls[CheckoutActions.ADDRESS_SELECT].failure_url);
      }
    }
  };

  const handleResponseSuccess = (checkoutResponse: any, accountResponse: any) => {
    handleCheckoutResponse(checkoutResponse);
    handleAccountResponse(accountResponse, checkoutResponse);
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_ADDRESS_SELECTED,
      eventType: "click",
    });
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse);
  };

  const handleAccountResponse = (accountResponse: any, checkoutResponse: any) => {
    const parsedUserData: UserType = parseUserData(accountResponse, checkoutResponse);
    setUserData(parsedUserData);
  };

  const getPrimaryButtonText = () => {
    if (isPrimaryBtnLoading) return "";
    if (user?.uid && !Boolean(user?.addresses)) return t("add_new_address_button");
    const cta = merchant?.primaryCtaConfig?.find(
      (config: CtaConfig) => config.type === "ORDER_SUMMARY",
    )?.ctaText;
    return cta;
  };

  const addressListSkeleton = (
    <div className="space-y-3">
      {new Array(3).fill(null).map((_, index) => (
        <div className="border-base px-3 py-4 bg-white space-y-2" key={index}>
          <div className="skeleton-loader h-[16px] rounded-md w-2/5"></div>
          <div className="skeleton-loader h-[16px] rounded-md w-full"></div>
        </div>
      ))}
    </div>
  );

  return (
    <React.Fragment>
      <>
        <AnimateLoading loading={checkoutLoading}>
          <AnimateLoading.Skeleton>{addressListSkeleton}</AnimateLoading.Skeleton>
          <AnimateLoading.Content>
            <ul className={classNames("flex flex-col space-y-4")}>
              {user?.addresses
                ?.filter((address: AddressCardFields) => address?.source === "SHIPPING_ADDRESS")
                ?.map((address: AddressCardFields, index: number) => (
                  <li
                    id={`flo__address__address${index + 1}`}
                    key={address?.id}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedAddrId(address?.id ?? "");
                    }}>
                    <AddressCard
                      {...address}
                      setAddress={() => setSelectedAddrId(address?.id ?? "")}
                      isChecked={selectedAddrId === address?.id}
                      selectedAddrId={selectedAddrId}
                      handleEditAddress={handleEditAddress}
                      defaultShippingAddress={user?.default_shipping_address?.id ?? ""}
                      defaultBillingAddress={user?.default_billing_address?.id ?? ""}
                      errorMessage={
                        address?.id
                          ? Boolean(addressErrors[address?.id]?.hasError)
                            ? addressErrors[address?.id].errorMessage
                            : ""
                          : ""
                      }
                    />
                  </li>
                ))}
            </ul>
          </AnimateLoading.Content>
        </AnimateLoading>
      </>
      <DialogFooter>
        <PrimaryButton
          isDisabled={isValidating}
          buttonText={getPrimaryButtonText()}
          onClick={updateDefaultAddress}
          height="h-14"
          isCheckout={true}
          isLoading={isPrimaryBtnLoading}
          id="flo_address__btn"
        />
      </DialogFooter>

      {isLoading && <OverlaySpinner />}
    </React.Fragment>
  );
};

export default React.memo(AddressModify);
