import PrimaryButton from "components/common/buttons/PrimaryButton";
import { DialogBody, DialogFooter, DialogHeader } from "components/common/dialogs/GenericDialog";
import InputField from "components/common/forms/InputField";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useLocale } from "lib/hooks/useLocale";
import { FloWalletData } from "lib/types/checkout";
import { currencyFormatter } from "lib/utils/formatters";
import { floorToTwoDecimals } from "lib/utils/helpers";
import React, { ChangeEvent, useState } from "react";
import { X } from "react-feather";

interface FloWalletPointsSelectorProps {
  setIsOpen: (value: boolean) => void;
  floWalletData: FloWalletData;
  updateWalletData: (id: number) => Promise<void>;
}

const FloWalletPointsSelector: React.FC<FloWalletPointsSelectorProps> = ({
  setIsOpen,
  floWalletData,
  updateWalletData,
}) => {
  const { t } = useLocale();
  const {
    state: { appliedWalletData },
  } = useCheckoutContext();
  const [points, setPoints] = useState<number>(
    appliedWalletData?.totalReducedPoints > 0
      ? appliedWalletData?.totalReducedPoints
      : floWalletData?.maxBurnablePoints,
  );

  const handleCoinsSubmit = (e: any) => {
    e.preventDefault();
    setIsOpen(false);
    updateWalletData(points);
  };

  const handlePointsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber;

    if (value > floWalletData?.maxBurnablePoints) {
      setPoints(floWalletData?.maxBurnablePoints);
      return;
    }
    setPoints(value);
  };

  return (
    <>
      <DialogHeader className="relative flex justify-between pl-4 pr-6">
        <div className="flex flex-col gap-1 py-[18px]">
          <h3 className="font-medium text-carbon-dark">Enter amount</h3>
          <p className="text-xs font-medium text-coal-dark">
            {t("max_usable_x_coins", {
              total: currencyFormatter(floWalletData?.maxBurnablePoints, false),
            })}
            {floWalletData?.coinNamePlural}
            {floWalletData?.maxBurnablePoints === Number(points) ? " 🔥" : ""}
          </p>
          <p className="text-xs text-coal-dark">
            {t("you_have")}
            <span className="font-medium text-yay-dark">
              {t("x_coins", {
                total: currencyFormatter(floWalletData?.totalPointsBalance, false),
                brand:
                  floWalletData?.totalPointsBalance > 1
                    ? floWalletData?.coinNamePlural
                    : floWalletData?.coinName,
              })}
            </span>
            {t("in_your_wallet")}
          </p>
        </div>
        {/* close icons here */}
        <div className="absolute right-0 flex h-full items-center pr-6">
          <button onClick={() => setIsOpen(false)}>
            <X className="h-6 w-6 cursor-pointer text-coal-dark" />
          </button>
        </div>
      </DialogHeader>
      <DialogBody className="relative !py-0 px-3">
        <InputField
          type="number"
          placeholder={t("enter_coupon_code")}
          name="coupon-code"
          id="coupon-code"
          maxLength={50}
          autoComplete="off"
          autoFocus={false}
          value={`${points}`}
          onChange={handlePointsChange}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.key === "Enter" && handleCoinsSubmit(e);
          }}
          customClass={`h-[3.25rem] border text-base border-gray-light !rounded-2xl  placeholder-gray-dark text-sm font-normal text-coal-light w-full mb-10`} // on focus remove 2px padding from left to avoid placeholder shifing because of the ring
          showLabel={false}
          tabIndex={-1}
        />
        <span className="text-normal absolute right-7 -top-5 flex h-full items-center text-coal-light">
          {Boolean(points * floWalletData?.coinConversionRate) && (
            <span>
              {floWalletData?.coinNamePlural} ={" "}
              <span className="font-medium text-yay-dark">
                {currencyFormatter(floorToTwoDecimals(points * floWalletData?.coinConversionRate))}
              </span>
            </span>
          )}
        </span>
      </DialogBody>
      <DialogFooter className="relative pb-6 md:relative">
        <PrimaryButton
          buttonText={t("redeem")}
          id="flo_wallet_redeem_button"
          type="submit"
          onClick={handleCoinsSubmit}
          height="h-14"
          isLoading={false}
          isDisabled={!Boolean(points) || Number(points) === 0}
        />
      </DialogFooter>
    </>
  );
};

export default FloWalletPointsSelector;
