import { useMemo } from "react";
import { AlertCircle, AlertTriangle } from "react-feather";

import { GenericDialog } from "components/common/dialogs/GenericDialog";
import { useLocale } from "lib/hooks/useLocale";
import { DialogBody, DialogFooter } from "components/common/dialogs/GenericDialog";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import CheckoutItems from "components/checkout/CheckoutItems";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useCheckoutErrors } from "lib/hooks/useCheckoutErrors";
import { redirectUrl } from "lib/utils/helpers";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { CheckoutErrorResponseCode } from "lib/types/checkout";

export const UnservicableProductDialog = () => {
  const { t } = useLocale();
  const { errorMessage } = useCheckoutErrors();
  const {
    state: { checkoutItems, checkoutModal, errorResponses },
    actions: { setCheckoutModal, setCheckoutView },
  } = useCheckoutContext();

  const {
    state: { merchant },
  } = useMerchantContext();

  const unservicableError = useMemo(
    () => errorResponses?.find((error) => error.code === CheckoutErrorResponseCode.UARC_008),
    [errorResponses],
  );
  if (!unservicableError) return null;

  const { metadata } = unservicableError;
  if (!metadata) return null;

  const unserviceableItems = checkoutItems.filter((outerItem) =>
    metadata?.items?.some((innerItem) => innerItem.item_id === outerItem.variant_id),
  );

  if (!unserviceableItems.length) return null;

  const handleGoBackToStore = () => {
    if (merchant?.brandUrl) {
      redirectUrl(merchant.brandUrl);
    }
    setCheckoutModal("NONE");
    setCheckoutView("ADDRESS_LIST");
  };

  return (
    <GenericDialog
      isOpen={checkoutModal === "UNSERVICEABLE_PRODUCTS"}
      setIsOpen={() => setCheckoutModal("NONE")}
      translateAxis="y"
      dialogOverlay={true}
      customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-fit"
      closeOnOverlayClick
      modalType="UNSERVICEABLE_PRODUCTS">
      <DialogBody className="!px-3 !pt-4 h-fit">
        <div className="flex flex-col">
          <h1 className="text-base font-medium text-carbon-dark">{t("items_cannot_be_delivered")}</h1>

          <div className="flex items-center gap-2 p-3 mt-3 mb-4 rounded-lg border-[1px] border-blizzard-light relative">
            <div className="absolute top-0 left-0 w-full h-full bg-blizzard-light rounded-lg opacity-10 border-1"></div>
            <AlertCircle className="text-blizzard-dark" size={16} />
            <p className="text-xs text-blizzard-dark">
              {t("We are unable ship items in your cart to 560025")}
            </p>
          </div>
          <CheckoutItems items={unserviceableItems} disableOOSItems={true} />
        </div>
      </DialogBody>

      <DialogFooter>
        <PrimaryButton
          buttonText={t("go_back_to_store")}
          onClick={handleGoBackToStore}
          height="h-14"
          isCheckout={false}
        />
      </DialogFooter>
    </GenericDialog>
  );
};
