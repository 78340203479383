import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { useLocale } from "lib/hooks/useLocale";
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  GenericDialog,
} from "components/common/dialogs/GenericDialog";
import { Dialog } from "@headlessui/react";
import { X } from "react-feather";
import { classNames } from "lib/utils/helpers";
import Tag from "components/common/Tag";
import { currencyFormatter } from "lib/utils/formatters";
import { getPrepaidDiscountData } from "lib/utils/payments";
import { PaymentModeType } from "lib/types/payments";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface CODConfirmationProps {}

const CODConfirmation: React.FC<CODConfirmationProps> = () => {
  const {
    actions: { setPaymentMethodDialog, handlePaymentMethodSelected },
    state: { paymentMethods, openPaymentMethodDialog },
  } = usePaymentContext();
  const { t } = useLocale();
  const {
    state: { billing },
  } = useCheckoutContext();

  const splitCod = useMemo(
    () => paymentMethods.find((method: any) => method?.mode === "SPLIT_COD" && Boolean(method?.is_available)),
    [paymentMethods],
  );
  const cod = useMemo(() => paymentMethods.find((method: any) => method?.mode === "COD"), [paymentMethods]);

  const [selectedMode, setSelectedMode] = useState<PaymentModeType | "">("");

  useEffect(() => {
    if (Boolean(cod)) {
      setSelectedMode("COD");
    }
    if (Boolean(splitCod)) {
      setSelectedMode("SPLIT_COD");
    }
  }, [cod, splitCod]);

  const prepaidDiscountData = getPrepaidDiscountData(paymentMethods);

  const hasPrepaidDiscount = Boolean(prepaidDiscountData?.maxDiscountCoupon);

  const getDescriptionText = useCallback(
    (method: any) => {
      if (!["COD", "SPLIT_COD"].includes(method?.mode)) {
        return t("pay_using_online");
      }
      if (method?.mode === "COD" && Boolean(billing?.cod)) {
        return t("include_cod_fee", { amount: billing.cod });
      }
      if (method?.mode === "SPLIT_COD") {
        return t("split_payment_online_and_cod");
      }
      return "";
    },
    [billing.cod, t],
  );

  const getOptionLabel = useCallback((method: any) => {
    if (method?.mode === "SPLIT_COD") {
      return t("split_cod_pay_amount", {
        current_amount: currencyFormatter(method?.amount),
        cod_amount: currencyFormatter(method?.metadata?.cod_amount),
      });
    }
    if (method?.mode === "COD") {
      return t("pay_x_amount_on_delivery", {
        amount: currencyFormatter(method?.amount),
      });
    }
    return t("pay_x_amount_online", {
      amount: currencyFormatter(method?.amount ?? 0),
    });
  }, []);

  const getOptionTotal = useCallback((method: any) => {
    if (method?.mode === "SPLIT_COD") {
      return method.amount + (method?.metadata?.cod_amount ?? 0);
    }
    if (method?.mode === "COD") {
      return method?.amount ?? 0;
    }
    return method?.amount ?? 0;
  }, []);

  const getOptionTag = useCallback(
    (method: any) => {
      if (!["COD", "SPLIT_COD"].includes(method.mode)) {
        return prepaidDiscountData?.hasFreebie
          ? t("freebie_gift", {
              amount: Boolean(prepaidDiscountData?.freebieMethod?.discount_metadata?.mode_freebies)
                ? prepaidDiscountData?.freebieMethod?.discount_metadata?.mode_freebies
                : 0,
            })
          : prepaidDiscountData?.maxPrepaidDiscountText ?? null;
      }
      return billing?.cod === 0 ? t("free_cod") : null;
    },
    [prepaidDiscountData, billing],
  );

  const handleContinue = () => {
    if (selectedMode === "SPLIT_COD") {
      return handlePaymentMethodSelected("SPLIT_COD", splitCod?.context);
    }
    if (selectedMode === "COD") {
      return setPaymentMethodDialog("COD_ORDER_PLACING");
    }
    return setPaymentMethodDialog("NONE");
  };

  return (
    <>
      <GenericDialog
        isOpen={openPaymentMethodDialog === "COD"}
        setIsOpen={() => setPaymentMethodDialog("NONE")}
        translateAxis="y"
        customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
        modalType="COD_CONFIRMATION"
        dialogOverlay={true}>
        <DialogHeader>
          <Dialog.Title
            as="h3"
            className="flex h-full w-full flex-row items-center justify-between bg-white py-4 text-base font-medium text-carbon-dark">
            <div className="flex items-center">
              <h2 className="inline-flex gap-2 font-medium text-carbon-dark">
                {hasPrepaidDiscount ? t("confirm_payment_method") : t("select_cod_option")}
              </h2>
            </div>
            <button className="outline-none">
              <X
                className="h-6 w-6 cursor-pointer text-coal-dark"
                onClick={() => setPaymentMethodDialog("NONE")}
              />
            </button>
          </Dialog.Title>
        </DialogHeader>
        <DialogBody className="flex flex-col gap-4 px-3 pb-36">
          <ul className="space-y-4">
            {[cod, splitCod, ...(hasPrepaidDiscount ? [prepaidDiscountData.maxDiscountMethod] : [])].map(
              (method: any) =>
                method ? (
                  <li
                    key={method.id}
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedMode(method.mode);
                    }}>
                    <Option
                      id={method.id}
                      isChecked={selectedMode === method.mode}
                      name={method.mode}
                      title={getOptionLabel(method)}
                      description={getDescriptionText(method)}
                      total={getOptionTotal(method)}
                      tag={getOptionTag(method)}
                    />
                  </li>
                ) : null,
            )}
          </ul>
        </DialogBody>
        <DialogFooter className="bg-white p-3 pb-14">
          <button
            className="h-14 w-full rounded-xl bg-primary-dark py-3 font-medium text-white"
            onClick={handleContinue}
            disabled={selectedMode === ""}
            type="button">
            {t("Continue")}
          </button>
        </DialogFooter>
      </GenericDialog>
    </>
  );
};

interface OptionProps {
  id: string;
  name: string;
  isChecked: boolean;
  total: string | number;
  title: React.ReactNode;
  description?: React.ReactNode;
  tag?: React.ReactNode;
}

const Option: React.FC<OptionProps> = React.memo(({ isChecked, total, tag, title, description }) => {
  return (
    <>
      <label
        className={classNames(
          isChecked ? "z-10 border-[1.5px] border-primary-dark bg-white" : "border border-gray-light",
          "relative flex cursor-pointer border-[1.5px] p-4 focus:outline-none",
          "relative flex cursor-pointer rounded-2xl bg-white px-6 py-4 shadow-sm focus:outline-none",
        )}>
        <span
          className={classNames(
            isChecked ? "border-2 border-primary-dark " : "border-[1.5px] border-gray-light ",
            "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border bg-white",
          )}
          aria-hidden="true">
          <span
            className={classNames(isChecked ? "bg-primary-dark" : "bg-white", "h-1.5 w-1.5 rounded-full ")}
          />
        </span>
        <span className="ml-3 flex flex-[2] flex-col gap-1">
          <span className={classNames("block text-sm font-medium text-coal-dark")}>{title}</span>
          <span className={classNames("block text-xs text-coal-light")}>{description}</span>

          {tag && <Tag className="rounded-2xl bg-yay-light px-2 text-xs text-yay-dark">{tag}</Tag>}
        </span>
        <span className="flex flex-col gap-[2px]">
          <span className="text-xs text-coal-light">Order total</span>
          <span className="text-sm font-medium text-carbon-dark">{currencyFormatter(total)}</span>
        </span>
      </label>
    </>
  );
});

export default React.memo(CODConfirmation);
