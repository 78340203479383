import { useMemo, useState } from "react";

import { apps } from "./common";
import { useLocale } from "lib/hooks/useLocale";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { getAutomationPayloadForMethod, isMobile, redirectUrl } from "lib/utils/helpers";
import { analyticsTypes } from "lib/utils/constants";
import { getUpiAppLink } from "lib/utils/payments";
import { analyticsEvents } from "lib/utils/constants";
import useForm, { FormField } from "lib/hooks/useForm";
import { getPaymentMethodAction } from "lib/core/apiMethods";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { UpiIntentApps } from "lib/types/payments";
import { MethodLogo } from "./MethodLogo";
import InputField from "components/common/forms/InputField";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { errorToast } from "lib/utils/toasters";
import { UPIIntentMethodType } from "lib/types/payments";
import upiOthersLogo from "assests/images/upi-other.png";
import { currencyFormatter } from "lib/utils/formatters";

export const UPICollect = () => {
  const { t } = useLocale();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const [isLoading, setIsLoading] = useState(false);
  const [responseErr, setResponseErr] = useState<string | null>(null);

  const {
    state: { paymentMethods },
    actions: { setPaymentState },
  } = usePaymentContext();

  const {
    state: { checkoutId },
    actions: { setCheckoutExpired },
  } = useCheckoutContext();

  const upiApps: UPIIntentMethodType[] = useMemo(() => {
    const availableApps = paymentMethods?.find((e: any) => e.mode === "UPI_INTENT")?.metadata?.available_apps;

    if (availableApps) {
      const filteredApps: any = apps.filter((app: any) => availableApps.includes(app.id));
      return filteredApps;
    }
    return apps;
  }, [t, paymentMethods]);

  const upiAmount = useMemo(() => {
    const upiMethod = paymentMethods?.find((e: any) => e.mode === "UPI_INTENT");
    return upiMethod?.amount;
  }, [paymentMethods]);

  const {
    handleSubmit,
    setErrors,
    inputProps,
    state: { values, errors },
  } = useForm({
    initialState: { id: "", save: false },
    validationSchema: {
      id: {
        required: t("invalid_upi"),
        type: "string",
        when: (value: string) => {
          const upiRegex = /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/;
          const isValid = upiRegex.test(value);
          if (!isValid) {
            return {
              status: true,
              message: t("invalid_upi"),
            };
          }
        },
      },
    },
  });

  const openUPIApp = async (upiApp: UpiIntentApps) => {
    try {
      // Only proceed if we're on mobile and UPI_INTENT is selected
      if (!isMobile()) {
        errorToast(t("invalid_upi"));
        return;
      }

      setIsLoading(true);
      const response = await getPaymentMethodAction(
        "UPI_INTENT",
        checkoutId,
        undefined,
        undefined,
        setCheckoutExpired,
      );
      if (!Boolean(response)) {
        return;
      }
      const url = response?.next_steps?.pg_action?.action_url;
      const upiAppLink = getUpiAppLink(upiApp, url ?? "");
      if (!Boolean(upiAppLink)) return;
      const methodData = paymentMethods?.find((e: any) => e.mode === "UPI_INTENT");
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
        eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
        eventType: "click",
        metaData: {
          methodType: "upi_intent",
          automationData: getAutomationPayloadForMethod(methodData),
        },
      });
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_PAYMENT_MODE_SELECTED,
        eventType: "click",
        metaData: {
          modeType: upiApp,
          methodType: "upi_intent",
          automationData: getAutomationPayloadForMethod(methodData),
        },
      });

      setPaymentState({
        isProcessingDialogVisible: true,
        paymentStatus: "PROCESSING",
        isPolling: true,
      });

      redirectUrl(upiAppLink);
    } catch (error) {
      setResponseErr(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (values: { id: string; save: boolean }) => {
    try {
      const methodData = paymentMethods?.find((e: any) => e.mode === "UPI_COLLECT");
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
        eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
        eventType: "click",
        metaData: {
          methodType: "UPI_COLLECT",
          automationData: getAutomationPayloadForMethod(methodData),
        },
      });
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_PAYMENT_MODE_SELECTED,
        eventType: "click",
        metaData: {
          modeType: "UPI_COLLECT",
          methodType: "UPI_COLLECT",
          automationData: getAutomationPayloadForMethod(methodData),
        },
      });

      setPaymentState({
        isProcessingDialogVisible: true,
        paymentStatus: "PROCESSING",
        isPolling: true,
      });
      await getPaymentMethodAction(
        "UPI_COLLECT",
        checkoutId,
        undefined,
        {
          vpa: values?.id,
        },
        setCheckoutExpired,
      );
    } catch (e: any) {
      setPaymentState({
        isProcessingDialogVisible: false,
      });
      setErrors({ id: { status: true, message: t("invalid_upi") } });
      errorToast(t("invalid_upi"));
      throw new Error(e);
    }
  };
  return (
    <div className="rounded-xl border-[2px] border-[#f0f0f0] bg-white">
      <div className="flex flex-row items-center justify-between px-4 pt-2">
        <div className="flex flex-row items-center gap-2">
          <img
            src={upiOthersLogo}
            alt="upi_logo"
            className={`h-7 w-7 rounded object-contain ${isLoading && "upiLogoLoader-img"}`}
          />
          <div className="text-sm font-medium text-coal-dark">UPI</div>
        </div>
        <div className="text-sm font-medium text-coal-dark">{upiAmount && currencyFormatter(upiAmount)}</div>
      </div>
      {isMobile() && (
        <>
          <div className="flex w-full flex-col rounded-2xl">
            <ul className="grid w-full auto-cols-fr grid-flow-col gap-2 px-4 pt-4">
              {upiApps?.map((method) => (
                <li
                  key={method.id}
                  onClick={() => openUPIApp(method.id)}
                  className="flex cursor-pointer items-center justify-center rounded-lg border border-gray-200 p-2 hover:bg-gray-50">
                  <MethodLogo logo={method.logo} name={method.name} id={method.id} isLoading={isLoading} />
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col space-y-3 rounded-2xl pt-3">
            <div className="flex flex-row items-center justify-center gap-2">
              <div className="h-px w-full bg-gray-light"></div>
              <p className="text-sm font-medium text-coal-light">or</p>
              <div className="h-px w-full bg-gray-light"></div>
            </div>
          </div>
        </>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col space-y-3 rounded-2xl p-3"
        id="card-form">
        <div className="relative flex items-center space-x-2">
          <div className="w-full">
            <InputField
              type="text"
              placeholder={t("upi_placeholder")}
              {...inputProps(FormField.id)}
              autoComplete="off"
              error={errors.id}
              height="h-10"
            />
          </div>
          <span>
            <PrimaryButton
              height="h-10"
              width="w-16"
              type="submit"
              borderRadius="rounded-xl"
              buttonText={t("pay")}
              isCheckout={false}
              isDisabled={!values?.id?.length || errors?.id?.status}
              className="whitespace-nowrap"
            />
          </span>
        </div>
      </form>
    </div>
  );
};
