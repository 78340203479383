import React, { useCallback, useState } from "react";
import { LoyaltyRedemptionHandle, YotpoLoyaltyType } from "lib/types/checkout";
import { useLocale } from "lib/hooks/useLocale";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { ArrowRight, Edit } from "react-feather";
import { infoToast } from "lib/utils/toasters";
import Price from "components/common/Price";
import { currencyFormatter } from "lib/utils/formatters";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import LoyaltyCoinsSelector from "components/payments/dialogs/LoyaltyCoinsSelecor";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import loyaltyLogo from "assests/images/payment-methods/loyalty-star.svg";
import { mutate } from "swr";
import { getUnApplicablePaymentReason } from "lib/utils/payments";
import OTPDialog from "components/auth/dialogs/OTPDialog";
import EmailSelector from "components/checkout/dialogs/EmailSelector";
import { AddressCardFields } from "lib/types/address";
import { isValidEmail } from "lib/utils/validations";
import useRewards from "lib/hooks/useRewards";
import { RewardsPayloadType, RewardsUpdatePayload } from "lib/types/rewards";

interface YotpoLoyaltyProps {
  loyaltyDetails: YotpoLoyaltyType;
  selectedEmail: string;
  setSelectedEmail: (email: string) => void;
  isUnusable: boolean;
  isVerified: boolean;
  setIsVerified: (isVerified: boolean) => void;
  context?: "cart" | "checkout";
}

interface LoyaltyPointsRedeemProps {
  loyaltyData: YotpoLoyaltyType;
  updateLoyaltyDetails: (id: string) => Promise<void>;
  removeLoyaltyDetails: () => Promise<void>;
  appliedLoyaltyHandle: LoyaltyRedemptionHandle;
  disabled: boolean;
  handleUnapplicability: () => void;
  unapplicabilityReason: string;
}

const YotpoLoyalty: React.FC<YotpoLoyaltyProps> = ({
  context = "checkout",
  loyaltyDetails,
  selectedEmail,
  setSelectedEmail,
  isUnusable,
  isVerified,
  setIsVerified,
}) => {
  const { t } = useLocale();
  const {
    state: { checkoutId, appliedLoyalty },
    actions: { updateCheckoutBasedOnCheckoutResponse },
  } = useCheckoutContext();
  const {
    state: { user },
  } = useUserContext();
  const {
    actions: { handleUnapplicablePaymentMethod },
  } = usePaymentContext();

  const {
    actions: { removeRewards, updateRewards },
  } = useRewards();

  const [openEmailDialog, setOpenEmailDialog] = useState<boolean>(false);
  const [openOTPDialog, setOpenOTPDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleEmailSelectorClick = () => {
    setOpenEmailDialog(true);
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse, true);
  };

  const getUserEmails = useCallback(() => {
    const emails = user?.addresses
      ?.map((address: AddressCardFields) => address?.email)
      .filter((email: any) => email);
    return emails && Boolean(emails.length) ? Array.from(new Set(emails)) : [];
  }, [user]);

  const handleMethodClick = () => {
    if (Boolean(isUnusable)) {
      infoToast(t("create_loyalty_account"));
      return;
    }
    setOpenOTPDialog(true);
  };

  const removeLoyaltyPoints = async () => {
    try {
      setIsLoading(true);
      const response = await removeRewards({
        rewards_type: RewardsPayloadType.LOYALTY,
      });
      handleCheckoutResponse(response);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const updateLoyaltyPoints = async (id: string) => {
    const payload: RewardsUpdatePayload = {
      redemption_id: id,
      email: selectedEmail,
      rewards_type: RewardsPayloadType.LOYALTY,
    };
    try {
      setIsLoading(true);
      const response = await updateRewards(payload);
      handleCheckoutResponse(response);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleEmailSubmit = async () => {
    try {
      setIsLoading(true);
      if (!isValidEmail(selectedEmail)) {
        setError(true);
        setIsLoading(false);
        return;
      }
      setOpenEmailDialog(false);
      if (appliedLoyalty.totalPoints > 0) {
        await removeLoyaltyPoints();
        return;
      }
      if (selectedEmail) {
        mutate(`/checkout/v2/checkout/${checkoutId}/rewards?email=${selectedEmail}`);
      } else {
        mutate(`/checkout/v2/checkout/${checkoutId}/rewards`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="border-base bg-white p-3">
      <h2 className="mb-3 text-xs uppercase text-gray-dark">{t("rewards_tile_header")}</h2>
      <div className=" mb-3 flex w-full flex-row gap-2 overflow-hidden rounded-lg border border-gray-light px-1.5 py-1.5">
        <button
          onClick={handleEmailSelectorClick}
          className={`items-left flex w-full flex-col justify-between gap-1.5 px-1.5 py-1.5 font-normal text-coal-dark`}>
          <div className="flex w-full items-center ">
            <h1 className="text-sm text-coal-dark">
              {!Boolean(isUnusable)
                ? loyaltyDetails?.email
                : Boolean(selectedEmail)
                ? selectedEmail
                : user?.default_shipping_address?.email}
            </h1>
            <Edit className="ml-auto h-4 w-4 text-coal-dark" />
          </div>
          <div className="mr-6 text-left text-xs text-gray-dark">
            <h2>{!Boolean(isUnusable) ? t("loyalty_line1") : t("create_loyalty_account")}</h2>
          </div>
        </button>
      </div>

      {!Boolean(isVerified) ? (
        <div onClick={handleMethodClick} className="rounded-lg border-gray-light">
          <div
            className={`flex min-h-[64px] w-full cursor-pointer flex-row items-center justify-between space-x-6 rounded-lg border border-gray-light  py-3 pl-2 pr-4 hover:bg-gray-lighter ${
              isUnusable ? "bg-[#fafafa] opacity-75" : ""
            } `}>
            <div className="flex flex-row space-x-3">
              <div className="flex h-9 w-9 flex-row items-center justify-center rounded-lg border border-gray-light p-1">
                <img
                  src={loyaltyLogo}
                  alt={"Loyalty"}
                  className="h-7 min-h-[1.75rem] w-7 min-w-[1.75rem] object-contain"
                />
              </div>
              <div className="flex flex-col justify-center space-y-1">
                <h3 className="text-sm font-medium text-coal-dark">{`${t("redeem")} ${
                  loyaltyDetails?.coinNamePlural
                }`}</h3>{" "}
                <p className="text-xs font-normal text-coal-light">{t("loyalty_payment_subheader")}</p>
              </div>
            </div>
            <ArrowRight className={`!-ml-2 h-4 w-4 text-coal-dark`} />
          </div>
        </div>
      ) : (
        <>
          {loyaltyDetails && (
            <>
              {Boolean(loyaltyDetails?.redemptionOptions?.length) ? (
                <LoyaltyPointsRedeem
                  appliedLoyaltyHandle={appliedLoyalty}
                  loyaltyData={loyaltyDetails}
                  updateLoyaltyDetails={updateLoyaltyPoints}
                  removeLoyaltyDetails={removeLoyaltyPoints}
                  disabled={!loyaltyDetails?.isAvailable}
                  handleUnapplicability={() =>
                    handleUnapplicablePaymentMethod("LOYALTY", loyaltyDetails?.unavailabilityReasons)
                  }
                  unapplicabilityReason={getUnApplicablePaymentReason(
                    loyaltyDetails?.unavailabilityReasons,
                    "Loyalty points",
                  )}
                />
              ) : (
                <div
                  className={
                    " flex min-h-[64px] w-full cursor-pointer flex-row items-center justify-between space-x-6 rounded-lg border border-gray-light bg-[#fafafa] py-3 pl-2 pr-4 opacity-75 hover:bg-gray-lighter"
                  }
                  onClick={() => infoToast(t("not_enough_coins", { brand: loyaltyDetails?.coinNamePlural }))}>
                  <div className="flex flex-row space-x-3">
                    <div className="flex h-9 w-9 flex-row items-center justify-center rounded-lg border border-gray-light p-1">
                      <img
                        src={loyaltyLogo}
                        alt={"Loyalty"}
                        className="h-7 min-h-[1.75rem] w-7 min-w-[1.75rem] object-contain"
                      />
                    </div>
                    <div className="flex flex-col justify-center space-y-1">
                      <h3 className="text-sm font-medium text-coal-dark">{`${t("redeem")} ${
                        loyaltyDetails?.coinNamePlural
                      }`}</h3>
                      <p className="text-xs font-normal text-coal-light">{t("loyalty_points_credited")}</p>
                    </div>
                  </div>
                  <ArrowRight className={`!-ml-2 h-4 w-4 text-coal-dark`} />
                </div>
              )}
            </>
          )}
        </>
      )}

      {loyaltyDetails && (
        <GenericDialog
          isOpen={openOTPDialog}
          translateAxis="y"
          dialogOverlay={true}
          modalType="AUTH"
          customClass="overflow-scroll md:!top-auto md:absolute">
          <OTPDialog
            setIsOpen={(value: boolean) => setOpenOTPDialog(value)}
            setIsVerified={(value: boolean) => setIsVerified(value)}
            refreshLoyalty={async () => {
              mutate(`/checkout/v2/checkout/${checkoutId}/rewards`);
            }}
            loyaltyData={loyaltyDetails}
          />
        </GenericDialog>
      )}

      {loyaltyDetails && (
        <GenericDialog
          isOpen={openEmailDialog}
          translateAxis="y"
          dialogOverlay={true}
          modalType="AUTH"
          customClass="overflow-scroll md:!top-auto md:absolute">
          <EmailSelector
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
            setIsOpen={(value: boolean) => setOpenEmailDialog(value)}
            emails={getUserEmails()}
            onSubmit={handleEmailSubmit}
            error={error}
            setError={(value: boolean) => setError(value)}
            loyaltyData={loyaltyDetails}
          />
        </GenericDialog>
      )}
      {isLoading && <OverlaySpinner />}
    </div>
  );
};

export default YotpoLoyalty;

const LoyaltyPointsRedeem: React.FC<LoyaltyPointsRedeemProps> = ({
  loyaltyData,
  updateLoyaltyDetails,
  removeLoyaltyDetails,
  appliedLoyaltyHandle,
  disabled,
  handleUnapplicability,
  unapplicabilityReason,
}) => {
  const { t } = useLocale();
  const {
    state: { hasDefaultShippingHandleSelected, initialCheckoutStep },
    actions: { setCheckoutModal },
  } = useCheckoutContext();

  const [openSelectorDialog, setOpenSelectorDialog] = useState<boolean>(false);

  const handleRedeemClicked = () => {
    if (Boolean(disabled)) {
      handleUnapplicability();
      return;
    }

    const hasDefaultHandle = hasDefaultShippingHandleSelected;
    if (!Boolean(hasDefaultHandle) && initialCheckoutStep !== "PAYMENTS") {
      setCheckoutModal("SHIPPING_HANDLES");
      return;
    }

    if (!Boolean(appliedLoyaltyHandle.totalPoints)) {
      updateLoyaltyDetails(loyaltyData?.redemptionOptions[0]?.id);
    } else {
      removeLoyaltyDetails();
    }
  };

  return (
    <>
      <div className="mb-3 flex w-full flex-col rounded-lg border">
        <div
          className={`flex w-full flex-col items-start justify-between gap-2 rounded-t-2xl p-3 text-sm transition-[background] duration-300 ${
            Boolean(appliedLoyaltyHandle?.totalPoints) || !Boolean(disabled)
              ? "bg-yay-lighter text-yay-dark"
              : "bg-none text-gray-dark"
          }`}>
          <div
            className="flex w-full cursor-pointer items-center justify-between gap-1"
            onClick={handleRedeemClicked}>
            <div className="flex w-full items-center gap-2 font-normal">
              <input
                type="checkbox"
                className="cursor-pointer accent-yay-dark"
                checked={Boolean(appliedLoyaltyHandle?.totalPoints)}
                readOnly
              />
              <h1 className={!Boolean(appliedLoyaltyHandle?.totalPoints) ? "text-coal-dark" : ""}>
                {t("redeem_x_coins", {
                  total: currencyFormatter(
                    appliedLoyaltyHandle.totalPoints > 0
                      ? appliedLoyaltyHandle.totalPoints
                      : loyaltyData?.totalReducedPoints,
                    false,
                  ),
                  brand: loyaltyData?.coinNamePlural,
                })}
              </h1>
            </div>
            <Price
              total={
                appliedLoyaltyHandle.totalPoints > 0
                  ? appliedLoyaltyHandle.totalPrice
                  : loyaltyData?.totalReductionAmount
              }
              isDiscounted={true}
            />
          </div>

          {Boolean(disabled) && Boolean(unapplicabilityReason) && (
            <p className="text-xs font-normal text-gray-dark">{unapplicabilityReason}</p>
          )}
        </div>
        <div
          onClick={() => {
            if (Boolean(disabled)) {
              handleUnapplicability();
              return;
            }

            const hasDefaultHandle = hasDefaultShippingHandleSelected;
            if (!Boolean(hasDefaultHandle) && initialCheckoutStep !== "PAYMENTS") {
              setCheckoutModal("SHIPPING_HANDLES");
              return;
            }

            setOpenSelectorDialog(true);
          }}
          className="flex cursor-pointer items-center justify-between rounded-b-2xl border-t bg-[#fafafa] px-3 py-3">
          <h2 className="text-sm font-semibold text-coal-dark">{t("change_amount")}</h2>
          <ArrowRight className={`h-4 w-4 text-coal-dark`} />
        </div>
      </div>

      <GenericDialog
        isOpen={openSelectorDialog}
        translateAxis="y"
        dialogOverlay={true}
        modalType="NONE"
        customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]">
        <LoyaltyCoinsSelector
          setIsOpen={(value: boolean) => setOpenSelectorDialog(value)}
          loyaltyData={loyaltyData}
          updateLoyaltyDetails={updateLoyaltyDetails}
          removeLoyaltyDetails={removeLoyaltyDetails}
          appliedLoyaltyHandle={appliedLoyaltyHandle}
        />
      </GenericDialog>
    </>
  );
};
