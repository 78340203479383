import { useLocale } from "lib/hooks/useLocale";
import { StandardNetworkLoyaltyType } from "lib/types/checkout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChevronRight } from "react-feather";
import PopCoinsIcon from "assests/images/popcoins-icon.png";
import { deleteRequest } from "lib/core/apiClient";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { mutate } from "swr";
import { DialogBody, DialogHeader, GenericDialog } from "components/common/dialogs/GenericDialog";
import SwitchCouponDialog from "components/checkout/dialogs/SwitchCouponDialog";
import LoaderDialog from "components/checkout/dialogs/LoaderDialog";
import { errorToast } from "lib/utils/toasters";
import { XIcon } from "@heroicons/react/solid";
import { floorToTwoDecimals } from "lib/utils/helpers";
import useRewards from "lib/hooks/useRewards";
import { RewardsPayloadType, RewardsUpdatePayload } from "lib/types/rewards";
import { apiURI } from "lib/utils/constants";
import RewardCoinIcon from "assests/icons/RewardCoinIcon";
import { AnimateLoading } from "components/animation/AnimateLoading";

interface NetworkLoyaltyProps {
  loyaltyDetails: StandardNetworkLoyaltyType;
  isUnusable: boolean;
  context?: "cart" | "checkout";
}

const NetworkLoyalty: React.FC<NetworkLoyaltyProps> = ({
  loyaltyDetails,
  isUnusable,
  context = "checkout",
}) => {
  const { t } = useLocale();

  const {
    state: { checkoutId, appliedLoyalty, checkoutModal },
    actions: { updateCheckoutBasedOnCheckoutResponse, setCheckoutModal },
  } = useCheckoutContext();

  const {
    actions: { updateRewards, removeRewards },
  } = useRewards();

  const [applyReward, setApplyReward] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (appliedLoyalty?.totalPoints > 0 || appliedLoyalty?.totalPrice > 0) {
      setApplyReward(true);
    }
  }, [appliedLoyalty]);

  const earnEnabled = loyaltyDetails?.earnValue?.isEnabled && Boolean(loyaltyDetails?.earnValue?.value);
  const burnEnabled = Boolean(loyaltyDetails?.userDetails?.maxApplicablePoints);
  const invalidDiscountCodes = loyaltyDetails?.unavailabilityReasons.map((discount: any) => {
    if (discount?.type === "INVALID_DISCOUNTS") {
      return discount?.values;
    }
    return "";
  });

  const hasUnhandledAutomationBlockBeforeRedeem = useMemo(() => {
    if (
      !applyReward &&
      loyaltyDetails &&
      !loyaltyDetails.isAvailable &&
      !Boolean(loyaltyDetails?.unavailabilityReasons?.length)
    ) {
      return true;
    }
    return false;
  }, [loyaltyDetails, applyReward]);
  
  const hasInvalidDiscounts = loyaltyDetails?.unavailabilityReasons?.some(
    (discount: any) => discount?.type === "INVALID_DISCOUNTS",
  );

  const removeLoyaltyPoints = async () => {
    try {
      setIsLoading(true);
      const response = await removeRewards({
        rewards_type: RewardsPayloadType.LOYALTY,
      });
      updateCheckoutBasedOnCheckoutResponse(response, true);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      setApplyReward(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateLoyaltyPoints = async (id: string) => {
    const payload: RewardsUpdatePayload = {
      redemption_id: id,
      amount: loyaltyDetails?.userDetails?.reductionAmount,
      rewards_type: RewardsPayloadType.LOYALTY,
    };
    try {
      setIsLoading(true);
      const response = await updateRewards(payload);
      if (response?.status === "FAILED") {
        mutate(`/checkout/v2/checkout/${checkoutId}/rewards`);
      }
      updateCheckoutBasedOnCheckoutResponse(response, true);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      if (hasInvalidDiscounts) {
        mutate(`/checkout/v1/checkout/${checkoutId}/discounts`);
        mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
      }
      setApplyReward(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApply = () => {
    if (applyReward) {
      removeLoyaltyPoints();
      return;
    }
    if (!loyaltyDetails?.isAvailable && hasInvalidDiscounts) {
      setCheckoutModal("LOYALTY_CONFIRMATION");
      return;
    }
    updateLoyaltyPoints(loyaltyDetails?.redemptionId);
  };

  const getIconComponent = useCallback(() => {
    if (loyaltyDetails?.provider === "POP_COINS") {
      return <img src={PopCoinsIcon} alt="pop-coin" className="w-[20px] h-[20px] rounded-full" />;
    }
    return <RewardCoinIcon fill={applyReward ? "#2c874a" : "#4d4d4d"} />;
  }, [loyaltyDetails, applyReward]);

  if (isUnusable || (!earnEnabled && !burnEnabled)) return <></>;

  return (
    <div
      className={`border-2 ${
        applyReward ? "border-yay-light" : "border-gray-light"
      } rounded-xl flex flex-col bg-white delay-[100ms]`}>
      <AnimateLoading loading={isLoading}>
        <AnimateLoading.Content>
          {burnEnabled && context === "checkout" && !hasUnhandledAutomationBlockBeforeRedeem && (
            <div className={`relative p-3 rounded-xl flex space-x-3 cursor-pointer`}>
              <div className="flex justify-between items-center w-full">
                <div className="flex flex-row justify-start items-start space-x-2 w-full">
                  <div className="flex justify-start w-7 items-start">{getIconComponent()}</div>
                  <div className="flex flex-col space-y-1 w-full justify-between text-sm font-medium">
                    <p className={`${applyReward ? "text-yay-dark" : "text-coal-dark"}`}>
                      {applyReward
                        ? t("rupees_redeemed", { amount: appliedLoyalty?.totalPrice })
                        : t("rupees_redeemable", { amount: loyaltyDetails?.userDetails?.reductionAmount })}
                    </p>
                    <span
                      onClick={() => setCheckoutModal("LOYALTY_INFORMATION")}
                      className="flex justify-start space-x-0.5 items-end text-sm font-normal text-gray-dark">
                      <span>{loyaltyDetails?.coinNamePlural}</span>
                      <ChevronRight className="w-4 h-4" />
                    </span>
                  </div>
                  {applyReward ? (
                    <div onClick={handleApply} className="flex justify-start items-start h-full w-[4.5rem]">
                      <span className="text-sm font-semibold text-coal-dark cursor-pointer rounded-lg py-2">
                        {t("remove")}
                      </span>
                    </div>
                  ) : (
                    <div onClick={handleApply} className="flex justify-start items-start h-full w-[4.5rem]">
                      <span className="text-xs font-medium text-yay-dark cursor-pointer rounded-lg px-3 py-2 border border-yay-dark bg-yay-lighter">
                        {t("redeem")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {earnEnabled ? (
            context === "checkout" ? (
              <div
                className={`${
                  burnEnabled ? "rounded-b-xl border-t-2 border-dashed" : "rounded-xl"
                } p-3 bg-gray-lightest flex space-x-3`}>
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal text-coal-dark">
                    {t("earn_x_coins_worth_y_on_this_order", {
                      x: loyaltyDetails?.earnValue?.value,
                      y: loyaltyDetails?.earnValue?.normalizedValue,
                      coin: loyaltyDetails?.coinNamePlural,
                    })}
                  </p>
                  <p className="text-xs font-normal text-gray-dark">{t("redeem_on_next_purchase")}</p>
                </div>
              </div>
            ) : (
              <div className={`rounded-xl p-3 bg-gray-lightest flex space-x-3`}>
                {getIconComponent()}
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-normal text-coal-dark">
                    {t("earn_x_coins_worth_y_on_this_order", {
                      x: loyaltyDetails?.earnValue?.value,
                      y: loyaltyDetails?.earnValue?.normalizedValue,
                      coin: loyaltyDetails?.coinNamePlural,
                    })}
                  </p>
                  <p className="text-xs font-normal text-gray-dark">{t("redeem_on_next_purchase")}</p>
                </div>
              </div>
            )
          ) : (
            <></>
          )}
        </AnimateLoading.Content>
      </AnimateLoading>
      <LoaderDialog title={t("replacing_coupons")} />
      <GenericDialog
        isOpen={checkoutModal === "LOYALTY_INFORMATION"}
        translateAxis="y"
        dialogOverlay={true}
        modalType="LOYALTY_INFORMATION"
        customClass="overflow-scroll md:!top-auto md:absolute"
        setIsOpen={() => setCheckoutModal("NONE")}>
        <DialogHeader>
          <div className="flex pt-4 pb-6 h-full w-full flex-row items-center justify-between border-b border-gray-light border-dashed">
            <div className="flex flex-col space-y-1">
              <h1 className={`text-base font-medium`}>
                {t("your_coin_name_points_balance", { coinName: loyaltyDetails?.coinNamePlural })}
              </h1>
              <p className="text-xs font-normal text-gray-dark">
                {floorToTwoDecimals(
                  loyaltyDetails?.userDetails?.maxApplicablePoints /
                    loyaltyDetails?.userDetails?.reductionAmount,
                ) +
                  " " +
                  (Boolean(
                    Number(
                      loyaltyDetails?.userDetails?.maxApplicablePoints /
                        loyaltyDetails?.userDetails?.reductionAmount,
                    ) === 1,
                  )
                    ? loyaltyDetails.coinName
                    : loyaltyDetails?.coinNamePlural) +
                  " = ₹1"}
              </p>
            </div>
            <button className="outline-none">
              <XIcon
                className="h-6 w-6 cursor-pointer text-coal-dark"
                onClick={() => setCheckoutModal("NONE")}
              />
            </button>
          </div>
        </DialogHeader>
        <DialogBody className="!pb-10 !pt-24 px-4">
          <div className="flex text-xs text-coal-light flex-col space-y-2">
            <div className="flex flex-row space-x-2 justify-between">
              <p className="font-normal ">{t("balance_label", { coins: loyaltyDetails?.coinNamePlural })}</p>
              <p className="font-medium">
                {loyaltyDetails?.userDetails?.pointBalance}&nbsp;
                {loyaltyDetails?.coinNamePlural}
              </p>
            </div>
            <div className="flex flex-row space-x-2 justify-between">
              <p className="font-normal">
                {t("max_usable_label", { coins: loyaltyDetails?.coinNamePlural })}
              </p>
              <p className="font-medium">
                {loyaltyDetails?.userDetails?.maxApplicablePoints}&nbsp;
                {loyaltyDetails?.coinNamePlural}
              </p>
            </div>
          </div>
        </DialogBody>
      </GenericDialog>
      <GenericDialog
        isOpen={checkoutModal === "LOYALTY_CONFIRMATION"}
        translateAxis="y"
        dialogOverlay={true}
        modalType="LOYALTY_CONFIRMATION"
        customClass="overflow-scroll md:!top-auto md:absolute">
        <SwitchCouponDialog
          closePopup={() => setCheckoutModal("NONE")}
          applyCoupon={async () => {
            try {
              setCheckoutModal("LOADER");
              const promises = invalidDiscountCodes[0]?.map((code: string) =>
                deleteRequest(`/checkout/v1/checkout/${checkoutId}/discounts`, { discount_code: code }),
              );
              const responses = await Promise.allSettled(promises);
              let replacedSuccessfully = true;
              responses.forEach((response) => {
                if (response?.status !== "fulfilled") {
                  replacedSuccessfully = false;
                }
              });
              if (!replacedSuccessfully) {
                errorToast(t("failed_to_replace_coupons"));
                return;
              }
              updateLoyaltyPoints(loyaltyDetails?.redemptionId);
            } catch (error) {
              console.error(error);
              errorToast(t("failed_to_replace_coupons"));
            } finally {
              setCheckoutModal("NONE");
            }
          }}
          appliedDiscountCode={loyaltyDetails?.coinNamePlural}
          invalidDiscountCodes={invalidDiscountCodes[0]}
          invalidReason={"REWARDS_NOT_COMBINABLE"}
          isReward
        />
      </GenericDialog>
    </div>
  );
};

export default NetworkLoyalty;
