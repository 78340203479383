import React, { useCallback, useEffect, useState } from "react";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useSearchParams } from "react-router-dom";
import { useMerchantUiConfig } from "lib/contexts/MerchantUIConfigProvider";
import { useAuthContext } from "lib/contexts/AuthProvider";

interface GenericBannerStripProps {
  bannerType?: string;
}

const GenericBannerStrip: React.FC<GenericBannerStripProps> = React.memo(({ bannerType }) => {
  const {
    state: { checkoutView },
  } = useCheckoutContext();
  const {
    state: { checkoutUIAttributes, attributes },
  } = useMerchantUiConfig();
  const {
    state: { isAuthenticated },
  } = useAuthContext();

  const [isBannerEnabled, setIsBannerEnabled] = useState<boolean>(true);
  const [bannerHtmlStr, setBannerHtmlStr] = useState<string>("");
  const [bannerBg, setBannerBg] = useState<string>("");
  const [textColor, setTextColor] = useState<string>("");

  useEffect(() => {
    if (checkoutUIAttributes) {
      setBannerStates();
    }
  }, [checkoutUIAttributes, checkoutView]);

  const setBannerStates = () => {
    let htmlStr = "";
    let bgColor = "";
    let txtColor = "";
    let isEnabled = false;

    let showBannerType = bannerType;

    if (!bannerType) {
      const isSinglePageCheckout = attributes.is_one_page_checkout;
      if (isSinglePageCheckout) {
        showBannerType = "PAYMENTS";
      } else {
        // If checkout view is not loaded means user is on address list page where fetch API is made
        showBannerType = checkoutView === "NA" ? "ORDER_SUMMARY" : checkoutView;
      }
    }

    if (checkoutView === "ADDRESS_LIST") {
      showBannerType = "ORDER_SUMMARY";
    }

    if (!isAuthenticated) {
      showBannerType = "AUTH";
    }

    let currentCheckoutViewBanner = checkoutUIAttributes.banner_config.find(
      (banner: any) => banner.type === showBannerType,
    );

    if (Boolean(currentCheckoutViewBanner)) {
      htmlStr = currentCheckoutViewBanner?.html_text_string ?? "";
      bgColor = `${currentCheckoutViewBanner?.background_color ?? "black"}`;
      txtColor = `${currentCheckoutViewBanner?.text_color ?? "white"}`;
      isEnabled = currentCheckoutViewBanner?.enabled ?? false;
    }

    setBannerHtmlStr(htmlStr);
    setBannerBg(bgColor);
    setTextColor(txtColor);
    setIsBannerEnabled(isEnabled);
  };

  useEffect(() => {
    document?.documentElement?.style?.setProperty("--flo-bannerstrip-bg-color", `${bannerBg}`);
    document?.documentElement?.style?.setProperty("--flo-bannerstrip-txt-color", `${textColor}`);
  }, [bannerBg, textColor]);

  if (!isBannerEnabled || !Boolean(checkoutUIAttributes.banner_config)) return <></>;
  return (
    <div
      className={`bannerStripClass sticky top-0 z-20 flex  min-h-[1.875rem]  w-full items-center justify-center px-3 py-2 text-center text-sm`}
      dangerouslySetInnerHTML={{ __html: bannerHtmlStr }}></div>
  );
});

export default GenericBannerStrip;
