import { XIcon } from "@heroicons/react/solid";
import { DialogHeader, DialogBody } from "components/common/dialogs/GenericDialog";
import InputField from "components/common/forms/InputField";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { postRequest } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import { apiURI } from "lib/utils/constants";
import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { mutate } from "swr";

interface RedeemVoucherDialogProps {
  gyftrBalance: number;
}
const RedeemVoucherDialog = ({ gyftrBalance }: RedeemVoucherDialogProps) => {
  const { t } = useLocale();
  const {
    state: { checkoutId, billing },
    actions: { setCheckoutModal, updateCheckoutBasedOnCheckoutResponse },
  } = useCheckoutContext();

  const [values, setValues] = useState({
    redeemAmount: Math.min(billing.total_payable, gyftrBalance).toString(),
    error: "",
    loading: false,
  });

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      redeemAmount: Math.min(billing.total_payable, gyftrBalance).toString(),
    }));
  }, [billing.total_payable, gyftrBalance]);
  
  const handleRedeem = async () => {
    setValues((prev) => ({
      ...prev,
      loading: true,
    }));
    try {
      const response: any = await postRequest(`/checkout/v2/checkout/${checkoutId}/gc/voucher`, {
        redemption_amount: Number(values.redeemAmount),
        voucher_payment_mode: "GYFTR",
      });

      if (response.status && response.status === "FAILED") {
        setValues((prev) => ({
          ...prev,
          error: response.error,
        }));
        return;
      }

      updateCheckoutBasedOnCheckoutResponse(response);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      mutate(`/checkout/v1/checkout/${checkoutId}/discounts`);
      mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
      mutate(`/checkout/v2/checkout/${checkoutId}/gc/voucher?voucher_payment_mode=GYFTR`);
      setCheckoutModal("NONE");
    } catch (err: any) {
      console.error("error", err);
      setValues((prev) => ({
        ...prev,
        error: err.response.data.error,
      }));
    } finally {
      setValues((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue === "") {
      setValues({ ...values, redeemAmount: "0" });
      return;
    }

    const newAmount = Number(inputValue);
    if (isNaN(newAmount)) return;

    const maxAllowedAmount = Math.max(0, Math.min(newAmount, billing.total_payable, gyftrBalance));

    setValues({
      ...values,
      redeemAmount: inputValue.includes(".")
        ? (newAmount > maxAllowedAmount ? maxAllowedAmount : inputValue).toString().slice(0, 15)
        : maxAllowedAmount.toString(),
    });
  };

  return (
    <>
      <DialogHeader>
        <div className="flex h-full w-full flex-row items-center justify-between border-b border-dashed border-carbon-lighter py-3 mt-2">
          <h1 className={`text-base font-medium`}>{t("gift_voucher_or_epay")}</h1>
          <button className="outline-none">
            <XIcon
              className="h-6 w-6 cursor-pointer text-coal-dark"
              onClick={() => {
                setCheckoutModal("NONE");
              }}
            />
          </button>
        </div>
      </DialogHeader>
      <DialogBody className="!pb-4 !pt-12">
        <div className="relative flex w-full flex-col space-y-16 px-5 pb-8 pt-4 mt-4">
          <div className="flex items-center justify-between bg-gray-lightest p-3 rounded-xl border border-gray-light">
            <div className=" flex flex-col  rounded-xl gap-2 ">
              <div className="text-base text-carbon font-medium">₹{gyftrBalance}</div>
              <div className="text-sm text-coal-light">{t("gift_voucher_balance")}</div>
            </div>
            <div
              className="flex items-center gap-1 justify-center rounded-xl cursor-pointer "
              onClick={() => {
                setCheckoutModal("GYFTR_ADD_COUPON_DIALOG");
              }}>
              <Plus size={14} color="#4D4D4D" />
              <span className="text-sm text-coal-dark font-medium">{t("add_more")}</span>
            </div>
          </div>

          <div className="mt-8">
            <div className="text-base mb-2">{t("enter_amount_to_redeem")}</div>
            <div className="relative">
              <InputField
                type="number"
                label=""
                id="redeem_amount"
                name="redeemAmount"
                value={values.redeemAmount.toString()}
                onChange={handleAmountChange}
                filled={Boolean(values.redeemAmount)}
                autoComplete="off"
                required={false}
                startIcon={<span className="text-coal-dark">₹</span>}
              />
              <div className="absolute right-2 top-1/2 -translate-y-1/2">
                <button
                  className="text-primary-dark font-medium min-w-[80px]"
                  onClick={handleRedeem}
                  disabled={values.loading}>
                  {values.loading ? (
                    <div className="flex justify-end items-center pr-4">
                      <div className="w-5 h-5 border-2 border-primary-dark border-t-transparent rounded-full animate-spin" />
                    </div>
                  ) : (
                    t("redeem")
                  )}
                </button>
              </div>
            </div>
            {values.error && <p className="text-xs text-red-dark px-1 mt-1">{values.error}</p>}
          </div>
        </div>
      </DialogBody>
    </>
  );
};

export default RedeemVoucherDialog;
