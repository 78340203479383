/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck
import { analyticsEvents } from "lib/utils/constants";
import { sleep } from "lib/utils/helpers";
import { withScope } from "@sentry/react";
import { ErrorType } from "lib/types/error";

export const initialiseFBQ = (
  channel: string,
  pixelId: string,
  userEmail?: string,
  userName?: string,
  userPhone?: string,
) => {
  if (!pixelId) return;
  if ((window as any).fbq) return;
  (() => {
    const initPayload = {
      ...(Boolean(userEmail) && { em: userEmail }),
      ...(Boolean(userName) && { fn: userName }),
      ...(Boolean(userPhone) && { ph: userPhone }),
    };
    try {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

      if (Array.isArray(pixelId)) {
        pixelId.forEach((pixel: any) => {
          if (pixel.channels?.includes(channel)) {
            fbq("init", pixel.id, initPayload);
          }
        });
        return;
      }

      fbq("init", pixelId, initPayload);
    } catch (e) {
      console.error("Error while initializing FB Pixel", e);
    }
  })();
};

interface FBQEventData {
  orderTotal: number;
  lineItems: any;
  currency: string;
  sessionId: string;
  pixelId: string;
  userEmail?: string;
  userName?: string;
  userPhone?: string;
  checkoutChannel: string;
}

export const sendPaymentInfoFbqEvent = async ({
  orderTotal,
  lineItems,
  currency = "INR",
  sessionId,
  pixelId,
  userName,
  userEmail,
  userPhone,
  checkoutChannel,
}: FBQEventData) => {
  if (!(window as any).fbq) {
    initialiseFBQ(checkoutChannel, pixelId, userEmail, userName, userPhone);
    await sleep(100);
  }

  if (!(window as any).fbq) {
    return;
  }

  try {
    const payload = {
      content_type: "product_group",
      value: (+orderTotal)?.toFixed(2),
      content_ids: lineItems?.map((item: any) => parseInt(item.product_id)),
      num_items: lineItems?.reduce((sum: any, item: any) => sum + (item.quantity || 0), 0),
      currency: currency,
    };
    (window as any).fbq("track", "AddPaymentInfo", payload, { eventID: `payment_initiated-${sessionId}` });
  } catch (e) {
    console.error("Error while sending fbq Payment Event", e);
  }
};

export const sendCheckoutInitiateFbqEvent = async ({
  orderTotal,
  lineItems,
  currency = "INR",
  sessionId,
  pixelId,
  userName,
  userEmail,
  userPhone,
  checkoutChannel,
}: FBQEventData) => {
  if (!(window as any).fbq) {
    initialiseFBQ(checkoutChannel, pixelId, userEmail, userName, userPhone);
    await sleep(100);
  }

  if (!(window as any).fbq) {
    return;
  }

  try {
    if (!lineItems?.length) {
      throw new Error("Facebook Pixel: No line items found");
    }
    const payload = {
      content_type: "product_group",
      value: (+orderTotal)?.toFixed(2),
      content_ids: lineItems?.map((item: any) => parseInt(item.product_id)),
      num_items: lineItems?.reduce((sum: any, item: any) => sum + (item.quantity || 0), 0),
      currency: currency,
    };
    (window as any).fbq("track", "InitiateCheckout", payload, { eventID: `checkout_initiated-${sessionId}` });
  } catch (e) {
    withScope((scope) => {
      scope.setTag("error_type", ErrorType.PIXEL_ERROR);
      scope.setExtra("lineItems", lineItems);
      scope.setExtra("eventType", "InitiateCheckout");
      scope.captureException(e);
    });
  }
};

export const sendPaymentCompletedFbqEvent = async ({
  orderTotal,
  lineItems,
  currency = "INR",
  sessionId,
  pixelId,
  userName,
  userEmail,
  userPhone,
  checkoutChannel,
}: FBQEventData) => {
  if (!(window as any).fbq) {
    initialiseFBQ(checkoutChannel, pixelId, userEmail, userName, userPhone);
    await sleep(100);
  }

  if (!(window as any).fbq) {
    return;
  }

  try {
    const payload = {
      content_type: "product_group",
      value: (+orderTotal)?.toFixed(2),
      content_ids: lineItems?.map((item: any) => parseInt(item.product_id)),
      num_items: lineItems?.reduce((sum: any, item: any) => sum + (item.quantity || 0), 0),
      currency: currency,
    };
    (window as any).fbq("track", "Purchase", payload, { eventID: `order_completed-${sessionId}` });
  } catch (e) {
    console.error("Error while sending fbq Payment Completed Event", e);
  }
};

export const sendAddToCartFbqEvent = async ({
  orderTotal,
  lineItems,
  currency = "INR",
  sessionId,
  pixelId,
  userName,
  userEmail,
  userPhone,
  checkoutChannel,
}: FBQEventData) => {
  if (!(window as any).fbq) {
    initialiseFBQ(checkoutChannel, pixelId, userEmail, userName, userPhone);
    await sleep(100);
  }

  if (!(window as any).fbq) {
    return;
  }

  try {
    if (!lineItems?.length) {
      throw new Error("Facebook Pixel: No line items found");
    }
    const payload = {
      content_type: "product_group",
      value: (+orderTotal)?.toFixed(2),
      content_ids: lineItems?.map((item: any) => parseInt(item.product_id)),
      num_items: lineItems?.reduce((sum: any, item: any) => sum + (item.quantity || 0), 0),
      currency: currency,
    };
    (window as any).fbq("track", "AddToCart", payload, {
      eventID: `${analyticsEvents.FLO_ADDED_TO_CART_UI}-${sessionId}`,
    });
  } catch (e) {
    withScope((scope) => {
      scope.setTag("error_type", ErrorType.PIXEL_ERROR);
      scope.setExtra("lineItems", lineItems);
      scope.setExtra("eventType", "AddToCart");
      scope.captureException(e);
    });
  }
};
