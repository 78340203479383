import { Dialog } from "@headlessui/react";
import { DialogBody, DialogHeader, GenericDialog } from "components/common/dialogs/GenericDialog";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { useLocale } from "lib/hooks/useLocale";
import { currencyFormatter } from "lib/utils/formatters";
import { useCallback, useEffect, useState } from "react";
import { X } from "react-feather";
import useForm from "lib/hooks/useForm";
import { postRequest, staticOptions } from "lib/core/apiClient";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { resentCodOtp, verifyCodOtp } from "lib/utils/cod";
import { setPhoneNumberSpacing } from "lib/utils/helpers";
import OTPForm from "components/auth/forms/OTPForm";
import useSWR from "swr";

type StatusType = "idle" | "input" | "loading" | "verified" | "failed";

const ConfirmOtpForCOD = () => {
  const { t } = useLocale();
  const {
    state: { openPaymentMethodDialog, paymentMethods },
    actions: { handleNonPaymentOrderPlacement, setPaymentMethodDialog },
  } = usePaymentContext();

  const {
    state: { checkoutId },
  } = useCheckoutContext();

  const isOpen = openPaymentMethodDialog === "COD_OTP";

  const [counter, setCounter] = useState(30);
  const [contextId, setContextId] = useState("");
  const [otpCheckoutId, setOtpCheckoutId] = useState("");
  const [phone, setPhone] = useState("");
  const [otpLimitReached, setOtpLimitReached] = useState(false);
  const [counterExpired, setCounterExpired] = useState(false);
  const [status, setStatus] = useState<StatusType>("idle");

  const {
    setValueOf,
    setErrors,
    handleSubmit,
    state: { values, errors },
  } = useForm({
    initialState: { otp: "" },
    validationSchema: {
      otp: {
        required: t("enter_your_otp"),
        numeric: t("otp_incorrect"),
        length: {
          limit: 4,
          message: t("otp_incorrect"),
        },
        formatters: ["NUMERIC"],
      },
    },
  });

  const { data: otpResponse, error: otpError } = useSWR(
    isOpen ? `/checkout/v1/checkout/${checkoutId}/cod-otp/send` : null,
    (url) => postRequest(url, {}),
    staticOptions,
  );

  const handleResendOtp = async () => {
    setStatus("loading");
    setValueOf("otp", "");
    try {
      await resentCodOtp(contextId, phone, otpCheckoutId);
      setCounter(30);
      setCounterExpired(false);
    } catch (err: any) {
      console.error(err);
      if (err?.response?.status === 400) {
        setStatus("failed");
        setOtpLimitReached(true);
        setErrors({
          otp: {
            status: true,
            message: t("otp_limit_exceeded"),
          },
        });
        return;
      }
    }
    setErrors({ otp: { status: false } });
    setStatus("input");
  };

  const cod = paymentMethods.find((method: any) => method?.mode === "COD");

  const handlePayment = useCallback(() => {
    handleNonPaymentOrderPlacement("COD");
  }, []);

  useEffect(() => {
    const handleOtp = async () => {
      try {
        setValueOf("otp", "");
        setErrors({ otp: { status: false, showAlert: false } });
        setCounter(30);
        setCounterExpired(false);
        setStatus("input");
        if (!Boolean(otpResponse)) return;
        if (otpResponse.cod_verification_required) {
          setContextId(otpResponse.cod_otp?.context_id);
          setOtpCheckoutId(otpResponse.cod_otp?.checkout_id);
          setPhone(otpResponse.cod_otp?.phone);
        } else {
          setStatus("verified");
          setPaymentMethodDialog("NONE");
          handlePayment();
        }
      } catch (err: any) {
        setStatus("failed");
        if (err?.response?.status === 400) {
          setOtpLimitReached(true);
          setErrors({
            otp: {
              status: true,
              message: t("otp_limit_exceeded"),
            },
          });
        }
      }
    };
    if (isOpen && otpResponse) handleOtp();
  }, [checkoutId, isOpen, otpResponse]);

  useEffect(() => {
    if (otpError) {
      setStatus("failed");
      console.error(otpError);
      if (otpError?.response?.status === 400) {
        setOtpLimitReached(true);
        setErrors({
          otp: {
            status: true,
            message: t("otp_limit_exceeded"),
          },
        });
      }
    }
  }, [otpError, setErrors, t]);

  useEffect(() => {
    if (counter === 0) setCounterExpired(true);
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const validateOtp = useCallback(
    async (otp: string) => {
      if (Boolean(otp) && otp?.length !== 4) return;
      setErrors({ otp: { status: false } });
      setStatus("loading");

      try {
        await verifyCodOtp(contextId, otp, otpCheckoutId);
        setStatus("verified");
        setPaymentMethodDialog("NONE");
        handlePayment();
      } catch (error) {
        setStatus("failed");
        setErrors({ otp: { status: true, message: t("otp_incorrect") } });
      }
    },
    [contextId, handlePayment, otpCheckoutId, setErrors, t],
  );

  const isLoading = status === "loading";
  const hasError = errors?.otp?.status === true;
  
  useEffect(() => {
    if (values.otp && values.otp.length === 4) {
      validateOtp(values.otp);
    }
  }, [values.otp]);

  return (
    <GenericDialog
      isOpen={openPaymentMethodDialog === "COD_OTP"}
      setIsOpen={() => {
        setPaymentMethodDialog("COD");
      }}
      translateAxis="y"
      customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
      modalType="COD_OTP"
      dialogOverlay={true}>
      <DialogHeader>
        <Dialog.Title
          as="h3"
          className="flex h-full w-full flex-row items-center justify-between bg-white py-4 text-base font-medium text-carbon-dark">
          <div className="flex items-center">
            <h2 className="inline-flex gap-2 font-medium text-carbon-dark">
              {t("confirm_cod_order_for_x", { amount: currencyFormatter(cod?.amount) })}
            </h2>
          </div>
          <button className="outline-none">
            <X
              className="h-6 w-6 cursor-pointer text-coal-dark"
              onClick={() => {
                setPaymentMethodDialog("COD");
              }}
            />
          </button>
        </Dialog.Title>
      </DialogHeader>
      <DialogBody className="flex flex-col items-center gap-2 px-3 pb-56">
        {phone ? (
          <h2 className="text-sm font-medium text-coal-light">
            {`${t("enter_otp_to_verify")} `}{" "}
            <span className="text-coal-dark">{setPhoneNumberSpacing(phone)}</span>
          </h2>
        ) : null}

        <div className="px-4 py-3">
          <OTPForm
            isLoading={isLoading}
            otpLength={4}
            context={"cod"}
            otpValue={values.otp ?? ""}
            handleSubmit={handleSubmit(validateOtp)}
            handleChange={(code: string) => {
              setValueOf("otp", code);
              if (errors?.otp?.status) {
                setErrors({ otp: { status: false } });
              }
            }}
            validateOtp={validateOtp}
            disableResend={hasError && otpLimitReached}
            handleResendOtp={handleResendOtp}
            error={errors?.otp?.status || false}
            errorMessage={errors?.otp?.message || ""}
            autoFocus={true}
            otpCounter={counter}
            otpCounterExpired={counterExpired}
            emailValidation={false}
            showSecuredByShopflo={true}
          />
        </div>
      </DialogBody>
    </GenericDialog>
  );
};

export default ConfirmOtpForCOD;
