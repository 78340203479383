import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { deleteRequest, getRequest, postRequest, staticOptions } from "lib/core/apiClient";
import { useCallback, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import { parseRewardsResponse } from "lib/utils/rewards";
import { RewardsRemovePayload, RewardsUpdatePayload, UseRewardsReturn } from "lib/types/rewards";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { apiURI } from "lib/utils/constants";

const useRewards = (): UseRewardsReturn => {
  const {
    state: { checkoutId, appliedLoyalty },
  } = useCheckoutContext();
  const {
    state: { isAuthenticated },
  } = useAuthContext();

  const [selectedEmail, setSelectedEmail] = useState<string>("");

  const {
    data: rewardsResponse,
    isValidating,
    error,
    mutate: mutateRewards,
  } = useSWR(
    Boolean(checkoutId && isAuthenticated)
      ? [`/checkout/${checkoutId}/rewards${selectedEmail ? `?email=${selectedEmail}` : ""}`, "KRATOS_PRIVATE"]
      : null,
    getRequest,
    staticOptions,
  );

  const removeRewards = useCallback(
    async (payload: RewardsRemovePayload) => {
      try {
        const response = await deleteRequest(`/checkout/${checkoutId}/rewards`, payload, "KRATOS_PRIVATE");
        mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    [checkoutId],
  );

  const updateRewards = useCallback(
    async (payload: RewardsUpdatePayload) => {
      try {
        const response = await postRequest(`/checkout/${checkoutId}/rewards`, payload, "KRATOS_PRIVATE");
        mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    [checkoutId],
  );

  const parsedResponse = useMemo(
    () => parseRewardsResponse(rewardsResponse, appliedLoyalty),
    [rewardsResponse, appliedLoyalty],
  );

  const returnValue: UseRewardsReturn = {
    state: {
      mode: parsedResponse.mode,
      rewardsConfig: parsedResponse,
      isTileLoading: isValidating,
      error,
    },
    actions: {
      updateRewards,
      removeRewards,
      setSelectedEmail,
    },
  };

  return returnValue;
};

export default useRewards;
