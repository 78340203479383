import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { postRequest, staticOptions } from "lib/core/apiClient";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useCODPayment = () => {
  const [sendOtp, setSendOtp] = useState(false);

  const {
    state: { merchant },
  } = useMerchantContext();

  const {
    state: { checkoutId },
  } = useCheckoutContext();

  const {
    data: otpResponse,
    isValidating: sendingOTP,
    error: otpError,
  } = useSWR(
    sendOtp ? `/checkout/v1/checkout/${checkoutId}/cod-otp/send` : null,
    (url) => postRequest(url, {}),
    staticOptions,
  );

  const {
    actions: { setPaymentMethodDialog, handleNonPaymentOrderPlacement },
  } = usePaymentContext();

  useEffect(() => {
    if (sendOtp && !sendingOTP) {
      if (Boolean(otpResponse) && !otpResponse?.cod_verification_required && !otpError) {
        setPaymentMethodDialog("NONE");
        handleNonPaymentOrderPlacement("COD");
        setSendOtp(false);
        return;
      }
      setPaymentMethodDialog("COD_OTP");
      setSendOtp(false);
    }
  }, [otpResponse, sendOtp, sendingOTP]);

  const placeCODOrder = () => {
    if (merchant?.codVerification === "PRE_COD") {
      return setSendOtp(true);
    }
    handleNonPaymentOrderPlacement("COD");
    setPaymentMethodDialog("NONE");
  };
return { placeCODOrder };
};
