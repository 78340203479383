import { useEffect, useMemo, useState } from "react";
import { X } from "react-feather";
import useSWR from "swr";
import { Dialog } from "@headlessui/react";

import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  GenericDialog,
} from "components/common/dialogs/GenericDialog";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { postRequest, staticOptions } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import { currencyFormatter } from "lib/utils/formatters";
import { getPrepaidDiscountData } from "lib/utils/payments";

const PlaceCODOrderDialog = () => {
  const {
    state: { paymentMethods, openPaymentMethodDialog },
    actions: { setPaymentMethodDialog, handleNonPaymentOrderPlacement },
  } = usePaymentContext();
  const { t } = useLocale();
  const {
    state: { billing, checkoutId },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const [sendOtp, setSendOtp] = useState(false);

  const {
    data: otpResponse,
    isValidating: sendingOTP,
    error: otpError,
  } = useSWR(
    sendOtp ? `/checkout/v1/checkout/${checkoutId}/cod-otp/send` : null,
    (url) => postRequest(url, {}),
    staticOptions,
  );

  const cod = paymentMethods.find((method: any) => method?.mode === "COD");
  const splitCod = paymentMethods.find((method: any) => method?.mode === "SPLIT_COD");

  // Adding this to detect opening of COD Confirmation Modal and instead of opening modal directly place order in case if the COD Confirmation Modal is hidden
  useEffect(() => {
    if (openPaymentMethodDialog === "COD_ORDER_PLACING" && merchant?.hideCODpopup) {
      handlePlaceCODOrder();
    }
  }, [openPaymentMethodDialog]);

  const handlePlaceCODOrder = () => {
    if (merchant?.codVerification === "PRE_COD") {
      return setSendOtp(true);
    }
    handleNonPaymentOrderPlacement("COD");
    setPaymentMethodDialog("NONE");
  };

  useEffect(() => {
    if (sendOtp && !sendingOTP) {
      if (Boolean(otpResponse) && !otpResponse?.cod_verification_required && !otpError) {
        setPaymentMethodDialog("NONE");
        handleNonPaymentOrderPlacement("COD");
        setSendOtp(false);
        return;
      }
      setPaymentMethodDialog("COD_OTP");
      setSendOtp(false);
    }
  }, [otpResponse, sendOtp, sendingOTP]);

  const { maxDiscountMethod } = getPrepaidDiscountData(paymentMethods);

  const hasCodCharge = Boolean(billing?.cod);

  const renderPayonlineButtonText = () => {
    if (hasCodCharge)
      return t("pay_online_x_save_y", {
        payment: currencyFormatter(
          Boolean(maxDiscountMethod?.amount) ? maxDiscountMethod?.amount : billing.total_payable,
        ),
        off: currencyFormatter(
          billing.cod + (Boolean(maxDiscountMethod?.discount) ? maxDiscountMethod?.discount : 0),
        ),
      });

    return !Boolean(maxDiscountMethod?.discount + billing.cod)
      ? t("pay_online")
      : t("pay_online_x_save_y", {
          payment: currencyFormatter(maxDiscountMethod?.amount),
          off: currencyFormatter(maxDiscountMethod?.discount),
        });
  };

  const headerText = useMemo(() => {
    if (Boolean(maxDiscountMethod?.discount)) {
      return t("pay_online_and_save_more");
    }
    if (hasCodCharge) {
      return t("pay_online_and_skip_cod_charge");
    }
    return t("confirm_your_payment_method");
  }, [hasCodCharge, maxDiscountMethod]);

  if (!cod) return null;

  return (
    <GenericDialog
      isOpen={openPaymentMethodDialog === "COD_ORDER_PLACING" && !merchant?.hideCODpopup}
      setIsOpen={() => setPaymentMethodDialog("NONE")}
      translateAxis="y"
      customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
      modalType="COD_ORDER_PLACING"
      dialogOverlay={true}>
      <DialogHeader className="px-4">
        <Dialog.Title
          as="h3"
          className="flex h-full w-full flex-row items-center justify-between bg-white py-4 text-base font-medium text-carbon-dark">
          <div className="flex items-center">
            <h2 className="inline-flex gap-2 font-medium text-carbon-dark">{headerText}</h2>
          </div>
          <button className="outline-none">
            <X className="h-6 w-6 cursor-pointer text-coal-dark" onClick={()=> setPaymentMethodDialog("NONE")} />
          </button>
        </Dialog.Title>
      </DialogHeader>
      <DialogBody className={`flex flex-col px-3 ${hasCodCharge ? "pb-36" : "!h-0"}`}></DialogBody>
      <DialogFooter className="max-h-[none] bg-white p-3 pb-11">
        <div className="flex w-full flex-col gap-2">
          <button
            className="h-14 w-full rounded-xl bg-primary-dark py-3 font-medium text-btnPrimaryText"
            onClick={handlePlaceCODOrder}
            type="button">
            {t("confirm_cod_order_of_x", { amount: currencyFormatter(cod?.amount) })}
          </button>
          <button
            onClick={() => {
              setPaymentMethodDialog("NONE");
            }}
            className={`h-14 w-full rounded-xl py-3 font-medium text-coal-dark border-2 border-gray-light`}>
            {renderPayonlineButtonText()}
          </button>
        </div>
      </DialogFooter>
    </GenericDialog>
  );
};

export default PlaceCODOrderDialog;
