import React, { useCallback, useEffect, useMemo } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { EmiDetailsType, PaymentModeType } from "lib/types/payments";
import { paymentMethodsConfig } from "lib/utils/payments";
import { classNames, floorToTwoDecimals } from "lib/utils/helpers";
import { currencyFormatter } from "lib/utils/formatters";
import { IconProps } from "assests/icons/types";
import { ChevronRight, Gift } from "react-feather";
import Tag from "components/common/Tag";
import InvalidOffersDialog from "components/payments/dialogs/InvalidOffers";
import { useMethod } from "components/payments/methods/useMethod";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import UPIQRIcon from "assests/icons/UPIQRIcon";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { useCODPayment } from "lib/hooks/useCODPayment";
interface MethodCardProps {
  modeData: any;
  customClass?: string;
  inFocus?: boolean;
}

const MethodCard: React.FC<MethodCardProps> = ({ customClass = "", modeData, inFocus }) => {
  const { t } = useLocale();
  const {
    mode,
    discount,
    amount: total,
    metadata,
    is_available,
    unavailability_reasons,
    emi_details,
  }: any = modeData;

  const {
    title,
    description,
    hideDescription,
    logo: customLogoUrl,
    additional_charge_type = "FLAT",
    additional_charge_value = 0,
  } = metadata || {};
  const {
    state: { merchant },
  } = useMerchantContext();

  const {
    hasDiscountOrFreebie,
    handleMethodClick,
    invalidOfferDialogOpen,
    handleInvalidOfferDialogClose,
    freebiePrice,
    hasPrepaidFreebie,
    discountCondition,
    billing,
  } = useMethod({ modeData, inFocus });

  const {
    state: { paymentMethods },
  } = usePaymentContext();

  const { placeCODOrder } = useCODPayment();

  const concessionAmount = floorToTwoDecimals(
    hasPrepaidFreebie ? freebiePrice : modeData?.discount_metadata?.coupon_metadata?.concession_amount,
  );

  const MethodLogo = ({ color, size }: IconProps) => {
    let LogoComponent = paymentMethodsConfig[mode as PaymentModeType]?.logo;

    if (mode === "UPI" && merchant?.upiTileWithIcons) {
      LogoComponent = UPIQRIcon;
    }

    return (
      <LogoComponent
        color={color}
        size={size}
        fill="none"
        shouldUpdateOnHover={hasDiscountOrFreebie && is_available}
      />
    );
  };

  const emiDetails: EmiDetailsType[] = useMemo(() => {
    if (!Boolean(emi_details)) return [];
    return emi_details.map((e: any) => ({
      tenure: e.tenure,
      remainingAmount: e.remaining_amount,
      downPayment: e.down_payment,
    }));
  }, [emi_details]);

  const offerText = useMemo(() => {
    if (
      modeData?.discount_metadata?.coupon_metadata?.is_custom_header &&
      modeData?.discount_metadata?.coupon_metadata?.is_custom_header === "CUSTOM"
    ) {
      return modeData?.discount_metadata?.coupon_metadata?.header?.replaceAll(
        "<value>",
        Boolean(modeData?.discount_metadata?.coupon_metadata?.maximum_concession_amount)
          ? floorToTwoDecimals(modeData?.discount)
          : concessionAmount,
      );
    }
    if (hasPrepaidFreebie) {
      if (modeData?.discount_metadata?.mode_specific_cart_alteration?.length > 1) {
        return `Get items worth ₹${concessionAmount}`;
      }
      return `Get an item worth ₹${concessionAmount}`;
    }
    if (modeData?.discount_metadata?.coupon_metadata?.deduction_type === "FLAT") {
      return `Get ₹${concessionAmount} off`;
    }
    if (Boolean(modeData?.discount_metadata?.coupon_metadata?.maximum_concession_amount)) {
      return `Get ₹${floorToTwoDecimals(modeData?.discount)} off`;
    }
    return `Extra ${concessionAmount}% off`;
  }, [modeData, concessionAmount, hasPrepaidFreebie]);

  const renderUnavailableReason = useCallback(() => {
    if (!unavailability_reasons?.length) return <></>;

    const hasShippingBlocker = unavailability_reasons.some((e: any) => e.type === "SHIPPING_HANDLES");
    if (hasShippingBlocker) {
      return (
        <p className={`flex items-center gap-1 space-x-1 text-sm font-medium text-carbon-dark`}>
          <span>{t("select_shipping")}</span> <ChevronRight className="h-4 w-4 text-coal-dark" />
        </p>
      );
    }
    return (
      <p className={`flex items-center gap-1 space-x-1 text-sm font-medium text-carbon-dark`}>
        <span>{t("remove_offers")}</span> <ChevronRight className="h-4 w-4" />
      </p>
    );
  }, [unavailability_reasons, t]);

  const renderSnapmintEMI = useCallback(() => {
    const monthsValue = emiDetails.map((e) => e.tenure).join("/");
    return (
      <div className={`flex items-center space-x-2 ${hasDiscountOrFreebie ? "pt-3" : ""}`}>
        <div className={`flex flex-col items-end justify-end space-y-1 `}>
          <span>
            {currencyFormatter(emiDetails[0]?.downPayment)} {Boolean(emiDetails?.length) ? t("now") : ""}
          </span>
          {Boolean(emiDetails?.length) && (
            <span className="text-xs font-normal text-coal-dark">
              {currencyFormatter(emiDetails[0]?.remainingAmount)}
              <span className="text-xs font-normal text-gray-dark">
                {" "}
                {t("in_x_months", { months: monthsValue })}
              </span>
            </span>
          )}
        </div>
        <ChevronRight className="h-4 w-4 text-coal-dark" />
      </div>
    );
  }, [mode, emiDetails]);

  const renderSimplPayInThree = useCallback(() => {
    const monthsValue = emiDetails.map((e) => e.tenure).join("/");
    return (
      <div className={`flex items-center space-x-2 ${hasDiscountOrFreebie ? "pt-3" : ""}`}>
        <div className={`flex flex-col items-end justify-end space-y-1 `}>
          <span>
            {currencyFormatter(emiDetails[0]?.downPayment)} {Boolean(emiDetails?.length) ? t("now") : ""}
          </span>
          {Boolean(emiDetails?.length) && (
            <span className="text-xs font-normal text-coal-dark">
              {currencyFormatter(emiDetails[0]?.remainingAmount)}
              <span className="text-xs font-normal text-gray-dark">
                {" "}
                {t("in_x_months", { months: monthsValue })}
              </span>
            </span>
          )}
        </div>
        <ChevronRight className="h-4 w-4 text-coal-dark" />
      </div>
    );
  }, [mode, emiDetails]);

  const renderSplitCODTagTile = useCallback(() => {
    return (
      <div className={`flex items-center space-x-2 `}>
        <div className={`flex flex-col items-end justify-end space-y-1`}>
          <Tag
            className={classNames(
              is_available ? "!bg-coal-dark !text-white" : "!text-gray-dark",
              "px-2 py-0.5 text-xs font-medium ",
            )}>
            {t("pay_x_now_and_y_on_delivery_tag", {
              amount: currencyFormatter(total),
              cod_amount: currencyFormatter(metadata.cod_amount),
            })}
          </Tag>
        </div>
      </div>
    );
  }, [mode]);

  const shouldShowAdditionalCharge = !["SPLIT_COD", "COD"].includes(mode) && additional_charge_value > 0;
  const isSplitCODMode = mode === "SPLIT_COD";
  const isCODMode = ["SPLIT_COD", "COD"].includes(mode);

  const hasCODCharge = () => {
    const codValue = Number(billing?.cod ?? 0);
    const additionalCharge = mode === "SPLIT_COD" ? 0 : Number(additional_charge_value ?? 0);
    return codValue + additionalCharge > 0;
  };

  const excludeCodCharges = merchant?.splitCod?.excludeCodCharges ?? false;

  const shouldShowCodCharge = isCODMode && hasCODCharge() && !(isSplitCODMode && excludeCodCharges);

  const additionalChargeText = useMemo(() => {
    if (mode === "SIMPL" || mode === "SIMPL_PL") {
      return t("includes_charges");
    }
    if (mode === "SPLIT_COD" && shouldShowCodCharge) {
      return t("include_cod_fee", {
        amount: currencyFormatter(billing?.cod ?? 0, false),
      });
    }
    if (additional_charge_type === "FLAT" && shouldShowAdditionalCharge) {
      return t("include_additional_charges", {
        amount: currencyFormatter(additional_charge_value),
      });
    }
    if (additional_charge_type === "FLAT" && shouldShowCodCharge) {
      return t("include_cod_fee", {
        amount: currencyFormatter((billing?.cod || 0) + additional_charge_value, false),
      });
    }
    if (additional_charge_type === "PERCENTAGE" && shouldShowAdditionalCharge) {
      return t("include_additional_charges_in_percentage", {
        amount: additional_charge_value,
      });
    }
    if (additional_charge_type === "PERCENTAGE" && shouldShowCodCharge) {
      return t("include_cod_fee_in_percentage", {
        amount: additional_charge_value,
      });
    }
  }, [additional_charge_type, additional_charge_value]);

  const handleCheckForCOD = () => {
    const isOnlyCOD = paymentMethods.length === 1 && paymentMethods[0].mode === "COD";
    if (isOnlyCOD) {
      placeCODOrder();
      return;
    }
    handleMethodClick();
  };

  return (
    <>
      <div
        className={`group flex flex-row items-center justify-between ${customClass} relative min-h-[72px] w-full cursor-pointer ${
          hasDiscountOrFreebie || is_available ? "hover:payment-method-focus" : ""
        }`}
        onClick={handleMethodClick}>
        <div className={"flex h-full flex-[2] items-center gap-3 p-3"}>
          <div className="self-start">
            {Boolean(customLogoUrl) ? (
              <img
                src={customLogoUrl}
                alt={paymentMethodsConfig[mode as PaymentModeType]?.header}
                className="h-9 w-9 object-contain"
              />
            ) : (
              <MethodLogo color={!is_available ? "#949494" : "#4D4D4D"} />
            )}
          </div>
          <div className="flex w-full flex-1 flex-col items-start justify-start space-y-1">
            <div className={`flex w-full flex-row items-center justify-start space-x-3`}>
              <div className="flex flex-1 flex-col justify-center space-y-1">
                <h3 className={`text-sm font-medium ${is_available ? "text-coal-dark" : "text-gray-dark"}`}>
                  {Boolean(title) ? title : paymentMethodsConfig[mode as PaymentModeType]?.header}
                </h3>
                {!Boolean(hideDescription) && (
                  <p className="method-card-description-clamp overflow-hidden text-ellipsis text-xs font-normal text-gray-dark">
                    {Boolean(description)
                      ? description
                      : paymentMethodsConfig[mode as PaymentModeType]?.subheader}
                  </p>
                )}
              </div>
            </div>
            {isSplitCODMode && renderSplitCODTagTile()}
            {shouldShowCodCharge && (
              <Tag className={`${is_available ? "text-coal-dark" : "text-gray-dark"}`}>
                {additionalChargeText}
              </Tag>
            )}

            {shouldShowAdditionalCharge && (
              <Tag className={`${is_available ? "text-coal-dark" : "text-gray-dark"}`}>
                {additionalChargeText}
              </Tag>
            )}
          </div>
        </div>
        <div className="flex justify-end px-3">
          {hasDiscountOrFreebie && (
            <div
              className={`payment-method-rewards-banner absolute right-0 top-0 flex flex-col items-center justify-center space-y-1 px-3 py-1 text-yay-dark`}>
              <p className={` flex items-center space-x-1 text-xs font-medium`}>{offerText}</p>
            </div>
          )}
          {!is_available && renderUnavailableReason()}
          {is_available && (
            <p className={`flex items-center gap-1 space-x-1 text-sm font-medium text-carbon-dark`}>
              {mode === "SNPM" && Boolean(emiDetails?.length)
                ? renderSnapmintEMI()
                : mode === "SIMPL" && Boolean(emiDetails?.length)
                ? renderSimplPayInThree()
                : total && (
                    <>
                      <span>{currencyFormatter(total)}</span>
                      <ChevronRight className="h-4 w-4 text-coal-dark" />
                    </>
                  )}
            </p>
          )}
        </div>
      </div>

      <InvalidOffersDialog
        isOpen={invalidOfferDialogOpen}
        handleClose={handleInvalidOfferDialogClose}
        unavailabilityReasons={unavailability_reasons}
        mode={modeData}
      />
    </>
  );
};

export default React.memo(MethodCard);
