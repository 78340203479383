
import { SSOMarketingPopup } from "@shopflo/ui";
import { createContext, useCallback, useContext, useMemo, useReducer } from "react";

const Context = createContext<SSOContext>({ state: {}, actions: {} } as SSOContext);

export enum ActionType {
  SET_POPUP,
}

export type Action = { type: ActionType.SET_POPUP; payload: SSOMarketingPopup };

export interface SSOState {
  popup?: SSOMarketingPopup;
}

export interface SSOContext {
  state: SSOState;
  actions: {
    setPopup: (popup: SSOMarketingPopup) => void;
  };
}

function reducer(state: SSOState, action: Action): SSOState {
  switch (action.type) {
    case ActionType.SET_POPUP: {
      return {
        ...state,
        popup: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

const ssoInitialState: SSOState = {
  popup: undefined,
};

export const SSOProvider: React.FC<React.PropsWithChildren<{ initialState?: SSOState }>> = ({
  initialState = ssoInitialState,
  children,
}) => {
  const [reducerState, dispatch] = useReducer(reducer, initialState);

  const setPopup = useCallback((popup: SSOMarketingPopup) => {
    dispatch({
      type: ActionType.SET_POPUP,
      payload: popup,
    });
  }, []);

  
  const actions = useMemo(
    () => ({
      setPopup,
    }),
    [setPopup],
  );

  return (
    <Context.Provider
      value={{
        state: reducerState,
        actions,
      }}>
      {children}
    </Context.Provider>
  );
};

export function useSSOContext() {
  if (!Boolean(Context)) throw new Error("useSSOContext must be used within a SSOProvider");
  const ssoContext = useContext(Context);
  return ssoContext as SSOContext;
}
