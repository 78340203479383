import { useCallback, useState } from "react";
import { putRequest, getRequest, postRequest, deleteRequest } from "lib/core/apiClient";
import { errorToast } from "lib/utils/toasters";
import { useLocale } from "lib/hooks/useLocale";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { mutate } from "swr";
import { analyticsEvents, analyticsTypes, apiURI, eventTypes } from "lib/utils/constants";
import { publishItemUpdate, publishCartUpdate, getItems, getBillingDetails } from "lib/utils/checkout";
import { AddOnLevelType, AddOnType, BillingType, ShippingHandlesType } from "lib/types/checkout";
import useSendAnalyticsEvent from "./useAnalytics";
import { publishPostMessage, redirectUrl, sleep } from "lib/utils/helpers";
import { useCheckoutErrors, checkIfHasServiceabilityError } from "./useCheckoutErrors";

const MIN_ITEM_QUANTITY = 1;

export type ItemEditAction = "increment" | "decrement" | "delete";

export interface UseItemEditParams {
  item_id?: string;
  quantity?: number;
  availableQuantity?: number;
  isCartItem?: boolean;
  variant_id?: string;
  setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
  billing?: BillingType;
}

export interface UseItemEditReturn {
  handleItemEdit: (action: ItemEditAction) => Promise<void>;
  isLoading: boolean;
  showFullScreenLoader: boolean;
  openRemoveItemDialog: boolean;
  setOpenRemoveItemDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleItemDeleteSubmit: (isLastItem: boolean) => Promise<void>;
  handleAddOnEdit: (args: HandleEditAddOnReturn) => Promise<void>;
}

export interface HandleEditAddOnReturn {
  action: "ADD" | "DELETE";
  addOnLevel: AddOnLevelType;
  addOnId: string;
  refetch?: boolean;
  showFullScreenLoader?: boolean;
}

export const useItemEdit = ({
  item_id,
  quantity,
  availableQuantity,
  isCartItem,
  variant_id,
  billing,
  setIsUpdating,
}: UseItemEditParams = {}) => {
  const { t } = useLocale();
  const {
    state: {
      checkoutId,
      checkoutView,
      checkoutUIMetadata,
      initialCheckoutStep,
      redirectUrl: backUrl,
      checkoutItems,
      checkoutValidations,
    },
    actions: {
      updateCheckoutBasedOnCheckoutResponse,
      setShippingHandles,
      setCheckoutModal,
      setCheckoutItems,
      setCoupons,
      setCheckout,
      setBilling,
      setCheckoutView,
      setCheckoutValidations,
    },
  } = useCheckoutContext();
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const [isLoading, setIsLoading] = useState(false);
  const [showFullScreenLoader, setShowFullScreenLoader] = useState(false);
  const [openRemoveItemDialog, setOpenRemoveItemDialog] = useState<boolean>(false);
  const { isNotServicable, errorMessage } = useCheckoutErrors();

  const handleItemDeleteSubmit = async (isLastItem: boolean) => {
    //handling redirect to store on FE since BE can't do it as of now
    if (isLastItem) {
      publishCartUpdate([]);
      await sleep(50);
      if (isCartItem) {
        publishPostMessage(eventTypes.CLEAR_CART_AND_CLOSE, {});
      } else {
        redirectUrl(`${backUrl}/?floRedirectStatus=oos`);
      }
      setOpenRemoveItemDialog(false);
      return;
    }
    try {
      await handleItemEdit("delete");
    } catch (e) {
      console.error(e);
    } finally {
      setOpenRemoveItemDialog(false);
    }
  };

  const handleMiscUpdates = (
    response: any,
    {
      addType,
    }: {
      addType: "ITEM" | "ADDON";
    },
  ) => {
    updateCheckoutBasedOnCheckoutResponse(response);
    const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
    const shippingHandles = response?.metadata?.available_shipping_handles ?? [];
    setShippingHandles(shippingHandles as ShippingHandlesType);

    // Consolidated upsell mutation logic
    const shouldMutateUpsell =
      addType === "ITEM" &&
      Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled) &&
      (checkoutView === "PAYMENTS" || isCartItem);

    if (shouldMutateUpsell) {
      const upsellEndpoint = isAuthenticated
        ? `/checkout/v1/checkout/${checkoutId}/upsell`
        : `/v1/checkout/${checkoutId}/upsell`;
      mutate(upsellEndpoint);
    }

    if (checkoutView === "PAYMENTS") {
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
    }

    const hasServiceabilityError = checkIfHasServiceabilityError(response.metadata.error_responses);
    if (
      shippingHandles.length > 0 &&
      Boolean(showShippingHandles) &&
      Boolean(checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS") &&
      !Boolean(hasServiceabilityError)
    ) {
      setCheckoutModal("SHIPPING_HANDLES");
      return;
    }
    mutate(`UPI_INTENT`);
  };

  const handleItemEdit = useCallback(
    async (action: "increment" | "decrement" | "delete") => {
      if (!quantity || !availableQuantity) throw new Error("Quantity or available quantity is not defined");
      let newQuantity = quantity;
      switch (action) {
        case "increment": {
          if (
            Boolean(quantity + 1 > availableQuantity) ||
            (merchant?.maxQuantityPerItem &&
              Boolean(merchant?.maxQuantityPerItem) &&
              quantity + 1 > merchant?.maxQuantityPerItem)
          )
            return;
          newQuantity++;
          break;
        }
        case "decrement": {
          if (quantity === MIN_ITEM_QUANTITY) return;
          newQuantity--;
          break;
        }
        case "delete": {
          newQuantity = 0;
          setShowFullScreenLoader(true);
          break;
        }
      }
      try {
        setIsLoading(true);
        setIsUpdating?.(true);
        const payload = {
          items: [
            {
              id: item_id,
              quantity: newQuantity,
            },
          ],
        };
        let response: any;
        if (isAuthenticated) {
          response = await putRequest(`/checkout/v1/checkout/${checkoutId}/items`, payload);
          const discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
          mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
          setCoupons(discountResponse);
        } else {
          response = await putRequest(`/v1/checkout/${checkoutId}/items`, payload, "CHECKOUT_PUBLIC");
          const discountResponse = await getRequest(
            `/v1/checkout/${checkoutId}/discounts`,
            "CHECKOUT_PUBLIC",
          );
          setCoupons(discountResponse);
        }

        if (Boolean(response?.items)) {
          const cartItems = getItems(response?.items);
          publishItemUpdate(item_id, action === "delete" ? checkoutItems : cartItems, action, isCartItem);
          setCheckoutItems(cartItems);
          if (!cartItems || cartItems?.length === 0) {
            publishCartUpdate([]);
            handleItemDeleteSubmit(true);
            return;
          }
        }

        if (isCartItem && action === "increment") {
          sendAnalyticsEvent({
            eventName: analyticsEvents.FLO_ADDED_TO_CART_UI,
            eventType: "click",
            eventFor: [analyticsTypes.FACEBOOK_PIXEL, analyticsTypes.SF_ANALYTICS],
            metaData: {
              checkoutItems: getItems(response?.items),
            },
          });
        }

        setIsLoading(false);
        setIsUpdating?.(false);
        setShowFullScreenLoader(false);

        handleMiscUpdates(response, {
          addType: "ITEM",
        });
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setIsUpdating?.(false);
        setShowFullScreenLoader(false);
      }
    },
    [
      availableQuantity,
      billing?.shipping,
      checkoutId,
      checkoutView,
      isAuthenticated,
      item_id,
      quantity,
      checkoutItems,
      variant_id,
    ],
  );

  const handleAddOnEdit = async (args: HandleEditAddOnReturn) => {
    try {
      const { action, addOnLevel, addOnId, showFullScreenLoader } = args;
      setIsLoading(true);
      setIsUpdating?.(true);

      const payload = {
        addon_id: addOnId,
        item_id: item_id ?? null,
        addon_level: addOnLevel,
      };
      let response;

      switch (action) {
        case "ADD": {
          setShowFullScreenLoader(showFullScreenLoader ?? false);
          if (isAuthenticated) {
            response = await postRequest(`/checkout/${checkoutId}/addon`, payload, "KRATOS_PRIVATE");
          } else {
            response = await postRequest(`/checkout/${checkoutId}/addon`, payload, "KRATOS");
          }
          break;
        }
        case "DELETE": {
          setShowFullScreenLoader(showFullScreenLoader ?? false);
          if (isAuthenticated) {
            response = await deleteRequest(`/checkout/${checkoutId}/addon`, payload, "KRATOS_PRIVATE");
          } else {
            response = await deleteRequest(`/checkout/${checkoutId}/addon`, payload, "KRATOS");
          }
          break;
        }
      }

      if (Boolean(response?.items)) {
        if (addOnLevel === "ITEM") {
          const cartItems = getItems(response?.items);
          setCheckoutItems(cartItems);
        } else if (addOnLevel === "CART") {
          setCheckout({
            appliedCartAddOns: response?.cart_addons?.filter((item: AddOnType) => !item?.removed) ?? [],
            availableAddOns: response?.available_add_ons,
          });
        }
        const parsedBilling = getBillingDetails(response?.pricing);
        setBilling(parsedBilling);
      }

      if (action === "ADD") {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_ADDON_ADDED,
          eventType: "click",
          eventFor: [analyticsTypes.FACEBOOK_PIXEL, analyticsTypes.SF_ANALYTICS],
          metaData: {
            checkoutItems: getItems(response?.items),
            addOnData: {
              addOnType: addOnLevel,
              addOnId: addOnId,
              itemId: item_id,
            },
          },
        });
      }

      setIsLoading(false);
      setIsUpdating?.(false);
      setShowFullScreenLoader(false);

      handleMiscUpdates(response, {
        addType: "ADDON",
      });
    } catch (e) {
      errorToast(t("something_went_wrong"));
      setCheckoutItems([...checkoutItems]);
      setIsLoading(false);
      setIsUpdating?.(false);
      setShowFullScreenLoader(false);
    }
  };

  return {
    handleItemEdit,
    isLoading,
    showFullScreenLoader,
    openRemoveItemDialog,
    setOpenRemoveItemDialog,
    handleItemDeleteSubmit,
    handleAddOnEdit,
  };
};
