import { useLocale } from "lib/hooks/useLocale";
import { AddOnMetaType } from "lib/types/checkout";
import { classNames, truncateString } from "lib/utils/helpers";
import defaultPackageImage from "assests/images/package.png";
import { currencyFormatter } from "lib/utils/formatters";

export const AddOnCard = ({
  addOnData,
  isApplied,
  handleAction,
}: {
  addOnData: AddOnMetaType;
  isApplied: boolean;
  handleAction?: (value: boolean) => void;
}) => {
  const { t } = useLocale();

  return (
    <div
      className={classNames(
        "bg-white flex justify-between items-center gap-2 p-[10px] rounded-xl border border-gray-light",
      )}>
      <div className="flex items-center gap-2">
        {addOnData?.show_image && (
          <img
            src={addOnData?.image ?? ""}
            alt={"add-on-image"}
            className="h-10 w-10 rounded-md"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = defaultPackageImage;
            }}
          />
        )}
        <p className="text-sm font-normal text-coal-dark flex space-x-1 items-center">
          {isApplied ? (
            <span>{`${truncateString(addOnData?.product_name, 40)} added`}</span>
          ) : (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  addOnData?.description
                    ?.replaceAll("{{product_title}}", truncateString(addOnData?.product_name, 40))
                    ?.replaceAll("{{price}}", currencyFormatter(addOnData?.price?.toString())) ?? "",
              }}></span>
          )}
        </p>
      </div>
      {isApplied ? (
        <div
          onClick={() => handleAction?.(false)}
          className="flex justify-start py-3 pr-3 items-start h-full w-[4.5rem]">
          <span className="text-sm font-semibold text-coal-dark cursor-pointer rounded-lg py-2">
            {t("remove")}
          </span>
        </div>
      ) : (
        <div onClick={() => handleAction?.(true)} className="flexjustify-start p-3 items-start w-[4.5rem]">
          <span className="text-xs font-medium text-yay-dark cursor-pointer rounded-lg px-3 py-2 border border-yay-dark bg-yay-lighter">
            {t("add")}
          </span>
        </div>
      )}
    </div>
  );
};
