import { GenericDialog } from "components/common/dialogs/GenericDialog";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { deleteRequest, getRequest, postRequest, staticOptions } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import { getGyftrBalance } from "lib/utils/rewards";
import { ChevronRight, Gift } from "react-feather";
import useSWR, { mutate } from "swr";
import RedeemVoucherDialog from "./methods/GyftrDialogs/GyftrRedeemDialog";
import AddCouponDialog from "./methods/GyftrDialogs/AddGyftrVoucherDialog";
import { apiURI } from "lib/utils/constants";
import GyftrIcon from "assests/images/GyftrLogo.png";
import { AnimateLoading } from "components/animation/AnimateLoading";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import SwitchCouponDialog from "components/checkout/dialogs/SwitchCouponDialog";
import { errorToast } from "lib/utils/toasters";

const Gyftr = () => {
  const {
    state: { checkoutModal, checkoutId, billing },
    actions: { setCheckoutModal, updateCheckoutBasedOnCheckoutResponse },
  } = useCheckoutContext();

  const {
    state: { paymentMethods },
    actions: { handleNonPaymentOrderPlacement },
  } = usePaymentContext();

  const {
    data: gyftrResponse,
    isValidating,
    error,
  } = useSWR(
    Boolean(checkoutId)
      ? [`/checkout/v2/checkout/${checkoutId}/gc/voucher?voucher_payment_mode=GYFTR`, "CHECKOUT"]
      : null,
    getRequest,
    staticOptions,
  );
  const { t } = useLocale();

  const gyftrMode = paymentMethods?.find((method) => method.mode === "GYFTR");
  const gyftrBalance = gyftrResponse ? getGyftrBalance(gyftrResponse) : 0;

  const handleRemoveGiftCard = async () => {
    try {
      const response: any = await deleteRequest(`/checkout/v2/checkout/${checkoutId}/gc/voucher`, {
        voucher_payment_mode: "GYFTR",
        redemption_amount: billing.gift_card,
      });
      updateCheckoutBasedOnCheckoutResponse(response);
      mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
      mutate(`/checkout/v1/checkout/${checkoutId}/discounts`);
      mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
      mutate(`/checkout/v2/checkout/${checkoutId}/gc/voucher?voucher_payment_mode=GYFTR`);
    } catch (error) {}
  };

  const invalidDiscountCodes = gyftrMode?.unapplicability_reasons?.map((discount: any) => {
    if (discount?.type === "INVALID_DISCOUNTS") {
      return discount?.values;
    }
    return "";
  });

  const handleRedeemVoucher = () => {
    if (!gyftrMode.is_available && gyftrMode?.unapplicability_reasons.length) {
      setCheckoutModal("GYFTR_SWITCH_COUPON_DIALOG");
      return;
    }
    setCheckoutModal("GYFTR_REDEEM_VOUCHER_DIALOG");
  };

  if (error) return <></>;
  return (
    <>
      <AnimateLoading loading={isValidating}>
        <AnimateLoading.Content>
          {billing.gift_card <= 0 && gyftrBalance <= 0 ? (
            <div className="flex items-center justify-between p-4 bg-white rounded-xl shadow-sm mt-2">
              <div className="flex items-center justify gap-3">
                <img src={GyftrIcon} alt="Gyftr" className="w-6 h-6" />
                <div className="flex flex-col gap-1">
                  <span className="text-coal-dark text-sm ">{t("have_a_gift_voucher_or_epay")}</span>
                  <span className="text-gray-dark text-xs ">{t("powered_by_gyftr")}</span>
                </div>
              </div>
              <div
                onClick={() => setCheckoutModal("GYFTR_ADD_COUPON_DIALOG")}
                className="flex justify-end items-end h-full w-[4.5rem] pr-1">
                <span className="text-xs font-medium text-yay-dark cursor-pointer rounded-lg px-3 py-2 border border-yay-dark bg-yay-lighter">
                  {t("add")}
                </span>
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-xl shadow-sm p-4 flex flex-col mt-2">
              {billing.gift_card > 0 ? (
                <>
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center gap-2">
                      <Gift size={16} color="#2C874A" />
                      <div className="text-yay-dark font-semibold text-sm">
                        {t("rupees_applied", { amount: Number(billing.gift_card).toFixed(2) })}
                      </div>
                    </div>
                    <button
                      className="text-coal-dark text-sm font-semibold"
                      onClick={() => handleRemoveGiftCard()}>
                      {t("remove")}
                    </button>
                  </div>
                  <div className="text-sm text-coal-light mb-3">
                    {t("remaining_balance", {
                      amount: Number((gyftrBalance - billing.gift_card).toFixed(2)),
                    })}
                  </div>
                </>
              ) : (
                <div className="flex items-center justify-between pb-3 gap-3">
                  <div className="flex items-center gap-2">
                    <img src={GyftrIcon} alt="Gyftr" className="w-6 h-6" loading="lazy"/>
                    <span className="text-coal-dark text-sm font-medium">
                      {t("gv_or_epay_balance", { amount: Number(gyftrBalance).toFixed(2) })}
                    </span>
                  </div>
                  <div
                    onClick={() => handleRedeemVoucher()}
                    className="flex justify-start items-start h-full w-[4.5rem]">
                    <span className="text-xs font-medium text-yay-dark cursor-pointer rounded-lg px-3 py-2 border border-yay-dark bg-yay-lighter">
                      {t("redeem")}
                    </span>
                  </div>
                </div>
              )}

              <div className="border-t border-dashed pt-3">
                <div
                  onClick={() => setCheckoutModal("GYFTR_ADD_COUPON_DIALOG")}
                  className="text-sm text-coal-dark flex flex-col gap-1 cursor-pointer">
                  <span className="text-sm">{t("have_more_gift_vouchers")}</span>
                  <div className="font-medium text-xs flex items-center gap-1">
                    {t("add")} <ChevronRight size={14} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </AnimateLoading.Content>
      </AnimateLoading>

      <GenericDialog
        isOpen={checkoutModal === "GYFTR_ADD_COUPON_DIALOG"}
        translateAxis="y"
        dialogOverlay={true}
        modalType="GYFTR_ADD_COUPON_DIALOG"
        customClass="overflow-scroll md:!top-auto md:absolute">
        <AddCouponDialog />
      </GenericDialog>
      <GenericDialog
        isOpen={checkoutModal === "GYFTR_REDEEM_VOUCHER_DIALOG"}
        translateAxis="y"
        dialogOverlay={true}
        modalType="GYFTR_REDEEM_VOUCHER_DIALOG"
        customClass="overflow-scroll md:!top-auto md:absolute">
        <RedeemVoucherDialog gyftrBalance={gyftrBalance} />
      </GenericDialog>
      <GenericDialog
        isOpen={checkoutModal === "GYFTR_SWITCH_COUPON_DIALOG"}
        translateAxis="y"
        dialogOverlay={true}
        modalType="GYFTR_SWITCH_COUPON_DIALOG"
        customClass="overflow-scroll md:!top-auto md:absolute">
        <SwitchCouponDialog
          closePopup={() => setCheckoutModal("NONE")}
          applyCoupon={async () => {
            try {
              setCheckoutModal("LOADER");
              const promises = invalidDiscountCodes[0]?.map((code: string) =>
                deleteRequest(`/checkout/v1/checkout/${checkoutId}/discounts`, { discount_code: code }),
              );
              const responses = await Promise.allSettled(promises);
              let replacedSuccessfully = true;
              let lastResponse;

              responses.forEach((response) => {
                if (response?.status !== "fulfilled") {
                  replacedSuccessfully = false;
                } else {
                  lastResponse = response.value;
                }
              });

              if (!replacedSuccessfully) {
                errorToast(t("failed_to_replace_coupons"));
                return;
              }

              updateCheckoutBasedOnCheckoutResponse(lastResponse);
              mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
              mutate(`/checkout/v1/checkout/${checkoutId}/discounts`);
              mutate([`/checkout/${checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
            } catch (error) {
              console.error(error);
              errorToast(t("failed_to_replace_coupons"));
            } finally {
              setCheckoutModal("GYFTR_REDEEM_VOUCHER_DIALOG");
            }
          }}
          appliedDiscountCode={"GYFTR"}
          invalidDiscountCodes={invalidDiscountCodes?.[0] ?? ""}
          invalidReason={"REWARDS_NOT_COMBINABLE"}
        />
      </GenericDialog>
    </>
  );
};

export default Gyftr;
