import { useUserContext } from "lib/contexts/UserProvider";
import { AddressCardFields } from "lib/types/address";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { checkMandatoryFields } from "lib/utils/helpers";
import { useMerchantContext } from "lib/contexts/MerchantProvider";

export function useAddressValidation() {
  const {
    state: { checkoutId },
  } = useCheckoutContext();

  const {
    state: { addressConfig },
  } = useMerchantContext();

  const {
    state: { user },
  } = useUserContext();

  const verifyDefaultAddress = () => {
    const selectedAddressId = user?.default_shipping_address?.id ? user?.default_shipping_address?.id : "";
    const selectedAddress = user?.addresses?.find(
      (address: AddressCardFields) => address?.id === selectedAddressId,
    );

    if (!selectedAddress) {
      return false;
    }
    const addressValidity = checkMandatoryFields(selectedAddress, addressConfig, checkoutId);
    if (addressValidity.available) {
      return true;
    }
    return false;
  };

  return { verifyDefaultAddress };
}
