import { useEffect, useRef, useState } from "react";

export const useToggleTexts = (texts: string[]) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const currentText = texts[currentIndex];

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCurrentIndex((prev) => {
        const nextIndex = (prev + 1) % texts.length;
        return nextIndex;
      });
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return {
    currentText,
  };
};
