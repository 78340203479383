import React, { Suspense, useEffect } from "react";
import useSWR from "swr";
import { fetcher, staticOptions } from "lib/core/apiClient";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { useLocation } from "react-router-dom";
import { getCODShippingHandles, getItems } from "lib/utils/checkout";
import PaymentMethods from "components/payments/methods";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import PaymentMethodDialog from "components/payments/dialogs/PaymentMethodDialog";
import { useLoader } from "lib/contexts/LoaderContext";
import PaymentProcessingDialog from "components/payments/dialogs/PaymentProcessing";
import MarketingConsent from "components/common/MarketingConsent";
import { findModeSpecificCartAlteration } from "lib/utils/payments";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useLocale } from "lib/hooks/useLocale";
import { PaymentModeAutomationPayloadForEvents } from "lib/types/payments";
import { PaymentSkeleton } from "components/common/skeleton/PaymentSkeleton";
import { AnimateLoading } from "components/animation/AnimateLoading";

interface PaymentsProps {}

const Payments: React.FC<PaymentsProps> = React.memo(() => {
  const { t } = useLocale();
  const {
    state: { checkoutId, billing, shippingHandles, checkoutItems, appliedCoupons },
    actions: { setCheckoutItems, setBilling, setHasCODMode },
  } = useCheckoutContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const {
    state: { paymentMethods },
    actions: { setPaymentMethods, handlePaymentMethodSelected, setPaymentMethodDialog },
  } = usePaymentContext();
  const { setIsLoading } = useLoader();

  const { hash: hashParam } = useLocation();

  const { data: paymentsResponse, isValidating } = useSWR(
    Boolean(checkoutId) ? `/checkout/v2/checkout/${checkoutId}/payments` : null,
    fetcher,
    staticOptions,
  );

  useEffect(() => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_PAYMENT_LOADED,
      eventType: "load",
    });
  }, []);

  useEffect(() => {
    if (isValidating || !Boolean(paymentsResponse?.modes?.length)) return;
    const automationPayload: PaymentModeAutomationPayloadForEvents[] = [];
    paymentsResponse?.modes?.forEach((method: any) => {
      method?.rule_group_mapping?.rule_groups?.AUTOMATION?.forEach((automationId: string) => {
        if (!automationPayload.find((e) => e.id === automationId)) {
          automationPayload.push({ id: automationId, blockedModes: [], allowedModes: [] });
        }
      });
    });
    paymentsResponse?.modes?.forEach((method: any) => {
      if (Boolean(method?.metadata?.rule_groups?.length)) {
        method?.metadata?.rule_groups?.forEach((ruleGroup: any) => {
          if (ruleGroup?.source === "AUTOMATION") {
            ruleGroup?.failed_rule_groups?.forEach((rule: string) => {
              automationPayload?.find((e) => e.id === rule)?.blockedModes?.push(method?.mode);
            });
            ruleGroup?.successful_rule_groups?.forEach((rule: string) => {
              automationPayload?.find((e) => e.id === rule)?.allowedModes?.push(method?.mode);
            });
          }
        });
      }
    });

    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_PAYMENT_REFRESHED,
      eventFor: [analyticsTypes.SF_ANALYTICS],
      eventType: "load",
      metaData: {
        automationData: automationPayload,
      },
    });
  }, [paymentsResponse, isValidating]);

  useEffect(() => {
    if (!Boolean(paymentsResponse) && isValidating) return;

    // Check if COD mode is present
    const consistsCODMode = paymentsResponse?.modes?.some((e: any) => e.mode === "COD");
    setHasCODMode(consistsCODMode);

    //Adding the transient prepaid freebie to the cart items. This conditionally adds if the item(s) is not added to the cart already
    const containsPrepaidFreebie = findModeSpecificCartAlteration(paymentsResponse?.modes);
    if (Boolean(containsPrepaidFreebie)) {
      setCheckoutItems(getItems(paymentsResponse?.items));
    }

    const prepaidDiscount = paymentsResponse?.modes[0]?.amount;
    setBilling({ ...billing, prepaid_total: prepaidDiscount });
    setPaymentMethods(paymentsResponse?.modes);

    //If Cashfree mode is present, redirect to cashfree payment page
    const hasCashfree = paymentsResponse?.modes?.some((e: any) => e.mode === "CASHFREE");
    const hasCODMode = paymentsResponse?.modes?.some((e: any) => e.mode === "COD");
    if (hasCashfree && !hasCODMode) {
      handlePaymentMethodSelected("CASHFREE");
      return;
    }

    setIsLoading(false);
  }, [paymentsResponse, isValidating, appliedCoupons]);

  //TODO: Handle this
  useEffect(() => {
    if (
      Boolean(hashParam?.length) &&
      hashParam === "#COD" &&
      !Boolean(getCODShippingHandles(shippingHandles)?.length > 1)
    ) {
      setPaymentMethodDialog("COD");
    }
  }, [shippingHandles, hashParam]);

  if (!isValidating && !Boolean(paymentMethods)) return <></>;

  return (
    <>
      <AnimateLoading loading={isValidating}>
        <AnimateLoading.Skeleton>
          <PaymentSkeleton />
        </AnimateLoading.Skeleton>
        <AnimateLoading.Content>
          <Suspense fallback={<PaymentSkeleton />}>
            <PaymentMethods />
          </Suspense>
          <PaymentMethodDialog />
          <PaymentProcessingDialog />
          <MarketingConsent />
        </AnimateLoading.Content>
      </AnimateLoading>
    </>
  );
});
export default Payments;
