import { useState } from "react";
import { getRequest } from "lib/core/apiClient";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { getETDText } from "lib/utils/merchant";
import { hexToRgba } from "lib/utils/helpers";

export const useAddressETD = (addressId: string) => {
  const [etdLoading, setEtdLoading] = useState<boolean>(false);
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: { etdText, uid },
    actions: { setEtdText },
  } = useCheckoutContext();

  const primaryColor = merchant?.colorPallet?.primaryColor?.substring(0, 7);
  const ETD_COLOR = hexToRgba(primaryColor ?? "#000000", 0.2);
  const ETD_SHIMMER_COLOR = hexToRgba(primaryColor ?? "#000000", 0.23);
  const isETDEnabled = merchant?.addressConfig?.deliveryConfig?.isETDEnabled;

  const fetchEstimatedTimeDelivery = async () => {
    if (etdText[addressId]) return;
    setEtdLoading(true);
    try {
      const response = await getRequest(`/checkout/${uid}/address/${addressId}/etd`, "KRATOS_PRIVATE");
      setEtdText({ addressId, text: getETDText(response) });
    } catch (error: any) {
      console.error(error);
    } finally {
      setEtdLoading(false);
    }
  };

  return {
    etdLoading,
    etdText: etdText[addressId],
    ETD_COLOR,
    ETD_SHIMMER_COLOR,
    isETDEnabled,
    fetchEstimatedTimeDelivery,
    primaryColor: merchant?.colorPallet?.primaryColor,
  };
};
