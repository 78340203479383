import React, { ReactNode, useRef, useState, useEffect, Children, JSXElementConstructor } from "react";
import { motion, AnimatePresence, HTMLMotionProps, usePresenceData } from "motion/react";

interface AnimateLoadingProps {
  children: ReactNode;
  loading: boolean;
  size?: "md" | "sm";
}

export const AnimateLoading: React.FC<AnimateLoadingProps> & {
  Skeleton: typeof Skeleton;
  Content: typeof Content;
} = ({ children, loading, size = "md" }) => {
  const [skeletonHeight, setSkeletonHeight] = useState<number | "auto">("auto");
  const skeletonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (skeletonRef.current) {
      setSkeletonHeight(skeletonRef.current.offsetHeight);
    }
  }, [loading]);

  // Extract AnimateLoading.Content and AnimateLoading.Skeleton from children
  const content = Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Content,
  );

  let skeleton = Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Skeleton,
  );

  // Use default skeleton if none is provided
  if (!skeleton) {
    skeleton = (
      <Skeleton>
        <div className="border-base px-3 py-4 bg-white space-y-2">
          {size === "md" && <div className="skeleton-loader h-4 rounded-md w-2/5"></div>}
          <div className="skeleton-loader h-4 rounded-md w-full"></div>
        </div>
      </Skeleton>
    );
  }

  return (
    <AnimatePresence mode="wait" custom={skeletonHeight}>
      {loading ? (
        React.cloneElement(skeleton as React.ReactElement, {
          ref: skeletonRef,
        })
      ) : (
        <>{content}</>
      )}
    </AnimatePresence>
  );
};

// Skeleton Component
const Skeleton = React.forwardRef<
  HTMLDivElement,
  { children: ReactNode; motionProps?: HTMLMotionProps<"div"> }
>(({ children, motionProps = {} }, ref) => {
  return (
    <motion.div
      ref={ref}
      key="loading-skeleton"
      initial={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: "auto" }}
      transition={{ duration: 0.4 }}
      {...motionProps}>
      {children}
    </motion.div>
  );
});
Skeleton.displayName = "AnimateLoadingSkeleton";

// Content Component
const Content: React.FC<{
  children: ReactNode;
  motionProps?: HTMLMotionProps<"div">;
}> = ({ children, motionProps = {} }) => {
  const skeletonHeight = usePresenceData();
  return (
    <motion.div
      key="loaded-content"
      initial={{ opacity: 0, height: skeletonHeight }}
      animate={{ opacity: 1, height: "auto" }}
      transition={{ duration: 0.4 }}
      {...motionProps}>
      {children}
    </motion.div>
  );
};
Content.displayName = "AnimateLoadingContent";

AnimateLoading.Skeleton = Skeleton;
AnimateLoading.Content = Content;

export default AnimateLoading;
