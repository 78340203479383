import React, { useCallback } from "react";
import RipplePulse from "components/common/loaders/RipplePulse";
import GenericButton from "components/common/buttons/GenericButton";
import { useLocale } from "lib/hooks/useLocale";
import NotPlaced from "components/payments/dialogs/NotPlaced";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { GenericDialog } from "components/common/dialogs/GenericDialog";

const PaymentProcessing: React.FC<{}> = ({}) => {
  const { t } = useLocale();
  const {
    state: { isProcessingDialogVisible, paymentStatus, isCancellable },
    actions: { hidePaymentProcessingDialog, handleCancelPayment },
  } = usePaymentContext();

  const processingMsg = useCallback(() => {
    if (paymentStatus === "PROCESSING" && !isCancellable) return t("placing_order");
    if (paymentStatus === "PROCESSING") return t("payment_processing");
    if (paymentStatus === "PLACING_ORDER") return t("placing_order");
    if (paymentStatus === "PROCESSING_UPI") return t("payment_processing_upi");
    return t("payment_processing");
  }, [paymentStatus]);

  return (
    <GenericDialog
      isOpen={isProcessingDialogVisible}
      setIsOpen={(value: boolean) => hidePaymentProcessingDialog()}
      translateAxis="y"
      dialogOverlay={true}
      customClass={`bottom-0 overflow-scroll ${
        paymentStatus === "ORDER_FAILED"
          ? "h-screen md:h-[85vh]"
          : "h-fit !top-auto md:absolute !rounded-t-2xl"
      }`}
      modalType="PROCESSING">
      <>
        <div
          className={`flex flex-col items-center justify-center space-y-6 px-4 pt-6 pb-12 ${
            paymentStatus === "ORDER_FAILED" && "h-full"
          }`}>
          {paymentStatus === "ORDER_FAILED" ? (
            <NotPlaced />
          ) : (
            <>
              <p className="text-center font-light">{processingMsg()}</p>
              <div className="mb-6">
                <RipplePulse />
              </div>
              {isCancellable && handleCancelPayment && (
                <GenericButton
                  buttonText={t("cancel_payment")}
                  customClass="opacity-70 underline underline-offset-4 decoration-primary-dark decoration-1 rounded-lg text-xs tracking-wider space-x-2"
                  onClick={() => {
                    hidePaymentProcessingDialog();
                    handleCancelPayment();
                  }}
                />
              )}
            </>
          )}
        </div>
      </>
    </GenericDialog>
  );
};

export default React.memo(PaymentProcessing);
