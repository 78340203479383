import React from "react";

interface RipplePulseProps {}

const RipplePulse: React.FC<RipplePulseProps> = ({}) => {
  return (
    <div className="ripple-loader">
      <span></span>
      <span></span>
    </div>
  );
};

export default RipplePulse;
