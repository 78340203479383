interface DividerWithLabelProps {
  label: string;
  className?: string;
}

const DividerWithLabel = ({ label, className = "" }: DividerWithLabelProps) => {
  return (
    <div className="relative w-full">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-light" />
      </div>
      <div className="relative flex justify-start">
        <span className={` ${className} ml-4 bg-gray-lightest px-2 text-gray-dark`}>{label}</span>
      </div>
    </div>
  );
};

export default DividerWithLabel;
