import Cookies from "js-cookie";
import { MerchantType, CtaConfig, CouponDisplayConfig, TrustBadgeDisplayConfig } from "lib/types/merchant";
import { constants } from "lib/utils/constants";
import { t } from "i18next";

export const parseMerchantData = (data: any) => {
  const merchantData: MerchantType = {
    merchantId: data?.uid,
    displayName: data?.account_attributes?.attributes?.shop_data?.name ?? "Shopflo Technologies",
    colorPallet: {
      primaryColor: data?.account_attributes?.attributes?.ui?.color_pallet?.primary_color,
      secondaryColor: data?.account_attributes?.attributes?.ui?.color_pallet?.secondary_color,
    },
    primaryCtaConfig: getPrimaryCtaConfig(data),
    couponDisplayConfig: getCouponDisplayConfig(data),
    trustBadgeConfig: getTrustBadgeDisplayConfig(data),
    couponStackLength: data?.account_attributes?.attributes?.max_discount_stack_length ?? 2,
    logoUrl: data?.account_attributes?.attributes?.ui?.logo_url?.length
      ? data?.account_attributes?.attributes?.ui?.logo_url
      : data?.account_attributes?.attributes?.logo_url,
    isDiscountEnabled: data?.account_attributes?.attributes?.ui?.is_discounts_enabled ?? true,
    prepaidDiscounts: {
      type: data?.account_attributes?.attributes?.prepaid_discounts?.type,
      value: data?.account_attributes?.attributes?.prepaid_discounts?.value,
      limit: data?.account_attributes?.attributes?.prepaid_discounts?.limit,
      enabled: data?.account_attributes?.attributes?.prepaid_discounts?.enabled,
    },
    addressConfig: {
      isGSTInputEnabled: data?.account_attributes?.attributes?.address_config?.gst_input_enabled,
      isBillingAddressEnabled:
        data?.account_attributes?.attributes?.address_config?.billing_address_enabled ?? false,
      isInternationalAddressEnabled:
        data?.account_attributes?.attributes?.address_config?.international_address_enabled,
      deliveryConfig: {
        isEnabled: data?.account_attributes?.attributes?.address_config?.delivery_config?.enabled,
        deliveryText: data?.account_attributes?.attributes?.address_config?.delivery_config?.delivery_text,
        isETDEnabled:
          data?.account_attributes?.attributes?.address_config?.delivery_config?.is_etd_enabled ?? false,
      },
      codConfig: {
        verifyCod: data?.account_attributes?.attributes?.address_config?.cod_config?.verify_cod,
      },
      disable_unavailable_pincode:
        data?.account_attributes?.attributes?.address_config?.disable_unavailable_pincode ?? false,
      enabledCountries: data?.account_attributes?.attributes?.address_config?.enabled_countries ?? [],
    },
    discountSettings: {
      showMaxDiscount: data?.account_attributes?.attributes?.discounts?.max_discount_enabled ?? true,
    },
    brandUrl: data?.account_attributes?.attributes?.website_url,
    ...(Boolean(data?.account_attributes?.attributes?.ui?.custom_auth_banner) && {
      customBanners: {
        auth: data?.account_attributes?.attributes?.ui?.custom_auth_banner,
      },
    }),
    isTwoStepCheckout: data?.account_attributes?.attributes?.metadata?.is_two_step_checkout ?? false,
    showAllItemProperties: data?.account_attributes?.attributes?.metadata?.show_all_item_properties ?? false,
    showOriginalPrice: data?.account_attributes?.attributes?.metadata?.show_original_price ?? false,
    showItemPropertyKey: data?.account_attributes?.attributes?.metadata?.show_item_property_key ?? false,
    ...(Boolean(data?.account_attributes?.attributes?.ui?.item_properties) && {
      itemProperties: data?.account_attributes?.attributes?.ui?.item_properties,
    }),
    analytics: data?.analytics ?? {},
    showCheckoutExitConfirmation: data?.account_attributes?.attributes?.ui?.show_exit_confirmation ?? true,
    exitCheckoutAction: data?.account_attributes?.attributes?.ui?.exit_checkout_action ?? "none",
    marketingContextText: data?.account_attributes?.attributes?.marketing_consent_text ?? "",
    salesCommsContext: data?.account_attributes?.attributes?.sales_comms_context ?? 1,
    codVerification: data?.account_attributes?.attributes?.cod_verification ?? "POST_COD",
    domain: data?.account_attributes?.attributes?.shop_data?.domain,
    celebrationPopupConfig: {
      celebrationsTotalSavingsBtnText:
        data?.account_attributes?.attributes?.ui?.celebration_popup_config?.total_savings_btn_text,
      freebieAppliedCelebrationsText:
        data?.account_attributes?.attributes?.ui?.celebration_popup_config?.freebie_applied_celebrations_text,
      freeGiftsText: data?.account_attributes?.attributes?.ui?.celebration_popup_config?.free_gifts_text,
    },
    forceCustomThankYouPage: data?.account_attributes?.attributes?.force_custom_thank_you_page ?? false,
    upiTileWithIcons: data?.account_attributes?.attributes?.upi_tile_with_icons ?? false,
    hideCODpopup: data?.account_attributes?.attributes?.hide_cod_popup ?? false,
    showShippingOnPayments: data?.account_attributes?.attributes?.show_shipping_on_popup ?? false,
    maxQuantityPerItem: data?.account_attributes?.attributes?.max_quantity_per_item,
    isMarketingPopupEnabled: data?.account_attributes?.attributes?.new_sso_popup_enabled ?? false,
    immutablePriceBand: {
      minPrice: data?.account_attributes?.attributes?.immutable_price_band?.min_price,
      maxPrice: data?.account_attributes?.attributes?.immutable_price_band?.max_price,
    },
    splitCod: {
      excludeCodCharges: data?.account_attributes?.attributes?.split_cod?.exclude_cod_charges ?? false
    },
    hideShippingMaxDays: data?.account_attributes?.attributes?.hide_shipping_max_days ?? false,
  };
  return merchantData;
};


export const getIsTwoStepCheckout = (data: any) => {
  const isTwoStepEnabled = Boolean(data?.account_attributes?.attributes?.metadata?.is_two_step_checkout);

  return !Boolean(Cookies.get(constants.REFRESH_TOKEN_COOKIE_CLIENT)) && isTwoStepEnabled;
};

const getPrimaryCtaConfig = (data: any) => {
  const defaultCtaConfig: CtaConfig[] = [
    { type: "AUTH", ctaText: t("add_address") },
    { type: "ORDER_SUMMARY", ctaText: t("proceed_to_pay") },
    { type: "PAYMENTS", ctaText: "" },
  ];
  const config = data?.account_attributes?.attributes?.ui?.primary_cta_config;
  if (!Array.isArray(config) || !Boolean(config)) return defaultCtaConfig;
  let primaryCtaConfig: CtaConfig[] = [];
  config.forEach((value: any) => {
    const ctaValue: CtaConfig = {
      type: value?.type,
      ctaText: Boolean(value?.cta_text)
        ? value?.cta_text
        : defaultCtaConfig.find((type) => type === value?.type)?.ctaText,
    };
    primaryCtaConfig.push(ctaValue);
  });
  return primaryCtaConfig;
};

const getCouponDisplayConfig = (data: any) => {
  const defaultCouponDisplayConfig: CouponDisplayConfig[] = [
    {
      type: "AUTH",
      isDisplayEnabled: true,
      displayType: "INPUT",
    },
    {
      type: "ORDER_SUMMARY",
      isDisplayEnabled: true,
      displayType: "INPUT",
    },
    {
      type: "PAYMENTS",
      isDisplayEnabled: true,
      displayType: "INPUT",
    },
  ];
  const config = data?.account_attributes?.attributes?.ui?.coupon_visibility_config;
  if (!Array.isArray(config) || !Boolean(config)) return defaultCouponDisplayConfig;
  let couponDisplayConfig: CouponDisplayConfig[] = [];
  config.forEach((value: any) => {
    const couponConfig: CouponDisplayConfig = {
      type: value?.type,
      isDisplayEnabled: value?.visibile,
      displayType: value?.visibility_extent === "codeEntry" ? "INPUT" : "STRIP",
    };
    couponDisplayConfig.push(couponConfig);
  });
  return couponDisplayConfig;
};

const getTrustBadgeDisplayConfig = (data: any) => {
  const defaultConfig: TrustBadgeDisplayConfig = {
    type: "AUTH",
    showBadges: false,
    badges: [],
  };
  const config = data?.account_attributes?.attributes?.ui?.trust_badges;
  if (!Boolean(config)) return defaultConfig;
  const badges = config.badges?.filter((badge: any) => badge.isActive);
  const trustBadgeConfig: TrustBadgeDisplayConfig = {
    type: config?.type ?? "AUTH",
    showBadges: Boolean(config?.showBadges),
    badges: badges ?? [],
  };
  return trustBadgeConfig;
};

const getEarliestDeliveryText = (minDays: number) => {
  const deliveryDate = new Date();
  deliveryDate.setDate(deliveryDate.getDate() + minDays);

  const weekday = deliveryDate.toLocaleDateString("en-US", { weekday: "short" });
  const day = deliveryDate.getDate();
  const month = deliveryDate.toLocaleDateString("en-US", { month: "short" });

  return `Earliest delivery by ${weekday}, ${day} ${month}`;
};

export const getETDText = (data: any) => {
  const minDays = data?.min_days_to_deliver ? parseInt(data?.min_days_to_deliver) : 4;
  const earliestDeliveryText = getEarliestDeliveryText(minDays);
  return earliestDeliveryText;
};

export const getDeliveryInformation = (
  minDays: number,
  maxDays: number,
  etdText: string,
  minDaysOnly: boolean,
) => {
  if (!Boolean(minDays) && !Boolean(maxDays)) return null;

  const getDayText = (days: number) => (days === 1 ? "day" : "days");

  if (minDaysOnly && Boolean(minDays)) {
    return getEarliestDeliveryText(minDays);
  }

  const daysText = `${minDays} - ${maxDays} ${getDayText(maxDays)}`;
  return `${etdText} ${minDays ? daysText : `${maxDays} ${getDayText(maxDays)}`}`;
};
