import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { redirectUrl } from "lib/utils/helpers";
import { errorToast } from "lib/utils/toasters";
import React, { useState } from "react";
import { ChevronRight } from "react-feather";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { getRequest } from "lib/core/apiClient";
import { getUnApplicablePaymentReason } from "lib/utils/payments";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { useLocale } from "lib/hooks/useLocale";

const GiftCardIcon = React.lazy(() => import("assests/icons/GiftCard"));

const ShopifyGiftCard: React.FC = ({}) => {
  const { t } = useLocale();
  const {
    state: { checkoutId, hasDefaultShippingHandleSelected },
    actions: { setCheckoutModal },
  } = useCheckoutContext();

  const {
    state: { paymentMethods },
  } = usePaymentContext();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const gcModeConfig = paymentMethods?.find((e: any) => e.mode === "SHOPIFY_GC");
  const unapplicabilityReason = getUnApplicablePaymentReason(
    gcModeConfig?.unavailability_reasons,
    gcModeConfig?.metadata?.title,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGCClick = async () => {
    const hasDefaultHandle = hasDefaultShippingHandleSelected;
    if (!Boolean(hasDefaultHandle)) {
      setCheckoutModal("SHIPPING_HANDLES");
      return;
    }

    try {
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
        eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
        eventType: "click",
        metaData: {
          methodType: "SHOPIFY_GC",
        },
      });
      setIsLoading(true);
      const response = await getRequest(`/checkout/v1/checkout/${checkoutId}/draft-order`);
      const success_url = response?.metadata?.action_urls?.GIFT_CARD_TILE?.success_url;
      if (success_url) {
        redirectUrl(success_url);
      } else {
        throw new Error("Error while applying gift cards");
      }
    } catch (err) {
      errorToast(t("shopify_gift_card_error"));
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="border-base overflow-hidden bg-white">
      <button
        className={`w-full ${gcModeConfig?.is_available ? "cursor-pointer" : "cursor-not-allowed"}`}
        disabled={!gcModeConfig?.is_available}
        onClick={() => {
          handleGCClick();
        }}>
        <div
          className={`flex w-full  flex-row items-center justify-center space-x-2 p-3 ${
            gcModeConfig?.is_available ? "text-coal-dark" : "text-gray-dark"
          }`}>
          <div className={`flex h-9 w-9 items-center justify-center`}>
            <GiftCardIcon color={gcModeConfig?.is_available ? "#4D4D4D" : "#949494"} />
          </div>
          <div className="flex w-full flex-1 flex-col items-start justify-start space-y-1">
            <h3 className="text-sm font-medium">{gcModeConfig?.metadata?.title ?? ""}</h3>
            {!Boolean(gcModeConfig?.metadata?.hideDescription) && (
              <p className="text-left text-xs font-normal text-gray-dark">
                {gcModeConfig?.metadata?.description ?? ""}
              </p>
            )}
          </div>

          {/* {!gcModeConfig?.is_available && (
            <span className="text-sm font-medium text-carbon-dark">{t("remove_offers")}</span>
          )} */}
          <ChevronRight className="h-4 w-4 text-coal-dark" />
        </div>
        {Boolean(!gcModeConfig.is_available) && Boolean(unapplicabilityReason) && (
          <div className="bg-[#FAFAFA]">
            <p className="px-3 py-2 text-left text-xs font-normal text-gray-dark">{unapplicabilityReason}</p>
          </div>
        )}
      </button>
      {isLoading && <OverlaySpinner />}
    </div>
  );
};

export default ShopifyGiftCard;
