import ShippingDeliveryIcon, { ShippingDeliveryIconType } from "components/common/ShippingHandleIcon";
import { OrderSummaryErrorCustom } from "components/error-handling/OrderSummaryError";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import { useAddressETD } from "lib/hooks/useAddressETD";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { useCheckoutErrors } from "lib/hooks/useCheckoutErrors";
import { useLocale } from "lib/hooks/useLocale";
import { getDefaultShippingHandle } from "lib/utils/checkout";
import { analyticsEvents } from "lib/utils/constants";
import { capitalizeFirstCharacter, scrollToID, truncateString } from "lib/utils/helpers";
import { getDeliveryInformation } from "lib/utils/merchant";
import React, { useEffect, useMemo } from "react";
import { ArrowRight, ChevronRight, MapPin, Truck } from "react-feather";
import AddressETD, { UserAddressDisplay } from "../AddressETD";

interface AddressSectionProps {}

const AddressSection: React.FC<AddressSectionProps> = () => {
  const { t } = useLocale();
  const {
    state: { user },
  } = useUserContext();
  const {
    state: { isC2P, shippingHandles, activeComponent, checkoutId },
    actions: { setCheckoutModal },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const { isNotServicable } = useCheckoutErrors();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const billingAddressEnabled = merchant?.addressConfig?.isBillingAddressEnabled;
  const selectedAddressId = user?.default_shipping_address?.id ? user?.default_shipping_address?.id : "";
  const { isETDEnabled, etdText } = useAddressETD(selectedAddressId);

  const selectedShippingHandle = useMemo(() => {
    const selectedData = getDefaultShippingHandle(shippingHandles);
    return {
      etd: selectedData?.etd ?? "",
      icon: selectedData?.icon ?? ShippingDeliveryIconType.Truck,
      etd_text: selectedData?.etd_text ?? "Delivery information: ",
      min_days_to_deliver: selectedData?.min_days_to_deliver ?? 0,
      max_days_to_deliver: selectedData?.max_days_to_deliver ?? 0,
    };
  }, [shippingHandles]);

  const deliveryInformation = useMemo(
    () =>
      getDeliveryInformation(
        selectedShippingHandle?.min_days_to_deliver,
        selectedShippingHandle?.max_days_to_deliver,
        selectedShippingHandle?.etd_text ?? "Delivery information: ",
        merchant?.hideShippingMaxDays ?? false,
      ),
    [selectedShippingHandle],
  );

  useEffect(() => {
    if (activeComponent === "ADDRESS_SECTION") {
      scrollToID("address-section");
    }
  }, [activeComponent]);

  return (
    <div
      id="address-section"
      className={`flex flex-col`}
      onClick={() => {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_DELIVERY_ADDRESS_CHANGE_CLICKED,
          eventType: "click",
        });
        if (Boolean(user?.addresses?.length)) {
          setCheckoutModal("ADDRESS_LIST");
          return;
        }
        setCheckoutModal("ADDRESS_NEW");
      }}>
      <div className={`border-base flex w-full flex-col items-center gap-2 bg-white p-3`}>
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex items-center justify-between space-x-2">
            <div className="flex items-center space-x-2">
              <MapPin className="h-4 w-4 text-coal-dark outline-none" strokeWidth={2.5} />
              <h2 className="text-sm font-medium text-coal-dark">{t("deliver_to")}</h2>
              {Boolean(user?.default_shipping_address?.type) && (
                <div className="flex w-fit rounded-xl bg-gray-lighter px-2 py-0.5">
                  <p className="w-full px-[3px] text-left text-sm font-medium text-coal-light">
                    {truncateString(
                      capitalizeFirstCharacter(
                        user?.default_shipping_address?.type ? user?.default_shipping_address?.type : "",
                      ),
                      12,
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
          {!isC2P && (
            <span className="inline-flex cursor-pointer space-x-1 text-coal-dark">
              <span className="text-sm font-medium">
                {Boolean(user?.addresses?.length) ? t("edit") : t("add_address")}
              </span>
              <ChevronRight strokeWidth={2.5} size={16} />
            </span>
          )}
        </div>

        {Boolean(user?.addresses?.length && user?.default_shipping_address?.id) && <UserAddressDisplay />}
        {isNotServicable && (
          <div className="flex w-full justify-start">
            <OrderSummaryErrorCustom />
          </div>
        )}
        {!isNotServicable && Boolean(!isETDEnabled) && Boolean(deliveryInformation) && (
          <AddressETD icon={selectedShippingHandle?.icon} etdText={deliveryInformation ?? ""} />
        )}
        {!isNotServicable && Boolean(isETDEnabled) && Boolean(etdText) && (
          <AddressETD icon={ShippingDeliveryIconType.Truck} etdText={etdText} />
        )}
      </div>
    </div>
  );
};

export default AddressSection;
