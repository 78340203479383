import React from "react";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import { DialogHeader, DialogBody } from "components/common/dialogs/GenericDialog";
import { Dialog } from "@headlessui/react";
import { X } from "react-feather";
import MethodsContainer from "components/payments/methods/MethodsContainer";
import Price from "components/common/Price";
import { floorToTwoDecimals } from "lib/utils/helpers";
import { useLocale } from "lib/hooks/useLocale";
import { currencyFormatter } from "lib/utils/formatters";

interface SplitCODProps {}

const SplitCOD: React.FC<SplitCODProps> = () => {
  const {
    state: { paymentMethods, openPaymentMethodDialog },
    actions: { getSplittedPaymentMethods, setPaymentMethodDialog },
  } = usePaymentContext();

  const { t } = useLocale();

  const GetDialogHeader = React.memo(() => {
    const splitPayment = paymentMethods.find((method: any) => method.mode === "SPLIT_COD");
    const prepaidAmount = floorToTwoDecimals(splitPayment.amount);
    const CODAmount = floorToTwoDecimals(splitPayment.metadata.cod_amount);
    return (
      <div className="flex flex-col gap-1 ">
        <h1 className="flex space-x-1 font-medium text-carbon-dark">
          {t("pay_x_now_and_y_on_delivery_header", {
            amount: currencyFormatter(prepaidAmount),
            cod_amount: currencyFormatter(CODAmount),
          })}
        </h1>
        <p className="text-coal-light text-xs font-normal">
          {splitPayment?.metadata?.description ?? t("split_cod_payment_subheader")}
        </p>
      </div>
    );
  });

  return (
    <>
      <GenericDialog
        isOpen={openPaymentMethodDialog === "SPLIT_COD"}
        setIsOpen={() => setPaymentMethodDialog("NONE")}
        translateAxis="y"
        dialogOverlay={true}
        customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
        modalType="PAYMENT_GROUP">
        <DialogHeader className="border-b">
          <Dialog.Title
            as="div"
            className="flex h-full w-full flex-row items-center justify-between bg-white py-4 text-base font-medium text-carbon-dark">
            {<GetDialogHeader />}

            <button className="outline-none">
              <X
                className="h-6 w-6 cursor-pointer text-coal-dark"
                onClick={() => setPaymentMethodDialog("NONE")}
              />
            </button>
          </Dialog.Title>
        </DialogHeader>
        <DialogBody>
          {
            <div className="mx-3 flex flex-col gap-4 py-7">
              <MethodsContainer renderMethods={getSplittedPaymentMethods()} />
            </div>
          }
        </DialogBody>
      </GenericDialog>
    </>
  );
};

export default SplitCOD;
