import { XIcon } from "@heroicons/react/solid";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { DialogBody, DialogFooter, DialogHeader } from "components/common/dialogs/GenericDialog";
import Seperator from "components/common/Seperator";
import { useLocale } from "lib/hooks/useLocale";
import { YotpoLoyaltyType } from "lib/types/checkout";
import React, { useState } from "react";
import { AlertCircle } from "react-feather";

interface EmailSelectorProps {
  selectedEmail: string;
  setSelectedEmail: any;
  emails: (string | undefined)[];
  setIsOpen: (value: boolean) => void;
  onSubmit: () => Promise<void>;
  error: boolean;
  setError: (value: boolean) => void;
  loyaltyData: YotpoLoyaltyType;
  title?: string;
}

const EmailSelector: React.FC<EmailSelectorProps> = ({
  selectedEmail,
  setSelectedEmail,
  emails,
  onSubmit,
  setIsOpen,
  title,
  error,
  setError,
  loyaltyData,
}) => {
  const { t } = useLocale();
  const [customEmail, setCustomEmail] = useState<string>("");

  const handleClose = () => {
    setIsOpen(false);
    setSelectedEmail(loyaltyData?.email);
    setError(false);
  };

  const handleEmailChange = (e: any) => {
    setCustomEmail(e.target.value);
    setSelectedEmail(e.target.value);
    setError(false);
  };

  const handleEmailSelect = (email: string) => {
    setSelectedEmail(email ?? "");
    setError(false);
  };

  return (
    <>
      <DialogHeader>
        <div className="flex h-full w-full flex-row items-center justify-between ">
          <div className="items-left flex flex-col justify-center gap-1">
            <h1 className="text-carbon -mx-3">{title ?? t("change_email")}</h1>
          </div>
          <button className="-mx-3 outline-none">
            <XIcon className="h-6 w-6 cursor-pointer text-coal-dark" onClick={handleClose} />
          </button>
        </div>
      </DialogHeader>
      <DialogBody className="overflow-x-hidden">
        <ul className="-mx-3 flex flex-col gap-2 ">
          {emails?.map((email) => {
            return (
              <li
                key={email}
                className={`mx-5 flex cursor-pointer items-center justify-between rounded-2xl border px-3 py-4 text-sm ${
                  selectedEmail === email
                    ? "border-2 border-primary-dark px-[11px] py-[15px] text-coal-dark"
                    : "text-coal-light"
                }`}
                onClick={() => handleEmailSelect(email ?? "")}>
                {email}
              </li>
            );
          })}
        </ul>
        <div className="my-2">
          <Seperator title="OR" />
        </div>
        <div className="mx-3">
          <input
            type="email"
            id="email"
            value={customEmail}
            onChange={handleEmailChange}
            onFocus={() => {
              setSelectedEmail("");
            }}
            placeholder={t("enter_new_email")}
            className={`flex w-full cursor-pointer items-center justify-between rounded-2xl border px-3 py-4 text-sm outline-0 focus:border-[1px] focus:border-primary-dark focus:px-[12px] focus:py-[16px] focus:text-coal-dark focus:ring-[2px] focus:ring-primary-light ${
              error ? "border-ouch" : ""
            }`}
          />
          {error ? (
            <div className="mt-1 flex items-center gap-1">
              <AlertCircle className="-mt-[1px] h-3 w-3 shrink-0 text-ouch" />
              <label
                htmlFor={"email"}
                className="bg-transparent text-xs font-normal text-ouch transition-all peer-focus:hidden">
                {t("valid_email")}
              </label>
            </div>
          ) : (
            <div className="mt-1 h-4"></div>
          )}
        </div>
      </DialogBody>
      <DialogFooter className="pb-16">
        <PrimaryButton
          buttonText={t("use_this_email")}
          type="submit"
          onClick={onSubmit}
          height="h-14"
          isLoading={false}
          isDisabled={false}
        />
      </DialogFooter>
    </>
  );
};

export default EmailSelector;
