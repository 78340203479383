import React, { useEffect, useState } from "react";
import { AddressCardFields } from "lib/types/address";
import { deleteRequest, getRequest } from "lib/core/apiClient";
import { useUserContext } from "lib/contexts/UserProvider";
import { formatAllAddresses } from "lib/utils/address";
import { errorToast, successToast } from "lib/utils/toasters";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { useLocale } from "lib/hooks/useLocale";
import ConfirmationPopup from "components/common/popups/ConfirmationPopup";
import { MoreVertical, Edit, Trash2, Truck, AlertTriangle } from "react-feather";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useAddressETD } from "lib/hooks/useAddressETD";
import { Popover } from "@headlessui/react";
import { capitalizeFirstCharacter, setPhoneNumberSpacing } from "lib/utils/helpers";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, analyticsTypes, checkoutIssueReasons } from "lib/utils/constants";
import { OrderSummaryErrorCustom } from "components/error-handling/OrderSummaryError";
import { useCheckoutErrors } from "lib/hooks/useCheckoutErrors";

export const AddressCard: React.FC<AddressCardFields> = ({
  id,
  type,
  name,
  address,
  phone,
  email,
  isChecked = false,
  selectedAddrId,
  handleEditAddress,
  defaultShippingAddress,
  defaultBillingAddress,
  setAddress,
  errorMessage,
}) => {
  const {
    state: { user },
    actions: { setUserData },
  } = useUserContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const { etdLoading, isETDEnabled, fetchEstimatedTimeDelivery, ETD_COLOR, ETD_SHIMMER_COLOR, etdText } =
    useAddressETD(id ?? "");
  const { isNotServicable } = useCheckoutErrors();

  const { t } = useLocale();
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleDelete = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenConfirmDelete(true);
  };

  const handleEdit = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    if (handleEditAddress) handleEditAddress(id ?? "");
  };

  const handleConfirmDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();
    event?.stopPropagation();
    closePopup();
    try {
      setIsLoading(true);
      await deleteRequest(`/attributes/v1/address/${id}`);
      const addresses = await getRequest("/attributes/v1/account/addresses");
      setIsLoading(false);
      const default_address_id =
        selectedAddrId !== id ? selectedAddrId || "" : addresses?.length > 0 ? addresses[0].uid : "";
      const formattedAddress = formatAllAddresses(addresses, default_address_id ?? "");
      setUserData({
        addresses: formattedAddress,
        default_billing_address:
          formattedAddress?.length && default_address_id
            ? formattedAddress?.filter((e) => e?.id === default_address_id)[0]
            : {},
      });
      successToast(t("address_delete_success"));
    } catch (e) {
      console.error(e, "error while deleting address");
      setIsLoading(false);
      errorToast(t("address_delete_failed"));
    }
  };

  const closePopup = () => {
    setOpenConfirmDelete(false);
  };

  useEffect(() => {
    if (isChecked && isETDEnabled) fetchEstimatedTimeDelivery();
  }, [isChecked]);

  const shouldShowOrderSummaryError = isChecked && user?.default_shipping_address?.id === id;

  return (
    <>
      <div
        className={`relative flex cursor-pointer flex-col justify-center gap-2 rounded-2xl border p-3 hover:ring-[2px] ${
          isChecked
            ? "border-transparent shadow-[0_0_10px_rgba(0,0,0,0.1)] ring-[2px] ring-primary-dark  hover:ring-primary-dark"
            : "border-gray-light hover:ring-gray-light"
        }`}>
        <div className="flex items-center">
          <div
            className={`flex h-4 w-4 min-w-[1rem] items-center justify-center rounded-full border-2 ${
              isChecked ? "border-primary-dark" : "border-flo-coal-light"
            }`}>
            <span className={`h-2 w-2 rounded-full ${isChecked ? "bg-primary-dark" : "bg-none"}`}></span>
          </div>

          <span className="ml-1.5 w-fit rounded-md bg-gray-lighter p-[4.5px] px-2 text-xs font-medium text-coal-dark">
            {capitalizeFirstCharacter(type ?? "")}
          </span>

          <Popover className="ml-auto flex justify-self-end rounded-[4px] p-[2px] hover:bg-gray-lighter">
            <Popover.Button onClick={setAddress}>
              <MoreVertical className="h-4 w-4 outline-none" />
            </Popover.Button>
            <Popover.Panel className="absolute right-5 top-8 z-20 rounded border border-gray-light bg-white shadow-[0_0_10px_rgba(0,0,0,0.1)]">
              <div className="flex flex-col">
                <button
                  className="flex items-center space-x-2 rounded-t p-4 text-sm font-medium text-coal-dark hover:bg-gray-lighter"
                  onClick={handleEdit}>
                  <Edit className="h-4 w-4 text-coal-dark " />
                  <span>Edit</span>
                </button>
                {!Boolean(
                  defaultBillingAddress === selectedAddrId || defaultShippingAddress === selectedAddrId,
                ) && (
                  <button
                    className="flex items-center space-x-2 rounded-b p-4 text-sm font-medium text-coal-dark hover:bg-gray-lighter"
                    onClick={handleDelete}>
                    <Trash2 className="h-4 w-4 text-coal-dark" />
                    <span>Delete</span>
                  </button>
                )}
                <button
                  className="flex items-center space-x-2 rounded-b p-4 text-sm font-medium text-coal-dark hover:bg-gray-lighter"
                  onClick={() => {
                    sendAnalyticsEvent({
                      eventName: analyticsEvents.FLO_CHECKOUT_ISSUE_REPORTED,
                      eventFor: [analyticsTypes.SF_ANALYTICS],
                      eventType: "click",
                      metaData: {
                        issueReason: checkoutIssueReasons.ADDRESS_ISSUE,
                        addressData: {
                          addressId: id,
                          name: name,
                          address: address,
                          phone: phone,
                          email: email,
                          type: type,
                        },
                      },
                    });
                    window.open(
                      "mailto:siddhant.jagtap@shopflo.com?subject=Issue%20with%20shopflo%20checkout&body=I%20want%20to%20report%20an%20issue",
                      "_blank",
                    );
                  }}>
                  <AlertTriangle className="h-4 w-4 text-coal-dark" />
                  <span>Report an issue</span>
                </button>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
        <div>
          <AddressCardDetails
            name={name}
            address={address}
            phone={phone}
            email={email}
            isChecked={isChecked}
          />
        </div>
        {/* {!errorMessage && */}
        {isETDEnabled && !isNotServicable &&
          (etdLoading ? (
            <div
              className="mt-2 p-3 rounded-lg"
              style={{
                backgroundImage: `linear-gradient(90deg, ${ETD_COLOR} 0px, ${ETD_SHIMMER_COLOR} 40px, ${ETD_COLOR} 80px)`,
                backgroundSize: "275px",
                animation: "shine 1s infinite linear",
                height: "38px",
              }}></div>
          ) : etdText ? (
            <div
              className="flex items-center mt-2 p-3 rounded-lg gap-2 w-full"
              style={{ backgroundColor: ETD_COLOR }}>
              <Truck size={16} color={merchant?.colorPallet?.primaryColor} />
              <span className="text-xs font-medium text-coal-dark">{etdText}</span>
            </div>
          ) : null)}
        {/* TODO shradhan: To be removed once the error message is removed from the backend */}
        {/* {Boolean(errorMessage) && <span className="text-xs font-medium text-ouch">{errorMessage}</span>} */}
        {shouldShowOrderSummaryError && <OrderSummaryErrorCustom />}
      </div>

      {isLoading && <OverlaySpinner />}

      <ConfirmationPopup
        popupTitle={t("confirm_address_delete_header")}
        popupBody={t("confirm_address_delete_desc")}
        onClickPrimaryBtn={(e) => {
          handleConfirmDelete(e);
        }}
        isOpen={openConfirmDelete}
        closePopup={closePopup}
        modalType="DELETE_ADDRESS_CONFIRMATION"
      />
    </>
  );
};

export const AddressCardDetails: React.FC<AddressCardFields> = React.memo(
  ({ name, address, phone, email }) => {
    return (
      <div className="flex flex-col space-y-1.5 text-sm">
        <div className="font-medium text-coal-dark">{name}</div>
        <div className="font-normal text-coal-dark">{address}</div>
        <div className="pt-1 text-xs font-normal text-gray-dark">
          {`${phone ? setPhoneNumberSpacing(phone) : ""}${email ? `, ${email}` : ""}`}
        </div>
      </div>
    );
  },
);
