export type CheckoutErrorType = {
  url: string;
  err: any;
  payload: any;
};

export enum ErrorType {
  CHECKOUT_ERROR = "CHECKOUT_ERROR",
  HEALTH_CHECK_ERROR = "HEALTH_CHECK_ERROR",
  PIXEL_ERROR = "PIXEL_ERROR",
}
