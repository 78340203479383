import { useMerchantContext } from "lib/contexts/MerchantProvider";
import ShippingDeliveryIcon, { ShippingDeliveryIconType } from "../common/ShippingHandleIcon";
import { hexToRgba, truncateString } from "lib/utils/helpers";
import { useUserContext } from "lib/contexts/UserProvider";
import { ChevronRight, Mail, MapPin, Phone } from "react-feather";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useAddressETD } from "lib/hooks/useAddressETD";
import { useLocale } from "lib/hooks/useLocale";
import { useEffect, useMemo, useState } from "react";
import { SelectedShippingHandleType } from "lib/types/checkout";
import { getDefaultShippingHandle } from "lib/utils/checkout";
import { getDeliveryInformation } from "lib/utils/merchant";
import { AnimateLoading } from "components/animation/AnimateLoading";

import { useCheckoutErrors } from "lib/hooks/useCheckoutErrors";
import { OrderSummaryErrorCustom } from "components/error-handling/OrderSummaryError";
interface AddressETDProps {
  icon: ShippingDeliveryIconType;
  etdText: string;
}

const AddressETD = ({ icon, etdText }: AddressETDProps) => {
  const {
    state: { merchant },
  } = useMerchantContext();
  const primaryColor = merchant?.colorPallet?.primaryColor?.substring(0, 7);
  const ETD_COLOR = hexToRgba(primaryColor ?? "#000000", 0.2);
  return (
    <div className="flex items-center p-2 rounded-lg gap-2 w-full " style={{ backgroundColor: ETD_COLOR }}>
      <ShippingDeliveryIcon icon={icon} color={primaryColor ?? "#000000"} />
      <span className="text-xs font-medium text-coal-dark">{etdText}</span>
    </div>
  );
};

export default AddressETD;

const removeLastPincode = (address: string) => {
  const parts = address.split(",").map((part) => part.trim());
  if (parts.length > 1) {
    parts.pop();
  }
  return parts.join(", ");
};

export const UserAddressDisplay = () => {
  const {
    state: { user },
  } = useUserContext();

  return (
    <div className={`flex w-full flex-col space-y-2 p-2 bg-gray-lightest rounded-lg`}>
      <p className="w-full px-[3px] text-left text-xs font-normal text-coal-light leading-5 line-clamp-2">
        <span className="font-medium text-coal-dark">{user?.default_shipping_address?.name + ", "}</span>
        <span className="font-normal text-coal-light">
          {truncateString(removeLastPincode(user?.default_shipping_address?.address ?? ""), 75)}
        </span>
        <span className="text-coal-dark font-medium ml-2">{user?.default_shipping_address?.zip}</span>
      </p>
      <div className="flex flex-row space-x-2 px-[3px] text-gray-dark truncate">
        <div className="flex flex-row space-x-1 items-center">
          <Phone size={14} color="#6E6E6E" strokeWidth={3} />
          <span className="text-xs font-normal">{user?.default_shipping_address?.phone}</span>
        </div>
        <div className="flex flex-row space-x-1 items-center min-w-0">
          <Mail className="flex-shrink-0" size={14} color="#6E6E6E" strokeWidth={3} />
          <span className="text-xs font-normal truncate">{user?.default_shipping_address?.email}</span>
        </div>
      </div>
    </div>
  );
};

interface DeliveryCardProps {
  handleDeliveryAddressBtnClick: () => void;
}
export const DeliveryCard = ({ handleDeliveryAddressBtnClick }: DeliveryCardProps) => {
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: { isC2P, shippingHandles, checkoutLoading },
  } = useCheckoutContext();
  const {
    state: { user },
  } = useUserContext();
  const { t } = useLocale();

  const selectedAddressId = user?.default_shipping_address?.id ? user?.default_shipping_address?.id : "";
  const { isETDEnabled, etdText } = useAddressETD(selectedAddressId);
  const { isNotServicable } = useCheckoutErrors();

  const [selectedShippingHandle, setSelectedShippingHandle] = useState<SelectedShippingHandleType>({
    etd: "",
    icon: ShippingDeliveryIconType.Truck,
    etd_text: "Delivery information: ",
    min_days_to_deliver: 0,
    max_days_to_deliver: 0,
  });

  const deliveryInformation = useMemo(
    () =>
      getDeliveryInformation(
        selectedShippingHandle?.min_days_to_deliver ?? 0,
        selectedShippingHandle?.max_days_to_deliver ?? 0,
        selectedShippingHandle?.etd_text ?? "",
        merchant?.hideShippingMaxDays ?? false,
      ),
    [selectedShippingHandle],
  );

  useEffect(() => {
    if (!Boolean(shippingHandles?.length)) return;
    const selectedHandle = getDefaultShippingHandle(shippingHandles);
    if (Boolean(selectedHandle)) {
      setSelectedShippingHandle({
        etd: selectedHandle?.etd ?? "",
        icon: selectedHandle?.icon ?? ShippingDeliveryIconType.Truck,
        etd_text: selectedHandle?.etd_text ?? "Delivery information: ",
        min_days_to_deliver: selectedHandle?.min_days_to_deliver ?? 0,
        max_days_to_deliver: selectedHandle?.max_days_to_deliver ?? 0,
      });
    }
  }, [shippingHandles]);

  const deliverySkeleton = (
    <div className="border-base px-3 py-4 bg-white space-y-2">
      <div className="skeleton-loader h-[16px] rounded-md w-full"></div>
      <div className="skeleton-loader h-[16px] rounded-md w-2/5"></div>
    </div>
  );

  return (
    <AnimateLoading loading={checkoutLoading}>
      <AnimateLoading.Skeleton>{deliverySkeleton}</AnimateLoading.Skeleton>
      <AnimateLoading.Content>
        <div className={`flex flex-col`}>
          <button
            className={`border-base flex w-full flex-col items-center gap-3 bg-white p-3 pt-4 ${
              isC2P && "cursor-default"
            }`}
            onClick={handleDeliveryAddressBtnClick}>
            <div className="flex w-full flex-row items-center">
              <div className="flex w-full items-center justify-between space-x-2">
                <div className="flex space-x-2">
                  <MapPin className="h-4 w-4 text-coal-dark outline-none" strokeWidth={2.5} />
                  <h2 className="text-sm font-medium text-coal-dark">{t("delivering_to_address")}</h2>
                </div>
              </div>
              {!isC2P && (
                <span className="inline-flex cursor-pointer space-x-1 text-coal-dark">
                  <span className="text-sm font-medium">
                    {Boolean(user?.addresses?.length) ? t("edit") : t("add_address")}
                  </span>
                  <ChevronRight strokeWidth={2.5} size={16} />
                </span>
              )}
            </div>

            <UserAddressDisplay />
            {!isNotServicable && Boolean(!isETDEnabled) && Boolean(deliveryInformation) && (
              <AddressETD icon={selectedShippingHandle?.icon} etdText={deliveryInformation ?? ""} />
            )}
            {!isNotServicable && Boolean(isETDEnabled) && Boolean(etdText) && (
              <AddressETD icon={ShippingDeliveryIconType.Truck} etdText={etdText} />
            )}
            <OrderSummaryErrorCustom />
          </button>
        </div>
      </AnimateLoading.Content>
    </AnimateLoading>
  );
};
