import { useMerchantContext } from "lib/contexts/MerchantProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import useForm, { ValidationSchema, FormField } from "lib/hooks/useForm";
import { AddressFields } from "lib/types/address";
import { getUserAddressById, getBillingAddressFields } from "lib/utils/address";
import React, { useEffect, useMemo, useState } from "react";
import { analyticsEvents } from "lib/utils/constants";
import { DialogBody, DialogHeader } from "components/common/dialogs/GenericDialog";
import { Dialog } from "@headlessui/react";
import { X } from "react-feather";
import TextArea from "components/common/forms/TextArea";
import InputField from "components/common/forms/InputField";
import DropDown from "components/common/fields/DropDown";
import { capitalizeFirstCharacter, isEmptyObj, isChecked } from "lib/utils/helpers";
import { getDetailsByZip } from "lib/core/apiMethods";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { getRequest, postRequest } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import { useUserContext } from "lib/contexts/UserProvider";
import { UserType } from "lib/types/user";
import { parseUserData } from "lib/utils/user";
import SectionTitle from "components/common/SectionTitle";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface BillingAddressFormProps {
  setIsOpen: () => void;
}

const BillingAddressForm: React.FC<BillingAddressFormProps> = ({ setIsOpen }) => {
  const { t } = useLocale();
  const {
    state: { addressConfig },
  } = useMerchantContext();
  const {
    state: { checkoutId },
    actions: { updateCheckoutBasedOnCheckoutResponse, setCheckoutView },
  } = useCheckoutContext();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const {
    state: { user },
    actions: { setUserData },
  } = useUserContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [states, setStates] = useState<any>([]);
  const [stateDisabled, setStateDisabled] = useState<boolean>(false);
  const [isCloneOfShipping, setIsCloneOfShipping] = useState<boolean>(false);
  const [isBusinessNameRequired, setIsBusinessNameRequired] = useState<boolean>(false);

  const validationSchema: ValidationSchema = useMemo(() => {
    const schema = getBillingAddressFields(addressConfig, isBusinessNameRequired);
    return schema;
  }, [addressConfig, isBusinessNameRequired]);

  const initialState: AddressFields = {
    zip: "",
    address1: "",
    address2: "",
    name: capitalizeFirstCharacter(user?.name ?? ""),
    phone: user?.phone ?? "",
    city: "",
    state: "",
    state_code: "",
    country: "India",
    country_code: "IN",
    type: "Home",
    email: user?.email?.trim() ?? "",
    gstin: "",
    gst_business_name: "",
  };

  const {
    handleSubmit,
    inputProps,
    setValues,
    setErrors,
    setValueOf,
    handleFieldChange,
    state: { values, errors, validations },
  } = useForm({ initialState, validationSchema });

  useEffect(() => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_BILLING_ADDRESS_EDIT_FORM_LOADED,
      eventType: "load",
    });
  }, []);

  useEffect(() => {
    const gstin = values?.gstin;
    if (Boolean(gstin)) {
      setIsBusinessNameRequired(true);
      return;
    }
    setIsBusinessNameRequired(false);
    setErrors((oldValues) => ({
      ...oldValues,
      gst_business_name: { ...oldValues.gst_business_name, status: false },
    }));
  }, [values]);

  useEffect(() => {
    const defaultShippingAddressId = user?.default_shipping_address?.id;
    const defaultBillingAddressId = user?.default_billing_address?.id ?? "";
    const billingAddress = getUserAddressById(defaultBillingAddressId, user?.addresses);
    //If default shipping and billing address are same, then set as shipping
    //If billing address is not available, then set as shipping address
    if (defaultShippingAddressId === defaultBillingAddressId || isEmptyObj(billingAddress)) {
      setFormFieldValues(defaultShippingAddressId ?? "");
      setIsCloneOfShipping(true);
      return;
    }

    setFormFieldValues(defaultBillingAddressId ?? "");
  }, [user]);

  const setFormFieldValues = async (addressId: string) => {
    try {
      if (!Boolean(addressId)) return;
      setIsLoading(true);
      const respone = getUserAddressById(addressId, user?.addresses);
      setValues({
        type: respone.type ?? "Other",
        zip: respone?.zip ?? "",
        address1: respone?.address1,
        address2: respone?.address2 ?? "",
        name: respone?.name ?? "",
        phone: respone?.phone ?? "",
        city: respone?.city ?? "",
        state: respone?.state ?? "",
        country: respone?.country ?? "",
        email: respone?.email,
        country_code: respone?.country_code ?? "IN",
        state_code: respone?.state_code ?? "",
        gst_business_name: respone?.gst_business_name,
        gstin: respone?.gstin,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleZipChange = async (zip: string) => {
    const countryCode = values?.country_code;
    if (Boolean(/\W/.test(zip)) || (countryCode === "IN" && Boolean(/\D/.test(zip)))) return; //Checks for special characters
    handleFieldChange(FormField.zip, zip);
    let zipDetails: any = {};
    if (Boolean(zip) && countryCode === "IN" && zip?.length === 6) {
      zipDetails = await getDetailsByZip(zip);
    }

    if (isEmptyObj(zipDetails)) {
      setStateDisabled(false);
      return;
    }
    setValues((oldValues: any) => ({
      ...oldValues,
      city: capitalizeFirstCharacter(zipDetails.district),
      state: capitalizeFirstCharacter(zipDetails.state),
      state_code: zipDetails.state_code,
      country: capitalizeFirstCharacter(zipDetails.country),
    }));
    setErrors((oldValues) => ({
      ...oldValues,
      city: { ...oldValues.city, status: false },
      state: { ...oldValues.state, status: false },
      country: { ...oldValues.country, status: false },
      state_code: { ...oldValues.state_code, status: false },
    }));
    setStateDisabled(true);
  };

  const onBillingAddressSubmit = async () => {
    let response;
    try {
      setIsLoading(true);
      let payload: any = {
        source: "BILLING_ADDRESS",
        address_data: {
          ...values,
          gst: values.gstin?.trim(),
          gst_business_name: values.gst_business_name?.trim(),
        },
      };

      payload = { ...payload, account_id: user?.uid, address_type: "Other" };
      response = await postRequest(`/checkout/${checkoutId}/address`, payload, "KRATOS_PRIVATE");

      // if (Boolean(isCloneOfShipping)) {
      //   payload = { ...payload, account_id: user?.uid, address_type: "Other" };
      //   response = await postRequest(`/checkout/v1/checkout/${checkoutId}/address`, payload);
      // } else {
      //   response = await putRequest(
      //     `/checkout/v1/checkout/${checkoutId}/address/${user?.default_billing_address?.id}`,
      //     payload,
      //   );
      // }

      const accountResponse = await getRequest("/gatekeeper/v1/accounts?include=address,attributes");
      handleResponseSuccess(response, accountResponse);
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_BILLING_ADDRESS_EDIT_FORM_LOADED,
        eventType: "load",
      });
      setIsOpen();
    } catch (error: any) {
      if (error?.response?.data?.error?.includes("gstin")) {
        setErrors((oldValues) => ({
          ...oldValues,
          gstin: { ...oldValues.gstin, status: true, message: t("invalid_gstin") },
        }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResponseSuccess = (checkoutResponse: any, accountResponse: any) => {
    handleCheckoutResponse(checkoutResponse);
    handleAccountResponse(accountResponse, checkoutResponse);
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_ADDRESS_SELECTED,
      eventType: "click",
    });
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse, true);
  };

  const handleAccountResponse = (accountResponse: any, checkoutResponse: any) => {
    const parsedUserData: UserType = parseUserData(accountResponse, checkoutResponse);
    setUserData(parsedUserData);
  };

  const handleSameAsShippingToggle = () => {
    const isCheckboxChecked = isChecked("clone-shipping--checkbox");
    setIsCloneOfShipping(isCheckboxChecked);
    if (isCheckboxChecked) {
      const defaultShippingAddressId = user?.default_shipping_address?.id;
      setFormFieldValues(defaultShippingAddressId ?? "");
    }
  };

  return (
    <>
      <DialogHeader>
        <Dialog.Title as="div" className="flex h-full w-full flex-row items-center justify-between bg-white">
          <h1 className="text-base font-medium text-carbon-dark">{t("billing_address_gst")}</h1>
          <button className="outline-none">
            <X
              className="h-6 w-6 cursor-pointer text-coal-light"
              onClick={() => {
                setIsOpen();
              }}
            />
          </button>
        </Dialog.Title>
      </DialogHeader>
      <DialogBody className="!pt-12">
        <form
          className="flex flex-col space-y-4 px-6 pt-4"
          onSubmit={handleSubmit(onBillingAddressSubmit)}
          id="billing-form">
          <fieldset>
            <div className="flex flex-col space-y-2">
              <InputField
                type="text"
                maxLength={15}
                label={`${t("gstin")} (${t("optional_text")})`}
                {...inputProps(FormField.gstin)}
                filled={Boolean(values?.gstin?.length)}
                autoComplete="gstin"
                error={errors.gstin}
                required={Boolean(validationSchema?.gstin?.required)}
                showSuccessIcon={validations?.gstin && Boolean(values?.gstin?.length)}
              />
              <p className="pb-2 text-xs text-gray-dark">{t("gst_form_note")}</p>
              <InputField
                type="text"
                label={t("gst_business_name")}
                {...inputProps(FormField.gst_business_name)}
                filled={Boolean(values?.gst_business_name?.length)}
                autoComplete="company"
                error={errors.gst_business_name}
                required={
                  Boolean(validationSchema?.gst_business_name?.required) || Boolean(values?.gstin?.length)
                }
                showSuccessIcon={validations?.gst_business_name}
              />
              <SectionTitle
                title={t("billing_address_header")}
                customClass="mb-3 -mx-4 text-xs uppercase !text-coal-dark pt-3 pb-4 font-medium"
              />

              <div className="flex items-center gap-2 pb-2">
                <input
                  type="checkbox"
                  checked={isCloneOfShipping}
                  className="cursor-pointer accent-coal-dark"
                  id="clone-shipping--checkbox"
                  onChange={handleSameAsShippingToggle}
                />
                <p className="text-sm font-normal text-coal-dark">Same as delivery address</p>
              </div>

              {Boolean(validationSchema?.address1) && (
                <TextArea
                  label={t("address1")}
                  {...inputProps(FormField.address1)}
                  filled={Boolean(values.address1?.length)}
                  autoComplete={
                    Boolean(validationSchema?.address1) ? "address-line1" : "shipping street-address"
                  }
                  error={errors.address1}
                  required={Boolean(validationSchema?.address1?.required)}
                  showSuccessIcon={validations?.address1}
                />
              )}
              {Boolean(validationSchema?.address2) && (
                <TextArea
                  label={t("address2")}
                  {...inputProps(FormField.address2)}
                  filled={Boolean(values.address2?.length)}
                  autoComplete={
                    Boolean(validationSchema?.address2) ? "address-line2" : "shipping street-address"
                  }
                  error={errors.address2}
                  required={Boolean(validationSchema?.address2?.required)}
                  showSuccessIcon={validations?.address2}
                />
              )}

              {Boolean(validationSchema?.zip) && (
                <InputField
                  type="tel"
                  label={t("address_pincode")}
                  maxLength={values.country_code === "IN" ? 6 : 25}
                  filled={Boolean(values.zip?.length)}
                  autoComplete="shipping postal-code"
                  {...inputProps(FormField.zip, {
                    onChange: (e: any) => {
                      handleZipChange(e.target.value);
                    },
                  })}
                  error={errors.zip}
                  required={Boolean(validationSchema?.zip?.required)}
                  showSuccessIcon={validations?.zip}
                />
              )}

              <div className="flex w-full flex-row items-start space-x-2">
                {Boolean(validationSchema?.city) && (
                  <InputField
                    type="text"
                    label={t("address_city")}
                    {...inputProps(FormField.city)}
                    filled={Boolean(values.city?.length)}
                    autoComplete="shipping address-level2"
                    error={errors.city}
                    required={Boolean(validationSchema?.city?.required)}
                    showSuccessIcon={validations?.city}
                  />
                )}

                {Boolean(validationSchema?.state) &&
                  (states?.length ? (
                    <DropDown
                      placeholder={t("address_state")}
                      {...inputProps(FormField.state_code, {
                        onChange: (e: any) => {
                          setValueOf("state_code", e?.target?.value);
                          setValueOf("state", e?.target?.selectedOptions[0]?.text);
                          Boolean(errors.state) && delete errors.state;
                        },
                      })}
                      autoComplete="shipping address-level1"
                      disabled={stateDisabled}
                      options={states}
                      optionKey="code"
                      optionValue="name"
                      required={Boolean(validationSchema?.state?.required)}
                      error={errors.state}
                      customContainerClass="!mt-3"
                    />
                  ) : (
                    <InputField
                      type="text"
                      label={t("address_state")}
                      {...inputProps(FormField.state)}
                      filled={Boolean(values.state?.length)}
                      autoComplete="shipping address-level1"
                      error={errors.state}
                      required={Boolean(validationSchema?.state?.required)}
                      showSuccessIcon={validations?.state}
                    />
                  ))}
              </div>
            </div>
          </fieldset>
          <div className="mt-4">
            <PrimaryButton
              id="billing_submit_button"
              type="submit"
              buttonText={t("submit")}
              height="h-14"
              // isDisabled={isBillingSame}
            />
          </div>
        </form>
      </DialogBody>
      {isLoading && <OverlaySpinner />}
    </>
  );
};

export default BillingAddressForm;
