import { useCallback, Suspense, useMemo } from "react";
import BillingSummaryDialog from "components/cart/dialogs/BillingDialog";
import CartAuthenticationDialog from "components/cart/dialogs/CartAuthenticationDialog";
import CartOtpVerficationDialog from "components/cart/dialogs/CartAuthenticationOtpVerificationDialog";
import { ItemCard } from "components/checkout/CheckoutItems";
import { useLocale } from "lib/hooks/useLocale";
import { useEffect, useRef, useState } from "react";
import { AddOnMetaType, AddOnType, BannerType, ItemType } from "lib/types/checkout";
import { X } from "react-feather";
import { ShoppingCart } from "assests/icons/CartIcons";
import { AccordionRefType } from "components/common/Accordian";

import TrustBadgeGray from "assests/images/trust-badge-gray.svg";
import cartImage from "assests/images/cart.png";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { CartDialogType } from "lib/types/cart";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useSearchParams } from "react-router-dom";
import { getTotalItems, hasAutoAppliedCoupon, initializeCart, showAddOnLineItems } from "lib/utils/checkout";
import { currencyFormatter } from "lib/utils/formatters";
import Skeleton from "components/common/loaders/Skeleton";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { Checkbox, Milestone, MilestoneCategory, ProgressBar } from "@shopflo/ui";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";

import {
  classNames,
  closeIframe,
  inIframe,
  isEmptyObj,
  isThirdPartyCookieEnabled,
  publishPostMessage,
} from "lib/utils/helpers";
import { eventTypes, analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { parseUserData } from "lib/utils/user";
import { UserType } from "lib/types/user";
import { putRequest } from "lib/core/apiClient";
import Coupons from "components/checkout/Coupons";
import { errorToast } from "lib/utils/toasters";
import UpSellAndWishlistSection from "components/upsell/UpSellSection";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import MarketingConsent from "components/common/MarketingConsent";
import CartConfetti from "components/common/confetti/CartConfetti";
import CartBannerStrip from "components/common/notification-strips/CartBannerStrip";
import { PublishMessageOrigin } from "lib/types/fields";
import Rewards from "components/checkout/Rewards";
import { AddOnItem } from "components/common/AddOnItem";
import { AddOnCheckboxTile } from "components/common/AddOnCheckboxTile";
import { useItemEdit } from "lib/hooks/useItemEdit";

// TODO: shradhan This is a temporary solution to handle the cart consequent events.
// We need to find a better way to handle this.
class PromiseQueue {
  private queue: { event: any; resolve: Function; reject: Function }[] = [];
  private isProcessing = false;

  constructor(
    private initialize: (event: any) => Promise<void> | void,
    private logout: (event: any) => Promise<void> | void,
    private login: (event: any) => Promise<void> | void,
    private closeAllModals: (event: any) => Promise<void> | void,
  ) {
    this.initialize = initialize;
    this.logout = logout;
    this.login = login;
  }

  enqueue(event: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.queue.push({ event, resolve, reject });
      this.processNext();
    });
  }

  private async processNext() {
    if (this.isProcessing || this.queue.length === 0) return;

    this.isProcessing = true;
    const { event, resolve, reject } = this.queue[0];

    try {
      await this.processEvent(event);
      resolve();
    } catch (error) {
      reject(error);
    } finally {
      this.queue.shift();
      this.isProcessing = false;
      this.processNext();
    }
  }

  private async processEvent(event: any) {
    switch (event?.data?.type) {
      case eventTypes.CART_OPEN:
        await this.initialize(event);
        break;
      case eventTypes.LOGOUT_FROM_CHECKOUT:
        await this.logout(event);
        break;
      case eventTypes.LOGIN_FROM_CHECKOUT:
        await this.login(event);
        break;
      case eventTypes.CLOSE_ALL_MODALS:
        await this.closeAllModals(event);
        break;
    }
  }

  clear() {
    this.queue = [];
    this.isProcessing = false;
  }

  get length() {
    return this.queue.length;
  }
}

const Cart: React.FC = () => {
  const [searchParams] = useSearchParams();

  const isBackgroundLoad = searchParams.get("is_background_load") === "true";

  const authContext = useAuthContext();

  const {
    state: { isAuthenticated },
    actions: { logout, login },
  } = authContext;

  const userContext = useUserContext();
  const {
    state: { marketingConsent },
    actions: { setUserData },
  } = userContext;

  const checkoutContext = useCheckoutContext();
  const {
    state: {
      checkoutItems,
      billing,
      appliedCoupons,
      checkoutId,
      checkoutUIMetadata,
      checkoutModal,
      cartDialog,
      wishlistConfig,
      availableAddOns,
      appliedCartAddOns,
    },
    actions: { updateCheckoutBasedOnCheckoutResponse, setCheckoutModal, setCartDialog },
  } = checkoutContext;

  const merchantContext = useMerchantContext();
  const {
    state: { merchant },
  } = merchantContext;

  const analytics = useSendAnalyticsEvent();

  const { t } = useLocale();

  const accordionRef = useRef<AccordionRefType>(null);
  const mainContentRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  const isCountBased = useMemo(() => {
    return checkoutUIMetadata?.progressBarConfig?.customizations?.category === MilestoneCategory.COUNT_BASED;
  }, [checkoutUIMetadata?.progressBarConfig?.customizations?.category]);

  const itemCountForProgressBar = useMemo(() => {
    return checkoutItems?.filter((item) => !item.is_freebie).reduce((acc, item) => acc + item.quantity, 0);
  }, [checkoutItems]);

  const previousTotalItems = useRef(itemCountForProgressBar);
  const previousTotalPrice = useRef(billing?.sub_total);

  const totalItems = getTotalItems(checkoutItems);

  useEffect(() => {
    const isInCart = history.state?.cart === "ongoing";
    if (inIframe() && isInCart) {
      publishPostMessage(eventTypes.SET_CART_ITEM_COUNT, { totalItems });
    }
  }, [totalItems]);

  useEffect(() => {
    previousTotalItems.current = itemCountForProgressBar;
    previousTotalPrice.current = billing?.sub_total;
  }, [itemCountForProgressBar, billing?.sub_total]);

  useEffect(() => {
    if (!isLoading && (!checkoutItems || checkoutItems?.length === 0)) {
      closeIframe(false, {
        origin: PublishMessageOrigin.CART,
      });
    }
  }, [checkoutItems, isLoading]);

  useEffect(() => {
    if (inIframe()) {
      publishPostMessage(eventTypes.REMOVE_CART_LOADING_STATE, {});
    }
    setTimeout(() => {
      accordionRef.current?.openAccordion();
    }, 3000);
  }, []);

  useEffect(() => {
    const addOnList = Object.values(availableAddOns ?? {});
    if (!addOnList.length) return;
    const isCartLevelAddOn = addOnList.some(
      (item) => item?.addon_level === "CART" && ["CART", "DEFAULT"].includes(item?.ui_asset),
    );
    const isItemLevelAddOn = addOnList.some(
      (item) => item?.addon_level === "ITEM" && ["CART", "DEFAULT"].includes(item?.ui_asset),
    );
    if (merchant?.merchantId) {
      analytics.sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_ADDON_LOADED,
        eventFor: [analyticsTypes.SF_ANALYTICS],
        eventType: "load",
        metaData: {
          merchantId: merchant?.merchantId,
          addOnData: {
            view: "CART",
            isCartLevelAddOn,
            isItemLevelAddOn,
          },
        },
      });
    }
  }, [availableAddOns, merchant?.merchantId]);

  const [isCartLoaded, setIsCartLoaded] = useState(false);
  useEffect(() => {
    if (!isCartLoaded) {
      initialize().then(() => {
        setIsCartLoaded(true);
      });
    }
  }, [isCartLoaded]);

  useEffect(() => {
    const promiseQueue = new PromiseQueue(
      () => initialize(Boolean(checkoutId)),
      () => logout(true),
      (event: any) => {
        if (event?.data?.payload) {
          const { idToken, refreshToken, authExpiry, refreshExpiry } = event.data.payload;
          login(idToken, refreshToken, authExpiry, refreshExpiry);
        }
      },
      () => {
        setCheckoutModal("NONE");
      },
    );

    const handleMessage = (event: any) => {
      promiseQueue.enqueue(event).catch((error) => {
        console.error("error processing cart event:", error);
      });
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
      promiseQueue.clear();
    };
  }, [isLoading, isAuthenticated, checkoutId]);

  useEffect(() => {
    history.pushState({ cart: "ongoing" }, "");
  }, []);

  const closeCart = () => {
    setCheckoutModal("NONE");
    publishPostMessage(eventTypes.CLOSE_CART_IFRAME, {});
  };

  const backButtonHandler = useCallback(() => {
    if (checkoutModal !== "NONE") {
      setCheckoutModal("NONE");
      history.pushState({ cart: "close_modal" }, "");
      return;
    }
    closeCart();
  }, [checkoutModal]);

  useEffect(() => {
    window.addEventListener("popstate", backButtonHandler);
    return () => {
      window.removeEventListener("popstate", backButtonHandler);
    };
  }, [backButtonHandler]);

  const initialize = async (isUpdate: boolean = false) => {
    setIsLoading(true);
    try {
      const response = await initializeCart({
        tokenId: searchParams.get("tokenId"),
        analytics,
        userContext,
        authContext,
        checkoutContext,
        merchantContext,
        isUpdate,
      });
      if (response !== undefined) {
        const { checkoutResponse, accountResponse, tokenData } = response;
        handleResponseSuccess(checkoutResponse, accountResponse, tokenData);
      }
    } catch (error: any) {
      if (error?.response?.status === 403) logout(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResponseSuccess = (checkoutResponse: any, accountResponse: any, tokenData: any) => {
    handleAccountResponse(accountResponse, checkoutResponse, tokenData);
    handleCheckoutResponse(checkoutResponse);
  };

  const handleCheckoutResponse = (checkoutResponse: any) => {
    updateCheckoutBasedOnCheckoutResponse(checkoutResponse, false, true);
    updateMarketingConsent();
  };

  const updateMarketingConsent = async () => {
    if (marketingConsent === undefined) return;
    if (!Boolean(isAuthenticated)) return;
    try {
      await putRequest("/attributes/v1/account-attributes", {
        attributes: {
          marketing_consent: marketingConsent,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleAccountResponse = (accountResponse: any, checkoutResponse: any, tokenData: any) => {
    if (!Boolean(accountResponse) || isEmptyObj(accountResponse)) {
      return;
    }

    const parsedUserData: UserType = parseUserData(accountResponse, checkoutResponse);
    setUserData(parsedUserData);
  };

  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    const mainContent = mainContentRef.current;
    if (!mainContent) return;

    const checkIfScrollable = () => {
      setIsScrollable(mainContent.scrollHeight > mainContent.clientHeight);
    };

    checkIfScrollable();
  });

  const getCartPrimaryCTAText = () => {
    return (
      checkoutUIMetadata?.primaryCTAConfig?.find((config: { type: string }) => config.type === "CART")
        ?.ctaText ?? "Checkout"
    );
  };

  const mileStonesAmount =
    checkoutUIMetadata?.progressBarConfig?.milestones?.map((milestone) =>
      Number(milestone.milestone_amount),
    ) ?? [];

  const handleMilestoneReached = (milestone: Milestone) => {
    if (isBackgroundLoad) return;
    analytics.sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_CHECKOUT_MILESTONE_REACHED,
      eventType: "flo_action",
      metaData: {
        milestone,
        milestones: checkoutUIMetadata?.progressBarConfig?.milestones,
        progressBarCategory: checkoutUIMetadata?.progressBarConfig?.customizations?.category,
        progressBarType: checkoutUIMetadata?.progressBarConfig?.customizations?.type,
      },
    });
  };

  if (isLoading && !checkoutId) {
    return <Skeleton />;
  }

  return (
    <div className="items-between flex h-full flex-col bg-white relative">
      {Boolean(checkoutUIMetadata?.progressBarConfig?.isEnabled) && (
        <CartConfetti
          total={isCountBased ? itemCountForProgressBar : billing?.sub_total}
          mileStonesAmount={mileStonesAmount}
          previousTotal={isCountBased ? previousTotalItems.current : previousTotalPrice.current}
          isCountBased={isCountBased}
        />
      )}
      <CartHeader totalItems={totalItems} />
      <CartBannerStrip bannerPosition={BannerType.HEADER} />
      {checkoutItems?.length > 0 ? (
        <>
          <div
            className="flex flex-1 flex-col gap-3 overflow-auto overflow-x-hidden pt-3 pb-5"
            ref={mainContentRef}>
            {checkoutUIMetadata &&
              checkoutUIMetadata.progressBarConfig &&
              checkoutUIMetadata.progressBarConfig.isEnabled && (
                <>
                  <ProgressBar
                    total={isCountBased ? itemCountForProgressBar : billing?.sub_total}
                    milestones={checkoutUIMetadata.progressBarConfig.milestones}
                    customizations={checkoutUIMetadata.progressBarConfig.customizations}
                    onMilestoneReached={handleMilestoneReached}
                  />
                </>
              )}
            <div className="flex flex-col gap-3  px-3 pb-5">
              <CartItems
                items={checkoutItems}
                disableOOSItems={false}
                checkoutItemsMutable={true}
                isUpdating={isLoading}
                setIsUpdating={setIsLoading}
                availableAddOns={availableAddOns}
                cartLevelAddOns={appliedCartAddOns}
              />
              {(hasAutoAppliedCoupon(appliedCoupons) || Boolean(merchant?.isDiscountEnabled)) && (
                <Coupons context="cart" />
              )}
              {Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled) ||
              Boolean(
                isAuthenticated && wishlistConfig?.isEnabled && wishlistConfig?.config?.cart?.enabled,
              ) ? (
                <Suspense fallback={<OverlaySpinner />}>
                  <UpSellAndWishlistSection parent="CART" />
                </Suspense>
              ) : (
                <></>
              )}
              <Rewards context="cart" />
              <MarketingConsent customClass="!pt-4 !p-0 !h-2 justify-start" />
            </div>
          </div>
          <CartFooter
            setDialog={setCartDialog}
            price={billing?.total_payable}
            checkoutId={checkoutId}
            isLoading={isLoading}
            showShadow={isScrollable}
            primaryCTAText={getCartPrimaryCTAText()}
            availableAddOns={availableAddOns}
            cartLevelAddOns={appliedCartAddOns}
            setIsUpdating={setIsLoading}
          />

          <BillingSummaryDialog
            open={cartDialog === "billingDetails"}
            setDialog={setCartDialog}
            isLoading={isLoading}
          />
          <CartAuthenticationDialog
            open={cartDialog === "authentication" || cartDialog === "cartAuthentication"}
            setDialog={setCartDialog}
          />
          <CartOtpVerficationDialog
            open={cartDialog === "otpVerification" || cartDialog === "cartOtpVerification"}
            setDialog={setCartDialog}
          />
        </>
      ) : (
        <div className="flex h-full w-full flex-col">
          <div className="flex flex-1 flex-col items-center justify-center space-y-6">
            <div className="flex flex-col items-center space-y-2">
              <img src={cartImage} className="" alt="cart" />
              <div className="text-sm font-medium text-coal-light ">{t("cart_is_empty")}</div>
            </div>
            <PrimaryButton
              buttonText={t("continue_shopping")}
              containerClassName="p-4"
              width="w-[228px]"
              isLoading={isLoading}
              onClick={closeCart}
            />
          </div>
          <img src={TrustBadgeGray} className="mb-14 h-4" alt={"shopflo-logo"} />
        </div>
      )}
      {/*isUpdate && <OverlaySpinner /> */}
    </div>
  );
};

export default Cart;

function CartHeader({ totalItems }: { totalItems?: number }) {
  const { t } = useLocale();
  const {
    actions: { setCheckoutModal },
  } = useCheckoutContext();
  return (
    <div className="flex w-full items-center justify-between px-6 pb-4 pt-6">
      <div className="flex h-6 items-center gap-2 font-medium text-coal-dark">
        <ShoppingCart className="h-4 w-4" />
        <div className="flex gap-1">
          <span>{t("your_cart")}</span>
          {Boolean(totalItems) && (
            <>
              <span>·</span>
              <span className="font-normal text-coal-light">{t("n_items", { items: totalItems })}</span>
            </>
          )}
        </div>
      </div>
      <button
        onClick={() => {
          setCheckoutModal("NONE");
          publishPostMessage(eventTypes.CLOSE_CART_IFRAME, {});
        }}>
        <X className="h-6 w-6 text-coal-dark" />
      </button>
    </div>
  );
}

function CartItems({
  items,
  disableOOSItems,
  checkoutItemsMutable,
  isUpdating = false,
  setIsUpdating,
  availableAddOns,
  cartLevelAddOns,
}: {
  items: any[];
  disableOOSItems: boolean;
  checkoutItemsMutable: boolean;
  isUpdating?: boolean;
  setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
  availableAddOns: Record<string, AddOnMetaType>;
  cartLevelAddOns: AddOnType[];
}) {
  const { handleAddOnEdit, isLoading } = useItemEdit({
    setIsUpdating,
    isCartItem: true,
  });

  const isAddOnLineItemsVisible = showAddOnLineItems(availableAddOns, cartLevelAddOns);
  const itemLevelAddons = Object.values(availableAddOns)?.filter((item) => item?.addon_level === "ITEM");
  const filteredCartAppliedAddOns = cartLevelAddOns?.filter((item) =>
    ["CART", "DEFAULT"].includes(availableAddOns?.[item?.addon_id]?.ui_asset ?? ""),
  );

  return (
    <ul className="flex w-full flex-col space-y-3">
      <li>
        {isAddOnLineItemsVisible &&
          filteredCartAppliedAddOns?.map((item) => {
            return (
              <AddOnItem
                key={item?.addon_id}
                disableActions={isLoading}
                variant="CART"
                addOnData={availableAddOns[item?.addon_id]}
                handleAddOnDelete={() =>
                  handleAddOnEdit({
                    action: "DELETE",
                    addOnLevel: "CART",
                    addOnId: item?.addon_id,
                  })
                }
              />
            );
          })}
      </li>
      {items?.map((item: ItemType, index: number) => (
        <li key={`${item.item_id}_${index}`}>
          <ItemCard
            {...item}
            disableOOSItems={disableOOSItems}
            checkoutItemsMutable={checkoutItemsMutable}
            isCartItem={true}
            isUpdating={isUpdating}
            setIsUpdating={setIsUpdating}
            itemLevelAddons={itemLevelAddons ?? []}
          />
        </li>
      ))}
    </ul>
  );
}

function CartFooter({
  setDialog,
  price,
  checkoutId,
  isLoading = false,
  showShadow,
  primaryCTAText = "Checkout",
  availableAddOns,
  cartLevelAddOns,
  setIsUpdating,
}: {
  setDialog: (dialog: CartDialogType) => void;
  price?: number;
  checkoutId: string;
  isLoading?: boolean;
  showShadow?: boolean;
  primaryCTAText: string;
  availableAddOns?: Record<string, AddOnMetaType>;
  cartLevelAddOns: AddOnType[];
  setIsUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: { checkoutItems, appliedCoupons, billing },
  } = useCheckoutContext();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const { handleAddOnEdit } = useItemEdit({
    setIsUpdating,
    isCartItem: true,
  });

  const { t } = useLocale();

  const handleAuthenticated = async () => {
    try {
      await putRequest(`/checkout/v1/cart/${checkoutId}/checkout`, {});
      publishPostMessage(eventTypes.CART_TO_CHECKOUT, {
        checkoutId: checkoutId,
        lineItems: checkoutItems,
        cartTotal: billing.total_payable,
        coupons: appliedCoupons,
      });
    } catch (error) {
      console.error("error in going to checkout", error);
      errorToast("Failed loading checkout");
    }
  };

  const handleCheckoutClick = () => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_CHECKOUT_CLICKED,
      eventType: "click",
    });
    if (isAuthenticated) {
      handleAuthenticated();
      return;
    }
    if (inIframe() && !isThirdPartyCookieEnabled()) {
      let redirectUrl = new URL(document.location.href);
      let checkoutUrlSearchParams = new URLSearchParams(redirectUrl.search);
      checkoutUrlSearchParams.delete("page");
      checkoutUrlSearchParams.delete("referrer");
      checkoutUrlSearchParams.append("checkoutId", checkoutId);
      checkoutUrlSearchParams.append("referrer", "cart");
      redirectUrl.search = checkoutUrlSearchParams.toString();
      publishPostMessage(eventTypes.PARENT_REDIRECT, {
        redirectUrl: redirectUrl.href,
        clearCart: false,
      });
      return;
    }
    setDialog("authentication");
  };

  const renderCartTotal = useMemo(() => {
    const formattedPrice = currencyFormatter(price);
    const wholePart = formattedPrice?.split(".")[0];
    const decimalPart = formattedPrice?.split(".")[1];
    return (
      <div className="text-[18px] font-semibold text-coal-dark">
        <span>{wholePart}</span>
        {Boolean(decimalPart) && (
          <>
            <span>.</span>
            <span className="text-sm font-normal">{decimalPart}</span>
          </>
        )}
      </div>
    );
  }, [price]);

  const addOnsList = Object.values(availableAddOns ?? {}).filter(
    (item) => item?.addon_level === "CART" && ["CART", "DEFAULT"].includes(item?.ui_asset),
  );

  return (
    <div>
      <CartBannerStrip bannerPosition={BannerType.FOOTER} />
      {addOnsList.map((item, index) => (
        <div
          key={item.addon_id}
          className={showShadow && index === 0 ? "shadow-[0px_-7px_8px_0px_#0000001A]" : ""}>
          <AddOnCheckboxTile
            apiActionLoading={isLoading}
            key={item?.addon_id}
            disableOnClick
            variant="CART"
            addOnData={item}
            isChecked={cartLevelAddOns.some((addOn) => addOn?.addon_id === item?.addon_id) ?? false}
            handleCheckBoxClick={(value) => {
              handleAddOnEdit({
                action: value ? "ADD" : "DELETE",
                addOnLevel: "CART",
                addOnId: item?.addon_id,
              });
            }}
          />
        </div>
      ))}
      <div
        className={classNames(
          "flex flex-col space-y-3 px-3 pb-6 pt-4",
          showShadow && !addOnsList?.length ? "shadow-[0px_-1px_8px_0px_#0000001A]" : "",
        )}>
        <div className="item-center flex justify-between space-x-6">
          <div
            className="flex flex-col justify-center cursor-pointer"
            onClick={() => {
              setDialog("billingDetails");
            }}>
            {renderCartTotal}
            <button
              className="truncate text-left text-[12px] text-coal-dark underline decoration-underline underline-offset-2"
              onClick={() => {
                setDialog("billingDetails");
              }}>
              {t("estimated_total")}
            </button>
          </div>
          <PrimaryButton
            containerClassName="min-w-40"
            buttonText={primaryCTAText}
            onClick={handleCheckoutClick}
            isLoading={isLoading}
          />
        </div>
        <img src={TrustBadgeGray} className="h-4" alt={"shopflo-logo"} />
      </div>
    </div>
  );
}
