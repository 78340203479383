import { useLocale } from "lib/hooks/useLocale";
import { NetworkLoyaltyType } from "lib/types/checkout";
import React, { useEffect, useState } from "react";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import YotpoLoyalty from "./network-loyalty-widgets/YotpoLoyalty";
import NetworkLoyalty from "./network-loyalty-widgets/NetworkLoyalty";
import useRewards from "lib/hooks/useRewards";
import { RewardsMode } from "lib/types/rewards";
import { AnimateLoading } from "components/animation/AnimateLoading";

interface LoyaltyPointsProps {
  context?: "cart" | "checkout";
}

const STANDARD_LOYALTY_PROVIDERS = ["POP_COINS", "NECTOR", "DAEIOU_JEWELS"] as const;

const LoyaltyPoints: React.FC<LoyaltyPointsProps> = ({ context = "checkout" }) => {
  const { t } = useLocale();
  const {
    state: { appliedGiftCards, appliedCoupons },
  } = useCheckoutContext();

  const {
    state: { mode, rewardsConfig, isTileLoading, error },
  } = useRewards();

  const [isUnusable, setIsUnusable] = useState<boolean>(true);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [loyaltyDetails, setLoyaltyDetails] = useState<NetworkLoyaltyType>();
  const [selectedEmail, setSelectedEmail] = useState<string>("");

  useEffect(() => {
    if (mode !== RewardsMode.LOYALTY || rewardsConfig.mode !== RewardsMode.LOYALTY) return;

    const loyaltyData = rewardsConfig.data;
    if (!loyaltyData?.provider) return;

    if (loyaltyData.provider === "YOTPO") {
      setLoyaltyDetails({
        provider: loyaltyData.provider,
        value: loyaltyData.value,
        isVerified: loyaltyData.isVerified,
        accountExists: loyaltyData.accountExists,
      });
      setSelectedEmail(loyaltyData.value?.email);
      setIsVerified(loyaltyData.isVerified);
      setIsUnusable(!loyaltyData.accountExists);
    } else if (STANDARD_LOYALTY_PROVIDERS.includes(loyaltyData.provider)) {
      setLoyaltyDetails({
        provider: loyaltyData.provider,
        value: loyaltyData.value,
      });
      setIsUnusable(false);
    }
  }, [appliedCoupons, appliedGiftCards, rewardsConfig]);

  const getRenderComponent = () => {
    switch (loyaltyDetails?.provider) {
      case "YOTPO":
        return (
          <YotpoLoyalty
            context={context}
            loyaltyDetails={loyaltyDetails.value}
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
            isUnusable={isUnusable}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
          />
        );
      case "POP_COINS":
      case "NECTOR":
      case "DAEIOU_JEWELS":
        return (
          <NetworkLoyalty context={context} isUnusable={isUnusable} loyaltyDetails={loyaltyDetails?.value} />
        );
      default:
        return <></>;
    }
  };

  if (Boolean(error)) return <></>;
  if (!loyaltyDetails || !Boolean(loyaltyDetails)) return <></>;

  return (
    <AnimateLoading loading={isTileLoading}>
      <AnimateLoading.Content>{getRenderComponent()}</AnimateLoading.Content>
    </AnimateLoading>
  );
};

export default LoyaltyPoints;
