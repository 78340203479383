import { useEffect, useState } from "react";
import { Checkbox } from "@shopflo/ui";
import { AddOnLevelType, AddOnMetaType, AddOnType } from "lib/types/checkout";
import { truncateString } from "lib/utils/helpers";
import defaultPackageImage from "assests/images/package.png";
import { currencyFormatter } from "lib/utils/formatters";

export const AddOnCheckboxTile = ({
  apiActionLoading,
  addOnData,
  variant,
  isChecked,
  handleCheckBoxClick,
  disableOnClick = false,
}: {
  addOnData: AddOnMetaType;
  variant: AddOnLevelType;
  isChecked: boolean;
  handleCheckBoxClick?: (value: boolean) => void;
  disableOnClick?: boolean;
  apiActionLoading?: boolean;
}) => {
  const uiConfig = {
    ITEM: {
      imageStyle: "h-8 w-8 rounded-md",
      containerStyle:
        "add-on-list-item flex justify-between items-center gap-2 p-[10px] bg-gradient-to-r from-gray-lighter to-gray-light border-t-[1px] border-t-gray-light cursor-pointer min-h-[53px]",
    },
    CART: {
      imageStyle: "h-6 w-6 rounded-md",
      containerStyle: "flex justify-between items-center gap-2 p-[10px] cursor-pointer",
    },
  };

  const [checked, setChecked] = useState(isChecked);
  const [disabled, setDisabled] = useState(false);


  // Need to enable the checkbox after API call is complete
  useEffect(() => {
    if (!apiActionLoading) {
      setDisabled(false);
      setChecked(isChecked);
    }
  }, [apiActionLoading]);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    if (disableOnClick) {
      setDisabled(true);
    }
    handleCheckBoxClick?.(!checked);
    setChecked((prev) => !prev);
  };
  
  return (
    <div className={uiConfig[variant].containerStyle} onClick={handleClick}>
      <div className="flex items-center gap-2">
        <span>
          <Checkbox
            size="sm"
            variant={variant === "ITEM" ? "secondary" : "primary"}
            checked={checked}
            disabled={disabled}
          />
        </span>
        <p
          dangerouslySetInnerHTML={{
            __html:
              addOnData?.description
                ?.replaceAll("{{product_title}}", truncateString(addOnData?.product_name, 40))
                ?.replaceAll("{{price}}", currencyFormatter(addOnData?.price?.toString())) ?? "",
          }}
          className="text-sm font-medium text-coal-dark"></p>
      </div>
      {addOnData?.show_image && (
        <img
          src={addOnData?.image ?? ""}
          alt={"add-on-image"}
          className={uiConfig[variant].imageStyle}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultPackageImage;
          }}
        />
      )}
    </div>
  );
};
