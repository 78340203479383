import React, { useEffect, useState, Suspense, useMemo } from "react";
import { useLocale } from "lib/hooks/useLocale";
import GenericButton from "components/common/buttons/GenericButton";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { useUserContext } from "lib/contexts/UserProvider";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import { Truck, Edit, ArrowRight, MapPin } from "react-feather";
import AddressModify from "components/address/dialogs/AddressModify";
import AddressForm from "components/address/dialogs/AddressForm";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { getDefaultShippingHandle, hasAutoAppliedCoupon } from "lib/utils/checkout";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import OOSDialog from "components/checkout/dialogs/OOSDialog";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  capitalizeFirstCharacter,
  checkMandatoryFields,
  truncateString,
  setPageClarityTags,
  classNames,
} from "lib/utils/helpers";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import Coupons from "components/checkout/Coupons";
import ShippingHandlesSection from "components/checkout/dialogs/ShippingHandlesSection";
import AddressListDialog from "components/address/dialogs/AddressListDialog";
import { AddressCardFields } from "lib/types/address";
import AddressSection from "components/address/dialogs/AddressSection";
import { getETDText } from "lib/utils/merchant.ts";
import { getRequest } from "lib/core/apiClient.ts";
import { useAddressETD } from "lib/hooks/useAddressETD.ts";
import Rewards from "components/checkout/Rewards.tsx";
import { AddOnCard } from "components/add-on/AddOnCard.tsx";
import { useItemEdit } from "lib/hooks/useItemEdit.ts";
import { useCheckoutErrors } from "lib/hooks/useCheckoutErrors";
import { DeliveryCard } from "components/address/AddressETD.tsx";

import { PaymentSkeleton } from "components/common/skeleton/PaymentSkeleton.tsx";
import { AnimateLoading } from "components/animation/AnimateLoading.tsx";
import BillingAddress from "components/address/BillingAddress";
import Payments from "components/payments/Payments";
import UpSellSection from "../../upsell/UpSellSection.tsx";
import { useMerchantUiConfig } from "lib/contexts/MerchantUIConfigProvider.tsx";
import { AnimateExit } from "components/animation/AnimateExit.tsx";
import UpsellSkeleton from "components/common/skeleton/UpsellSkeleton.tsx";
import UpSellAndWishlistSection from "../../upsell/UpSellSection.tsx";
import { SelectedShippingHandleType } from "lib/types/checkout.ts";
interface AuthenticatedViewsProps {
  isPrimaryBtnLoading?: boolean;
}
const ShippingHandlesDialog = React.lazy(() => import("../../checkout/dialogs/ShippingHandlesDialog.tsx"));

const AuthenticatedViews: React.FC<AuthenticatedViewsProps> = ({ isPrimaryBtnLoading = false }) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const {
    state: {
      isC2P,
      checkoutView,
      checkoutModal,
      isTwoStepCheckout,
      checkoutUIMetadata,
      initialCheckoutStep,
      appliedCoupons,
      checkoutValidations,
      uid,
      availableAddOns,
      appliedCartAddOns,
      checkoutId,
      checkoutLoading,
    },
    actions: {
      setCheckoutModal,
      setCheckoutView,
      setCheckoutValidations,
      setEtdText,
      setInitialCheckoutStep,
    },
  } = useCheckoutContext();
  const {
    state: { user },
  } = useUserContext();
  const {
    state: { merchant, addressConfig },
  } = useMerchantContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const {
    state: {
      checkoutMetadata: { default_view },
    },
  } = useMerchantUiConfig();

  const { handleAddOnEdit, showFullScreenLoader } = useItemEdit();

  const { isNotServicable } = useCheckoutErrors();

  const selectedAddressId = user?.default_shipping_address?.id ? user?.default_shipping_address?.id : "";
  const { hash: hashParam } = useLocation();
  const { isETDEnabled } = useAddressETD(selectedAddressId);
  const [editAddressId, setEditAddressId] = useState<string>("ADDRESS_NEW");
  const [initialLoad, setInitialLoad] = useState(false);

  const deliveryConfig = merchant?.addressConfig?.deliveryConfig;

  const cartAddons = useMemo(
    () =>
      Object.values(availableAddOns)?.filter(
        (item) => item?.addon_level === "CART" && ["CHECKOUT", "DEFAULT"].includes(item?.ui_asset),
      ),
    [availableAddOns],
  );

  useEffect(() => {
    if (Boolean(hashParam?.length)) {
      handleHashParams();
      return;
    }
    const defaultView = searchParams.get("default_view");
    if (defaultView === "PAYMENTS") {
      setInitialCheckoutStep("PAYMENTS");
      setCheckoutView("PAYMENTS");
    } else {
      setCheckoutView("ADDRESS_LIST");
    }
  }, []);

  useEffect(() => {
    if (Boolean(isC2P) || initialCheckoutStep === "PAYMENTS") {
      setCheckoutView("PAYMENTS");
      verifyDefaultAddress();
      if (Boolean(user?.addresses?.length)) {
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_ADDRESS_SELECTED,
          eventType: "click",
        });
      }
    }
  }, [isC2P, initialCheckoutStep]);

  useEffect(() => {
    if (isTwoStepCheckout) return;
    if (checkoutModal === "ADDRESS_NEW") {
      return;
    }
    setPageClarityTags(checkoutView);
  }, [checkoutView]);

  useEffect(() => {
    if (isTwoStepCheckout) return;
    if (user?.uid && !Boolean(user?.addresses)) {
      setCheckoutModal("ADDRESS_NEW");
      return;
    }
    if (checkoutModal === "ADDRESS_NEW" && user?.addresses) {
      setCheckoutModal("NONE");
    }
  }, [user]);

  const handleHashParams = () => {
    const hashValue = hashParam.slice(1);
    switch (hashValue) {
      case "PAYMENTS":
      case "COD":
        setCheckoutView("PAYMENTS");
        return;
      default:
        return;
    }
  };

  const verifyDefaultAddress = async () => {
    const selectedAddressId = user?.default_shipping_address?.id ? user?.default_shipping_address?.id : "";
    const selectedAddress = user?.addresses?.find(
      (address: AddressCardFields) => address?.id === selectedAddressId,
    );
    if (Boolean(isETDEnabled)) {
      const etdResponse = await getRequest(
        `/checkout/${uid}/address/${selectedAddressId}/etd`,
        "KRATOS_PRIVATE",
      );
      setEtdText({ addressId: selectedAddressId, text: getETDText(etdResponse) });
    }
    if (!selectedAddress) {
      return;
    }
    const addressValidity = checkMandatoryFields(selectedAddress, addressConfig, checkoutId);
    if (!addressValidity.available) {
      handleEditAddress(selectedAddressId);
      setCheckoutValidations({
        ...checkoutValidations,
        address: {
          isValid: false,
          metadata: {
            addressId: selectedAddressId,
            fieldData: addressValidity?.errorFieldData,
          },
        },
      });
      return;
    }
    setCheckoutValidations({
      ...checkoutValidations,
      address: {
        isValid: true,
      },
    });
  };

  const handleEditAddress = (id: string) => {
    setEditAddressId(id);
    setCheckoutModal("ADDRESS_EDIT");
  };

  const handleDeliveryAddressBtnClick = () => {
    if (isC2P) return;
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_DELIVERY_ADDRESS_CHANGE_CLICKED,
      eventType: "click",
    });
    setCheckoutView("ADDRESS_LIST");
  };

  const addressSkeleton = (
    <div className="bg-white space-y-2">
      <div className="skeleton-loader h-[16px] rounded-md w-2/5"></div>
      <div className="skeleton-loader h-[16px] rounded-md w-full"></div>
    </div>
  );

  const deliverySkeleton = (
    <div className="border-base px-3 py-4 bg-white space-y-2">
      <div className="skeleton-loader h-[16px] rounded-md w-2/5"></div>
      <div className="skeleton-loader h-[16px] rounded-md w-full"></div>
    </div>
  );

  const billingSkeleton = (
    <div className="border-base px-3 py-4 bg-white">
      <div className="skeleton-loader h-[16px] rounded-md w-full"></div>
    </div>
  );

  const renderAddressList = () => {
    return (
      <>
        <div className="border-base mx-3 mt-3 flex flex-col bg-white">
          <div
            className={`flex w-full flex-col gap-4 overflow-x-hidden rounded-b-[1.75rem] px-3 pt-4 ${
              isTwoStepCheckout ? "pb-4" : "pb-3"
            }`}>
            <AnimateLoading loading={checkoutLoading}>
              <AnimateLoading.Skeleton>{addressSkeleton}</AnimateLoading.Skeleton>
              <AnimateLoading.Content>
                <div className="flex w-full items-center justify-between space-x-2">
                  <div className="flex space-x-2">
                    <Truck className="h-4 w-4 text-coal-dark outline-none" strokeWidth={2.5} />
                    <h2 className="text-sm font-medium text-coal-dark">{t("delivering_to_address")}</h2>
                  </div>
                  {deliveryConfig?.isEnabled && (
                    <p className="flex w-1/2 grow justify-end overflow-hidden text-ellipsis text-sm font-normal text-yay-dark">
                      <span className="truncate">{deliveryConfig?.deliveryText}</span>
                    </p>
                  )}
                </div>
                <GenericButton
                  height="h-12"
                  width="w-full"
                  id="flo_add__address"
                  customClass="mt-4 text-sm font-medium text-coal-dark space-x-2 font-medium border-[1px] border-gray-light rounded-2xl px-3 flex items-center justify-start hover:ring-gray-light hover:ring-[2px]"
                  buttonText={t("add_new_address_button")}
                  iconComponent={<PlusCircleIcon className="h-5 w-5 text-yay-dark outline-none" />}
                  onClick={() => {
                    setEditAddressId("ADDRESS_NEW");
                    setCheckoutModal("ADDRESS_NEW");
                  }}
                />
              </AnimateLoading.Content>
            </AnimateLoading>
            <div>
              <div className="flex flex-col gap-4">
                <AddressModify
                  handleEditAddress={handleEditAddress}
                  isPrimaryBtnLoading={isPrimaryBtnLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {checkoutView === "ADDRESS_LIST" && renderAddressList()}

      {checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS" && (
        <>
          <div className="flex flex-col space-y-3 mx-3 mt-3">
            <DeliveryCard handleDeliveryAddressBtnClick={handleDeliveryAddressBtnClick} />
            {/* Billing Address */}
            <AnimateLoading loading={checkoutLoading}>
              <AnimateLoading.Skeleton>{billingSkeleton}</AnimateLoading.Skeleton>
              <AnimateLoading.Content
                motionProps={{
                  className: "!mt-0",
                }}>
                <BillingAddress />
              </AnimateLoading.Content>
            </AnimateLoading>
            {merchant?.showShippingOnPayments && (
              <div className="w-full">
                <ShippingHandlesSection loadPayments={() => setInitialLoad(true)} />
              </div>
            )}
            <Rewards />
            <AnimateExit
              isChildVisible={Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled) && !isC2P}
              loading={checkoutLoading}>
              <AnimateExit.Skeleton>
                <UpsellSkeleton />
              </AnimateExit.Skeleton>
              <AnimateExit.Content
                motionProps={{
                  className: "!mt-0",
                }}>
                <UpSellSection />
              </AnimateExit.Content>
            </AnimateExit>
            <AnimateLoading loading={checkoutLoading} size="sm">
              <AnimateLoading.Content
                motionProps={{
                  className: "!mt-0",
                }}>
                {!isC2P && (
                  <div className={classNames("space-y-3", cartAddons?.length > 0 ? "mt-3" : "")}>
                    {cartAddons?.map((item) => (
                      <AddOnCard
                        key={item?.addon_id}
                        addOnData={item}
                        isApplied={appliedCartAddOns?.some((addOn) => addOn?.addon_id === item?.addon_id)}
                        handleAction={(value) =>
                          handleAddOnEdit({
                            action: value ? "ADD" : "DELETE",
                            addOnLevel: "CART",
                            addOnId: item?.addon_id,
                            showFullScreenLoader: true,
                          })
                        }
                      />
                    ))}
                  </div>
                )}
              </AnimateLoading.Content>
            </AnimateLoading>
            <Suspense fallback={<PaymentSkeleton showGyftrLoading={true} />}>
              <Payments />
            </Suspense>
          </div>
        </>
      )}
      {checkoutView === "PAYMENTS" && initialCheckoutStep === "PAYMENTS" && (
        <>
          <div className="flex flex-col space-y-3 mx-3 mt-3">
            <AnimateLoading loading={checkoutLoading}>
              <AnimateLoading.Skeleton>{deliverySkeleton}</AnimateLoading.Skeleton>
              <AnimateLoading.Content>
                <AddressSection />
              </AnimateLoading.Content>
            </AnimateLoading>
            <AnimateLoading loading={checkoutLoading}>
              <AnimateLoading.Skeleton>{billingSkeleton}</AnimateLoading.Skeleton>
              <AnimateLoading.Content
                motionProps={{
                  className: "!mt-0",
                }}>
                <BillingAddress />
              </AnimateLoading.Content>
            </AnimateLoading>
            <AnimateLoading loading={checkoutLoading}>
              <AnimateLoading.Skeleton>{deliverySkeleton}</AnimateLoading.Skeleton>
              <AnimateLoading.Content>
                <ShippingHandlesSection loadPayments={() => setInitialLoad(true)} />
              </AnimateLoading.Content>
            </AnimateLoading>
            <div className={`flex w-full flex-row  items-center justify-between text-xs`}>
              <h2 className="text-sm font-medium text-coal-light">{t("offers_and_rewards")}</h2>
            </div>
            <AnimateExit
              isChildVisible={Boolean(hasAutoAppliedCoupon(appliedCoupons) || merchant?.isDiscountEnabled)}
              loading={checkoutLoading}>
              <AnimateExit.Skeleton>{deliverySkeleton}</AnimateExit.Skeleton>
              <AnimateExit.Content>
                <Coupons />
              </AnimateExit.Content>
            </AnimateExit>
            {!initialLoad ? deliverySkeleton : <Rewards />}
            <AnimateExit
              isChildVisible={
                initialCheckoutStep === "PAYMENTS" &&
                Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled) &&
                !isC2P
              }
              loading={!initialLoad}>
              <AnimateExit.Skeleton>
                <UpsellSkeleton />
              </AnimateExit.Skeleton>
              <AnimateExit.Content
                motionProps={{
                  className: "!mt-0",
                }}>
                <UpSellAndWishlistSection />
              </AnimateExit.Content>
            </AnimateExit>
            <AnimateLoading loading={checkoutLoading} size="sm">
              <AnimateLoading.Content
                motionProps={{
                  className: "!mt-0",
                }}>
                {!isC2P && (
                  <div className={classNames("space-y-3", cartAddons?.length > 0 ? "mt-3" : "")}>
                    {cartAddons?.map((item) => (
                      <AddOnCard
                        key={item?.addon_id}
                        addOnData={item}
                        isApplied={appliedCartAddOns?.some((addOn) => addOn?.addon_id === item?.addon_id)}
                        handleAction={(value) =>
                          handleAddOnEdit({
                            action: value ? "ADD" : "DELETE",
                            addOnLevel: "CART",
                            addOnId: item?.addon_id,
                            showFullScreenLoader: true,
                          })
                        }
                      />
                    ))}
                  </div>
                )}
              </AnimateLoading.Content>
            </AnimateLoading>
            {!initialLoad ? <PaymentSkeleton showGyftrLoading={true} /> : <Payments />}
          </div>
        </>
      )}

      <GenericDialog
        isOpen={checkoutModal === "ADDRESS_LIST"}
        setIsOpen={() => {
          setCheckoutModal("NONE");
        }}
        translateAxis="y"
        dialogOverlay={true}
        customClass="h-[92%] md:h-[77vh] md:top-[8vh]"
        modalType="ADDRESS_LIST">
        <AddressListDialog
          setIsOpen={() => setCheckoutModal("NONE")}
          isPrimaryBtnLoading={isPrimaryBtnLoading}
        />
      </GenericDialog>

      <GenericDialog
        isOpen={checkoutModal === "ADDRESS_NEW" || checkoutModal === "ADDRESS_EDIT"}
        setIsOpen={() => {
          setCheckoutModal("NONE");
          setInitialLoad(true);
        }}
        translateAxis="y"
        dialogOverlay={true}
        customClass="h-[92%] md:h-[77vh] md:top-[8vh]"
        modalType="ADDRESS_NEW">
        <AddressForm
          addressId={editAddressId}
          initialFormErrors={checkoutValidations.address?.metadata?.fieldData}
        />
      </GenericDialog>

      <GenericDialog
        isOpen={checkoutModal === "SHIPPING_HANDLES"}
        setIsOpen={() => setCheckoutModal("NONE")}
        translateAxis="y"
        customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
        dialogOverlay={true}
        modalType="SHIPPING_HANDLES">
        <ShippingHandlesDialog />
      </GenericDialog>

      <GenericDialog
        isOpen={checkoutModal === "OOS"}
        setIsOpen={() => setCheckoutModal("NONE")}
        translateAxis="y"
        dialogOverlay={true}
        customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
        modalType="OOS">
        <OOSDialog />
      </GenericDialog>
      {showFullScreenLoader && <OverlaySpinner />}
    </>
  );
};

export default AuthenticatedViews;
