import i18n from "assests/locales/i18n";
import { checkIfHasServiceabilityError } from "lib/hooks/useCheckoutErrors";
import { CartDialogType } from "lib/types/cart";
import {
  ActionUrlsType,
  BillingType,
  CheckoutValidationsType,
  CheckoutComponentType,
  CheckoutModalType,
  CheckoutUIMetadataType,
  CheckoutViewType,
  ExitSurveyOptionType,
  FloWalletRedemptionHandle,
  InitialCheckoutStepType,
  ItemType,
  LoyaltyRedemptionHandle,
  OrderSummaryStyleType,
  ShippingHandleType,
  ShippingHandlesType,
  CheckoutExpiredType,
  DiscountedProductSelectorDataType,
  DiscountedProductSelectorPayloadType,
  WishlistConfigType,
  DiscountProductSelectorModalType,
  AddOnType,
  AddOnMetaType,
  CheckoutErrorResponse,
} from "lib/types/checkout";
import {
  getAppliedFloWalletData,
  getAppliedLoyaltyData,
  getBillingDetails,
  getCouponData,
  getDiscountedProductSelectorData,
  getGiftCardData,
  getItems,
  getRedirectUrl,
  parseCheckoutUIMetadata,
  parseCartUIMetadata,
  showShippingChangeToast,
  getDiscountedProductSelectorPayload,
  getWishlistConfig,
} from "lib/utils/checkout";
import { apiURI } from "lib/utils/constants";
import { getIsTwoStepCheckout } from "lib/utils/merchant";
import { errorToast, successToast } from "lib/utils/toasters";
import { createContext, useReducer, useContext, useCallback, useMemo, useEffect } from "react";
import { mutate } from "swr";

export interface AppliedCouponType {
  code: string;
  title: string; //Will be null for all cases except freebies
  autoApplied: boolean;
  isManualCode: boolean;
  isFreebie: boolean;
  discountValue: number;
  isRemovable: boolean;
  freebieItemCount: number;
  isPrepaid: boolean;
  prepaidValue: number;
  discountPercentage: number;
  isReward?: boolean;
  couponRewardConfig?: {
    walletType: "CRED" | "TICKERTAPE" | string;
    amount: number;
    expiryConfig: any;
    couponId: string;
  };
  rewardData?: {
    header: string;
    description: string;
    longDescription: string[];
  };
  isProductSelector?: boolean;
}

const isCart = new URLSearchParams(window.location.search).get("page")?.toLowerCase() === "cart";

export interface AppliedGiftCardsType {
  cardId: string;
  cardValue: number;
  displayName: string;
}

const Context = createContext<CheckoutContext>({ state: {}, actions: {} } as CheckoutContext);

const defaultBillingDetails = {
  total_payable: 0,
  sub_total: 0,
  total: 0,
  tax: 0,
  rewards: 0,
  discount: 0,
  shipping: -1,
  cod: 0,
  cod_enabled: false,
  taxes_included: true,
  serviceable: true,
  prepaid_total: 0,
  prepaid_discount: 0,
  original_sub_total: 0,
  addon_total: 0,
  gift_card: 0,
};

export enum ActionType {
  SET_CHECKOUT,
  INITIALIZE,
  RESET,
  SET_LOADING,
  CHECKOUT_UPDATE,
  CHECKOUT_INIT,
  CHECKOUT_RESPONSE,
  SET_BILLING,
  SET_CHECKOUT_ID,
  SET_ORDER_ID,
  SET_APPLIED_COUPONS,
  SET_APPLIED_GIFT_CARDS,
  SET_APPLIED_LOYALTY,
  SET_APPLIED_WALLET_DATA,
  SET_REDIRECT_URL,
  SET_ORIGIN_URL,
  SET_PG_ORDER_ID,
  SET_BUY_NOW,
  SET_SHOPIFY_SESSION_ID,
  SET_CHECKOUT_TOKEN,
  SET_COUPONS,
  SET_IS_C2P,
  SET_SHIPPING_HANDLES,
  SET_CHECKOUT_VIEW,
  SET_CHECKOUT_MODAL,
  SET_IS_TWO_STEP_CHECKOUT,
  SET_OOS_ITEMS,
  SET_CHECKOUT_ITEMS,
  SET_EXIT_SURVEY_OPTIONS,
  SET_CHECKOUT_UI_METADATA,
  SET_ACTION_URLS,
  SET_DISCOUNTS_AND_REWARDS,
  SET_IS_BUY_NOW,
  SET_CHECKOUT_EXPIRED,
  SET_ACTIVE_COMPONENT,
  SET_CHECKOUT_VALIDATIONS,
  SET_IS_ORDER_SUMMARY_OPEN,
  SET_ETD_TEXT,
  SET_DISCOUNTED_PRODUCT_SELECTOR,
  SET_DISCOUNT_PRODUCT_SELECTOR_PAYLOAD,
  SET_CART_DIALOG,
  SET_DISCOUNT_PRODUCT_SELECTOR_MODAL,
  SET_SHOW_ERROR_SCREEN,
  SET_HAS_COD_MODE,
  SET_CHECKOUT_LOADING,
  SET_INITIAL_CHECKOUT_STEP,
}

export type Action =
  | { type: ActionType.SET_CHECKOUT; payload: Partial<CheckoutReducerState> }
  | { type: ActionType.INITIALIZE; payload: CheckoutReducerState }
  | { type: ActionType.RESET; payload: CheckoutReducerState }
  | { type: ActionType.CHECKOUT_INIT; payload: any } // checkout response
  | { type: ActionType.CHECKOUT_RESPONSE; payload: any } // chekcout response
  | { type: ActionType.SET_LOADING; payload: boolean }
  | { type: ActionType.SET_IS_BUY_NOW; payload: boolean }
  | { type: ActionType.SET_CHECKOUT_ID; payload: string }
  | { type: ActionType.SET_CHECKOUT_TOKEN; payload: string }
  | { type: ActionType.SET_REDIRECT_URL; payload: string }
  | { type: ActionType.SET_ORIGIN_URL; payload: string }
  | { type: ActionType.SET_ORDER_ID; payload: string }
  | { type: ActionType.SET_SHOPIFY_SESSION_ID; payload: string }
  | { type: ActionType.SET_BILLING; payload: BillingType }
  | { type: ActionType.SET_DISCOUNTS_AND_REWARDS; payload: any } // checkout response
  | { type: ActionType.SET_COUPONS; payload: any }
  | { type: ActionType.SET_CHECKOUT_VIEW; payload: CheckoutViewType }
  | { type: ActionType.SET_CHECKOUT_MODAL; payload: CheckoutModalType }
  | { type: ActionType.SET_OOS_ITEMS; payload: ItemType[] }
  | { type: ActionType.SET_CHECKOUT_ITEMS; payload: ItemType[] }
  | {
      type: ActionType.SET_SHIPPING_HANDLES;
      payload: ShippingHandlesType;
    }
  | { type: ActionType.SET_CHECKOUT_EXPIRED; payload: CheckoutExpiredType }
  | { type: ActionType.SET_ACTIVE_COMPONENT; payload: CheckoutComponentType }
  | { type: ActionType.SET_CHECKOUT_VALIDATIONS; payload: CheckoutValidationsType }
  | { type: ActionType.SET_IS_ORDER_SUMMARY_OPEN; payload: boolean }
  | { type: ActionType.SET_ETD_TEXT; payload: { addressId: string; text: string } }
  | { type: ActionType.SET_DISCOUNTED_PRODUCT_SELECTOR; payload: DiscountedProductSelectorDataType }
  | { type: ActionType.SET_DISCOUNT_PRODUCT_SELECTOR_PAYLOAD; payload: DiscountedProductSelectorPayloadType }
  | { type: ActionType.SET_DISCOUNT_PRODUCT_SELECTOR_MODAL; payload: DiscountProductSelectorModalType }
  | { type: ActionType.SET_SHOW_ERROR_SCREEN; payload: boolean }
  | { type: ActionType.SET_CART_DIALOG; payload: CartDialogType }
  | { type: ActionType.SET_HAS_COD_MODE; payload: boolean }
  | { type: ActionType.SET_CHECKOUT_LOADING; payload: boolean }
  | { type: ActionType.SET_INITIAL_CHECKOUT_STEP; payload: InitialCheckoutStepType };

export interface CheckoutReducerState {
  isLoading: boolean;
  checkoutId: string;
  orderId: string;
  billing: BillingType;
  appliedCoupons: AppliedCouponType[];
  appliedGiftCards: AppliedGiftCardsType[];
  appliedLoyalty: LoyaltyRedemptionHandle;
  appliedWalletData: FloWalletRedemptionHandle;
  redirectUrl: string;
  originUrl: string;
  pgOrderId: string;
  isBuyNow: boolean;
  shopifySessionId: string;
  checkoutToken: string;
  coupons: any[];
  isC2P: boolean;
  shippingHandles: ShippingHandlesType;
  checkoutView: CheckoutViewType;
  checkoutModal: CheckoutModalType;
  isTwoStepCheckout: boolean;
  oosItems: ItemType[];
  checkoutItems: ItemType[];
  exitSurveyOptions: ExitSurveyOptionType[];
  checkoutUIMetadata?: CheckoutUIMetadataType;
  actionUrls?: ActionUrlsType;
  checkoutExpired?: CheckoutExpiredType;
  initialCheckoutStep: InitialCheckoutStepType;
  checkoutItemsMutable: boolean;
  isOrderSummaryOpen: boolean;
  orderSummaryStyle: OrderSummaryStyleType;
  activeComponent: CheckoutComponentType;
  checkoutValidations: CheckoutValidationsType;
  segmentId: string;
  etdText: {
    [addressId: string]: string;
  };
  uid?: string;
  discountedProductSelectorData: DiscountedProductSelectorDataType;
  discountProductSelectorPayload: DiscountedProductSelectorPayloadType;
  discountProductSelectorModal: DiscountProductSelectorModalType;
  cartDialog: CartDialogType;
  wishlistConfig: WishlistConfigType;
  showErrorScreen: boolean;
  hasCODMode: boolean;
  appliedCartAddOns: AddOnType[];
  availableAddOns: Record<string, AddOnMetaType>;
  checkoutLoading: boolean;
  errorResponses: CheckoutErrorResponse[];
}

export interface CheckoutState extends CheckoutReducerState {
  hasDefaultShippingHandleSelected: boolean;
}

export interface CheckoutContext {
  state: CheckoutState;
  actions: {
    checkoutInit: (response: any) => void;
    setShippingHandles: (shippingHanldes: ShippingHandlesType) => void;
    updateCheckoutBasedOnCheckoutResponse: (
      response: any,
      checkDiscounts?: boolean,
      isCartUpdate?: boolean,
    ) => void;
    setCheckoutView: (view: CheckoutViewType, isFromPopState?: boolean) => void;
    setInitialCheckoutStep: (step: InitialCheckoutStepType) => void;
    setCheckoutModal: (modal: CheckoutModalType) => void;
    setOOSItems: (items: ItemType[]) => void;
    setCheckoutItems: (items: ItemType[]) => void;
    setCoupons: (coupons: any) => void;
    setBilling: (billingDetails: BillingType) => void;
    updateCheckoutFromPayments: (response: any) => void;
    setCheckout: (data: Partial<CheckoutState>) => void;
    setCheckoutExpired: (expired: boolean, isInvalidUser: boolean) => void;
    setShowErrorScreen: (showErrorScreen: boolean) => void;
    setActiveComponent: (component: CheckoutComponentType) => void;
    setCheckoutValidations: (actions: CheckoutValidationsType) => void;
    setIsOrderSummaryOpen: (isOpen: boolean) => void;
    setEtdText: (data: { addressId: string; text: string }) => void;
    setDiscountedProductSelectorData: (data: DiscountedProductSelectorDataType) => void;
    setDiscountProductSelectorPayload: (data: DiscountedProductSelectorPayloadType) => void;
    setDiscountProductSelectorModal: (modal: DiscountProductSelectorModalType) => void;
    setCartDialog: (dialog: CartDialogType) => void;
    setHasCODMode: (hasCODMode: boolean) => void;
    setCheckoutLoading: (loading: boolean) => void;
  };
}

function reducer(state: CheckoutReducerState, action: Action): CheckoutReducerState {
  switch (action.type) {
    case ActionType.INITIALIZE: {
      return action.payload;
    }
    case ActionType.SET_LOADING: {
      return { ...state, isLoading: action.payload };
    }
    case ActionType.CHECKOUT_RESPONSE: {
      const {
        billingDetails,
        checkoutId,
        discounts,
        rewards,
        shippingHandles,
        checkoutToken,
        actionUrls,
        checkoutItems,
        segmentId,
        checkoutModal,
        discountProductSelectorModal,
        errorResponses,
      } = action.payload;

      return {
        ...state,
        ...(checkoutId ? { checkoutId } : {}),
        ...(shippingHandles?.length ? { shippingHandles: shippingHandles } : {}),
        ...(checkoutToken ? { checkoutToken } : {}),
        ...(actionUrls ? { actionUrls } : {}),
        ...(checkoutModal ? { checkoutModal } : {}),
        ...(discountProductSelectorModal ? { discountProductSelectorModal } : {}),
        ...(errorResponses ? { errorResponses } : {}),
        billing: billingDetails,
        appliedCoupons: getCouponData(discounts),
        discountedProductSelectorData: getDiscountedProductSelectorData(discounts),
        discountProductSelectorPayload: getDiscountedProductSelectorPayload(discounts),
        appliedGiftCards: getGiftCardData(rewards),
        appliedLoyalty: getAppliedLoyaltyData(rewards),
        appliedWalletData: getAppliedFloWalletData(rewards),
        checkoutItems: checkoutItems,
        ...(segmentId && { segmentId }),
      };
    }
    case ActionType.SET_CHECKOUT: {
      const {
        checkoutId,
        orderId,
        billing,
        appliedCoupons,
        appliedGiftCards,
        appliedLoyalty,
        appliedWalletData,
        redirectUrl,
        originUrl,
        pgOrderId,
        isBuyNow,
        shopifySessionId,
        checkoutToken,
        coupons,
        isC2P,
        shippingHandles,
        checkoutView,
        checkoutModal,
        isTwoStepCheckout,
        oosItems,
        checkoutItems,
        exitSurveyOptions,
        checkoutUIMetadata,
        actionUrls,
        isOrderSummaryOpen,
        initialCheckoutStep,
        checkoutItemsMutable,
        orderSummaryStyle,
        activeComponent,
        checkoutValidations,
        availableAddOns,
        appliedCartAddOns,
      } = action.payload;

      return {
        ...state,
        ...(checkoutId ? { checkoutId } : {}),
        ...(checkoutId ? { checkoutId } : {}),
        ...(orderId ? { orderId } : {}),
        ...(redirectUrl ? { redirectUrl } : {}),
        ...(originUrl ? { originUrl } : {}),
        ...(pgOrderId ? { pgOrderId } : {}),
        ...(typeof isBuyNow === "boolean" ? { isBuyNow } : {}),
        ...(shopifySessionId ? { shopifySessionId } : {}),
        ...(coupons ? { coupons } : {}),
        ...(typeof isC2P === "boolean" ? { isC2P } : {}),
        ...(checkoutView ? { checkoutView } : {}),
        ...(shippingHandles?.length ? { shippingHandles } : {}),
        ...(checkoutToken ? { checkoutToken } : {}),
        ...(billing ? { billing } : {}),
        ...(appliedCoupons ? { appliedCoupons } : {}),
        ...(appliedGiftCards ? { appliedGiftCards } : {}),
        ...(appliedLoyalty ? { appliedLoyalty } : {}),
        ...(appliedWalletData ? { appliedWalletData } : {}),
        ...(checkoutModal ? { checkoutModal } : {}),
        ...(typeof isTwoStepCheckout === "boolean" ? { isTwoStepCheckout } : {}),
        ...(oosItems ? { oosItems } : {}),
        ...(checkoutItems ? { checkoutItems } : {}),
        ...(exitSurveyOptions ? { exitSurveyOptions } : {}),
        ...(checkoutUIMetadata ? { checkoutUIMetadata } : {}),
        ...(actionUrls ? { actionUrls } : {}),
        ...(isOrderSummaryOpen ? { isOrderSummaryOpen } : {}),
        ...(initialCheckoutStep ? { initialCheckoutStep } : {}),
        ...(checkoutItemsMutable ? { checkoutItemsMutable } : {}),
        ...(orderSummaryStyle ? { orderSummaryStyle } : {}),
        ...(activeComponent ? { activeComponent } : {}),
        ...(checkoutValidations ? { checkoutValidations } : {}),
        ...(availableAddOns ? { availableAddOns } : {}),
        ...(appliedCartAddOns ? { appliedCartAddOns } : {}),
      };
    }
    case ActionType.CHECKOUT_INIT: {
      const response = action.payload;
      const checkout = response?.checkout;
      const merchantAttributes = response?.merchant_attributes;

      const isCheckoutC2P = Boolean(checkout?.metadata?.c2p?.enabled);
      const showShippingHandles = checkout?.metadata?.show_shipping_handle_selector ?? true;
      let checkoutModal = {};
      if (isCheckoutC2P && showShippingHandles) {
        checkoutModal = {
          checkoutView: "PAYMENTS",
          checkoutModal: "SHIPPING_HANDLES",
        };
      }

      const exitSurveyOptions: ExitSurveyOptionType[] = checkout?.metadata?.ui_config?.survey_options?.map(
        (item: any) => {
          return {
            optionId: item?.key,
            optionLabel: item?.label,
          };
        },
      );

      const showProductSelector = checkout?.discounts?.some(
        (coupon: any) =>
          coupon?.coupon_metadata?.coupon_type === "BXGY" &&
          coupon?.cart_alterations?.some(
            (alteration: any) =>
              alteration?.type === "SHOW_PRODUCT_SELECTOR" && alteration?.product_details?.length > 0,
          ) &&
          Boolean(coupon?.selected_items) &&
          coupon?.selected_items?.length === 0 &&
          Boolean(
            coupon?.show_selector === undefined ||
              coupon?.show_selector === null ||
              coupon?.show_selector === true
              ? true
              : false,
          ),
      );

      return {
        ...state,
        checkoutUIMetadata: isCart
          ? parseCartUIMetadata(checkout?.metadata?.ui_config)
          : parseCheckoutUIMetadata(checkout?.metadata?.ui_config),
        actionUrls: checkout?.metadata?.action_urls,
        isTwoStepCheckout: getIsTwoStepCheckout(merchantAttributes),
        redirectUrl: getRedirectUrl(
          response?.token?.data?.redirect_url || response?.token?.data?.back_url,
          merchantAttributes?.uid,
        ),
        originUrl:
          response?.token?.data?.origin_url ??
          response?.merchant_attributes?.account_attributes?.attributes?.website_url,
        isBuyNow: Boolean(response?.token?.data?.is_buy_now),
        shopifySessionId: response?.token?.sf_session_id,
        checkoutItems: getItems(checkout?.items),
        appliedCoupons: getCouponData(checkout?.discounts),
        discountedProductSelectorData: getDiscountedProductSelectorData(checkout?.discounts),
        discountProductSelectorModal: showProductSelector ? "FREE_GIFTS" : "NONE",
        discountProductSelectorPayload: getDiscountedProductSelectorPayload(checkout?.discounts),
        appliedGiftCards: getGiftCardData(checkout?.rewards),
        appliedLoyalty: getAppliedLoyaltyData(checkout?.rewards),
        appliedWalletData: getAppliedFloWalletData(checkout?.rewards),
        isC2P: isCheckoutC2P,
        shippingHandles: checkout?.metadata?.available_shipping_handles ?? [],
        exitSurveyOptions: exitSurveyOptions,
        checkoutToken: response?.token?.uid,
        initialCheckoutStep: checkout?.metadata?.ui_config?.default_view === "PAYMENTS" ? "PAYMENTS" : "NONE",
        isOrderSummaryOpen: checkout?.metadata?.ui_config?.layout?.metadata?.is_order_summary_open ?? false,
        checkoutItemsMutable: checkout?.metadata?.ui_config?.layout?.items_mutable ?? false,
        orderSummaryStyle:
          checkout?.metadata?.ui_config?.default_view === "PAYMENTS" &&
          Boolean(merchantAttributes?.account_attributes?.attributes?.is_one_page_checkout)
            ? "HEADER"
            : checkout?.metadata?.ui_config?.layout?.order_summary_style ?? "TILE",
        ...checkoutModal,
        uid: checkout?.uid,
        wishlistConfig: getWishlistConfig(merchantAttributes?.account_attributes?.attributes?.wishlist),
        appliedCartAddOns: response?.checkout?.cart_addons?.filter((item: AddOnType) => !item?.removed) ?? [],
        availableAddOns: response?.checkout?.available_addons ?? {},
        errorResponses: checkout?.metadata?.error_responses ?? [],
      };
    }
    case ActionType.SET_DISCOUNTS_AND_REWARDS: {
      return {
        ...state,
        appliedCoupons: getCouponData(action.payload?.discounts),
        discountedProductSelectorData: getDiscountedProductSelectorData(action.payload?.discounts),
        discountProductSelectorPayload: getDiscountedProductSelectorPayload(action.payload?.discounts),
        appliedGiftCards: getGiftCardData(action.payload?.rewards),
        appliedLoyalty: getAppliedLoyaltyData(action.payload?.rewards),
        appliedWalletData: getAppliedFloWalletData(action.payload?.rewards),
      };
    }
    case ActionType.SET_BILLING: {
      return {
        ...state,
        billing: action.payload ?? defaultBillingDetails,
      };
    }
    case ActionType.SET_REDIRECT_URL: {
      return {
        ...state,
        redirectUrl: action.payload,
      };
    }
    case ActionType.SET_ORIGIN_URL: {
      return {
        ...state,
        originUrl: action.payload,
      };
    }
    case ActionType.SET_COUPONS: {
      return {
        ...state,
        coupons: action.payload,
      };
    }
    case ActionType.SET_SHIPPING_HANDLES: {
      return {
        ...state,
        shippingHandles: action.payload,
      };
    }
    case ActionType.SET_CHECKOUT_TOKEN: {
      return {
        ...state,
        checkoutToken: action.payload,
      };
    }
    case ActionType.SET_CHECKOUT_ID: {
      return { ...state, checkoutId: action.payload };
    }
    case ActionType.SET_CHECKOUT_VIEW: {
      return { ...state, checkoutView: action.payload };
    }
    case ActionType.SET_CHECKOUT_MODAL: {
      return { ...state, checkoutModal: action.payload };
    }
    case ActionType.SET_OOS_ITEMS: {
      return { ...state, oosItems: action.payload };
    }
    case ActionType.SET_CHECKOUT_ITEMS: {
      return { ...state, checkoutItems: action.payload };
    }
    case ActionType.SET_CHECKOUT_EXPIRED: {
      return { ...state, checkoutExpired: action.payload };
    }
    case ActionType.SET_SHOW_ERROR_SCREEN: {
      return { ...state, showErrorScreen: action.payload };
    }
    case ActionType.SET_ACTIVE_COMPONENT: {
      return { ...state, activeComponent: action.payload };
    }
    case ActionType.SET_CHECKOUT_VALIDATIONS: {
      return { ...state, checkoutValidations: action.payload };
    }
    case ActionType.SET_IS_ORDER_SUMMARY_OPEN: {
      return { ...state, isOrderSummaryOpen: action.payload };
    }
    case ActionType.SET_ETD_TEXT: {
      return {
        ...state,
        etdText: {
          ...state.etdText,
          [action.payload.addressId]: action.payload.text,
        },
      };
    }
    case ActionType.SET_DISCOUNTED_PRODUCT_SELECTOR: {
      return { ...state, discountedProductSelectorData: action.payload };
    }
    case ActionType.SET_DISCOUNT_PRODUCT_SELECTOR_PAYLOAD: {
      return { ...state, discountProductSelectorPayload: action.payload };
    }
    case ActionType.SET_CART_DIALOG: {
      return { ...state, cartDialog: action.payload };
    }
    case ActionType.SET_DISCOUNT_PRODUCT_SELECTOR_MODAL: {
      return { ...state, discountProductSelectorModal: action.payload };
    }
    case ActionType.SET_HAS_COD_MODE: {
      return { ...state, hasCODMode: action.payload };
    }
    case ActionType.SET_CHECKOUT_LOADING: {
      return { ...state, checkoutLoading: action.payload };
    }
    case ActionType.SET_INITIAL_CHECKOUT_STEP: {
      return { ...state, initialCheckoutStep: action.payload };
    }
  }
  return state;
}

const checkoutInitialState: CheckoutReducerState = {
  showErrorScreen: false,
  isLoading: true,
  checkoutId: "",
  orderId: "",
  billing: defaultBillingDetails,
  appliedCoupons: [],
  appliedGiftCards: [],
  appliedLoyalty: {
    id: "",
    title: "",
    desc: "",
    totalPoints: 0,
    totalPrice: 0,
    provider: "NONE",
  },
  appliedWalletData: {
    earnMultiplier: 1,
    totalReducedPoints: 0,
    totalReductionAmount: 0,
  },
  redirectUrl: "",
  originUrl: "",
  pgOrderId: "",
  isBuyNow: false,
  shopifySessionId: "",
  checkoutToken: "",
  coupons: [],
  isC2P: false,
  shippingHandles: [],
  checkoutView: "NA" as CheckoutViewType,
  checkoutModal: "NONE" as CheckoutModalType,
  isTwoStepCheckout: false,
  oosItems: [],
  checkoutItems: [],
  exitSurveyOptions: [],
  initialCheckoutStep: "NONE" as InitialCheckoutStepType,
  isOrderSummaryOpen: false,
  checkoutItemsMutable: false,
  orderSummaryStyle: "HEADER",
  activeComponent: "NONE",
  checkoutValidations: {
    address: {
      isValid: true,
    },
    shipping: {
      isValid: true,
    },
    cartLoginPopup: {
      isValid: true,
    },
  },
  etdText: {},
  uid: "",
  discountedProductSelectorData: {
    items: [],
    totalItems: 0,
    totalSelectedItems: 0,
  },
  discountProductSelectorPayload: {
    selection: [],
  },
  discountProductSelectorModal: "NONE",
  cartDialog: null,
  wishlistConfig: {
    isEnabled: false,
    config: {
      checkout: {
        enabled: false,
        header: "",
        emptyHeader: "",
      },
      cart: {
        enabled: false,
        header: "",
        emptyHeader: "",
      },
      store: {
        enabled: false,
        header: "",
        emptyHeader: "",
      },
    },
  },
  segmentId: "",
  hasCODMode: true,
  appliedCartAddOns: [],
  availableAddOns: {},
  checkoutLoading: false,
  errorResponses: [],
};

export const CheckoutProvider: React.FC<React.PropsWithChildren<{ initialState?: CheckoutReducerState }>> = ({
  initialState = checkoutInitialState,
  children,
}) => {
  const [reducerState, dispatch] = useReducer(reducer, initialState);

  const updateCheckoutBasedOnCheckoutResponse = useCallback(
    (response: any, checkDiscounts: boolean = false, isCartUpdate: boolean = false) => {
      if (!response) return;
      let payload: any = {};
      if (response.pricing) {
        const billingDetails = getBillingDetails(response.pricing) ?? defaultBillingDetails;
        payload = { ...payload, billingDetails };
        if (
          !isCartUpdate &&
          showShippingChangeToast(reducerState.billing.shipping, billingDetails.shipping) &&
          !checkIfHasServiceabilityError(response?.metadata?.error_responses as CheckoutErrorResponse[])
        ) {
          successToast(i18n.t("shipping_fee_updated"));
        }
      }
      if (checkDiscounts) {
        const filteredDiscounts = response?.discounts?.filter(
          (discount: any) =>
            !Boolean(discount?.is_prepaid) &&
            (discount?.coupon_metadata?.coupon_type !== "REWARD_AS_DISCOUNT" ||
              (discount?.coupon_metadata?.coupon_type === "REWARD_AS_DISCOUNT" &&
                (discount?.coupon_metadata?.coupon_reward_config?.wallet_type === "CRED" ||
                  discount?.coupon_metadata?.coupon_reward_config?.wallet_type === "TICKERTAPE"))),
        );
        if (
          Boolean(filteredDiscounts?.length) &&
          Boolean(filteredDiscounts?.length !== reducerState.appliedCoupons?.length)
        ) {
          errorToast(i18n.t("coupon_not_found"));
        }
      }
      if (response.checkout_token_id) {
        payload = { ...payload, tokenId: response.checkout_token_id };
      }
      if (response.uid) {
        payload = { ...payload, checkoutId: response.uid };
      }
      if (response.rewards) {
        payload = {
          ...payload,
          rewards: response.rewards,
        };
      }
      if (Boolean(response?.items)) {
        payload = {
          ...payload,
          checkoutItems: getItems(response?.items),
        };
      }
      if (Boolean(response?.metadata?.action_urls)) {
        payload = { ...payload, actionUrls: response.metadata.action_urls };
      }
      const updatedDiscountProductSelectorData = getDiscountedProductSelectorData(response.discounts);

      const currentProductSelectorQuantity = reducerState?.discountedProductSelectorData?.totalItems;
      let updatedProductSelectorQuantity = 0;
      response?.discounts?.forEach((coupon: any) => {
        if (coupon?.coupon_metadata?.coupon_type === "BXGY") {
          coupon?.cart_alterations?.some((alteration: any) => {
            if (alteration?.type === "SHOW_PRODUCT_SELECTOR" && alteration?.product_details?.length > 0) {
              updatedProductSelectorQuantity += alteration?.quantity;
            }
          });
        }
      });

      const selectorQuantityUpdated =
        updatedProductSelectorQuantity > currentProductSelectorQuantity &&
        Boolean(currentProductSelectorQuantity);

      const showProductSelector =
        response?.discounts?.some(
          (coupon: any) =>
            coupon?.coupon_metadata?.coupon_type === "BXGY" &&
            coupon?.cart_alterations?.some(
              (alteration: any) =>
                alteration?.type === "SHOW_PRODUCT_SELECTOR" && alteration?.product_details?.length > 0,
            ) &&
            Boolean(coupon?.selected_items) &&
            coupon?.selected_items?.length === 0 &&
            Boolean(
              coupon?.show_selector === undefined ||
                coupon?.show_selector === null ||
                coupon?.show_selector === true
                ? true
                : false,
            ),
        ) || selectorQuantityUpdated;
      payload = {
        ...payload,
        discountProductSelectorModal: showProductSelector ? "FREE_GIFTS" : "NONE",
      };

      payload = {
        ...payload,
        discounts: response.discounts,
        shippingHandles: response.metadata.available_shipping_handles,
        ...(response?.segment_id && { segmentId: response?.segment_id }),
      };
      if (response?.checkout?.cart_addons && response?.checkout?.available_addons) {
        payload = {
          ...payload,
          appliedCartAddOns:
            response?.checkout?.cart_addons?.filter((item: AddOnType) => !item?.removed) ?? [],
          availableCartAddOns: response?.checkout?.available_addons,
        };
      }

      if (response?.metadata?.error_responses) {
        payload = {
          ...payload,
          errorResponses: response?.metadata?.error_responses,
        };
      }

      dispatch({
        type: ActionType.CHECKOUT_RESPONSE,
        payload,
      });
    },
    [reducerState],
  );

  const updateCheckoutFromPayments = useCallback(
    (response: any) => {
      updateCheckoutBasedOnCheckoutResponse(response);
      let payload = {};
      if (Boolean(response?.items)) {
        payload = {
          ...payload,
          checkoutItems: getItems(response?.items),
        };
      }
      const isAddressServiceable = response?.pricing?.serviceable ?? false;
      if (!Boolean(isAddressServiceable) && Boolean(reducerState.checkoutView === "PAYMENTS")) {
        payload = {
          ...payload,
          ...(reducerState.initialCheckoutStep === "PAYMENTS"
            ? { activeComponent: "ADDRESS_SECTION" }
            : { checkoutView: "ADDRESS_LIST" }),
          checkoutToken: response?.checkout_token_id ?? "",
        };
        errorToast(i18n.t("serviceability_error"), 5000);
      }

      const shippingHandles = response?.metadata?.available_shipping_handles;
      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      payload = {
        ...payload,
        shippingHandles,
      };
      if (Boolean(showShippingHandles) && reducerState.checkoutView === "PAYMENTS") {
        payload = {
          ...payload,
          shippingHandles: reducerState.shippingHandles,
          ...(reducerState.initialCheckoutStep === "PAYMENTS"
            ? { activeComponent: "SHIPPING_SECTION" }
            : { checkoutModal: "SHIPPING_HANDLES" }),
        };
      } else {
        mutate(`/checkout/v2/checkout/${reducerState.checkoutId}/payments`);
        mutate([`/checkout/${reducerState.checkoutId}/rewards`, apiURI.KRATOS_PRIVATE]);
        mutate(`UPI_INTENT`);
      }
      dispatch({
        type: ActionType.SET_CHECKOUT,
        payload,
      });
    },
    [reducerState, updateCheckoutBasedOnCheckoutResponse],
  );

  const checkoutInit = useCallback((tokenResponse: any) => {
    dispatch({
      type: ActionType.CHECKOUT_INIT,
      payload: tokenResponse,
    });
  }, []);

  const setShippingHandles = useCallback((shippingHanldes: ShippingHandlesType) => {
    dispatch({
      type: ActionType.SET_SHIPPING_HANDLES,
      payload: shippingHanldes,
    });
  }, []);

  const setCheckoutView = (view: CheckoutViewType) => {
    dispatch({
      type: ActionType.SET_CHECKOUT_VIEW,
      payload: view,
    });
  };

  const setCheckoutModal = (modal: CheckoutModalType) => {
    dispatch({
      type: ActionType.SET_CHECKOUT_MODAL,
      payload: modal,
    });
  };

  const setOOSItems = useCallback((items: ItemType[]) => {
    dispatch({
      type: ActionType.SET_OOS_ITEMS,
      payload: items,
    });
  }, []);

  const setCheckoutItems = useCallback((items: ItemType[]) => {
    dispatch({
      type: ActionType.SET_CHECKOUT_ITEMS,
      payload: items,
    });
  }, []);

  const setCoupons = useCallback((items: ItemType[]) => {
    dispatch({
      type: ActionType.SET_COUPONS,
      payload: items,
    });
  }, []);

  const setBilling = useCallback((billingDetails: BillingType) => {
    dispatch({
      type: ActionType.SET_BILLING,
      payload: billingDetails,
    });
  }, []);

  const setCheckoutExpired = useCallback((expired: boolean, isInvalidUser: boolean) => {
    dispatch({
      type: ActionType.SET_CHECKOUT_EXPIRED,
      payload: { expired, isInvalidUser },
    });
  }, []);

  const setShowErrorScreen = useCallback((showErrorScreen: boolean) => {
    dispatch({
      type: ActionType.SET_SHOW_ERROR_SCREEN,
      payload: showErrorScreen,
    });
  }, []);

  const setCheckout = useCallback((data: Partial<CheckoutState>) => {
    dispatch({
      type: ActionType.SET_CHECKOUT,
      payload: data,
    });
  }, []);

  const setEtdText = useCallback((data: { addressId: string; text: string }) => {
    dispatch({
      type: ActionType.SET_ETD_TEXT,
      payload: data,
    });
  }, []);

  const setActiveComponent = useCallback((data: CheckoutComponentType) => {
    dispatch({
      type: ActionType.SET_ACTIVE_COMPONENT,
      payload: data,
    });
  }, []);

  const setCheckoutValidations = useCallback((data: CheckoutValidationsType) => {
    dispatch({
      type: ActionType.SET_CHECKOUT_VALIDATIONS,
      payload: data,
    });
  }, []);

  const setIsOrderSummaryOpen = useCallback((isOpen: boolean) => {
    dispatch({
      type: ActionType.SET_IS_ORDER_SUMMARY_OPEN,
      payload: isOpen,
    });
  }, []);

  const setDiscountedProductSelectorData = useCallback((data: DiscountedProductSelectorDataType) => {
    dispatch({
      type: ActionType.SET_DISCOUNTED_PRODUCT_SELECTOR,
      payload: data,
    });
  }, []);

  const setDiscountProductSelectorPayload = useCallback((data: DiscountedProductSelectorPayloadType) => {
    dispatch({
      type: ActionType.SET_DISCOUNT_PRODUCT_SELECTOR_PAYLOAD,
      payload: data,
    });
  }, []);

  const setDiscountProductSelectorModal = useCallback((modal: DiscountProductSelectorModalType) => {
    dispatch({
      type: ActionType.SET_DISCOUNT_PRODUCT_SELECTOR_MODAL,
      payload: modal,
    });
  }, []);

  const setCartDialog = useCallback((dialog: CartDialogType) => {
    dispatch({
      type: ActionType.SET_CART_DIALOG,
      payload: dialog,
    });
  }, []);

  const setHasCODMode = useCallback((hasCODMode: boolean) => {
    dispatch({
      type: ActionType.SET_HAS_COD_MODE,
      payload: hasCODMode,
    });
  }, []);

  const setCheckoutLoading = useCallback((loading: boolean) => {
    dispatch({
      type: ActionType.SET_CHECKOUT_LOADING,
      payload: loading,
    });
  }, []);

  const setInitialCheckoutStep = useCallback((step: InitialCheckoutStepType) => {
    dispatch({
      type: ActionType.SET_INITIAL_CHECKOUT_STEP,
      payload: step,
    });
  }, []);

  const hasDefaultShippingHandleSelected = useMemo(() => {
    if (!Boolean(reducerState.shippingHandles) || !Boolean(reducerState.shippingHandles.length)) return true;
    const hasDefault = reducerState.shippingHandles.some(
      (handle: ShippingHandleType) => handle.selected_handle,
    );
    return hasDefault;
  }, [reducerState]);

  const state = useMemo(
    () => ({
      ...reducerState,
      hasDefaultShippingHandleSelected,
    }),
    [reducerState, hasDefaultShippingHandleSelected, isCart],
  );

  const actions = useMemo(
    () => ({
      updateCheckoutBasedOnCheckoutResponse,
      updateCheckoutFromPayments,
      setShippingHandles,
      checkoutInit,
      setCheckoutView,
      setCheckoutModal,
      setOOSItems,
      setCheckoutItems,
      setCoupons,
      setBilling,
      setCheckout,
      setCheckoutExpired,
      setShowErrorScreen,
      setActiveComponent,
      setCheckoutValidations,
      setIsOrderSummaryOpen,
      setEtdText,
      setDiscountedProductSelectorData,
      setDiscountProductSelectorPayload,
      setCartDialog,
      setDiscountProductSelectorModal,
      setHasCODMode,
      setCheckoutLoading,
      setInitialCheckoutStep,
    }),
    [
      updateCheckoutBasedOnCheckoutResponse,
      updateCheckoutFromPayments,
      setShippingHandles,
      checkoutInit,
      setCheckoutView,
      setCheckoutModal,
      setOOSItems,
      setCheckoutItems,
      setCoupons,
      setBilling,
      setCheckout,
      setCheckoutExpired,
      setShowErrorScreen,
      setActiveComponent,
      setCheckoutValidations,
      setIsOrderSummaryOpen,
      setEtdText,
      setDiscountedProductSelectorData,
      setDiscountProductSelectorPayload,
      setCartDialog,
      setDiscountProductSelectorModal,
      setHasCODMode,
      setCheckoutLoading,
      setInitialCheckoutStep,
    ],
  );

  return (
    <Context.Provider
      value={{
        state,
        actions,
      }}>
      {children}
    </Context.Provider>
  );
};

export function useCheckoutContext() {
  if (!Boolean(Context)) throw new Error("useCheckoutContext must be used within a CheckoutProvider");
  const checkoutContext = useContext(Context);
  return checkoutContext as CheckoutContext;
}
