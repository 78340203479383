import React from "react";
import { DialogHeader, DialogBody } from "components/common/dialogs/GenericDialog";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import { Dialog } from "@headlessui/react";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { useLocale } from "lib/hooks/useLocale";
import { ArrowLeft, X } from "react-feather";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

const CashfreePaymentForm = (): JSX.Element => {
  const {
    state: { billing },
  } = useCheckoutContext();
  const { t } = useLocale();
  const {
    actions: { setPaymentMethodDialog },
    state: { openPaymentMethodDialog },
  } = usePaymentContext();

  return (
    <>
      <GenericDialog
        isOpen={openPaymentMethodDialog === "CF"}
        setIsOpen={() => setPaymentMethodDialog("NONE")}
        translateAxis="z"
        modalType="PAYMENT_METHOD">
        <DialogHeader>
          <Dialog.Title
            as="div"
            className="flex h-full w-full flex-row items-center justify-between bg-white text-coal-dark">
            <div className="flex h-full flex-row items-center">
              <div
                className="-ml-3 flex h-12 w-12 cursor-pointer items-center justify-center"
                onClick={() => setPaymentMethodDialog("NONE")}>
                <ArrowLeft className="h-6 w-6 " />
              </div>
              <h1 className="text-base font-medium">{t("payment_methods")}</h1>
            </div>
            <h1 className="text-base font-medium text-coal-dark">{`₹${parseFloat(
              billing?.prepaid_total?.toFixed(2),
            ).toLocaleString("en-IN")}`}</h1>
          </Dialog.Title>
        </DialogHeader>
        <DialogBody>
          <div className="h-full w-full" id="payment-form"></div>
        </DialogBody>
      </GenericDialog>
    </>
  );
};

export default React.memo(CashfreePaymentForm);
