import { Dialog } from "@headlessui/react";
import Tag from "components/common/Tag";
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
  GenericDialog,
} from "components/common/dialogs/GenericDialog";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import { useLocale } from "lib/hooks/useLocale";
import { currencyFormatter } from "lib/utils/formatters";
import { X } from "react-feather";

const InvalidOffersDialog = ({
  isOpen,
  handleClose,
  unavailabilityReasons,
  mode,
}: {
  isOpen: boolean;
  handleClose: () => void;
  unavailabilityReasons: any[];
  mode: any;
}) => {
  const {
    actions: { handleUnapplicablePaymentMethod },
  } = usePaymentContext();
  const { t } = useLocale();

  const handleInvalidOffers = () => {
    handleClose();
    handleUnapplicablePaymentMethod(mode?.mode, unavailabilityReasons);
  };

  return (
    <GenericDialog
      isOpen={isOpen}
      setIsOpen={handleClose}
      translateAxis="y"
      customClass="overflow-scroll md:!top-auto md:absolute rounded-t-2xl max-h-[81vh]"
      modalType="REMOVE_OFFERS"
      dialogOverlay={true}>
      <DialogHeader className="px-4">
        <Dialog.Title
          as="h3"
          className="flex h-full w-full flex-row items-center justify-between bg-white py-4 text-base font-medium text-carbon-dark">
          <div className="flex items-center">
            <h2 className="inline-flex gap-2 font-medium text-carbon-dark">
              {t("order_total_will_be_increased")}
            </h2>
          </div>
          <button className="outline-none" onClick={handleClose}>
            <X className="h-6 w-6 cursor-pointer text-coal-dark" />
          </button>
        </Dialog.Title>
      </DialogHeader>
      <DialogBody className={`flex flex-col gap-2 px-3 pb-[11.25rem]`}>
        <p className={"text-sm font-normal text-coal-dark"}>{t("payment_method_unavailable_for_offers")}</p>
        <div className="flex flex-wrap gap-2">
          {unavailabilityReasons
            ?.find((reason) => reason.type === "INVALID_DISCOUNTS")
            ?.values?.map((reasonDiscount: any, index: number) => (
              <Tag key={index} className="px-2 py-1 text-xs font-medium text-gray-dark">
                {reasonDiscount}
              </Tag>
            ))}
        </div>
      </DialogBody>
      <DialogFooter className="max-h-[none] bg-white px-3 pb-11 pt-0">
        <div className="flex w-full flex-col gap-2">
          <button
            className="h-14 w-full rounded-xl bg-carbon-dark py-3 font-medium text-white"
            onClick={handleClose}
            type="button">
            {t("pay_with_offers")}
          </button>
          <button
            onClick={handleInvalidOffers}
            className={`h-14 w-full rounded-xl py-3 font-medium text-coal-dark`}>
            {t("remove_offers")}
          </button>
        </div>
      </DialogFooter>
    </GenericDialog>
  );
};

export default InvalidOffersDialog;
