import React, { useCallback, useEffect } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { PaymentModeType } from "lib/types/payments";
import { paymentMethodsConfig } from "lib/utils/payments";
import {
  extractPrepaidDiscountOffString,
  getAutomationPayloadForMethod,
  isEmptyObj,
} from "lib/utils/helpers";
import { usePaymentContext } from "lib/contexts/PaymentProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { errorToast } from "lib/utils/toasters";
import { useUserContext } from "lib/contexts/UserProvider";
import { useCheckoutErrors } from "lib/hooks/useCheckoutErrors";
import { useAddressValidation } from "lib/hooks/useAddressValidation";

interface UseMethodCardProps {
  modeData: any;
  inFocus?: boolean;
}

export const useMethod = ({ modeData, inFocus }: UseMethodCardProps) => {
  const { t } = useLocale();
  const {
    state: {
      billing,
      shippingHandles,
      initialCheckoutStep,
      checkoutValidations,
      hasDefaultShippingHandleSelected,
    },
    actions: { setCheckoutView, setCheckoutModal, setActiveComponent, setIsOrderSummaryOpen },
  } = useCheckoutContext();
  const {
    state: { paymentMethods },
    actions: { handlePaymentMethodSelected, handleUnapplicablePaymentMethod, setPaymentMethodDialog },
  } = usePaymentContext();
  const {
    state: { user },
  } = useUserContext();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { triggerCheckoutErrorInfoToast } = useCheckoutErrors();

  const { verifyDefaultAddress } = useAddressValidation();

  const { mode, discount, metadata, context, discount_metadata, is_available, unavailability_reasons }: any =
    modeData;

  const { title } = metadata || {};
  const { coupon_metadata } = discount_metadata || {};
  const discountCondition =
    extractPrepaidDiscountOffString(coupon_metadata?.concession_amount, coupon_metadata?.deduction_type) ??
    "";

  const [hasPrepaidFreebie, freebiePrice] = useCallback(() => {
    if (!isEmptyObj(discount_metadata) && discount_metadata.is_prepaid && discount_metadata.is_freebie) {
      const totalPrice = Boolean(discount_metadata?.mode_freebies) ? discount_metadata?.mode_freebies : 0;
      return [true, totalPrice];
    }
    return [false, 0];
  }, [discount_metadata])();

  const [invalidOfferDialogOpen, setInvalidOfferDialogOpen] = React.useState(false);
  const handleInvalidOfferDialogClose = () => {
    setInvalidOfferDialogOpen(false);
  };

  useEffect(() => {
    if (inFocus) {
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_PAYMENT_TILE_SELECTED,
        eventType: "click",
        metaData: {
          methodName: Boolean(title) ? title : paymentMethodsConfig[mode as PaymentModeType]?.header,
          methodType: modeData?.mode,
        },
      });
    }
  }, [inFocus, mode, modeData?.mode, sendAnalyticsEvent, title]);

  const handleMethodClick = () => {
    const isAddressValid = verifyDefaultAddress();
    if (is_available) {
      if (!Boolean(user?.addresses?.length) && initialCheckoutStep === "PAYMENTS") {
        setCheckoutModal("ADDRESS_NEW");
        return;
      }

      if (initialCheckoutStep === "PAYMENTS" && !isAddressValid) {
        if (!Boolean(checkoutValidations?.address?.metadata)) {
          setCheckoutModal("ADDRESS_LIST");
          errorToast(t("serviceability_error"));
          return;
        }
        setCheckoutModal("ADDRESS_EDIT");
        return;
      }

      if (!Boolean(shippingHandles?.length) && initialCheckoutStep !== "PAYMENTS") {
        triggerCheckoutErrorInfoToast();
        handleUnapplicablePaymentMethod(mode, unavailability_reasons);
        setCheckoutView("ADDRESS_LIST");
        return;
      }

      if (!Boolean(shippingHandles?.length) && initialCheckoutStep === "PAYMENTS") {
        triggerCheckoutErrorInfoToast();
        handleUnapplicablePaymentMethod(mode, unavailability_reasons);
        setActiveComponent("ADDRESS_SECTION");
        return;
      }

      const hasSplit = paymentMethods.find((method: any) => method.mode === "SPLIT_COD");
      if (mode === "COD" && !hasSplit) {
        const methodData = paymentMethods?.find((e: any) => e.mode === "COD");
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_PAYMENT_METHOD_SELECTED,
          eventFor: [analyticsTypes.SF_ANALYTICS, analyticsTypes.FACEBOOK_PIXEL],
          eventType: "click",
          metaData: {
            methodType: mode,
            automationData: getAutomationPayloadForMethod(methodData),
          },
        });
        if (!Boolean(hasDefaultShippingHandleSelected)) {
          setCheckoutModal("SHIPPING_HANDLES");
          return;
        }
        return setPaymentMethodDialog("COD_ORDER_PLACING");
      }
      if (mode === "UPI_INTENT") {
        setPaymentMethodDialog("UPI_INTENT");
        return;
      }
      return handlePaymentMethodSelected(mode, context);
    }
    const hasShippingBlocker = unavailability_reasons.some((e: any) => e.type === "SHIPPING_HANDLES");
    if (hasShippingBlocker) {
      return handleUnapplicablePaymentMethod(mode, unavailability_reasons);
    }
    const hasUnavailableOffers = unavailability_reasons.some((e: any) => e.type === "INVALID_DISCOUNTS");
    if (hasUnavailableOffers) {
      return setInvalidOfferDialogOpen(true);
    }
    return handleUnapplicablePaymentMethod(mode, unavailability_reasons);
  };

  const isInSplitCod = context === "SPLIT_COD";

  const hasDiscountOrFreebie = !isInSplitCod && (Boolean(discount) || hasPrepaidFreebie);

  return {
    billing,
    invalidOfferDialogOpen,
    setInvalidOfferDialogOpen,
    handleInvalidOfferDialogClose,
    freebiePrice,
    hasPrepaidFreebie,
    discountCondition,
    hasDiscountOrFreebie,
    handleMethodClick,
  };
};
