import { LoyaltyRedemptionHandle } from "lib/types/checkout";
import { RewardsConfigType, RewardsMode } from "lib/types/rewards";

export const getRewardsMode = (rewardsResponse: any) => {
  if (Boolean(rewardsResponse?.wallet_config)) {
    return RewardsMode.REWARDS_WALLET;
  } else if (Boolean(rewardsResponse?.loyalty_config)) {
    return RewardsMode.LOYALTY;
  } else {
    return RewardsMode.NONE;
  }
};

export const getRedemptionOptionsData = (redemptionOptions: any) => {
  if (!Boolean(redemptionOptions) || !Boolean(redemptionOptions?.length)) return [];
  const redemptionData = redemptionOptions?.map((option: any, index: number) => {
    const data: LoyaltyRedemptionHandle = {
      provider: option?.provider ?? "NONE",
      id: option?.id,
      title: option?.name,
      desc: option?.desc ?? "",
      totalPoints: option?.point_amount,
      totalPrice: option?.reduction_amount,
    };
    return data;
  });
  return redemptionData;
};

export const parseRewardsResponse = (
  rewardsResponse: any,
  appliedLoyalty: LoyaltyRedemptionHandle,
): RewardsConfigType => {

  const mode = getRewardsMode(rewardsResponse);
  
  switch (mode) {
    case RewardsMode.LOYALTY: {
      const loyaltyConfig = rewardsResponse?.loyalty_config;
      if (loyaltyConfig?.provider === "YOTPO") {
        return {
          mode: RewardsMode.LOYALTY,
          data: {
            provider: loyaltyConfig?.provider,
            value: {
              email: loyaltyConfig?.user_details?.customer_id,
              totalPointsBalance: loyaltyConfig?.user_details?.point_balance,
              totalReducedPoints: loyaltyConfig?.user_details?.max_applicable_points,
              redemptionOptions: getRedemptionOptionsData(loyaltyConfig?.redemption_options),
              totalReductionAmount: loyaltyConfig?.user_details?.reduction_amount,
              coinName: loyaltyConfig?.coin_name,
              coinNamePlural: loyaltyConfig?.coin_name_in_plural,
              unavailabilityReasons: loyaltyConfig?.unavailability_reason ?? [],
              isAvailable: loyaltyConfig?.is_available ?? false,
            },
            accountExists: loyaltyConfig?.account_exists,
            isVerified: loyaltyConfig?.user_details?.verification_required,
          },
        };
      }
      return {
        mode: RewardsMode.LOYALTY,
        data: {
          provider: loyaltyConfig?.provider ?? "NONE",
          value: {
            provider: loyaltyConfig?.provider ?? "NONE",
            earnValue: {
              isEnabled: loyaltyConfig?.earn_response?.enabled,
              issuanceRate: loyaltyConfig?.earn_response?.issuance_rate,
              normalizedValue: loyaltyConfig?.earn_response?.normalized_value,
              value: loyaltyConfig?.earn_response?.value,
            },
            coinName: loyaltyConfig?.coin_name ?? "Coin",
            coinNamePlural: loyaltyConfig?.coin_name_in_plural ?? "Coins",
            redemptionId: loyaltyConfig?.redemption_options?.[0]?.id,
            userDetails: {
              customerId: loyaltyConfig?.user_details?.customer_id,
              pointBalance: loyaltyConfig?.user_details?.point_balance,
              maxApplicablePoints: loyaltyConfig?.user_details?.max_applicable_points,
              reductionAmount: Boolean(appliedLoyalty?.totalPrice)
                ? appliedLoyalty?.totalPrice
                : loyaltyConfig?.user_details?.reduction_amount,
            },
            unavailabilityReasons: loyaltyConfig?.unavailability_reason ?? [],
            isAvailable: loyaltyConfig?.is_available ?? false,
          },
        },
      };
    }
    case RewardsMode.REWARDS_WALLET: {
      const walletRewards = rewardsResponse?.wallet_config ?? {};
      return {
        mode: RewardsMode.REWARDS_WALLET,
        data: {
          floWalletData: {
            totalPointsBalance: walletRewards?.total_points ?? 0,
            totalAmount: walletRewards?.total_balance ?? 0,
            maxBurnablePoints: walletRewards?.max_burnable_points ?? 0,
            maxBurnableAmount: walletRewards?.max_burnable_amount ?? 0,
            maxEarnablePoints: walletRewards?.max_earnable_points ?? 0,
            maxEarnableAmount: walletRewards?.max_earnable_amount ?? 0,
            earnMultiplier: walletRewards?.earn_multiplier ?? 0,
            burnMultiplier: walletRewards?.burn_multiplier ?? 0,
            coinName: walletRewards?.coin_name,
            coinNamePlural: walletRewards?.coin_name_plural,
            earnEnabled: Boolean(walletRewards?.earn_enabled),
            burnEnabled: Boolean(walletRewards?.burn_enabled),
            walletDisabledMessage: walletRewards?.wallet_disabled_message || "",
            coinConversionRate: Boolean(walletRewards?.coin_conversion_rate)
              ? walletRewards?.coin_conversion_rate
              : 1,
            insufficientBalanceMessage: walletRewards?.insufficient_balance_message || "",
            personalizedWalletMessageEnabled: walletRewards?.personalized_wallet_display_message_enabled,
            rewardsEarnedMessage: walletRewards?.rewards_earned_message,
            currencyEarnedMessage: walletRewards?.currency_earned_message,
            currencyBurnedMessage: walletRewards?.currency_burned_message,
            currencyBurnedTemplate: walletRewards?.currency_burned_template,
            earnTileCustomization: walletRewards?.earn_tile_customise_ui,
            unavailabilityReasons: walletRewards?.unavailability_reason ?? [],
            isAvailable: walletRewards?.is_available ?? false,
          },
          isWalletCreated: Boolean(walletRewards?.wallet_exists),
        },
      };
    }
    default:
      return {
        mode: RewardsMode.NONE,
        data: {},
      };
  }
};

export const getGyftrBalance = (rewardsResponse: any) => {
  return rewardsResponse?.balance ?? 0;
};
