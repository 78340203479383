import Price from "components/common/Price";
import { classNames, insertBeforeLastOccurrence, truncateString } from "lib/utils/helpers";
import { Minus, Plus, Trash2 } from "react-feather";
import defaultPackageImage from "assests/images/package.png";
import { AddOnMetaType } from "lib/types/checkout";
import { currencyFormatter } from "lib/utils/formatters";

const quanitySelector = (quantity: number) => {
  return (
    <>
      <div
        className={classNames(
          "flex h-6 w-[4.25rem] flex-row items-center rounded border",
          "border-carbon-lighter bg-white",
        )}>
        <button
          className="flex h-full w-full grow basis-1/3 items-center justify-center cursor-pointer bg-gray-light rounded-l bg-[#F8F8F8] text-carbon-lighter"
          disabled>
          <Minus className="h-3.5 w-3.5 outline-none" strokeWidth={2.5} />
        </button>

        <p className="grow basis-1/3 text-center text-xs font-medium text-coal-dark">{quantity}</p>
        <button
          className="flex h-full w-full grow basis-1/3 items-center justify-center cursor-pointer bg-gray-light rounded-r bg-[#F8F8F8] text-carbon-lighter"
          disabled>
          <Plus className="h-3.5 w-3.5 outline-none" strokeWidth={2.5} />
        </button>
      </div>
    </>
  );
};

export const AddOnItem = ({
  variant,
  addOnData,
  parentTitle,
  handleAddOnDelete,
  disableActions = false,
  allowDelete = true,
  itemQuantity = 1,
}: {
  variant: "CART" | "CHECKOUT";
  addOnData: AddOnMetaType;
  parentTitle?: string;
  handleAddOnDelete?: () => void;
  disableActions?: boolean;
  allowDelete?: boolean;
  itemQuantity?: number;
}) => {
  const imageStyle = {
    CART: "h-[75px] w-[75px]",
    CHECKOUT: "h-[4.188rem] w-16",
  };

  const containerStyle = {
    CART: "mt-3 flex w-full min-w-full flex-row justify-between bg-gray-lightest p-2 rounded-xl items-start",
    CHECKOUT: "flex w-full min-w-full flex-row justify-between",
  };

  return (
    <div className={classNames(containerStyle[variant])}>
      <div>
        <img
          src={insertBeforeLastOccurrence(addOnData?.image ?? "", ".jpg", "_small")}
          alt={"add-on-image"}
          className={classNames("rounded-lg border border-gray-light object-cover", imageStyle[variant])}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultPackageImage;
          }}
        />
      </div>
      <div className="flex flex-col flex-1 ml-4 justify-between">
        <div className="flex flex-row justify-between items-start space-x-1">
          <div>
            <p className="overflow-hidden text-ellipsis  pt-0.5 text-sm font-medium text-coal-dark">
              {truncateString(addOnData?.product_name, 40)}
            </p>
            {parentTitle && (
              <h4 className="mt-1 w-full overflow-hidden text-ellipsis text-xs font-normal text-coal-light">
                For {truncateString(parentTitle, 40)}
              </h4>
            )}
          </div>
          {allowDelete && (
            <button
              className="cursor-pointer rounded-lg border border-gray-light p-1 bg-white max-h-fit"
              onClick={disableActions ? () => {} : handleAddOnDelete}>
              <Trash2 className="h-3.5 w-3.5 text-coal-light outline-none" strokeWidth={2.5} />
            </button>
          )}
        </div>
        <div className="mt-2 flex flex-row justify-between align-center">
          {quanitySelector(itemQuantity)}
          <p className="pt-1 text-sm font-medium text-coal-dark flex justify-end">
            <Price total={currencyFormatter(addOnData?.price * itemQuantity)} orientation="horizontal" />
          </p>
        </div>
      </div>
    </div>
  );
};
