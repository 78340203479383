import { withScope } from "@sentry/react";
import { publicFetcher, staticOptions } from "lib/core/apiClient";
import { InitialCheckoutStepType, OrderSummaryStyleType } from "lib/types/checkout";
import {
  MerchantUIAttributes,
  MerchantUIConfig,
  UIConfigAttributes,
  MerchantUIResponseType,
  CheckoutMetadata,
} from "lib/types/merchantConfigTypes";
import { eventTypes } from "lib/utils/constants";
import { customBranding } from "lib/utils/customBranding";
import { publishPostMessage } from "lib/utils/helpers";
import React, { createContext, useContext, useEffect, useState, useReducer, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";

// Types
export enum MerchantUIConfigActionType {
  SET_MERCHANT_ATTRIBUTES = "SET_MERCHANT_ATTRIBUTES",
  SET_VALIDATING = "SET_VALIDATING",
  SET_METADATA = "SET_METADATA",
}

export interface MerchantUIConfigState {
  isValidating: boolean;
  attributes: MerchantUIAttributes;
  checkoutUIAttributes: UIConfigAttributes;
  checkoutMetadata: CheckoutMetadata;
}

// Initial state
const merchantUIConfigIntialState: MerchantUIConfigState = {
  isValidating: true,
  checkoutUIAttributes: {
    logo_url: "",
    banner_config: [],
  },
  attributes: {
    is_one_page_checkout: false,
  },
  checkoutMetadata: {},
};

export interface MerchantUIContext {
  state: MerchantUIConfigState;
  actions: {
    setFallbackMerchantData: (data: any) => void;
  };
}

// Context
const MerchantContext = createContext<MerchantUIContext>({
  state: merchantUIConfigIntialState,
  actions: {
    setFallbackMerchantData: () => {},
  },
});

// Actions
type MerchantAction =
  | {
      type: MerchantUIConfigActionType.SET_MERCHANT_ATTRIBUTES;
      payload: Omit<MerchantUIResponseType, "isValidating">;
    }
  | { type: MerchantUIConfigActionType.SET_VALIDATING; payload: boolean }
  | {
      type: MerchantUIConfigActionType.SET_METADATA;
      payload: CheckoutMetadata;
    };

const getCartAttributes = (
  uiConfig: MerchantUIConfig[],
): {
  checkoutUIAttributes: UIConfigAttributes;
} => {
  const checkout = uiConfig.filter(
    (config) => config.sf_context.toLowerCase() === "checkout" && config.channel === "web",
  );

  return {
    checkoutUIAttributes: checkout[0]?.ui_attributes ?? merchantUIConfigIntialState.checkoutUIAttributes,
  };
};

// Reducer
function merchantReducer(state: MerchantUIConfigState, action: MerchantAction): MerchantUIConfigState {
  switch (action.type) {
    case MerchantUIConfigActionType.SET_MERCHANT_ATTRIBUTES: {
      //updating branding colors
      const ui: any = action.payload.attributes?.ui;
      if (Boolean(ui)) {
        customBranding(
          ui?.color_pallet?.primary_color,
          ui?.color_pallet?.secondary_color,
          ui?.color_pallet?.primary_text_color,
        );
      }

      return {
        ...state,
        attributes: action.payload.attributes ?? merchantUIConfigIntialState.attributes,
        checkoutUIAttributes: getCartAttributes(action.payload.ui_config ?? []).checkoutUIAttributes,
        isValidating: false,
      };
    }

    case MerchantUIConfigActionType.SET_METADATA: {
      const defaultView = action.payload?.default_view === null ? "ADDRESS" : action.payload?.default_view;
      return {
        ...state,
        checkoutMetadata: {
          ...(action.payload ?? merchantUIConfigIntialState.checkoutMetadata),
          default_view: defaultView,
        },
      };
    }

    case MerchantUIConfigActionType.SET_VALIDATING:
      return {
        ...state,
        isValidating: action.payload,
      };
    default:
      return state;
  }
}

// Provider
export const MerchantUIConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(merchantReducer, merchantUIConfigIntialState);
  const [searchParams] = useSearchParams();
  const shopUrl = searchParams.get("shop_url");

  const {
    data: merchantData,
    isValidating,
    error,
  } = useSWR<MerchantUIResponseType>(
    shopUrl ? `/v1/merchant-apps?shop-url=${shopUrl}` : null,
    publicFetcher,
    staticOptions,
  );

  const handleMerchantData = (event: any) => {
    if (event.data.type === eventTypes.MERCHANT_ATTRIBUTES) {
      const merchantAttributes = event?.data?.payload?.merchantAttributes;
      const metadata = event?.data?.payload?.uiConfig?.data;
      dispatch({
        type: MerchantUIConfigActionType.SET_MERCHANT_ATTRIBUTES,
        payload: {
          attributes: merchantAttributes?.attributes,
          ui_config: merchantAttributes?.ui_config,
        },
      });

      dispatch({
        type: MerchantUIConfigActionType.SET_METADATA,
        payload: {
          ...(metadata ?? {}),
        },
      });
      publishPostMessage(eventTypes.CLOSE_MERCHANT_SUBSCRIPTION, {});
    }
  };

  // For faster loading we take merchant data from parent
  useEffect(() => {
    window.addEventListener("message", handleMerchantData);

    return () => {
      window.removeEventListener("message", handleMerchantData);
    };
  }, []);

  // If we don't get data or if third party cookies are blocked
  // Fallback is merchant-apps API call data
  useEffect(() => {
    if (merchantData) {
      dispatch({ type: MerchantUIConfigActionType.SET_MERCHANT_ATTRIBUTES, payload: merchantData });
    }
  }, [merchantData, error]);

  const setFallbackMerchantData = (data: any) => {
    dispatch({
      type: MerchantUIConfigActionType.SET_MERCHANT_ATTRIBUTES,
      payload: {
        attributes: data?.attributes,
        ui_config: data?.ui_config,
      },
    });
  };

  const actions = {
    setFallbackMerchantData,
  };

  return (
    <MerchantContext.Provider
      value={{
        state: state,
        actions,
      }}>
      {children}
    </MerchantContext.Provider>
  );
};

export const useMerchantUiConfig = () => {
  const context = useContext(MerchantContext);
  return context;
};
