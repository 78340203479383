import React from "react";
import MethodCard from "components/payments/methods/MethodCard";
import { paymentMethodsConfig } from "lib/utils/payments";
import { isMobile } from "lib/utils/helpers";
import { PaymentModeType } from "lib/types/payments";

type PaymentMethod = {
  mode: keyof typeof paymentMethodsConfig;
};

interface MethodsContainerProps {
  renderMethods: PaymentMethod[];
}

const HIDDEN_MODES: PaymentModeType[] = ["UPI_COLLECT", "SIMPL_PL", "SNPM"];

const MethodsContainer: React.FC<MethodsContainerProps> = ({ renderMethods }) => {
  if (!renderMethods.length) return null;

  const shouldShowUPIMethod = (method: PaymentMethod, methods: PaymentMethod[]) => {
    const hasUPIIntent = methods.some(m => m.mode === "UPI_INTENT");
    const hasUPI = methods.some(m => m.mode === "UPI");
    const isMobileDevice = isMobile();

    if (method.mode === "UPI" && hasUPIIntent && isMobileDevice) return false;
    if (method.mode === "UPI_INTENT" && hasUPI && !isMobileDevice) return false;
    return true;
  };

  const filteredMethods = renderMethods.filter(method => 
    method.mode in paymentMethodsConfig &&
    !HIDDEN_MODES.includes(method.mode) &&
    shouldShowUPIMethod(method, renderMethods)
  );

  return (
    <>
      {filteredMethods.map((method, index) => (
        <div
          className="overflow-hidden rounded-xl border-[2px] border-[#f0f0f0]"
          key={`${method.mode}-${index}`}
          id={`flo__payments__${method.mode}`}
        >
          <MethodCard modeData={method} customClass="bg-white" />
        </div>
      ))}
    </>
  );
};

export default MethodsContainer;
