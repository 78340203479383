import { ErrorType } from "lib/types/address";
import { AlertCircle } from "react-feather";
import React from "react";
import CheckCircle from "assests/images/check-circle.svg";
import { classNames } from "lib/utils/helpers";

interface InputFieldProps {
  type: string;
  label?: string;
  placeholder?: string;
  id: string;
  name: string;
  autoComplete?: string;
  value?: string;
  required?: boolean;
  maxLength?: number;
  onChange?: (value: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  iconComponent?: React.ReactNode;
  autoCapitalize?: "on" | "off";
  customClass?: string;
  error?: ErrorType;
  disabled?: boolean;
  tabIndex?: number;
  filled?: boolean;
  autoFocus?: boolean;
  inputMode?: "text" | "numeric" | "email" | "tel";
  height?: string;
  width?: string;
  showLabel?: boolean;
  placeholderPadding?: string;
  showSuccessIcon?: boolean;
  labelClassName?: string;
  disabletheme?: boolean;
  startIcon?: React.ReactNode;
}

const InputField: React.FC<InputFieldProps> = ({
  type,
  label,
  placeholder,
  id,
  name,
  autoComplete,
  value = "",
  required = false,
  maxLength = 200,
  onChange,
  iconComponent,
  autoCapitalize = "off",
  customClass,
  error = { status: false, message: "", showAlert: true },
  disabled = false,
  tabIndex = 0,
  filled = false,
  autoFocus = false,
  inputMode = "text",
  height = "h-14",
  width = "w-full",
  showLabel = true,
  placeholderPadding = "",
  showSuccessIcon = false,
  labelClassName,
  onBlur,
  onFocus,
  onKeyDown,
  disabletheme = false,
  startIcon,
}) => {
  return (
    <div id={`flo-field-${id}`} className={`relative flex w-full flex-col`}>
      {showLabel && (
        <label
          htmlFor={id}
          className={classNames(
            required ? "required-field" : "",
            "mb-0.5  text-xxs uppercase text-gray-dark",
            labelClassName ? labelClassName : "",
          )}>
          {label}
        </label>
      )}

      <div className="relative">
        {startIcon && <div className="absolute left-3 top-1/2 -translate-y-1/2">{startIcon}</div>}
        <input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          autoFocus={autoFocus}
          className={classNames(
            `peer ${height} ${width} transition-inputLabel appearance-none rounded-xl border font-normal text-charcoal placeholder:tracking-normal placeholder-shown:pt-0 focus:border-[1px] focus:outline-none focus:ring-[2px]`,
            startIcon ? "pl-6" : "px-3",
            disabletheme
              ? "focus:right-black focus:border-black focus:ring-0"
              : "focus:border-primary-dark focus:ring-primary-light",
            error.status ? " border-ouch" : " border-gray-light",
            customClass ?? "",
            Boolean(value?.length) && !error?.status ? "border-[#D5E7DB]" : "",
            disabled ? "border !border-gray-light bg-gray-lighter text-gray-dark" : "",
          )}
          autoComplete={autoComplete}
          aria-label={placeholder}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          value={value}
          autoCapitalize={autoCapitalize}
          disabled={disabled}
          tabIndex={tabIndex}
          inputMode={inputMode}
          data-gramm="false"
          data-gramm_editor="false"
          data-enable-grammarly="false"
        />
        {showSuccessIcon && (
          <div className="absolute right-3 top-5">
            <img src={CheckCircle} className="h-4 w-4" />
          </div>
        )}
      </div>
      {error?.status && error.message && (
        <div className="mt-1 flex items-center gap-1 ">
          <AlertCircle className="-mt-[1px] h-3 w-3 shrink-0 text-ouch" />
          <label
            htmlFor={id}
            className="bg-transparent text-xs font-normal text-ouch transition-all peer-focus:hidden">
            {error?.message}
          </label>
        </div>
      )}
      {iconComponent}
    </div>
  );
};

export default InputField;
