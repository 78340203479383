import phonepeLogo from "assests/images/phonepe.png";
import gpayLogo from "assests/images/gpay.png";
import paytmLogo from "assests/images/paytm.png";
import upiOthersLogo from "assests/images/upi-other.png";
import credLogo from "assests/images/payment-methods/cred.png";
import i18n from "assests/locales/i18n";

export const apps = [
  {
    logo: phonepeLogo,
    name: i18n.t("phone_pe"),
    id: "phonepe",
  },
  {
    logo: gpayLogo,
    name: i18n.t("gpay"),
    id: "gpay",
  },
  {
    logo: paytmLogo,
    name: i18n.t("paytm"),
    id: "paytm",
  },
  {
    logo: credLogo,
    name: i18n.t("cred"),
    id: "cred",
  },
  {
    logo: upiOthersLogo,
    name: i18n.t("upi_others"),
    id: "others",
  },
  ,
];
