import FloWallet from "components/payments/methods/FloWallet";
import LoyaltyPoints from "components/payments/methods/LoyaltyPoints";
import useRewards from "lib/hooks/useRewards";
import { RewardsMode } from "lib/types/rewards";
import { AnimateExit } from "components/animation/AnimateExit";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

export interface RewardsProps {
  context?: "cart" | "checkout";
}

const Rewards = ({ context = "checkout" }: RewardsProps) => {
  const {
    state: { isC2P, checkoutLoading },
  } = useCheckoutContext();

  const {
    state: { mode, isTileLoading },
  } = useRewards();

  const renderRewardsComponent = () => {
    switch (mode) {
      case RewardsMode.LOYALTY:
        return <LoyaltyPoints context={context} />;
      case RewardsMode.REWARDS_WALLET:
        return <FloWallet context={context} />;
      default:
        return <></>;
    }
  };

  return (
    <AnimateExit
      isChildVisible={mode !== RewardsMode.NONE && !isC2P}
      loading={isTileLoading || checkoutLoading}>
      <AnimateExit.Content
        motionProps={{
          className: mode === RewardsMode.NONE ? "!mt-0" : "",
        }}>
        {renderRewardsComponent()}
      </AnimateExit.Content>
    </AnimateExit>
  );
};

export default Rewards;
