import React from "react";

const UpsellSkeleton: React.FC = () => (
  <div className="border-base px-3 py-4 bg-white space-y-2">
    <div className="skeleton-loader h-[16px] rounded-md w-2/5"></div>
    <div className="skeleton-loader h-[14px] rounded-md w-4/5"></div>
    <div className="flex flex-row gap-2 mt-2">
      <div className="border-base rounded-md h-[100px] w-[100px] px-3 py-4 space-y-2">
        <div className="skeleton-loader h-[16px] rounded-md w-2/5"></div>
        <div className="skeleton-loader h-[14px] rounded-md w-full"></div>
      </div>
      <div className="border-base rounded-md h-[100px] w-[100px] px-2 py-3 space-y-2">
        <div className="skeleton-loader h-[16px] rounded-md w-2/5"></div>
        <div className="skeleton-loader h-[14px] rounded-md w-full"></div>
      </div>
    </div>
  </div>
);

export default UpsellSkeleton;
