import { useMerchantContext } from "lib/contexts/MerchantProvider";
import React, { useCallback, useMemo, useState } from "react";
import { Edit, FileText } from "react-feather";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import BillingAddressForm from "components/address/dialogs/BillingAddressForm";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import { useLocale } from "lib/hooks/useLocale";
import { useUserContext } from "lib/contexts/UserProvider";
import { getUserAddressById } from "lib/utils/address";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface BillingAddressProps {}

const BillingAddress: React.FC<BillingAddressProps> = React.memo(({}) => {
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: { isC2P },
  } = useCheckoutContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const { t } = useLocale();
  const {
    state: { user },
  } = useUserContext();

  const [openBillingAddrForm, setOpenBillingAddrForm] = useState<boolean>(false);

  const billingAddress = useMemo(() => {
    const defaultBillingAddressId = user?.default_billing_address?.id ?? "";
    return getUserAddressById(defaultBillingAddressId, user?.addresses);
  }, [user]);

  const handleBillingAddressBtnClick = () => {
    if (isC2P) return;
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_BILLING_ADDRESS_CHANGE_CLICKED,
      eventType: "click",
    });
    setOpenBillingAddrForm(true);
  };

  const getBillingTileSummary = useCallback(() => {
    const defaultShippingAddressId = user?.default_shipping_address?.id;
    const defaultBillingAddressId = user?.default_billing_address?.id;

    if (!defaultBillingAddressId) return <></>;
    if (!Boolean(billingAddress?.gstin) || !Boolean(billingAddress?.gst_business_name)) return <></>;

    const isBillingCopyOfShipping: boolean = defaultShippingAddressId === defaultBillingAddressId;
    return (
      <>
        <div className="flex w-full items-baseline justify-start rounded-md bg-gray-lighter px-1.5 py-2 text-left text-xs font-normal text-coal-light">
          <p className="truncate">
            {isBillingCopyOfShipping
              ? t("same_as_delivery_address")
              : `${user?.default_billing_address?.address}`}
          </p>
          <span className="px-1">·</span>
          <p className="grow whitespace-nowrap">{`GST: ${billingAddress?.gstin}`}</p>
        </div>
      </>
    );
  }, [user, billingAddress, t]);

  if (!Boolean(merchant?.addressConfig?.isBillingAddressEnabled)) return <></>;

  if (Boolean(isC2P) && (!Boolean(billingAddress?.gstin) || !Boolean(billingAddress?.gst_business_name)))
    return <></>;

  return (
    <>
      <div
        className="border-base flex cursor-pointer flex-col bg-white mt-3"
        onClick={handleBillingAddressBtnClick}>
        <div className={`flex w-full flex-col items-center gap-3 p-3`}>
          <div className="flex w-full flex-row items-center">
            <div className="flex w-full items-center justify-between space-x-2">
              <div className="flex space-x-2">
                <FileText className="h-4 w-4 text-coal-dark outline-none" strokeWidth={2.5} />
                <h2 className="text-sm font-medium text-coal-dark">
                  {t("billing_gst")}{" "}
                  <span className="font-normal text-gray-dark">{`(${t("optional_text")})`}</span>
                </h2>
              </div>
            </div>

            {!isC2P && <Edit className="h-4 w-4 cursor-pointer text-coal-dark" />}
          </div>

          {getBillingTileSummary()}
        </div>
      </div>

      <GenericDialog
        isOpen={openBillingAddrForm}
        setIsOpen={setOpenBillingAddrForm}
        translateAxis="y"
        dialogOverlay={true}
        customClass="overflow-scroll md:!top-auto md:absolute h-[95%] md:h-[81vh] md:top-[4vh]"
        modalType="BILLING_ADDRESS_NEW">
        <BillingAddressForm setIsOpen={() => setOpenBillingAddrForm(false)} />
      </GenericDialog>
    </>
  );
});

export default BillingAddress;
