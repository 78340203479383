interface TagProps {
  children: React.ReactNode;
  className?: string;
}

const Tag = ({ children, className }: TagProps) => {
  return (
    <div
      className={`${className} inline-flex w-fit items-center rounded-lg bg-gray-light p-1 text-xs font-medium text-coal-dark transition-colors`}>
      {children}
    </div>
  );
};

export default Tag;
